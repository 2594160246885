import { Box, Grid, Stack, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { PaidService } from "interfaces/space"
import { useTranslate } from "@pankod/refine-core"
import Icon from "components/Icon"
import ShowMoreButton from "components/ShowMoreButton"
import CheckIcon from '../../../src/icons/check.svg'
import SpacePackagePaidServicesModal from "./SpacePackagePaidServicesModal"

interface SpacePackageCardPaidServicesProps {
    paidServices: PaidService[]
    isSM: boolean
}

const SpacePackageCardPaidServices: React.FC<SpacePackageCardPaidServicesProps> = (props) => {
    const { paidServices, isSM } = props
    const paidServiceVariant = isSM ? 'bodyMedium' : 'bodyLarge'
    const paidServiceIconSize = isSM ? 25 : 30
    const limit = 8
    const [canShowAll, setCanShowAll] = useState(paidServices.length <= limit)
    const filteredPaidServices = canShowAll ? [...paidServices] : paidServices.slice(0, limit);
    const t = useTranslate();
	const showMoreText = t('previewDetails.showMoreText');
    const [openPaidServicesModal, setOpenPaidServicesModal] = useState<boolean>(false);

    const onShowAll = useCallback(() => {
        setOpenPaidServicesModal(true)
    }, []);

    return <Stack spacing={'16px'}>
        <Grid container>
            {filteredPaidServices.map((item, index) => {
                return <Grid item xs={6} key={`paid-service-${index}`}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '8px', marginBottom: '8px'}}>
                    <Icon
                        iconWidth={paidServiceIconSize}
                        iconHeight={paidServiceIconSize}
                        iconSrc={CheckIcon}
                    />
                    <Typography variant={paidServiceVariant} sx={{letterSpacing: '0.25px'}}>{item.name}</Typography>
                </Box>
            </Grid>
            })}
        </Grid>
        {
            !canShowAll && <> 
                <ShowMoreButton buttonLabel={showMoreText} setShowAll={setOpenPaidServicesModal}/>
                <SpacePackagePaidServicesModal
                    openModal={openPaidServicesModal} 
                    setOpenModal={setOpenPaidServicesModal}
                    paidServices={paidServices}
                    isSM={isSM}
                />
            </>
        }
    </Stack> 
}

export default SpacePackageCardPaidServices