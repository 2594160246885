import { EReservationStatus } from "enums"
import { Circle, CircleContainer, Text } from "./components"
import { useTranslate } from "@pankod/refine-core";

interface ReservationStatusProps {
    reservationStatus: EReservationStatus,
    sx?: any
}

const ReservationStatus: React.FC<ReservationStatusProps> = (props) => {
    const { reservationStatus, sx } = props;
    const t = useTranslate();

    let reservationStatusName = '';
    let reservationStatusColor = '';
    switch(reservationStatus) {
        case EReservationStatus.PENDING:
            reservationStatusName = t('reservation.reservationStatusPending');
            reservationStatusColor = '#FD9F2C';
        break;

        case EReservationStatus.CONFIRMED: 
        case EReservationStatus.PAYMENTS_IN_PROCESS: 
            reservationStatusName = t('reservation.reservationStatusActive');
            reservationStatusColor = '#684DAC';
        break;

        case EReservationStatus.PAID:
            reservationStatusName = t('reservation.reservationStatusPaid');
            reservationStatusColor = '#67BE23';
        break;

        case EReservationStatus.CANCELLED:
            reservationStatusName = t('reservation.reservationStatusCancelled');
            reservationStatusColor = '#BA1B1B';
        break;

        case EReservationStatus.EXPIRED:
            reservationStatusName = t('reservation.reservationStatusExpired');
            reservationStatusColor = '#DD3730';
        break;
    }

    return <CircleContainer>
        <Circle sx={{backgroundColor: reservationStatusColor}}></Circle>
        <Text sx={{...sx}}>{reservationStatusName}</Text>
    </CircleContainer>
}

export default ReservationStatus;