import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import RoundedButton from "components/RoundedButton"

interface AdFixedButtonProps {
    onGoToCheckAvailability: () => void
}

const AdFixedButton: React.FC<AdFixedButtonProps> = (props) => {
    const { onGoToCheckAvailability } = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const textVariant = isSM ? 'titleSmall' : 'titleMedium'
    const t = useTranslate();
    const seeAvailability = t('previewDetails.seeAvailability')

    return <Box sx={{
        position: 'fixed',
        bottom: {xs: '16px', md: '24px'},
        zIndex: 999,
        width: '100%',
        textAlign: 'center'
    }}>
        <RoundedButton
            variant='contained'
            sx={{
                width: {xs: '177px', md: '198px'},
                height: {xs: '52px', md: '56px'},
                boxShadow: '0px 2px 6px 2px #00000026'
            }}
            onClick={onGoToCheckAvailability}
        >
            <Typography variant={textVariant}>{seeAvailability}</Typography>
        </RoundedButton>
    </Box>
}

export default AdFixedButton