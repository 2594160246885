import React from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {StorageReference} from "firebase/storage";
import useFileUpload from "../hooks/useFileUpload";
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from "@mui/material/CircularProgress";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

export interface CoverImageUploadProps {
    children?: React.ReactNode
    title: string
    downloadUrl: string | null
    uploadedRef: StorageReference | null
    onUrlChange: (url: string | null) => void
}

const allowedFormats = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/webp'
]

const PictureUpload: React.FC<CoverImageUploadProps> = (props) => {

    const {downloadUrl, uploadedRef} = props

    const theme = useTheme();

    const {
        uploadFile,
        reset,
        isUploading,
        isComplete,
        progress,
        error
    } = useFileUpload()

    const fileSelectionHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files
        if(fileList != null) {
            const currentFile = fileList[0];
            if (currentFile != null && uploadedRef !== null) {
                const fileType = currentFile.type;
                if(allowedFormats.includes(fileType)) {
                    await uploadFile(currentFile, uploadedRef!, (url: string) => {
                        props.onUrlChange(url)
                    })
                }
            }
        }
    }

    const clearClickHandler = async (event: React.MouseEvent<any>) => {
        event.preventDefault()
        reset()
        props.onUrlChange(null)
    }

    return <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        {downloadUrl !== null ?
            <Box sx={{
                position: 'relative',
            }} component='label'>
                <img alt="complex" src={downloadUrl!} width={300} height={300} style={{borderRadius: '8px'}}/>
                <input type="file" accept={allowedFormats.join(', ')} onChange={fileSelectionHandler} hidden/>
                <CancelIcon onClick={clearClickHandler} sx={{
                    position: 'absolute',
                    top: 15,
                    right: 5
                }}/>
            </Box> :
            <Box>
                {isUploading && <Box sx={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '300px',
                    height: '300px'
                }} component='div'>
                    <CircularProgress variant="determinate" value={progress}/>
                </Box>}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 1,
                    borderStyle: 'dashed',
                    borderRadius: "24px",
                    width: '300px',
                    height: '300px',
                    borderColor: theme.palette.neutral60.main,
                }} component='label'>
                    <input type="file" accept={allowedFormats.join(', ')} onChange={fileSelectionHandler} hidden/>
                    {!isUploading && <Box
                        sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <AddPhotoAlternateIcon sx={{width: '50px', height: '50px', color:'neutral60.main'}}/>
                        <Typography sx={{
                            mt: 2,
                            textAlign: 'center',
                            width: '208px'
                        }} variant='labelMedium' color='neutral60.main'>{props.title}</Typography>
                    </Box>}
                </Box>
            </Box>
        }
    </Box>
}

export default PictureUpload