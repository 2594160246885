import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import {styled} from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { useNavigation, useTranslate } from "@pankod/refine-core";
import { SpaceDetail } from "interfaces/space";
import paths from "constants/paths";

export interface SpaceDetailsAppBarProps {
    spaceDetail: SpaceDetail
}

const SpaceDetailsAppBar: React.FC<SpaceDetailsAppBarProps> = (props) => {
    const {spaceDetail} = props
    const t = useTranslate();
    const { push } = useNavigation();

    const StyledToolbar = styled(Toolbar)(({theme}) => ({
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    }));

    const onBack = () => {
        push( paths.adDetails + "/" + spaceDetail.id);
    }

    return <AppBar elevation={0} position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <StyledToolbar>
            <Box sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: "space-between"
            }}>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={onBack}>
                    <IconButton sx={{width: '36px', height: '36px'}}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <Typography
                        variant="titleSmall"
                        noWrap
                        component="div"
                        sx={{ml: 1, display: {xs: 'none', sm: 'none', md: 'block'}}}>
                        {t("updateSpace.goToPreview")}
                    </Typography>
                </Box>
                <Typography
                    variant="headlineSmall"
                    noWrap
                    component="div"
                    sx={{ml: 1, mt: 1, maxWidth: '85%'}}>
                    {spaceDetail.name}
                </Typography>
            </Box>
        </StyledToolbar>
    </AppBar>
}

export default SpaceDetailsAppBar