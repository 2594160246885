import { useTranslate } from "@pankod/refine-core";
import { Box } from "@pankod/refine-mui";
import { Subtitle, Title, Text } from "./components";

interface HeaderProps {
    reserationId: string,
    name: string,
}

const Header:React.FC<HeaderProps> = (props) => {
    const { reserationId, name } = props;
    const t = useTranslate();
    
    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', padding: '4px 4px 16px 4px', borderBottom: '2px solid', borderBottomColor: 'primary.main'}}>
        <Title>{t('reservation.details')}</Title>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
            <Subtitle>{t('reservation.eventId')}:</Subtitle>
            <Text>{reserationId}</Text>
        </Box>
        <Text>{name}</Text>
    </Box>
}

export default Header;