import { useMediaQuery, useTheme } from "@mui/material"
import { LazyLoadImage } from "react-lazy-load-image-component"

interface AdPictureProps {
    pictureUrl: string
    index: number
    showPicture: (index: number) => void
}

const AdPicture: React.FC<AdPictureProps> = (props) => {
    const { pictureUrl, index, showPicture } = props
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('sm'));
    
    const pictureHeight = isXS ? '68px' : '222px'
    const pictureSpace = isXS ? '4px' : '12px'

    return <LazyLoadImage
        src={pictureUrl}
        alt={"Ad picture"}
        style={{
            width: 'calc(50% - '+pictureSpace+')', 
            height: pictureHeight, 
            borderRadius: '16px',
            cursor: 'pointer'
        }}
        onClick={() => { showPicture(index) }}
    />
}

export default AdPicture