import React from "react";
import {OverridableStringUnion} from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";
import { TypographyPropsVariantOverrides } from "@mui/material/Typography/Typography";
import { CatalogEntry } from "interfaces/catalogEntry";
import { GroupPackage } from "interfaces/space";
import CollapsableText from "./CollapsableText";

interface SpaceAmenitiesCollapsableProps {
    spaceAmenities: CatalogEntry[] | string[],
    selectedPackage?: GroupPackage,
    textVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
}

const SpaceAmenitiesCollapsable: React.FC<SpaceAmenitiesCollapsableProps> = (props) => {
    const {spaceAmenities, selectedPackage, textVariant} = props;
    
    const amenitiesArray = selectedPackage === undefined 
        ?   spaceAmenities as CatalogEntry[] 
        :   selectedPackage.amenities as CatalogEntry[];

    let amenities = "";
    amenitiesArray.forEach((entry: CatalogEntry, index: number) => {
        amenities = amenities.concat(`${entry.value}`);
        amenities += index === amenitiesArray.length - 1 ? '' : ', ';
    });
    return <CollapsableText text={amenities} textVariant={textVariant ? textVariant : 'bodySmall'} threshold={150}/>
}

export default SpaceAmenitiesCollapsable