import { Exception } from "exception/Exception";

export enum SyncExceptionAttribute {
    Title,
    Content
}

export class SyncException extends Exception {

    title?: string
    content: string

    constructor(content: string, title?: string) {
        super(title ? title : '', content);
        this.title = title
        this.content = content
    }
}