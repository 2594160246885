import SpacePeopleSlider from "./SpacePeopleSlider";
import { Stack } from "@pankod/refine-mui";
import { Group, GroupRange, PriceTypes, SpaceDetail, WorkingTime } from "interfaces/space";
import { CatalogEntry } from "interfaces/catalogEntry";
import { useState } from "react";
import { getSelectedGroupRangeIndex, getSelectedWorkingTime } from "utils";
import SpaceEventTypes from "./SpaceEventTypes";
import SpaceWorkingDaysCalendar from "./SpaceWorkingDaysCalendar";
import SpaceEventHours from "./SpaceEventHours";
import SpaceTimes from "./SpaceTimes";
import SpacePeopleRanges from "./SpacePeopleRanges";

const initWorkingTime = (group: Group, date: Date | null) => {
    return getSelectedWorkingTime(group, date)
}

const initRangeIndex = (ranges: GroupRange[], people: number) => {
    return getSelectedGroupRangeIndex(ranges, people)
}

interface SpaceSettingsProps {
    space: SpaceDetail,
    selectedPeople: number,
    selectPeople: (people: number) => void,
    selectedDate: Date | null
    setSelectedDate: (date: Date | null) => void
    selectedTime: string | null
    setSelectedTime: (time: string | null) => void,
    selectedGroup: Group
    selectedEventType: CatalogEntry | null
    setSelectedEventType: (value: CatalogEntry) => void
    setSelectedGroup: (value: Group) => void
    selectedEventHours: number | null
    setSelectedEventHours: (eventHours: number | null) => void
    selectedRange: GroupRange | null
    setSelectedRange: (eventHours: GroupRange | null) => void
}

const SpaceSettings: React.FC<SpaceSettingsProps> = (props) => {
    const { 
        space, 
        selectedPeople, 
        selectPeople, 
        selectedDate,
        setSelectedDate,
        selectedTime,
        setSelectedTime,
        selectedGroup,
        selectedEventType,
        setSelectedEventType,
        setSelectedGroup,
        selectedEventHours,
        setSelectedEventHours,
        selectedRange,
        setSelectedRange
    } = props
    const isPriceByHour = selectedGroup && selectedGroup.priceType === PriceTypes.ByHour ? true : false

    const [availableTimes, setAvailableTimes] = useState<string[]>([])
    const [selectedWorkingTime, setSelectedWorkingTime] = useState<WorkingTime | null>(() => initWorkingTime(selectedGroup, selectedDate))
    const [selectedRangeIndex, setSelectedRangeIndex] = useState<number | null>(() => initRangeIndex(selectedWorkingTime ? selectedWorkingTime.ranges : [], selectedPeople))

    return <Stack spacing={4} sx={{
        width: '100%', 
        maxWidth: '656px',
        margin: '0 auto'
    }}>
        <SpaceEventTypes 
            groups={space.groups}
            selectedEventType={selectedEventType}
            setSelectedEventType={setSelectedEventType}
            setSelectedGroup={setSelectedGroup}
            setSelectedDate={setSelectedDate}
            setSelectedEventHours={setSelectedEventHours}
            setSelectedTime={setSelectedTime}
            setSelectedRange={setSelectedRange}
            setSelectedRangeIndex={setSelectedRangeIndex}
            setAvailableTimes={setAvailableTimes}
            setSelectedWorkingTime={setSelectedWorkingTime}
            selectPeople={selectPeople}
        />
        <SpaceWorkingDaysCalendar
            adDetail={space}
            selectedGroup={selectedGroup}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setAvailableTimes={setAvailableTimes}
            setSelectedWorkingTime={setSelectedWorkingTime}
            setSelectedEventHours={setSelectedEventHours}
            setSelectedTime={setSelectedTime}
            setSelectedRange={setSelectedRange}
            setSelectedRangeIndex={setSelectedRangeIndex}
            selectPeople={selectPeople}
        />
        {
            isPriceByHour && <SpaceEventHours
                eventMaxHours={selectedWorkingTime ? selectedWorkingTime.maxHours : 0}
                selectedEventHours={selectedEventHours}
                setSelectedEventHours={setSelectedEventHours}
            />
        }
        <SpaceTimes
            times={availableTimes}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            eventMaxHours={selectedWorkingTime ? selectedWorkingTime.maxHours : 0}
            isPriceByHour={isPriceByHour}
            selectedEventHours={selectedEventHours}
        />
        <SpacePeopleRanges 
            ranges={selectedWorkingTime ? selectedWorkingTime.ranges : []}
            selectedRangeIndex={selectedRangeIndex}
            setSelectedRangeIndex={setSelectedRangeIndex}
            setSelectedRange={setSelectedRange}
            selectPeople={selectPeople}
        />
        <SpacePeopleSlider
            min={selectedRange ? selectedRange.occupancy.minimum! : 0}
            max={selectedRange ? selectedRange.occupancy.maximum! : 0}
            selectedPeople={selectedPeople}
            selectPeople={selectPeople}
        />
    </Stack>
}

export default SpaceSettings;
