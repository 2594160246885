import { Box } from "@mui/material"
import verificationStampIconSrc from '../../icons/verification-stamp.svg'
import guaranteeStampIconSrc from '../../icons/guarantee-stamp.svg'
import { useTranslate } from "@pankod/refine-core"
import AdStamp from "./AdStamp"

interface AdStampsProps {
    verificationStamp?: boolean
    guaranteeStamp?: boolean
}

const AdStamps: React.FC<AdStampsProps> = (props) => {
    const { verificationStamp, guaranteeStamp } = props;
    const t = useTranslate()
    const verificationStampDescription = t('previewDetails.verificationStampDescription')
    const guaranteeStampDescription = t('previewDetails.guaranteeStampDescription')

    if(verificationStamp === undefined && guaranteeStamp === undefined) {
        return <></>
    }

    return <Box sx={{
        display: 'flex',
        gap: '8px'
    }}>
        { 
            verificationStamp && <AdStamp iconSrc={verificationStampIconSrc} title={verificationStampDescription}/> 
        }
        { 
            guaranteeStamp && <AdStamp iconSrc={guaranteeStampIconSrc} title={guaranteeStampDescription}/>
        }
    </Box>
}

export default AdStamps;