import defaultIcon from '../icons/event-types/default.svg'
import weddingAnniversaryIcon from '../icons/event-types/wedding-anniversary.svg'
import babyShowerIcon from '../icons/event-types/baby-shower.svg'
import artIcon from '../icons/event-types/art.svg'
import christeningIcon from '../icons/event-types/christening.svg'
import brunchIcon from '../icons/event-types/brunch.svg'
import weddingIcon from '../icons/event-types/wedding.svg'
import danceIcon from '../icons/event-types/dance.svg'
import boyScoutIcon from '../icons/event-types/boy-scout.svg'
import trainingIcon from '../icons/event-types/training.svg'
import yogaClassesIcon from '../icons/event-types/yoga-classes.svg'
import campIcon from '../icons/event-types/camp.svg'
import courseIcon from '../icons/event-types/course.svg'
import coWorkingIcon from '../icons/event-types/co-working.svg'
import corporateIcon from '../icons/event-types/corporate.svg'
import birthdayIcon from '../icons/event-types/birthday.svg'
import contestIcon from '../icons/event-types/contest.svg'
import sportActivitiesIcon from '../icons/event-types/sport-activities.svg'
import religiousCeremonyIcon from '../icons/event-types/religious-ceremony.svg'
import concertIcon from '../icons/event-types/concert.svg'
import roastedCarnitaIcon from '../icons/event-types/roasted-carnita.svg'
import cocktailIcon from '../icons/event-types/cocktail.svg'
import conventionIcon from '../icons/event-types/convention.svg'
import singlePartyIcon from '../icons/event-types/single-party.svg'
import bachelorettePartyIcon from '../icons/event-types/bachelorette-party.svg'
import breakfastIcon from '../icons/event-types/breakfast.svg'
import fashionShowIcon from '../icons/event-types/fashion-show.svg'
import petTrainingIcon from '../icons/event-types/pet-training.svg'
import expoIcon from '../icons/event-types/expo.svg'
import bonfireIcon from '../icons/event-types/bonfire.svg'
import partyIcon from '../icons/event-types/party.svg'
import childrensPartyIcon from '../icons/event-types/childrens-party.svg'
import glampingIcon from '../icons/event-types/glamping.svg'
import graduationIcon from '../icons/event-types/graduation.svg'
import genderRevealIcon from '../icons/event-types/gender-reveal.svg'
import jaripeoIcon from '../icons/event-types/jaripeo.svg'
import kermesIcon from '../icons/event-types/kermes.svg'
import productLaunchIcon from '../icons/event-types/product-launch.svg'
import businessMeetingIcon from '../icons/event-types/business-meeting.svg'
import bohemianNightIcon from '../icons/event-types/bohemian-night.svg'
import productionsIcon from '../icons/event-types/productions.svg'
import proposalIcon from '../icons/event-types/proposal.svg'
import firstCommunionIcon from '../icons/event-types/first-communion.svg'
import awardIcon from '../icons/event-types/award.svg'
import poolPartyIcon from '../icons/event-types/pool-party.svg'
import religiousRetreatIcon from '../icons/event-types/religious-retreat.svg'
import familyMeetingIcon from '../icons/event-types/family-meeting.svg'
import photoshootIcon from '../icons/event-types/photoshoot.svg'
import workshopsIcon from '../icons/event-types/workshops.svg'
import lateIcon from '../icons/event-types/late.svg'
import sportTournamentIcon from '../icons/event-types/sport-tournament.svg'
import eveningIcon from '../icons/event-types/evening.svg'
import fifteenYearsIcon from '../icons/event-types/fifteen-years.svg'

export const getEventTypeIcon = (eventTypeId: string): string => {
    if(process.env.NEXT_PUBLIC_REDIRECT_URL === 'https://test.ifiesta.com.mx') {
        return getIconsQA(eventTypeId)
    } else {
        return getIconsPROD(eventTypeId)
    }
}

const getIconsQA = (eventTypeId: string): string => {
    switch (eventTypeId) {
        case '63fcfa584bee558b4804cd0c': return weddingAnniversaryIcon
        case '63fcfce74bee558b4804cd76': return artIcon
        case '63fcfa084bee558b4804ccf1': return christeningIcon
        case '63fcfb714bee558b4804cd42': return babyShowerIcon
        case '63fcfa024bee558b4804ccee': return weddingIcon
        case '63fcf9fe4bee558b4804cceb': return sportActivitiesIcon
        case '63fcfa2c4bee558b4804cd00': return concertIcon
        case '63fcfae24bee558b4804cd12': return cocktailIcon
        case '63fcfbb74bee558b4804cd54': 
        case '63fcfbac4bee558b4804cd51': return singlePartyIcon
        case '63fcfba34bee558b4804cd4e': return bachelorettePartyIcon
        case '6439ee0a18ae0ef2da4c7d88':
        case '63fcfb654bee558b4804cd3f':
        case '63fcfa214bee558b4804ccfd': return partyIcon
        case '63fcfa134bee558b4804ccf7': return childrensPartyIcon
        case '63fcfb794bee558b4804cd45': return genderRevealIcon
        case '63fcfbf64bee558b4804cd66': return kermesIcon
        case '63fcfbd54bee558b4804cd5d': return productLaunchIcon
        case '640566d04bee558b4804d066': return businessMeetingIcon
        case '63fcfaea4bee558b4804cd15': return bohemianNightIcon
        case '63fcfa614bee558b4804cd0f': return firstCommunionIcon
        case '63fcfb1f4bee558b4804cd27': return awardIcon
        case '63fcfb034bee558b4804cd1e': return religiousRetreatIcon
        case '6439f0d818ae0ef2da4c7ddc': return familyMeetingIcon
        case '63fcfb354bee558b4804cd30': return lateIcon
        case '63fcfb244bee558b4804cd2a': return sportTournamentIcon
        case '63fcfafc4bee558b4804cd1b': return eveningIcon
        case '63fcfb4e4bee558b4804cd36': return fifteenYearsIcon
    
        default:
            return defaultIcon
    }
}

const getIconsPROD = (eventTypeId: string): string => {
    switch (eventTypeId) {
        case '6439bff918ae0ef2da4c7a5b': return weddingAnniversaryIcon
        case '6439f02818ae0ef2da4c7dd2': return artIcon
        case '6439eaaf18ae0ef2da4c7d55': return christeningIcon
        case '6439ea5318ae0ef2da4c7d3d': return brunchIcon
        case '6439bff418ae0ef2da4c7a57': return babyShowerIcon
        case '6439c03818ae0ef2da4c7a73': return weddingIcon
        case '6439bfff18ae0ef2da4c7a5f': return danceIcon
        case '64c02a5d2d16470532db2650': return boyScoutIcon
        case '649ce6ea2d16470532daeb16': return trainingIcon
        case '649b1ffc18ae0ef2da4cfab6': return yogaClassesIcon
        case '64949a8518ae0ef2da4ce9d0': return campIcon
        case '648b746418ae0ef2da4cd3d5': return courseIcon
        case '6483ba8b18ae0ef2da4cd07b': return coWorkingIcon
        case '6439c16b18ae0ef2da4c7adf': return corporateIcon
        case '6439c0b418ae0ef2da4c7aa9': 
        case '6439c0c018ae0ef2da4c7ab1': 
        case '6439c0bb18ae0ef2da4c7aad': return birthdayIcon
        case '6439c09b18ae0ef2da4c7a99': return contestIcon
        case '6439edce18ae0ef2da4c7d7c': return sportActivitiesIcon
        case '6439c04818ae0ef2da4c7a7f': return religiousCeremonyIcon
        case '6439c09518ae0ef2da4c7a95': return concertIcon
        case '6439c03e18ae0ef2da4c7a77': return roastedCarnitaIcon
        case '6439c07618ae0ef2da4c7a8d': return cocktailIcon
        case '6439c0a618ae0ef2da4c7aa1': return conventionIcon
        case '6439c13718ae0ef2da4c7ac7': 
        case '6439c13d18ae0ef2da4c7acb': return singlePartyIcon
        case '6439c11918ae0ef2da4c7abb': return bachelorettePartyIcon
        case '64cddea5cb932b8e4ee82556': return breakfastIcon
        case '6439c0c618ae0ef2da4c7ab5': return fashionShowIcon
        case '6494e6b618ae0ef2da4ceb7c': return petTrainingIcon
        case '6439c14318ae0ef2da4c7acf': return expoIcon
        case '6439ee2618ae0ef2da4c7d90': return bonfireIcon
        case '6439ee0a18ae0ef2da4c7d88':
        case '6439eed918ae0ef2da4c7dae':
        case '6439ee1718ae0ef2da4c7d8c': return partyIcon
        case '6439edf118ae0ef2da4c7d82': return childrensPartyIcon
        case '64aee60a2d16470532db0962': return glampingIcon
        case '6439c17718ae0ef2da4c7ae3': return graduationIcon
        case '6439f1bd18ae0ef2da4c7dea': return genderRevealIcon
        case '6439c14818ae0ef2da4c7ad3': return jaripeoIcon
        case '6439ed0e18ae0ef2da4c7d6e': return kermesIcon
        case '6439c15418ae0ef2da4c7adb': return productLaunchIcon
        case '6439efd118ae0ef2da4c7dc2': return businessMeetingIcon
        case '6439ee4718ae0ef2da4c7d94': return bohemianNightIcon
        case '64cdde8ccb932b8e4ee82550': return productionsIcon
        case '648cc7dc18ae0ef2da4cd860': return proposalIcon
        case '6439c14e18ae0ef2da4c7ad7': return firstCommunionIcon
        case '6439ee9018ae0ef2da4c7da4': return awardIcon
        case '6439eacc18ae0ef2da4c7d5d': return poolPartyIcon
        case '64949a7718ae0ef2da4ce9cc':
        case '6439ee5d18ae0ef2da4c7d98': return religiousRetreatIcon
        case '6439f0d818ae0ef2da4c7ddc': return familyMeetingIcon
        case '64948aec18ae0ef2da4ce98c': return photoshootIcon
        case '64ac59af2d16470532db0387': return workshopsIcon
        case '6439ea8c18ae0ef2da4c7d49': return lateIcon
        case '6439eeb918ae0ef2da4c7daa': return sportTournamentIcon
        case '6439ee7418ae0ef2da4c7d9e': return eveningIcon
        case '6439ec4518ae0ef2da4c7d68': return fifteenYearsIcon
    
        default:
            return defaultIcon
    }
}