import { Box } from "@pankod/refine-mui";
import { IBooking } from "interfaces";
import { Subtitle } from "./components";
import { useTranslate } from "@pankod/refine-core";
import PricesPayments from "./pricesPayments";
import PricesDetail from "./pricesDetail";

interface PricesProps {
    reservation: IBooking
}

const Prices: React.FC<PricesProps> = (props) => {
    const { reservation } = props;
    const t = useTranslate();

    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px 0px'}}>
        <Subtitle>{t('reservation.packagesAndServices')}</Subtitle>
        <PricesDetail
            reservation={reservation}
        ></PricesDetail>
    </Box>
}

export default Prices;