import React, { useCallback } from "react";
import {Result} from "typescript-monads";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { useTranslate } from "@pankod/refine-core";
import { Exception } from "exception/Exception";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import CancelationPolicySwitch from "./CancelationPolicySwitch";
import DataAsList from "components/DataAsList";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { spaceDetailConstants } from "constants/spaceDetailConstants";
import { spaceActions } from "store/space";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

interface SpaceCancelationPolicyProps {
    spaceDetail: SpaceDetail
}

export enum CancelationPolicy {
    Flexible = ("Flexible"),
    Moderate = ("Moderate"),
    Strict = ("Strict")
}

interface FormCancelationPolicy {
    cancelationPolicy?: CancelationPolicy
}

export const isCancelationPolicyEmpty = (data: SpaceDetail): boolean => data === undefined || data.cancelationPolicy === undefined

const isCancelationPolicyValid = (data: FormCancelationPolicy): boolean => {
    return data !== undefined && data?.cancelationPolicy !== undefined
}

const spaceDetailToCancelationPolicy = (data: SpaceDetail): FormCancelationPolicy => {
    return {cancelationPolicy: data.cancelationPolicy}
}

const stateHandlers = [StateHandler.Error]

const SpaceCancelationPolicyForm: React.FC<SpaceCancelationPolicyProps> = (props) => {

    const {spaceDetail} = props

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    const t = useTranslate()
    const title = t("updateSpace.spaceCancelTitle")
    const subtitle = t("updateSpace.spaceCancelSubtitle")
    const linkText = t("updateSpace.cps")
    const linkText2 = t("updateSpace.pc2")
    const flexibleText = t("updateSpace.spaceCancelA")
    const flexibleDescription = t("updateSpace.spaceCancelADescription")
    const flexibleExtraDescription = t("updateSpace.spaceCancelADescriptionExtra")

    const moderateText = t("updateSpace.spaceCancelB")
    const moderateDescription = t("updateSpace.spaceCancelBDescription")
    const moderateExtraDescription = t("updateSpace.spaceCancelBDescriptionExtra")

    const strictText = t("updateSpace.spaceCancelC")
    const strictDescription = t("updateSpace.spaceCancelCDescription")
    const strictExtraDescription = t("updateSpace.spaceCancelCDescriptionExtra")


    const getTextById = (id: CancelationPolicy): string => {
        switch (id) {
            case CancelationPolicy.Flexible:
                return flexibleText
            case CancelationPolicy.Moderate:
                return moderateText
            case CancelationPolicy.Strict:
                return strictText
        }
        return ""
    }

    const getErrorMessage = useSyncExceptionError(t)

    const {updateSpace} = useSpaceUpdate()

    const isChecked = (id: CancelationPolicy, data?: FormCancelationPolicy) => {
        return id === data?.cancelationPolicy
    }
    
    const onGoToCancellationPoliciesUrl = useCallback(() => {
        window.open('https://www.ifiesta.com.mx/documents/cancellation_policies');
    }, []);


    const renderEditableForm = (updateFn: (data: FormCancelationPolicy) => void, data?: FormCancelationPolicy) => {

        const onCheckChangedHandler = (id: CancelationPolicy, checked: boolean) => {
            if (checked) {
                const newData: FormCancelationPolicy = {cancelationPolicy: id}
                updateFn(newData)
            } else {
                updateFn({})
            }
        }

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            pr: 4,
            pt: 3
        }}>

            <Box sx={{width: '350px'}}>
                <a target="_blank" rel="noopener noreferrer">
                    <Typography color='primary' variant='labelLarge' onClick={onGoToCancellationPoliciesUrl}>{linkText2}</Typography>
                </a>
            </Box>

            <Box sx={{width: '350px'}}>
                <a target="_blank" rel="noopener noreferrer">
                    <Typography color='primary' variant='labelLarge' onClick={() => {
                        window.open('https://www.ifiesta.com.mx/documents/service_contract');
                    }}>{linkText}</Typography>
                </a>
            </Box>

            <CancelationPolicySwitch
                title={flexibleText}
                description={flexibleDescription}
                showDivider
                checked={isChecked(CancelationPolicy.Flexible, data)}
                id={CancelationPolicy.Flexible}
                onCheckChanged={onCheckChangedHandler}
                extraDescription={flexibleExtraDescription}
            />

            <CancelationPolicySwitch
                title={moderateText}
                description={moderateDescription}
                showDivider
                checked={isChecked(CancelationPolicy.Moderate, data)}
                id={CancelationPolicy.Moderate}
                onCheckChanged={onCheckChangedHandler}
                extraDescription={moderateExtraDescription}
            />
            <CancelationPolicySwitch
                title={strictText}
                description={strictDescription}
                showDivider={false}
                checked={isChecked(CancelationPolicy.Strict, data)}
                id={CancelationPolicy.Strict}
                onCheckChanged={onCheckChangedHandler}
                extraDescription={strictExtraDescription}
            />
        </Box>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        return <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <DataAsList data={[data?.cancelationPolicy ? getTextById(data.cancelationPolicy) : ""]}
                            fieldId={'space-type'}/>
            </Box>
        </>
    }

    const syncDataHandler = (data: FormCancelationPolicy) => {
        const body = {cancelationPolicy: data.cancelationPolicy}
        return updateSpace(spaceDetail.id, body)
    }

    const dispatch = useAppDispatch()
    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    const isFocused = useAppSelector(state => state.space.currentFocusId === spaceDetailConstants.cancellation)

    return <EditableSyncFormV2
        title={title}
        subtitle={subtitle}
        isFocused={isFocused}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        loadData={loadData}
        syncData={syncDataHandler}
        isFormDataValid={isCancelationPolicyValid}
        isServerDataEmpty={isCancelationPolicyEmpty}
        mapServerDataToFormData={spaceDetailToCancelationPolicy}
        getErrorMessage={getErrorMessage}
        onSync={onSyncHandler}
        stateHandlers={stateHandlers}/>
}

export default SpaceCancelationPolicyForm