import { Exception } from "exception/Exception"
import { SyncExceptionAttribute } from "../components/spaces/update/generalInfo/SyncException"
import { MinMaxException } from "exception/MinMaxException"
import { NetworkException } from "exception/NetworkException"


const useSyncExceptionError = (translate: (key: string) => string) => {

    return (exception: Exception, attribute: SyncExceptionAttribute): string => {
        if (exception instanceof MinMaxException) {
            return translate('max_min_error')
        }
        if (exception instanceof NetworkException) {
            switch (attribute) {
                case SyncExceptionAttribute.Title:
                    return ''
                case SyncExceptionAttribute.Content:
                    return translate(exception.messageKey)
            }
        }
        return translate('default_network_error')
    }
}

export default useSyncExceptionError