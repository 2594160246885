import React, {useState} from "react";
import {Result} from "typescript-monads";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddIcon from '@mui/icons-material/Add';
import Grid from "@mui/material/Grid";
import { Group, SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { spaceDetailConstants } from "constants/spaceDetailConstants";
import { useAppDispatch } from "store/hooks";
import useGroups from "hooks/useGroups";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import { useTranslate } from "@pankod/refine-core";
import { Exception } from "exception/Exception";
import { generateSpaceEventTypesEnable } from "utils";
import { spaceActions } from "store/space";
import EditableSyncFormV2 from "components/EditableSyncFormV2";
import GroupConfirmDeleteModal from "../groups/GroupConfirmDeleteModal";
import GroupFormModal from "../groups/GroupFormModal";
import GroupDetailModal from "../groups/GroupDetailModal";
import GroupDetailPreviewCard from "../groups/GroupDetailPreviewCard";
import GroupList from "../groups/GroupList";

interface SpaceGroupsFormProps {
    spaceDetail: SpaceDetail
}

interface FormGroups {
    groups: Group[]
}

export const isGroupsEmpty = (data: SpaceDetail): boolean => data === undefined || data?.groups === undefined  || data?.groups.length === 0

const isGroupsValid = (data: FormGroups): boolean => {
    return data.groups.length > 0
}

const stateHandlers = [StateHandler.Error]

const SpaceGroupsForm: React.FC<SpaceGroupsFormProps> = (props) => {

    const {spaceDetail} = props
    const fieldId = spaceDetailConstants.groups
    const {reloadSpace, deleteGroup, createGroup} = useGroups()
    const dispatch = useAppDispatch()

    const t = useTranslate()
    const title = t("updateSpace.spaceGroupsTitle")
    const subtitle = t("updateSpace.spaceGroupsSubtitle")
    const addGroup = t("updateSpace.spaceGroupsAdd")

    const getErrorMessage = useSyncExceptionError(t)

    const [openGroupModal, setOpenGroupModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [groupToDelete, setGroupToDelete] = useState<Group | null>(null)
    const [currentGroup, setCurrentGroup] = useState<Group | null>(null)
    const [canOpenGroupDetailModal, setCanOpenGroupDetailModal] = useState<boolean>(false)
    const [isFormEditing, setIsFormEditing] = useState<boolean>(false)

    const clearDialog = () => {
        setOpenGroupModal(false)
    }

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    const setFormAsEditingMode = () => {
        setTimeout(() => {
            setIsFormEditing(true)
            setTimeout(() => {
                setIsFormEditing(false)
            }, 2500)
        }, 2500)
    }

    const renderEditableForm = (updateFn: (data: FormGroups, forceSync: boolean) => void, data?: FormGroups) => {
        const spaceEventTypesEnabled = generateSpaceEventTypesEnable(spaceDetail)
        const canCreateGroup = spaceEventTypesEnabled.length > 0 ? true : false
        
        const onShowMoreClickHandler = (group: Group) => {
            setCurrentGroup(group)
            setCanOpenGroupDetailModal(true)
        }
        
        const onEditGroupClickHandler = (group: Group) => {
            setCurrentGroup(group)
            setOpenGroupModal(true)
        }
        
        const onDeleteGroupClickHandler = (group: Group) => {
            setGroupToDelete(group)
            setOpenDeleteModal(true)
        }
        
        const onDuplicateGroupClickHandler = async (group: Group) => {
            const enableEventTypes = generateSpaceEventTypesEnable(spaceDetail)
            const requestBody = {...group, _id: undefined, eventTypes: enableEventTypes}
            const result = await createGroup(spaceDetail.id, requestBody)
            if (result.isOk()) {
                setCurrentGroup(result.unwrap())
                setOpenGroupModal(true)
            }
        }
        
        const onAddGroupHandler = () => {
            setOpenGroupModal(true)
        }

        const newGroupDialogCloseHandler = () => {
            if (currentGroup !== null) {
                setCurrentGroup(null)
            }
            clearDialog()
        }

        const onSaveNewGroupHandler = async (newGroup: Group) => {
            const groups = spaceDetail?.groups ? [...spaceDetail.groups] : []
            const index = groups.findIndex((target) => target._id === newGroup._id)
            if (index === -1) {
                groups.push(newGroup)
            } else {
                groups[index] = newGroup
            }
            updateFn({groups}, true)
        }

        const onDeleteConfirmHandler = async () => {
            setOpenDeleteModal(false)
            const deleteResult = await deleteGroup(spaceDetail.id, groupToDelete?._id!!)
            if (deleteResult.isOk()) {
                const groups = spaceDetail?.groups ? [...spaceDetail.groups] : []
                const index = groups.findIndex((target) => target._id === groupToDelete?._id)
                if (index !== -1) {
                    groups.splice(index, 1)
                }
                updateFn({groups}, true)
                if(groups.length === 0) {
                    setFormAsEditingMode()
                }
            }
        }

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 3
        }}>
            <GroupConfirmDeleteModal
                canOpen={openDeleteModal}
                onClose={() => {
                    setOpenDeleteModal(false)
                    setGroupToDelete(null)
                }}
                onConfirm={onDeleteConfirmHandler}
            />

            <GroupFormModal
                currentGroup={currentGroup}
                onClose={newGroupDialogCloseHandler}
                open={openGroupModal}
                spaceDetail={spaceDetail}
                onSave={onSaveNewGroupHandler}
            />
            
            <GroupDetailModal
                group={currentGroup}
                canOpen={canOpenGroupDetailModal}
                closeModal={() => setCanOpenGroupDetailModal(false)}
                onEditGroupClickHandler={onEditGroupClickHandler}
                onDuplicateGroupClickHandler={onDuplicateGroupClickHandler}
                canCreateGroup={canCreateGroup}
            />
            {
                canCreateGroup && <Button
                    startIcon={<AddIcon/>}
                    variant='text'
                    id="add_group_button"
                    sx={{
                        mt: 1,
                        width: {xs: '200px', md: '200px'}
                    }}
                    onClick={onAddGroupHandler}>
                    <Typography variant='labelLarge'>{addGroup}</Typography>
                </Button>
            }
            {
                spaceDetail?.groups !== undefined && spaceDetail.groups.length > 0 &&
                <Grid container spacing={3} sx={{mt: 1}}>
                    {spaceDetail.groups.map((group) => {
                        return <Grid item key={`group-card-${group._id}`}>
                            <GroupDetailPreviewCard 
                                group={group}
                                canCreateGroup={canCreateGroup}
                                onShowMoreClickHandler={onShowMoreClickHandler}
                                onEditGroupClickHandler={onEditGroupClickHandler}
                                onDeleteGroupClickHandler={onDeleteGroupClickHandler}
                                onDuplicateGroupClickHandler={onDuplicateGroupClickHandler}
                            />
                        </Grid>
                    })}
                </Grid>
            }
        </Box>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        const spaceEventTypesEnabled = generateSpaceEventTypesEnable(spaceDetail)
        const canCreateGroup = spaceEventTypesEnabled.length > 0 ? true : false

        const onShowMoreClickHandler = (group: Group) => {
            setCurrentGroup(group)
            setCanOpenGroupDetailModal(true)
        }
        
        const onEditGroupClickHandler = (group: Group) => {
            setCurrentGroup(group)
            setOpenGroupModal(true)
        }

        const newGroupDialogCloseHandler = () => {
            if (currentGroup !== null) {
                setCurrentGroup(null)
            }
            clearDialog()
        }

        const onDuplicateGroupClickHandler = async (group: Group) => {
            const enableEventTypes = generateSpaceEventTypesEnable(spaceDetail)
            const requestBody = {...group, _id: undefined, eventTypes: enableEventTypes}
            const result = await createGroup(spaceDetail.id, requestBody)
            
            if (result.isOk()) {
                setCurrentGroup(result.unwrap())
                setOpenGroupModal(true)
            }
        }

        const onSaveNewGroupHandler = async (newGroup: Group) => {
            const groups = spaceDetail?.groups ? [...spaceDetail.groups] : []
            const index = groups.findIndex((target) => target._id === newGroup._id)
            if (index === -1) {
                groups.push(newGroup)
            } else {
                groups[index] = newGroup
            }
        }

        return <>
                <GroupFormModal
                currentGroup={currentGroup}
                onClose={newGroupDialogCloseHandler}
                open={openGroupModal}
                spaceDetail={spaceDetail}
                onSave={onSaveNewGroupHandler}
            />
            <GroupDetailModal
                group={currentGroup}
                canOpen={canOpenGroupDetailModal}
                closeModal={() => setCanOpenGroupDetailModal(false)}
                onEditGroupClickHandler={onEditGroupClickHandler}
                onDuplicateGroupClickHandler={onDuplicateGroupClickHandler}
                canCreateGroup={canCreateGroup}
            />
            {
                spaceDetail.groups.length > 0 && <GroupList 
                    groups={spaceDetail.groups}
                    onShowMoreClickHandler={onShowMoreClickHandler}
                />
            }
        </>
    }

    const spaceDetailToGroups = (data: SpaceDetail): FormGroups => {
        return {groups: data.groups}
    }

    const syncDataHandler = async (data: FormGroups) => {
        return reloadSpace(spaceDetail.id)
    }

    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    return <EditableSyncFormV2
        fieldId={fieldId}
        title={title}
        subtitle={subtitle}
        isFocused={false}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        loadData={loadData}
        syncData={syncDataHandler}
        isFormDataValid={isGroupsValid}
        isServerDataEmpty={isGroupsEmpty}
        mapServerDataToFormData={spaceDetailToGroups}
        getErrorMessage={getErrorMessage}
        hideSaveButton
        stateHandlers={stateHandlers}
        onSync={onSyncHandler}
        isFormEditing={isFormEditing}
    />
}

export default SpaceGroupsForm