import React, {useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {OverridableStringUnion} from "@mui/types";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";
import { useTranslate } from "@pankod/refine-core";

interface CollapsableTextProps {
    text: string
    textVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
    threshold?: number
}

const CollapsableText: React.FC<CollapsableTextProps> = (props) => {
    const t = useTranslate();
    const more = t("previewDetails.showMore");
    const less = t("previewDetails.showLess");
    const [collapsed, setCollapsed] = useState(true)

    const {text, threshold, textVariant} = props

    const _textVariant = textVariant ? textVariant : 'bodyLarge'
    const limit = threshold ? threshold : 1000
    const content = collapsed ? text.substring(0, limit) : text

    const onDisplayClickHandler = () => {
        setCollapsed((isCollapsed) => {
            return !isCollapsed
        })
    }

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column'
    }}>
        <Typography variant={_textVariant}>
            {content}
            {
                text.length > limit && <span
                    style={{
                        cursor: 'pointer', 
                        color: '#684DAC'
                    }}
                    onClick={onDisplayClickHandler}>{`   ${collapsed ? more : less}`}
                </span>
            }
        </Typography>
    </Box>
}

export default CollapsableText