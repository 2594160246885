export class Exception extends Error {
    messageKey: string
    message: string

    constructor(messageKey: string, message: string) {
        super(message);
        this.messageKey = messageKey
        this.message = message
    }
}
