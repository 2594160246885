import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface PageTitleProps {
    title: string;
    icon?: React.ReactNode;
    onBack: () => void;
}

const PageTitle: React.FC<PageTitleProps> = (props) => {
    const { title, icon, onBack } = props;
    return <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    }}>
        <IconButton sx={{padding: '4px', color: 'neutralBackground.dark'}} onClick={onBack}>
            {
                icon ? icon : <ArrowBackIcon/>
            }
        </IconButton>
        <Typography variant='titleLarge' sx={{ml: '12px', fontSize: {md: '24px'}, lineHeight: {md: '32px'}}}>{title}</Typography>
    </Box>
}

export default PageTitle;