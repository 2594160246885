import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import { SpaceDetail } from "interfaces/space"
import { useState } from "react"
import AdEventTypeTag from "./AdEventTypeTag"
import { getEventTypeIcon } from "utils/event-types"
import { CatalogEntry } from "interfaces/catalogEntry"
import ShowMoreButton from "components/ShowMoreButton"

interface AdEventTypesProps {
    spaceDetail: SpaceDetail
}

const AdEventTypes: React.FC<AdEventTypesProps> = (props) => {
    const {spaceDetail} = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'
    const t = useTranslate()
    const title = t('previewDetails.spaceTypesTitle')
    const [showAll, setShowAll] = useState(false)

    const limit = 10;
    const eventTypes = [...spaceDetail.eventTypes];
    const selectedEventTypes = eventTypes.length > limit 
        ?   
            showAll ? [...eventTypes] : eventTypes.slice(0, limit) 
        :   [...eventTypes];
    
    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
        <Typography variant={titleVariant}>{title}</Typography>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 4, sm: 2}}>
            {
                selectedEventTypes.map((eventType, index) => {
                    return <Grid item xs={6} sm={'auto'} key={`package-${index}`} >
                        <AdEventTypeTag icon={getEventTypeIcon((eventType as CatalogEntry).id)} label={(eventType as CatalogEntry).value}/>
                    </Grid>
                })
            }
        </Grid>
        {
            eventTypes.length > limit && !showAll && <ShowMoreButton setShowAll={setShowAll}/>
        }
    </Box>
}

export default AdEventTypes