import React from "react";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { SyncError } from "viewModels/EditableSyncViewModel";
import { useTranslate } from "@pankod/refine-core";
import Line, { LineOrientation } from "./Line";

export interface DisplayFormCardProps {
    children?: React.ReactNode
    title: string
    isFocused: boolean
    error: SyncError | null
    onEditClick: () => void
    onRetry: () => void
    showEdit: boolean
}

const DisplayFormCard: React.FC<DisplayFormCardProps> = (props) => {
    const theme = useTheme();
    const t = useTranslate()
    const retryText = t('updateSpace.retry')
    const editText = t('updateSpace.editButton')

    const editClickHandler = () => {
        props.onEditClick()
    }
    return <Box sx={{
        display: 'flex',
        flexDirection: 'column'
    }}>
        <Grid container spacing={0}>
            <Grid item xs={10}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography
                        variant='titleLarge'>{props.title}</Typography>
                    {props.children}
                </Box>
            </Grid>
            <Grid item xs={2} sx={{
                mt: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-start'
            }}>
                {props.showEdit && <Button variant='text' onClick={editClickHandler}>
                    <Typography
                        variant="labelLarge">{editText}</Typography>
                </Button>}
            </Grid>
            {props.error !== null && <Alert
                sx={{width: '100%'}}
                severity="error"
                action={props.error.showRetry ?
                    <Button color="inherit" size="small" onClick={props.onRetry}>
                        <Typography variant="labelLarge">
                            {retryText}
                        </Typography>
                    </Button> : null
                }
            >
                {props.error.errorTitle && <AlertTitle>{props.error.errorTitle}</AlertTitle>}
                <Typography variant="bodyMedium">{props.error.errorMessage}</Typography>
            </Alert>}
        </Grid>
        <Box sx={{mt: 2}}>
            <Line orientation={LineOrientation.Horizontal} size={'100%'}
                  color={props.isFocused ? theme.palette.primaryLine.main : theme.palette.neutral80.main}/>
        </Box>
    </Box>
}

export default DisplayFormCard