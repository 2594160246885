import { Box, Button, Stack, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import CustomModal from "components/CustomModal"
import MultiSelectChipGroup from "components/MultiSelectChipGroup"
import RoundedButton from "components/RoundedButton"
import { CatalogEntry } from "interfaces/catalogEntry"
import { useEffect, useState } from "react"
import { useAppSelector } from "store/hooks"

interface GroupAmenitiesModalProps {
    canOpen: boolean
    closeModal: () => void
    amenities: CatalogEntry[]
    setAmenities: (amenities: CatalogEntry[]) => void
}

const GroupAmenitiesModal: React.FC<GroupAmenitiesModalProps> = (props) => {
    const { canOpen, closeModal, amenities, setAmenities } = props

    const allAmenities: CatalogEntry[] = useAppSelector(state => state.catalog.amenities)
    const [selectedAmenities, setSelectedAmenities] = useState<CatalogEntry[]>(amenities)
    
    const t = useTranslate()
    const title = t("updateSpace.spaceAmenitiesTitle")
    const subtitle = t("updateSpace.spaceAmenitiesSubtitle")
    const cancelLabel = t("buttons.cancel")
    const saveLabel = t("buttons.save")

    const onAmenitiesChangeHandler = (selections: CatalogEntry[]) => {
        setSelectedAmenities(selections);
    };

    const onSaveClickHandler = () => {
        setAmenities(selectedAmenities)
        closeModal()
    }

    useEffect(()=> {
        setSelectedAmenities(amenities)
    }, [amenities])

    return <CustomModal 
        sxContainer={{maxWidth: '858px'}}
        sxCloseButton={{top: '18px', right: '8px'}}
        canOpen={canOpen}
        onCloseModal={closeModal}
    >
        <Stack>
            <Typography variant="titleLarge">{title}</Typography>
            <Typography variant="titleMedium" sx={{mt: 1, mb: 2, color: 'neutral50.light', letterSpacing: 0}}>{subtitle}</Typography>
            <Box sx={{maxHeight: '360px', overflow: 'auto'}}>
                <MultiSelectChipGroup
                    items={allAmenities}
                    selections={selectedAmenities}
                    onSelectionsUpdated={onAmenitiesChangeHandler}
                />
            </Box>
            <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} mt={3}>
                <Button
                    variant='text'
                    sx={{width: 'max-content'}}
                    onClick={closeModal}
                >
                    <Typography variant='labelLarge'>{cancelLabel}</Typography>
                </Button>
                <RoundedButton
                    variant='contained'
                    sx={{width: 'max-content'}}
                    onClick={onSaveClickHandler}
                >
                    <Typography variant='labelLarge'>{saveLabel}</Typography>
                </RoundedButton>
            </Stack>
        </Stack>
    </CustomModal>
}

export default GroupAmenitiesModal