import React, {useCallback, useEffect, useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {DetailsMenu} from "./SpaceDetailsMenu";
import { SpaceDetail } from "interfaces/space";
import { useTranslate } from "@pankod/refine-core";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { spaceDetailConstants } from "constants/spaceDetailConstants";
import { scrollToTop } from "utils/scroll";
import { spaceActions } from "store/space";
import { Focusable } from "components/Focusable";
import SpaceDaysToReserveForm from "./calendar/SpaceDaysToReserveForm";
import SpaceDaysOffForm from "./calendar/SpaceDaysOffForm";

interface SpaceDetailsCalendarProps {
    spaceDetail: SpaceDetail
    onScrollApplied: (item: DetailsMenu) => void
    omittedFields: string[]
    addOmittedField: (field: string) => void
}

const SpaceDetailsCalendar: React.FC<SpaceDetailsCalendarProps> = (props) => {

    const {spaceDetail, onScrollApplied, omittedFields, addOmittedField} = props
    const t = useTranslate()
    const header = t('updateSpace.menuCalendar')

    const daysToReserveRef = useRef<HTMLElement>(null)
    const daysOffRef = useRef<HTMLElement>(null)

    const dispatch = useAppDispatch()
    const currentFocusField = useAppSelector(state => state.space.currentFocusId)
    const shouldFocus = useAppSelector(state => state.space.shouldFocus)

    const [isDaysOffOmitted, setIsDaysOffOmitted] = useState<boolean>(false);

    const focusCurrentField = useCallback(() => {
        if (shouldFocus) {
            switch (currentFocusField) {
                case spaceDetailConstants.daysToReserve:
                    scrollToTop(daysToReserveRef, spaceDetailConstants.barOffset)
                    dispatch(spaceActions.setShouldFocus(false))
                    onScrollApplied(DetailsMenu.Calendar)
                    break;
                case spaceDetailConstants.daysOff:
                    scrollToTop(daysOffRef, spaceDetailConstants.barOffset)
                    dispatch(spaceActions.setShouldFocus(false))
                    onScrollApplied(DetailsMenu.Calendar)
                    break;
            }
        }
    }, [currentFocusField, dispatch, onScrollApplied, shouldFocus])

    //Requires empty dep array to only run once
    useEffect(() => {
        setTimeout(focusCurrentField, spaceDetailConstants.scrollTimeout)
    }, [])

    useEffect(() => {
        if(omittedFields.length > 0) {
            if(omittedFields.includes(spaceDetailConstants.daysOff)) {
                setIsDaysOffOmitted(true);
            }
        }
    }, [omittedFields]);

    focusCurrentField()

    const onSkip = useCallback(() => {
        setIsDaysOffOmitted(true);
        addOmittedField(spaceDetailConstants.daysOff)
    }, []);

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
    }}>
        <Typography variant="headlineLarge">{header}</Typography>
        <Box sx={{pt: 4, pr: 4}}>
            <Focusable ref={daysToReserveRef}>
                <SpaceDaysToReserveForm spaceDetail={spaceDetail}/>
            </Focusable>
        </Box>
        <Box sx={{pt: 4, pr: 4}}>
            <Focusable ref={daysOffRef}>
                <SpaceDaysOffForm spaceDetail={spaceDetail} onSkip={onSkip} isOmitted={isDaysOffOmitted}/>
            </Focusable>
        </Box>
    </Box>
}

export default SpaceDetailsCalendar