import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslate } from "@pankod/refine-core";
import RoundedButton from "components/RoundedButton";

interface SpaceReserveButtonProps {
    isDisabled: boolean,
    onReserveSpace: () => void 
}

const SpaceReserveButton: React.FC<SpaceReserveButtonProps> = (props) => {
    const { isDisabled, onReserveSpace } = props;
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const buttonVariant = isSM ? 'labelMedium' : 'titleMedium'
    const t = useTranslate();
    const reserve_title = t('previewDetails.reserveTitle');
    
    return <RoundedButton 
        disabled={isDisabled} 
        variant="contained" 
        fullWidth
        onClick={onReserveSpace}
        sx={{
            height: {xs: '48px', md: '56px'}
        }}
    >
        <Typography variant={buttonVariant}>
            {reserve_title}
        </Typography>
    </RoundedButton>
}

export default SpaceReserveButton;
