import { useNavigation, useOne, useRouterContext, useTranslate } from "@pankod/refine-core";
import {
  Box, Typography,
} from "@pankod/refine-mui";
import Notice from "components/Notice";
import PageTitle from "components/PageTitle";
import SpacePreviewEditButton from "components/details/SpacePreviewEditButton";
import SpaceAvailabilityContent from "components/preview/spaceAvailabilityContent";
import paths from "constants/paths";
import { spaceDetailConstants } from "constants/spaceDetailConstants";
import { CatalogEntry } from "interfaces/catalogEntry";
import { Group, GroupPackage, PaidService, PriceTypes, SpaceDetail } from "interfaces/space";
import { createRef, useCallback, useEffect, useState } from "react";
import { calculateRangeHours, dateStringToDate, filterPaidServicesById, findGroupPackageById, getEvetType, getReservationUrl, getSelectedGroup, isPaidServiceTheSame } from "utils";
import { scrollToTop } from "utils/scroll";

const getDefaultGroup = (groups: Group[]): Group | null => {
    return groups.length > 0 ? groups[0] : null
}

const initGroup = (groups: Group[], eventTypeId?: string): Group | null => {
    if(eventTypeId) {
        return getSelectedGroup(groups, eventTypeId)
    }
    return getDefaultGroup(groups)
}

const initEventType = (groups: Group[], eventTypeId?: string): CatalogEntry | null => {
    if(eventTypeId) {
        return getEvetType(groups, eventTypeId)
    }
    const defaultGroup = getDefaultGroup(groups)
    if(defaultGroup !== null) {
        return defaultGroup.eventTypes[0] as CatalogEntry
    }
    return null
}

const initDate = (date?: string): Date | null => {
    return date ? dateStringToDate(date) : null
}

const initTime = (time?: string): string | null => {
    return time ? time : null
}

const initPeople = (people?: string): number => {
    return people ? parseInt(people) : 0
}

const initEventHours = (group: Group | null, time?: string): number | null => {
    if(group === null || time === null) {
        return null
    }
    if(group.priceType === PriceTypes.ByHour && time) {
        return calculateRangeHours(time)
    }
    return null
}

export const AdvertisementAvailability: React.FC = () => {
	const t = useTranslate();
	const { useParams, } = useRouterContext()
	const params: any = useParams()

	const currentUrl = window.location.search
	const queryParams = new URLSearchParams(currentUrl)
	const paramEventTypeId = queryParams.get("eventTypeId")
	const paramDate = queryParams.get("date")
	const paramTime = queryParams.get("time")
	const paramPeople = queryParams.get("people")
	const paramPackageId = queryParams.get("packageId")
	const eventTypeId = paramEventTypeId ? paramEventTypeId : undefined
	const date = paramDate ? paramDate : undefined
	const time = paramTime ? paramTime : undefined
	const people = paramPeople ? paramPeople : undefined
	const packageId = paramPackageId ? paramPackageId : undefined
	const paidServices = undefined
	const mainContainerRef = createRef<HTMLDivElement>()

	const { data, isError, isLoading } = useOne<SpaceDetail>({
		resource: "advertisements",
		id: params.id,
	});
	const spaceDetail: SpaceDetail | undefined = data?.data

  	//const selectedPackage = spaceDetail ?  findPackageById(spaceDetail.packages, packageId) : undefined
    const [selectedGroup, setSelectedGroup] = useState<Group | null>(() => initGroup(spaceDetail ? spaceDetail.groups : [], eventTypeId))
    const [selectedEventType, setSelectedEventType] = useState<CatalogEntry | null>(() => initEventType(spaceDetail ? spaceDetail.groups : [], eventTypeId))
    const [selectedDate, setSelectedDate] = useState<Date | null>(() => initDate(date))
    const [selectedTime, setSelectedTime] = useState<string | null>(() => initTime(time))
    const [selectedPeople, setSelectedPeople] = useState<number>(() => initPeople(people))
    const [selectedPaidServices, setSelectedPaidServices] = useState<PaidService[]>([])
    const [canShowAllServices, setCanShowAllServices] = useState<boolean>(false)
    const [selectedEventHours, setSelectedEventHours] = useState<number | null>(() => initEventHours(selectedGroup, time))
    const selectedPackage = selectedGroup ? findGroupPackageById(selectedGroup.packages, packageId) : undefined

	const title = t('previewDetails.title');
    const notice = t('previewDetails.notice');

	const { push } = useNavigation();

	const onGoBack = useCallback(() => {
		push( paths.adDetails + "/" + data?.data.id);
	}, [data]);

	const initPaidServices = useCallback(() => {
		if (paidServices !== undefined) {
            let services = []
            if (selectedPackage !== undefined) {
                services = [...selectedGroup!.paidServices, ...selectedPackage.paidServices]
            } else {
                services = [...selectedGroup!.paidServices]
            }
            const selectedPaidServices = filterPaidServicesById(services, paidServices)
            setSelectedPaidServices(selectedPaidServices)
        } else {
            if(selectedPackage !== undefined) {
                setSelectedPaidServices([])
            }
        }
	}, [paidServices, selectedPackage, spaceDetail]);

	const selectPaidService = useCallback((paidService: PaidService, checked: boolean) => {
        setSelectedPaidServices((prevState) => {
            if (checked) {
                const newArray = [...prevState, paidService]
                return newArray

            } else {
                const newArray = [...prevState]
                newArray.splice(prevState.findIndex(currentPaidService => isPaidServiceTheSame(paidService, currentPaidService)), 1)
                return newArray
            }
        })
    }, []);

	const onReservePackage = useCallback((selectedPackage: GroupPackage) => {
        const url = getReservationUrl(spaceDetail!.id, false, undefined, undefined, selectedPackage._id)
        push(url)
    }, [spaceDetail])

	useEffect(() => {
		scrollToTop(mainContainerRef, spaceDetailConstants.barOffset);
		if(spaceDetail) {
			initPaidServices();
		}
	}, [selectedPackage]);

	if(isLoading) {
		return <></>
	}
	if(isError || !spaceDetail) {
		return <>Error al cargar el anuncio.</> 
	}

  	return <Box sx={{width: '100%', backgroundColor: 'white', pt: '32px'}}>
			<Box ref={mainContainerRef} sx={{
			width: '100%',
			maxWidth: '1094px',
			display: 'flex',
			flexDirection: 'column',
			margin: '0 auto',
			padding: '16px',
			backgroundColor: 'white'
		}}>
			<PageTitle title={title} onBack={onGoBack}></PageTitle>
			<Box sx={{marginTop: '28px', marginBottom: '40px'}}>
				<Notice>
					<Box sx={{display: 'flex', alignItems: 'center'}}>
						<Typography variant="labelLarge">{notice}</Typography>
					</Box>
				</Notice>
			</Box>
			<Box sx={{display: 'flex', justifyContent: 'flex-end', marginBottom: '24px'}}>
				<SpacePreviewEditButton spaceId={spaceDetail!.id}></SpacePreviewEditButton>
			</Box>
			<SpaceAvailabilityContent
				isPreview={true}
				setIsLoading={() => {}}
				space={spaceDetail}
				selectedPackage={selectedPackage}
				selectedPeople={selectedPeople}
				selectPeople ={setSelectedPeople}
				selectedPaidServices={selectedPaidServices}
				selectPaidService={selectPaidService}
				selectedDate={selectedDate}
				setSelectedDate={setSelectedDate}
				selectedTime={selectedTime}
				setSelectedTime={setSelectedTime}
				onCheckout={() => {}}
				canShowAllServices={canShowAllServices}
				setCanShowAllServices={setCanShowAllServices}
				reservePackage={onReservePackage}
				requestOpenChat={false}
				selectedGroup={selectedGroup!}
				selectedEventType={selectedEventType}
				setSelectedEventType={setSelectedEventType}
				setSelectedGroup={setSelectedGroup}
				selectedEventHours={selectedEventHours}
				setSelectedEventHours={setSelectedEventHours}
				setSelectedPaidServices={setSelectedPaidServices}
			></SpaceAvailabilityContent>
		</Box>
	</Box>
}

