import { useRouterContext } from "@pankod/refine-core"
import { useAppDispatch } from "store/hooks";
import { fetchCatalog } from "store/catalogActions";
import { CatalogType } from "interfaces/catalogType";
import { UpdateSpace } from "components/spaces/update/UpdateSpace";

export const AdvertisementUpdate: React.FC = () => {
    const dispatch = useAppDispatch()
    const { useParams } = useRouterContext()
    const params: any = useParams()

    dispatch(fetchCatalog(CatalogType.Spaces))
    dispatch(fetchCatalog(CatalogType.Amenities))
    dispatch(fetchCatalog(CatalogType.Events))
    dispatch(fetchCatalog(CatalogType.Tags))

    return <UpdateSpace spaceId={params.id}/>
}