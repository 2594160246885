import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import AdRating from "./AdRating"
import { SpaceDetail } from "interfaces/space"

interface AdNameProps {
    spaceDetail: SpaceDetail
}

const AdName: React.FC<AdNameProps> = (props) => {
    const { spaceDetail } = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const textVariant = isSM ? 'titleMedium' : 'headlineSmall'
    
    return <Stack>
        <Box sx={{display: 'flex', gap: '8px'}}>
            <Typography variant={textVariant}>{spaceDetail.name}</Typography>
            <AdRating rating={spaceDetail.rating} ratings={spaceDetail.ratings} size="medium" />
        </Box>
    </Stack>
}

export default AdName