import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import startGoldenIcon from '../../icons/star-golden.svg'
import Icon from "components/Icon"

interface AdRatingProps {
    rating?: number
    ratings?: number
    size: 'small' | 'medium'
}

const AdRating: React.FC<AdRatingProps> = (props) => {
    const { rating, ratings, size } = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const iconSize = size === 'small' || isSM ? 16 : 23
    const textVariant = size === 'small' || isSM ? 'labelLarge' : 'titleLarge'

    if(!ratings || ratings === 0) {
        return <></>
    }
    
    return <Box sx={{display: 'flex', alignItems: 'center', gap: '2px'}}>
        <Icon
            containerWidth={iconSize}
            containerHeight={iconSize}
            iconWidth={iconSize}
            iconHeight={iconSize}
            iconSrc={startGoldenIcon}
        />
        <Typography variant={textVariant}>{rating}</Typography>
    </Box> 
}

export default AdRating