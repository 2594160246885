import { useTranslate } from "@pankod/refine-core";
import { Box } from "@pankod/refine-mui";
import { Subtitle, Text, Label, Row} from "./components";
import { IBooking } from "interfaces";
import { EPaymentStatus } from "enums";
import { formatNumberPrice } from "utils";

interface LateFeeProps {
    surcharges: {label: string, amount: number}[]
}

const LateFee:React.FC<LateFeeProps> = (props) => {
    const t = useTranslate();
    
    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px 0px', borderTop: '1px solid', borderTopColor: 'primary.main'}}>
        <Subtitle>{t('reservation.lateFee')}</Subtitle>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px'}}>
            {
                props.surcharges.map((surcharge, index) => {
                    return <Row key={'surcharge-' + index}>
                        <Label>{surcharge.label}</Label>
                        <Text>{formatNumberPrice(surcharge.amount, true)}</Text>
                    </Row>
                })
            }
        </Box>
    </Box>;
}

export default LateFee;