import { Stack } from "@mui/material"
import GroupListItem from "./GroupListItem"
import { Group } from "interfaces/space"
import Divider from "components/Divider"

interface GroupListProps {
    groups: Group[]
    onShowMoreClickHandler: (group: Group) => void
}

const GroupList: React.FC<GroupListProps> = (props) => {
    const { groups, onShowMoreClickHandler } = props

    return <Stack spacing={3} sx={{mt: 3}}>
        {
            groups.map((group, index) => {
                return <Stack spacing={3} key={'group-item-' + index}>
                    <GroupListItem 
                        group={group} 
                        onShowMoreClickHandler={onShowMoreClickHandler}
                    />
                    {
                        index !== (groups.length - 1) && <Divider/>
                    }
                </Stack>
            })
        }
    </Stack>
}

export default GroupList