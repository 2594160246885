import { Box, useTheme } from "@mui/material"
import MuiModal from "@mui/material/Modal"
import Carousel from "react-material-ui-carousel"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SpaceDetail } from "interfaces/space"

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

interface AdPicturesModalProps {
    openModal: boolean,
    setOpenModal: (status: boolean) => void,
    spaceDetail: SpaceDetail,
    pictureIndex: number
}

const AdPicturesModal: React.FC<AdPicturesModalProps> = (props) => {
    const {spaceDetail, openModal, setOpenModal, pictureIndex} = props
    const theme = useTheme()
    const pictures = spaceDetail.picturesUrls ? [...spaceDetail.picturesUrls] : []
    if(spaceDetail.coverPictureUrl) {
        pictures.unshift(spaceDetail.coverPictureUrl)
    }
    if(spaceDetail.floorPlanUrl) {
        pictures.push(spaceDetail.floorPlanUrl)
    }

    return <MuiModal
        open={openModal}
        onClose={() => {
            setOpenModal(false)
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box sx={{...style}}>
            <Carousel
                sx={{
                    width: '100%',
                    height: '100%'
                }}
                index={pictureIndex}
                navButtonsAlwaysVisible
                navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                    style: {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 100
                    }
                }}
                navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                    style: {
                        bottom: '0',
                        top: 'unset'
                    }
                }}
                PrevIcon={<ArrowBackIcon/>}
                NextIcon={<ArrowForwardIcon/>}
            >
                {pictures.length > 0 && pictures.map((pictureUrl, index) => {
                    return <LazyLoadImage
                        key={`image_${index}`}
                        alt={"alt"}
                        style={{objectFit: 'contain'}}
                        height={'600px'}
                        src={pictureUrl}
                        width={'100%'}/>
                })}
            </Carousel>
        </Box>
    </MuiModal>
}

export default AdPicturesModal