import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import SpacePackageCard from "./SpacePackageCard"
import { Group, GroupPackage } from "interfaces/space"
import { useTranslate } from "@pankod/refine-core"
import Divider from "./Divider"

interface SpacePackagesProps {
    selectedGroup: Group
    onReservePackage: (packageInfo: GroupPackage) => void
    selectedPackage?: GroupPackage
}

const SpacePackages: React.FC<SpacePackagesProps> = (props) => {
    const {selectedGroup, onReservePackage, selectedPackage} = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'
    const t = useTranslate();
	const title = t('previewDetails.reservationPackagesTitle')

    if(!selectedGroup.packages || selectedGroup.packages.length === 0) {
        return <></>
    }

    const packages = [...selectedGroup.packages]
    if (selectedPackage) {
        packages.splice(packages.indexOf(selectedPackage), 1);
    }

    if(packages.length === 0) {
        return <></>
    }

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    }}>
        <Box sx={{
            width: '100%', 
            maxWidth: '656px',
            margin: '0 auto',
            mb: 2
        }}>
            <Divider/>
        </Box>
        <Typography variant={titleVariant} sx={{textAlign: 'center'}}>{title}</Typography>
        <Box sx={{display: 'flex', justifyContent: {xs: 'center', md: 'flex-start'}, flexWrap: 'wrap', gap: '24px'}}>
            {
                packages.map((item, index) => {
                    return <SpacePackageCard 
                        key={`group-package-${index}`}
                        packageInfo={item}
                        workingTimes={selectedGroup.workingTimes}
                        priceType={selectedGroup.priceType}
                        onSelectPackage={onReservePackage}
                        isSM={isSM}
                    />
                })
            }
        </Box>
    </Box>
}

export default SpacePackages