import { Stack, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import { TextfieldOutlined } from "components/TextfieldOutlined"
import fieldConstants from "constants/fieldConstants"
import { GroupRange, Occupancy, WorkingTime } from "interfaces/space"
import moment from "moment"
import { DEFAULT_WORKING_TIME_END, DEFAULT_WORKING_TIME_START } from "./GroupWorkingTimesDayTimes"

interface GroupWorkingTimesDayMaxHoursProps {
    workingTime: WorkingTime
    index: number
    updateWorkingTime: (index: number, workingTime: WorkingTime) => void
    spaceOccupancy: Occupancy
}

const GroupWorkingTimesDayMaxHours: React.FC<GroupWorkingTimesDayMaxHoursProps> = (props) => {
    const { workingTime, index, updateWorkingTime, spaceOccupancy } = props
    const t = useTranslate()
    const hoursLabel = t("updateSpace.hoursLabel")

    const onMaxHoursChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value.trim() === '') {
            const newWorkingTime = {...workingTime, maxHours: 0}
            updateWorkingTime(index, newWorkingTime)
        } else {
            let maxHours = parseInt(event.target.value)
            if(maxHours < 1) maxHours = 1
            if(maxHours > fieldConstants.defaultMaxHours) maxHours = fieldConstants.defaultMaxHours
            const newWorkingTime = {...workingTime, maxHours}
            // If the times are empty, add a default time
            if(newWorkingTime.times.length === 0) {
                const startHour = moment(DEFAULT_WORKING_TIME_START, 'HH:mm')
                const endHour = moment(DEFAULT_WORKING_TIME_END, 'HH:mm')
                const time = startHour.format('HH:mm') + '-' + endHour.format('HH:mm')
                const newWorkingTime = {...workingTime}
                newWorkingTime.times.push(time)
            }
            // If the ranges are empty, add a default range
            if(newWorkingTime.ranges.length === 0) {
                const range: GroupRange = {
                    occupancy: {
                        minimum: spaceOccupancy.minimum,
                        maximum: spaceOccupancy.maximum
                    },
                    prices: {
                        byEvent: 0,
                        byPerson: 0,
                        byHour: 0
                    }
                }
                newWorkingTime.ranges.push(range)
            }
            updateWorkingTime(index, newWorkingTime)
        }
    }

    return <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <TextfieldOutlined
            id='working-time-max-hours'
            onChange={onMaxHoursChangedHandler}
            value={workingTime.maxHours ? workingTime.maxHours : ''}
            inputProps={{
                min: 1,
                max: fieldConstants.defaultMaxHours,
                type: 'number'
            }}
        />
        <Typography variant="labelLarge">{hoursLabel}</Typography>
    </Stack>   
}

export default GroupWorkingTimesDayMaxHours