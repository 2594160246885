import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"

interface ShowMoreButtonProps {
    setShowAll: (status: boolean) => void
    buttonLabel?: string
    sx?: any
}

const ShowMoreButton: React.FC<ShowMoreButtonProps> = (props) => {
    const { setShowAll, buttonLabel, sx } = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const labelVariant = isSM ? 'labelLarge' : 'titleMedium'
    const t = useTranslate()
    const showMoreText = t('previewDetails.detailShowMore')
    const label = buttonLabel ? buttonLabel : showMoreText
    
    return <Button
        variant='text'
        sx={{width: 'max-content', ...sx}}
        onClick={() => {setShowAll(true)}}
    >
        <Typography variant={labelVariant}>{label}</Typography>
    </Button>
}

export default ShowMoreButton