import React, {useCallback, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import MuiModal from "@mui/material/Modal";
import { Stack } from "@mui/material";
import useGroups from "hooks/useGroups";
import { CatalogEntry } from "interfaces/catalogEntry";
import { Group, GroupPackage, GroupRange, PaidService, PriceTypes, SpaceDetail, WorkingTime } from "interfaces/space";
import { checkHasMissingMaxHour, checkHasOverlapTimes, checkRangesHasMissingData, checkRangesHasOccupancyExceeded, checkRangesHasWrong, checkTimesHasMissingHours, generateSpaceEventTypesDisabled } from "utils";
import GroupFormHeader from "./GroupFormHeader";
import GroupEventTypesForm from "./GroupEventTypesForm";
import GroupPriceTypeForm from "./GroupPriceTypeForm";
import GroupWorkingTimesForm from "./GroupWorkingTimesForm";
import GroupWorkingTimesDays from "./GroupWorkingTimesDays";
import GroupAmenitiesForm from "./GroupAmenitiesForm";
import GroupPaidServicesForm from "./GroupPaidServicesForm";
import GroupGuaranteeDepositForm from "./GroupGuaranteeDepositForm";
import GroupPackagesForm from "./GroupPackagesForm";
import GroupFormActions from "./GroupFormActions";

interface GroupFormModalProps {
    spaceDetail: SpaceDetail
    currentGroup: Group | null
    open: boolean
    onClose: () => void
    onSave: (newGroup: Group) => void
}

const style = {
    width: '100%',
    height: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24
};

const GroupFormModal: React.FC<GroupFormModalProps> = (props) => {
    const {spaceDetail, currentGroup, open, onClose, onSave} = props
    const {createGroup, updateGroup} = useGroups()
    const [eventTypes, setEventTypes] = useState<CatalogEntry[]>(currentGroup?.eventTypes ? currentGroup?.eventTypes as CatalogEntry[] : [])
    const [priceType, setPriceType] = useState<string>(currentGroup?.priceType ? currentGroup.priceType : '')
    const [workingTimes, setWorkingTimes] = useState<WorkingTime[]>(currentGroup?.workingTimes ? currentGroup?.workingTimes : [])
    const [amenities, setAmenities] = useState<CatalogEntry[]>(currentGroup?.amenities ? currentGroup?.amenities as CatalogEntry[] : [])
    const [paidServices, setPaidServices] = useState<PaidService[]>(currentGroup?.paidServices ? currentGroup?.paidServices : [])
    const [guaranteeDeposit, setGuaranteeDeposit] = useState<number | undefined>(currentGroup?.guaranteeDeposit ? currentGroup?.guaranteeDeposit : undefined)
    const [packages, setPackages] = useState<GroupPackage[]>(currentGroup?.packages ? currentGroup?.packages : [])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [spaceEventTypesDisabled, setSpaceEventTypesDisabled] = useState<CatalogEntry[]>([])
    const [hasWorkingTimesError, setHasWorkingTimesError] = useState<boolean>(false)

    const onEventTypesChangedHandler = useCallback((newEventTypes: CatalogEntry[]) => {
        setEventTypes(newEventTypes)
    }, [])

    const isValid = useCallback((): boolean => {
        return eventTypes.length > 0 && 
            priceType !== '' &&
            workingTimes.length > 0 && 
            amenities.length > 0 &&
            hasWorkingTimesError === false
    }, [eventTypes, priceType, workingTimes, amenities, hasWorkingTimesError])

    const onCloseHandler = useCallback(() => {
        setEventTypes([])
        setPriceType('')
        setWorkingTimes([])
        setAmenities([])
        setPaidServices([])
        setGuaranteeDeposit(undefined)
        setPackages([])
        onClose()
    }, [onClose])

    const onSaveHandler = useCallback(async () => {
        setIsLoading(true)
        const requestBody = {
            eventTypes,
            priceType: priceType as PriceTypes,
            workingTimes,
            amenities,
            paidServices,
            guaranteeDeposit,
            packages
        }
        let result;
        if (currentGroup === null) {
            result = await createGroup(spaceDetail.id, requestBody)
        } else {
            result = await updateGroup(spaceDetail.id, currentGroup._id!!, requestBody)
        }
        if (result.isOk()) {
            onSave(result.unwrap())
            onCloseHandler()
        }
        setIsLoading(false)
    }, [eventTypes, priceType, workingTimes, amenities, paidServices, guaranteeDeposit, packages])

    const onPriceTypeChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setPriceType(value);
    };

    const onCopyWorkingTimesHandler = () => {
        const workingTimesCopy = [...workingTimes]
        for(let i = 1; i < workingTimesCopy.length; i++) {
            const ranges: GroupRange[] = []
            workingTimesCopy[0].ranges.forEach((range: GroupRange) => {
                const rangeCopy = {
                    occupancy: {...range.occupancy},
                    prices: {...range.prices}
                }
                ranges.push(rangeCopy)
            })
            workingTimesCopy[i] = {
                day: workingTimesCopy[i].day,
                maxHours: workingTimesCopy[0].maxHours,
                times: [...workingTimesCopy[0].times],
                ranges: ranges
            }
        }
        setWorkingTimes(workingTimesCopy)
    }

    const validWorkingTimes = (workingTimes: WorkingTime[]) => {
        let hasErrors = false
        const hasMissisingMaxHoursError = checkHasMissingMaxHour(workingTimes)
        if(hasMissisingMaxHoursError) {
            hasErrors = true
        } else {
            for(let workingTime of workingTimes) {
                if(workingTime.times.length > 0) {
                    const hasMissisingError = checkTimesHasMissingHours(workingTime.times, workingTime.maxHours)
                    if(hasMissisingError) {
                        hasErrors = true
                        break
                    } else {
                        const hasOverlapError = checkHasOverlapTimes(workingTime.times)
                        if(hasOverlapError) {
                            hasErrors = true
                            break
                        } else {
                            const hasMissisingError = checkRangesHasMissingData(workingTime.ranges)
                            if(hasMissisingError) {
                                hasErrors = true
                                break
                            } else {
                                const hasWrongError = checkRangesHasWrong(workingTime.ranges)
                                if(hasWrongError) {
                                    hasErrors = true
                                    break
                                } else {
                                    const hasOccupancyExceededError = checkRangesHasOccupancyExceeded(workingTime.ranges, spaceDetail.occupancy)
                                    if(hasOccupancyExceededError) {
                                        hasErrors = true
                                        break
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        setHasWorkingTimesError(hasErrors)
    }

    useEffect(() => {
        if (currentGroup !== null) {
            setEventTypes(currentGroup.eventTypes ? currentGroup.eventTypes as CatalogEntry[] : [])
            setPriceType(currentGroup.priceType ? currentGroup.priceType : '')
            setWorkingTimes(currentGroup.workingTimes ? currentGroup.workingTimes : [])
            setAmenities(currentGroup.amenities ? currentGroup.amenities as CatalogEntry[] : [])
            setPaidServices(currentGroup.paidServices ? currentGroup.paidServices : [])
            setGuaranteeDeposit(currentGroup.guaranteeDeposit ? currentGroup.guaranteeDeposit : undefined)
            setPackages(currentGroup.packages ? currentGroup.packages : [])
        }
    }, [currentGroup])

    useEffect(() => {
        const eventTypesDisabled = generateSpaceEventTypesDisabled(spaceDetail, currentGroup ? currentGroup.eventTypes as CatalogEntry[] : [])
        setSpaceEventTypesDisabled(eventTypesDisabled)
    }, [currentGroup])

    useEffect(() => {
        if(workingTimes.length > 0) {
            validWorkingTimes(workingTimes)
        }
    }, [workingTimes])

    return <MuiModal
        open={open}
        onClose={onCloseHandler}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box sx={{...style}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative'
            }}> 
                <GroupFormHeader
                    onCloseHandler={onCloseHandler}
                    currentGroup={currentGroup}
                />
                <Box sx={{pt: '61px', overflowY: 'auto'}}>
                    <Container>
                        <Stack sx={{width: {xs: '100%', md: '826px'}, margin: '0 auto'}}>
                            <Stack spacing={'40px'}>
                                <GroupEventTypesForm 
                                    spaceEventTypes={spaceDetail.eventTypes as CatalogEntry[]}
                                    spaceEventTypesDisabled={spaceEventTypesDisabled}
                                    groupEventTypes={eventTypes}
                                    onEventTypesChangedHandler={onEventTypesChangedHandler}
                                />
                                <GroupPriceTypeForm
                                    priceType={priceType}
                                    onPriceTypeChangedHandler={onPriceTypeChangedHandler}
                                />
                                <GroupWorkingTimesForm
                                    workingTimes={workingTimes}
                                    setWorkingTimes={setWorkingTimes}
                                />
                                <GroupWorkingTimesDays 
                                    workingTimes={workingTimes}
                                    setWorkingTimes={setWorkingTimes}
                                    priceType={priceType as PriceTypes}
                                    spaceOccupancy={spaceDetail.occupancy!}
                                    onCopyWorkingTimesHandler={onCopyWorkingTimesHandler}
                                />
                                <GroupAmenitiesForm
                                    amenities={amenities}
                                    setAmenities={setAmenities}
                                />
                                <GroupPaidServicesForm
                                    paidServices={paidServices}
                                    setPaidServices={setPaidServices}
                                />
                                <GroupGuaranteeDepositForm
                                    guaranteeDeposit={guaranteeDeposit}
                                    setGuaranteeDeposit={setGuaranteeDeposit}
                                />
                                <GroupPackagesForm
                                    workingTimes={workingTimes}
                                    priceType={priceType as PriceTypes}
                                    groupAmenities={amenities}
                                    groupPaidServices={paidServices}
                                    packages={packages}
                                    setPackages={setPackages}
                                />
                            </Stack>
                            <GroupFormActions
                                onCloseHandler={onCloseHandler}
                                onSaveHandler={onSaveHandler}
                                isValid={isValid}
                                isLoading={isLoading}
                            />
                        </Stack>
                    </Container>
                </Box>
            </Box>
        </Box>
    </MuiModal>
}

export default GroupFormModal