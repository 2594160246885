import React from "react"
import Typography from "@mui/material/Typography"
import {InputAdornment, Stack} from "@mui/material"
import TextField from "@mui/material/TextField"
import {NumericFormat} from 'react-number-format'
import {NumberFormatValues, SourceInfo} from "react-number-format/types/types"
import { useTranslate } from "@pankod/refine-core"

interface GroupPackagesPriceFormProps {
    price: string
    onPriceChangeHandler: (values: NumberFormatValues, sourceInfo: SourceInfo) => void
}

const GroupPackagesPriceForm: React.FC<GroupPackagesPriceFormProps> = (props) => {
    const { price, onPriceChangeHandler } = props

    const t = useTranslate()
    const priceHint = t("updateSpace.spacePackagesPriceHint")
    const costTitle = t("updateSpace.spacePackagesCost")
    const costSubtitle = t("updateSpace.spacePackagesCostSubtitle")

    return <Stack>
        <Typography sx={{mt: 3}} variant="titleMedium">{costTitle}</Typography>
        <Typography sx={{mt: 1}} variant="bodyMedium">{costSubtitle}</Typography>
        <NumericFormat
            sx={{width: '250px', mt: 1}}
            placeholder={priceHint}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            value={price}
            customInput={TextField}
            type="text"
            thousandSeparator
            onValueChange={onPriceChangeHandler}
        />
    </Stack>
}

export default GroupPackagesPriceForm