import React, {useCallback, useEffect, useRef} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {DetailsMenu} from "../SpaceDetailsMenu";
import { useTranslate } from "@pankod/refine-core";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Focusable } from "components/Focusable";
import { spaceDetailConstants } from "constants/spaceDetailConstants";
import { scrollToTop } from "utils/scroll";
import { spaceActions } from "store/space";
import { SpaceDetail } from "interfaces/space";
import SpaceNameForm from "./SpaceNameForm";
import SpaceTypeForm from "./SpaceTypeForm";
import SpaceDescriptionForm from "./SpaceDescriptionForm";
import SpaceRulesForm from "./SpaceRulesForm";
import SpaceCancelationPolicyForm from "./SpaceCancelationPolicyForm";
import SpaceLocationForm from "./SpaceLocationForm";

interface SpaceDetailsGeneralInfoProps {
    spaceDetail: SpaceDetail
    onScrollApplied: (item: DetailsMenu) => void
    defaultAction?: string
}

const SpaceDetailsGeneralInfo: React.FC<SpaceDetailsGeneralInfoProps> = (props) => {
    const {spaceDetail, onScrollApplied} = props
    const t = useTranslate()
    const header = t('updateSpace.menuGeneralInfo')

    const nameRef = useRef<HTMLElement>(null)
    const typeRef = useRef<HTMLElement>(null)
    const descriptionRef = useRef<HTMLElement>(null)
    const locationRef = useRef<HTMLElement>(null)
    const cancelationRef = useRef<HTMLElement>(null)
    const rulesRef = useRef<HTMLElement>(null)

    const dispatch = useAppDispatch()
    const currentFocusField = useAppSelector(state => state.space.currentFocusId)
    const shouldFocus = useAppSelector(state => state.space.shouldFocus)

    const focusCurrentField = useCallback(() => {
        if (shouldFocus) {
            switch (currentFocusField) {
                case spaceDetailConstants.spaceType:
                    scrollToTop(typeRef, spaceDetailConstants.barOffset)
                    dispatch(spaceActions.setShouldFocus(false))
                    onScrollApplied(DetailsMenu.GeneralInfo)
                    break;
                case spaceDetailConstants.description:
                    scrollToTop(descriptionRef, spaceDetailConstants.barOffset)
                    dispatch(spaceActions.setShouldFocus(false))
                    onScrollApplied(DetailsMenu.GeneralInfo)
                    break;
                case spaceDetailConstants.location:
                    scrollToTop(locationRef, spaceDetailConstants.barOffset)
                    dispatch(spaceActions.setShouldFocus(false))
                    onScrollApplied(DetailsMenu.GeneralInfo)
                    break;
                case spaceDetailConstants.cancellation:
                    scrollToTop(cancelationRef, spaceDetailConstants.barOffset)
                    dispatch(spaceActions.setShouldFocus(false))
                    onScrollApplied(DetailsMenu.GeneralInfo)
                    break;
                case spaceDetailConstants.rules:
                    scrollToTop(rulesRef, spaceDetailConstants.barOffset)
                    dispatch(spaceActions.setShouldFocus(false))
                    onScrollApplied(DetailsMenu.GeneralInfo)
                    break;
            }
        }
    }, [currentFocusField, dispatch, onScrollApplied, shouldFocus])

    //Requires empty dep array to only run once
    useEffect(() => {
        setTimeout(focusCurrentField, spaceDetailConstants.scrollTimeout)
    }, [])

    focusCurrentField()

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
    }}>
        <Typography variant="headlineLarge">{header}</Typography>
        <Box sx={{pt: 6, pr: 4}}>
            <Focusable ref={nameRef}>
                <SpaceNameForm spaceDetail={spaceDetail}/>
            </Focusable>
        </Box>

        <Box sx={{pt: 4, pr: 4}}>
            <Focusable ref={typeRef}>
                <SpaceTypeForm spaceDetail={spaceDetail}/>
            </Focusable>
        </Box>
        <Box sx={{pt: 4, pr: 4}}>
            <Focusable ref={descriptionRef}>
                <SpaceDescriptionForm spaceDetail={spaceDetail}/>
            </Focusable>
        </Box>
        <Box sx={{pt: 4, pr: 4}}>
            <Focusable ref={rulesRef}>
                <SpaceRulesForm spaceDetail={spaceDetail}/>
            </Focusable>
        </Box>
        <Box sx={{pt: 4, pr: 4}}>
            <Focusable ref={cancelationRef}>
                <SpaceCancelationPolicyForm spaceDetail={spaceDetail}/>
            </Focusable>
        </Box>
        <Box sx={{pt: 4, pr: 4}}>
            <Focusable ref={locationRef}>
                <SpaceLocationForm spaceDetail={spaceDetail}/>
            </Focusable>
        </Box>
    </Box>
}

export default SpaceDetailsGeneralInfo