import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@pankod/refine-mui";
import Header from "components/details/header";
import Summary from "components/details/summary";
import { IBooking } from "interfaces";
import { EPaymentStatus } from "enums";
import Prices from "components/details/prices";
import LateFee from "components/details/lateFee";
import { CustomDrawer, CustomTab, CustomTabPanel, CustomTabs } from "components/details/components";
import { useCallback, useState } from "react";
import { useTranslate } from "@pankod/refine-core";
import PricesPayments from "components/details/pricesPayments";
import Payments from "components/details/payments";
import ReservationDetail from "components/details/reservationDetail";
import PaymentDetail from "components/details/paymentDetail";

interface DetailsProps {
    openSidebar: boolean,
    setOpenSidebar: (status: boolean) => void,
    reservation: IBooking | undefined,
}

const Details: React.FC<DetailsProps> = (props) => {
    const { openSidebar, setOpenSidebar, reservation } = props;
    const t = useTranslate();

    const [tabIndex, setTabIndex] = useState<number>(0);

    const onChangeTab = useCallback((event: React.SyntheticEvent, newTabIndex: number) => {
        setTabIndex(newTabIndex);
    }, []);
        
    return <CustomDrawer
        anchor={'right'}
        open={openSidebar}
        onClose={() => setOpenSidebar(false)}
    >
        { reservation &&
            <Box>
                <Header
                    reserationId={reservation.id}
                    name={reservation.advertisement.name}
                ></Header>
                <CustomTabs value={tabIndex} onChange={onChangeTab} sx={{paddingTop: '16px'}}>
                    <CustomTab label={t('reservation.eventDetails')} />
                    <CustomTab label={t('reservation.paymentDetails')} />
                </CustomTabs>
                <CustomTabPanel value={tabIndex} index={0}>
                    <ReservationDetail
                        reservation={reservation}
                    ></ReservationDetail>
                </CustomTabPanel>
                <CustomTabPanel value={tabIndex} index={1}>
                    <PaymentDetail
                        reservation={reservation}
                    ></PaymentDetail>
                </CustomTabPanel>
            </Box>
        }
        <IconButton 
            sx={{position: 'absolute', top: '8px', right: '8px'}}
            onClick={() => { setOpenSidebar(false) }}>
           <Close/>
        </IconButton>
    </CustomDrawer>
}

export default Details;