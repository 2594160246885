import { useCallback, useEffect, useState } from "react";
import { useTranslate } from "@pankod/refine-core";
import {
  Edit,
  Box,
  TextField,
  Switch,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { IAdvertisement } from "interfaces";
import { SpaceStatus } from "interfaces/space";

export const AdvertisementEdit: React.FC = () => {
  const t = useTranslate();
  const [valid, setValid] = useState<boolean>(true);
  const [verificationStamp, setVerificationStamp] = useState<boolean>(false);
  const [guaranteeStamp, setGuaranteeStamp] = useState<boolean>(false);
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const {
    saveButtonProps,
    register,
    refineCore: { queryResult },
    setValue
  } = useForm();

  const advertisement: IAdvertisement | undefined = queryResult?.data?.data as IAdvertisement;
  
  const updateVerificationStamp = (status: boolean) => {
    setValue('verificationStamp', status);
    setVerificationStamp(status);
  }

  const updateGuaranteeStamp = (status: boolean) => {
    setValue('guaranteeStamp', status);
    setGuaranteeStamp(status);
  }

  const updateIsApproved = (status: boolean) => {
    setValid(status)
    setValue('approved', status);
  }

  const updateIsPublished = (status: boolean) => {
    setValue('published', status);
    setIsPublished(status);
  }

  const updateIsActive = (status: boolean) => {
    setValue('active', status);
    setIsActive(status);
  }

  useEffect(() => {
    if(advertisement) {
      const approved = advertisement.status === SpaceStatus.Approved ? true : false;
      updateIsApproved(approved)

      const verificationStamp = advertisement.verificationStamp ? true : false;
      updateVerificationStamp(verificationStamp);

      const guaranteeStamp = advertisement.guaranteeStamp ? true : false;
      updateGuaranteeStamp(guaranteeStamp);

      updateIsPublished(advertisement.published)
      updateIsActive(advertisement.active)
      setMessage(advertisement.message);
    }
  }, [advertisement])

  if (!advertisement) return (
    <Box
      component="div"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Typography variant="h6">Cargando anuncio...</Typography>
    </Box>
  );

  const onChangeApprovedValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const approved = event.target.checked;
    updateIsApproved(approved)
    if(approved) {
      updateVerificationStamp(true);
      updateIsPublished(true)
      updateIsActive(true)
    } else {
      updateVerificationStamp(false)
      updateGuaranteeStamp(false)
      updateIsPublished(false)
      updateIsActive(false)
    }
  };

  const onChangeVerificationStamp = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if(!checked) {
      updateVerificationStamp(false);
    } else if(valid) {
      updateVerificationStamp(true);
    }
  };

  const onChangeGuaranteeStamp = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if(!checked) {
      updateGuaranteeStamp(false)
    } else if(valid) {
      updateGuaranteeStamp(true);
      updateVerificationStamp(true);
    }
  };

  const onChangeIsPublished = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    updateIsPublished(checked)
    updateIsActive(checked)
  };

  return (
    <Edit saveButtonProps={saveButtonProps} cardHeaderProps={{
      title: <Typography variant="h5">{t("actions.documentsReview")}</Typography>
    }} >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Typography variant="h6">{advertisement.name}</Typography>

        <Box
          component="div"
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          {
            advertisement.documentsUrls.map((document, i) => {
              if(document.includes('.pdf__'))
                return <iframe key={i} src={document} title={i.toString()} style={{ minHeight: '80vh', marginTop: '15px', borderTop: '10px dotted black'}} />

              return <img key={i} src={document} alt="Imagen del anuncio" width="500px"  style={{  minHeight: '30vh', marginTop: '15px', borderTop: '10px dotted black'}} />
            })
          }
        </Box>

        <Typography variant="h6" sx={{ marginTop: '1.4rem' }}>Fotos</Typography>

        <Box
          component="div"
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          {
            advertisement.picturesUrls && advertisement.picturesUrls.map((picture) => {
              return (
                <img src={picture} alt="Imagen del anuncio" width="400px"  />
            )})
          }
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', mt: '32px'}}>
          <Typography variant="h6">Sello de verificación y garantía.</Typography>
          <Box sx={{display: 'flex', gap: '12px'}}>
            <FormControlLabel control={<Checkbox checked={verificationStamp} onChange={onChangeVerificationStamp}/>} label="Sello de verificación"/>
            <FormControlLabel control={<Checkbox checked={guaranteeStamp} onChange={onChangeGuaranteeStamp}/>} label="Sello de garantía"/>
          </Box>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', mt: '16px'}}>
          <Typography variant="h6">Aprobación de espacio.</Typography>
          <FormControlLabel disabled={!advertisement.host.metadata.isValidHost} control={<Switch checked={valid} onChange={onChangeApprovedValue}/>} label="Aprobado"/>
          { 
            !valid && <TextField
              {...register("message", {
                required: t("errors.required.field", { field: "Motivo de rechazo" }),
              })}
              margin="normal"
              name="message"
              placeholder="Motivo de rechazo"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
        }
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column', mt: '16px'}}>
          <Typography variant="h6">Activar/Desactivar anuncio</Typography>
          <FormControlLabel disabled={!valid || !advertisement.host.active} control={<Switch checked={isPublished} onChange={onChangeIsPublished}/>} label="Anuncio activado"/>
        </Box>
      </Box>
    </Edit>
  );
};
