import { Stack, TextField, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"

interface GroupPackagesNameFormProps {
    name: string
    onNameChangedHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const GroupPackagesNameForm: React.FC<GroupPackagesNameFormProps> = (props) => {
    const { name, onNameChangedHandler } = props

    const t = useTranslate()
    const nameTitle = t("updateSpace.spacePackagesNameTitle")
    const nameHint = t("updateSpace.spacePackagesNameHint")
    
    return <Stack>
        <Typography sx={{mt: 3}} variant='titleMedium'>{nameTitle}</Typography>
        <TextField
            sx={{mr: 4}}
            margin="normal"
            placeholder={nameHint}
            id="package-name"
            name="package-name"
            onChange={onNameChangedHandler}
            value={name}
        />
    </Stack>
}

export default GroupPackagesNameForm