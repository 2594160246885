import { Button, Stack, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import { useState } from "react"
import { CatalogEntry } from "interfaces/catalogEntry"
import { useTranslate } from "@pankod/refine-core"
import MultiSelectChipGroup from "components/MultiSelectChipGroup"
import GroupAmenitiesModal from "./GroupAmenitiesModal"

interface GroupAmenitiesFormProps {
    amenities: CatalogEntry[]
    setAmenities: (amenities: CatalogEntry[]) => void
}

const GroupAmenitiesForm: React.FC<GroupAmenitiesFormProps> = (props) => {
    const { amenities, setAmenities } = props
    const [canOpenModal, setCanOpenModal] = useState<boolean>(false)

    const t = useTranslate()
    const title = t("updateSpace.spaceAmenitiesTitle")
    const subtitle = t("updateSpace.spaceAmenitiesSubtitle")
    const addAmenitiesLabel = t("updateSpace.spaceAddAmenities")

    const onAddAmenitiesClickHandler = () => {
        setCanOpenModal(true)
    }
    
    return <Stack sx={{mt: 3}}>
        <Typography variant="titleMedium">{title}</Typography>
        <Typography variant="labelLarge" sx={{mt: 1, color: 'neutral50.light'}}>{subtitle}</Typography>
        {
            amenities.length > 0 && <MultiSelectChipGroup
                sx={{mt: 1}}
                items={amenities}
                selections={amenities}
                onSelectionsUpdated={() => {}}
            />
        }
        <Button
            startIcon={<AddIcon/>}
            variant='text'
            sx={{width: 'max-content', mt: 2}}
            onClick={onAddAmenitiesClickHandler}
        >
            <Typography variant='labelLarge'>{addAmenitiesLabel}</Typography>
        </Button>
        <GroupAmenitiesModal
            amenities={amenities}
            canOpen={canOpenModal}
            closeModal={() => setCanOpenModal(false)}
            setAmenities={setAmenities}
        />
    </Stack>
}

export default GroupAmenitiesForm