import React, {useCallback, useState} from "react"
import Box from "@mui/material/Box"
import CloseIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import {Divider} from "@mui/material"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import MuiModal from "@mui/material/Modal"
import { GroupPackage, PaidService, PriceTypes, WorkingTime } from "interfaces/space"
import { CatalogEntry } from "interfaces/catalogEntry"
import { useTranslate } from "@pankod/refine-core"
import { NumberFormatValues, SourceInfo } from "react-number-format"
import { parseCommaPrice } from "utils"
import GroupPackagesNameForm from "./GroupPackagesNameForm"
import GroupPackagesDescriptionForm from "./GroupPackagesDescriptionForm"
import GroupPackagesAmenitiesForm from "./GroupPackagesAmenitiesForm"
import GroupPackagesPaidServicesForm from "./GroupPackagesPaidServicesForm"
import GroupPackagesPricesByOccupancy from "./GroupPackagesPricesByOccupancy"
import GroupPackagesPriceForm from "./GroupPackagesPriceForm"
import RoundedButton from "components/RoundedButton"

interface GroupPackagesCreateOrEditModalProps {
    workingTimes: WorkingTime[]
    priceType: PriceTypes
    packageData: GroupPackage | null
    packageIndex: number | null
    open: boolean
    onClose: () => void
    groupAmenities?: CatalogEntry[]
    groupPaidServices?: PaidService[]
    addPackage: (packageData: GroupPackage) => void
    updatePackage: (packageData: GroupPackage, packageIndex: number) => void
}

const style = {
    width: '100%',
    height: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24
};

const GroupPackagesCreateOrEditModal: React.FC<GroupPackagesCreateOrEditModalProps> = (props) => {
    const {
        workingTimes,
        priceType,
        groupAmenities, 
        packageData, 
        open, 
        onClose, 
        groupPaidServices, 
        addPackage, 
        updatePackage,
        packageIndex
    } = props
    
    const t = useTranslate()
    const addPackageLabel = t("updateSpace.spacePackagesAdd")
    const editPackageLabel = t("updateSpace.spacePackagesEdit")
    const cancelText = t("buttons.cancel")
    const saveText = t("buttons.save")
    
    const [name, setName] = useState(packageData ? packageData.name : '')
    const [description, setDescription] = useState(packageData ? packageData.description : '')
    const [amenities, setAmenities] = useState<CatalogEntry[]>(packageData ? packageData.amenities as CatalogEntry[] : [])
    const [paidServices, setPaidServices] = useState<PaidService[]>(packageData ? packageData.paidServices : [])
    const [price, setPrice] = useState(packageData ? packageData.price.toString() : '0')

    const onNameChangedHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }, [])

    const onDescriptionChangedHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value)
    }, [])

    const onAmenitiesChangedHandler = useCallback((newAmenities: CatalogEntry[]) => {
        setAmenities(newAmenities)
    }, [])

    const onPaidServicesChangedHandler = useCallback((newPaidServices: PaidService[]) => {
        setPaidServices(newPaidServices)
    }, [])

    const onPriceChangeHandler = useCallback((values: NumberFormatValues, sourceInfo: SourceInfo) => {
        if (values.formattedValue === '') {
            setPrice('0')
        } else {
            const value = values.formattedValue
            setPrice(value)
        }
    }, [])

    const isValid = useCallback((): boolean => {
        return name.length >= 3 && description.length >= 3 && price.length > 0
    }, [description.length, name.length, price.length])

    const onCloseHandler = useCallback(() => {
        setName('')
        setDescription('')
        setAmenities([])
        setPaidServices([])
        setPrice('0')
        onClose()
    }, [onClose])

    const onSaveButtonHandler = useCallback(async () => {
        const packageData: GroupPackage = {
            name: name,
            description: description,
            amenities: amenities,
            paidServices: paidServices,
            price: parseCommaPrice(price)
        }
        if (packageIndex === null) {
            addPackage(packageData)
        } else {
            updatePackage(packageData, packageIndex)
        }
        onCloseHandler()
    }, [name, description, amenities, paidServices, price, packageData])

    return <MuiModal
        open={open}
        onClose={onCloseHandler}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box sx={{...style}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 2,
                    ml: 4,
                    mr: 2
                }}>
                    <CloseIcon onClick={onCloseHandler}/>
                    <Typography sx={{ml: 3}} variant='titleLarge' color='neutral0'>{packageData === null ? addPackageLabel : editPackageLabel}</Typography>
                </Box>
                <Divider sx={{mt: 2}}/>
                <Container sx={{
                    maxWidth: '100% !important',
                    height: 'calc(100% - 56px)',
                    overflowY: 'auto'
                }}>
                    <Box sx={{
                        width: '100%',
                        maxWidth: {xs: '550px', md: '800px'},
                        margin: '0 auto'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <GroupPackagesNameForm
                                name={name}
                                onNameChangedHandler={onNameChangedHandler}
                            />
                            <GroupPackagesDescriptionForm
                                description={description}
                                onDescriptionChangedHandler={onDescriptionChangedHandler}
                            />
                            <GroupPackagesAmenitiesForm
                                groupAmenities={groupAmenities}
                                previous={packageData !== null ? (packageData.amenities as CatalogEntry[]) : null}
                                onChange={onAmenitiesChangedHandler}
                            />
                            <GroupPackagesPaidServicesForm
                                groupPaidServices={groupPaidServices}
                                previous={packageData !== null ? packageData.paidServices : null}
                                onChange={onPaidServicesChangedHandler}
                            />
                            <GroupPackagesPricesByOccupancy
                                workingTimes={workingTimes}
                                priceType={priceType}
                                packagePaidServices={paidServices}
                                packagePrice={parseInt(price)}
                            />
                            <GroupPackagesPriceForm
                                price={price}
                                onPriceChangeHandler={onPriceChangeHandler}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 3,
                            mb: 2
                        }}>
                            <Button 
                                sx={{mr: 3}} 
                                color="primary" 
                                variant='text'
                                onClick={onCloseHandler}
                            >
                                <Typography variant="labelLarge">
                                    {cancelText}
                                </Typography>
                            </Button>
                            <RoundedButton
                                loading={false}
                                variant="contained"
                                disabled={!isValid()}
                                onClick={onSaveButtonHandler}>
                                <Typography variant='labelLarge'>
                                    {saveText}
                                </Typography>
                            </RoundedButton>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    </MuiModal>
}

export default GroupPackagesCreateOrEditModal