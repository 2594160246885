import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core";
import { IPaidService } from "interfaces";
import { formatNumberPrice } from "utils";
import { BodyMedium, LabelSmall } from "./Typographys";
import { checkboxClasses, useMediaQuery, useTheme } from "@pankod/refine-mui";
import { useEffect, useState } from "react";

interface SpacePaidServiceCheckboxProps {
    paidService: IPaidService,
    isSelected: (paidService: IPaidService) => boolean,
    selectPaidService: (paidService: IPaidService, checked: boolean) => void,
    isIncludedInPackage?: boolean
}

const SpacePaidServiceCheckbox: React.FC<SpacePaidServiceCheckboxProps> = (props) => {
    const { paidService, isSelected, selectPaidService, isIncludedInPackage } = props;
    const isDisabled = paidService.mandatory || isIncludedInPackage
    const [isChecked, setChecked] = useState<boolean>(false);
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const paidServiceVariant = isSM ? 'titleSmall' : 'titleMedium'

    useEffect(() => {
        const isChecked = paidService.mandatory || isSelected(paidService) || isIncludedInPackage ? true : false
        setChecked(isChecked)
    }, [paidService, isIncludedInPackage, isSelected])
    
    const t = useTranslate();
    const servicePricePlaceholder = t('previewDetails.servicePricePlaceholder');
    const perEvent = t('previewDetails.perEvent');
    const perPerson = t('previewDetails.perPerson');
    const mandatory = t('previewDetails.mandatory');

    const price = servicePricePlaceholder
                .replace("${price}", formatNumberPrice(paidService.price))
                .replace("${type}", paidService.isByPerson ? perPerson : perEvent);

    return <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }}>
            <FormControlLabel
                disabled={isDisabled}
                control={
                    <Checkbox
                        checked={isChecked}
                        onChange={(event, checked) => {
                            selectPaidService(paidService, checked)
                        }}/>
                }
                label={<Typography variant={paidServiceVariant}>{paidService.name} {paidService.mandatory ? '('+mandatory+')' : ''} </Typography>}
            />
        </Box>
        <Typography variant={paidServiceVariant} sx={{color: 'outline.light', textAlign: 'right'}}>{price}</Typography>
    </Box>
}

export default SpacePaidServiceCheckbox;
