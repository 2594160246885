
import { DarkTheme } from "@pankod/refine-mui";
import { TypographyList } from "./typographyList";

export const CustomDarkTheme = {
  ...DarkTheme,
  typography: {
    ...DarkTheme.typography,
    ...TypographyList
  },
  palette: {
    ...DarkTheme.palette,
    primaryLine: {
      light: '#684DAC',
      main: '#684DAC',
      dark: '#D1BCFF'
    },
    primary30: {
      light: '#67be23',
      main: '#67be23',
      dark: '#67be23',
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    onSurfaceVariant: {
      light: '#FFFFFF',
      main: '#FFFFFF',
      dark: '#FFFFFF',
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    neutral80: {
      light: '#CAC5CA',
      main: '#CAC5CA',
      dark: '#CAC5CA',
    },
    neutral60: {
        light: '#FFFFFF',
        main: '#938F94',
        dark: '#FFFFFF',
    },
    onSecondaryContainer: {
      light: '#20005E',
      main: '#20005E',
      dark: '#E8DDFF',
    },
    outline: {
        light: '#79757F',
        main: '#79757F',
        dark: '#948F99',
    },
    surface: {
        light: '#684DAC1C',
        main: '#FFFBFE',
        dark: '#1D1B1F',
        contrastText: "rgba(0, 0, 0, 0.87)",
    },
    primaryContainer: {
        light: '#EADDFF',
        main: '#EADDFF',
        dark: '#503493',
    },
    onPrimary: {
        light: '#FFFFFF',
        main: '#FFFFFF',
        dark: '#39197B',
        contrastText: "rgba(0, 0, 0, 0.87)"
    },
    inverseOnSurface: {
        light: '#F4EFF4',
        main: '#F4EFF4',
    },
    neutral70: {
        light: '#AEAAAE',
        main: '#AEAAAE',
        dark: '#AEAAAE',
    },
    secondaryContainer: {
        light: '#E8DDFF',
        main: '#E8DDFF',
        dark: '#4C388B',
        contrastText: "rgba(0, 0, 0, 0.87)"
    },
    neutral50: {
        light: '#79767A',
        main: '#79767A',
        dark: '#79767A',
    },
  },
};


declare module '@mui/material/styles' {

  interface Palette {
    primaryLine: Palette['primary'];
    primary70: Palette['primary'];
    primary50: Palette['primary'];
    primary30: Palette['primary'];
    primary20: Palette['primary'];
    onPrimary: Palette['primary'];
    primaryContainer: Palette['primary'];
    onPrimaryContainer: Palette['primary'];

    onSecondary: Palette['primary'];
    secondaryContainer: Palette['primary'];
    onSecondaryContainer: Palette['primary'];

    tertiary: Palette['primary'];
    onTertiary: Palette['primary'];
    tertiaryContainer: Palette['primary'];
    onTertiaryContainer: Palette['primary'];

    onError: Palette['primary'];
    errorContainer: Palette['primary'];
    onErrorContainer: Palette['primary'];

    neutral80: Palette['primary'];
    neutral70: Palette['primary'];
    neutral60: Palette['primary'];
    neutral50: Palette['primary'];
    neutral40: Palette['primary'];
    neutral10: Palette['primary'];
    neutral0: Palette['primary'];
    neutralBackground: Palette['primary'];
    onBackground: Palette['primary'];
    surface: Palette['primary'];
    onSurface: Palette['primary'];

    surfaceVariant: Palette['primary'];
    onSurfaceVariant: Palette['primary'];
    outline: Palette['primary'];
    secondarySelected: Palette['primary'];
    primaryOpacity: Palette['primary'];
    errorRef: Palette['primary'];
    primary95: Palette['primary'];
    green: Palette['primary'];
    inverseOnSurface: Palette['primary'];

    azul100: Palette['primary'];
    success1: Palette['primary'];
    tertiary99: Palette['primary'];

    footer: Palette['primary'];
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    displayLarge: true;
    displayMedium: true;
    displaySmall: true;
    headlineLarge: true;
    headlineMedium: true;
    headlineSmall: true;
    titleLarge: true;
    titleMedium: true;
    titleSmall: true;
    labelLarge: true;
    labelMedium: true;
    labelSmall: true;
    bodyLarge: true;
    bodyMedium: true;
    bodySmall: true;
    menu: true;
  }
}