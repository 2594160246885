import { Button, Stack, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import moment from "moment"
import { PriceTypes, WorkingTime } from "interfaces/space"
import { useTranslate } from "@pankod/refine-core"
import { checkHasOverlapTimes } from "utils"
import GroupWorkingTimesDayTime from "./GroupWorkingTimesDayTime"

export const DEFAULT_WORKING_TIME_START = '00:00'
export const DEFAULT_WORKING_TIME_END = '00:00'

interface GroupWorkingTimesDayTimesProps {
    workingTime: WorkingTime
    index: number
    updateWorkingTime: (index: number, workingTime: WorkingTime) => void
    priceType: PriceTypes
}

const GroupWorkingTimesDayTimes: React.FC<GroupWorkingTimesDayTimesProps> = (props) => {
    const { 
        workingTime, 
        index, 
        updateWorkingTime
    } = props

    const t = useTranslate()
    const addTimeLabel = t("updateSpace.addTime")
    const overlapError = t("updateSpace.spaceTimeRangeOverlapError")
    const severalWorkingTimesLabel = t("updateSpace.groupSeveralWorkingTimes")

    const getLastHour = () => {
        const lastTime = workingTime.times[workingTime.times.length - 1]
        const hours = lastTime.split('-')
        return hours[1]
    }

    const onAddTimeClickHandler = () => {
        const startString = workingTime.times.length === 0 ? DEFAULT_WORKING_TIME_START : getLastHour()
        const startHour = moment(startString, 'HH:mm')
        const endHour = workingTime.times.length === 0 ? moment(DEFAULT_WORKING_TIME_END, 'HH:mm') : startHour.clone().add(workingTime.maxHours, 'hours')
        const time = startHour.format('HH:mm') + '-' + endHour.format('HH:mm')
        const newWorkingTime = {...workingTime}
        newWorkingTime.times.push(time)
        updateWorkingTime(index, newWorkingTime)
    }

    const deleteTime = (timeIndex: number) => {
        const newWorkingTime = {...workingTime}
        newWorkingTime.times.splice(timeIndex, 1) 
        updateWorkingTime(index, newWorkingTime)
    }

    const updateTime = (timeIndex: number, time: string) => {
        const newWorkingTime = {...workingTime}
        newWorkingTime.times[timeIndex] = time
        updateWorkingTime(index, newWorkingTime)
    }

    const hasOverlapInTimes = checkHasOverlapTimes(workingTime.times)
    let errorText = '' 
    if(hasOverlapInTimes) {
        errorText = overlapError
    }

    return <Stack>
        <Typography variant="titleMedium" sx={{mt: 2}}>{severalWorkingTimesLabel}</Typography>
        {
            workingTime.times.map((time, index) => <GroupWorkingTimesDayTime 
                key={'working-times-day-time-' + index} 
                index={index}
                time={time}
                maxHours={workingTime.maxHours}
                updateTime={updateTime}
                deleteTime={deleteTime}
            />)
        }
        { errorText && <Typography sx={{mt: 2}} color='error' variant='labelLarge'>{errorText}</Typography> }
        <Button
            startIcon={<AddIcon/>}
            variant='text'
            sx={{mt: 2, width: 'max-content'}}
            onClick={onAddTimeClickHandler}
            disabled={!workingTime.maxHours}
        >
            <Typography variant='labelLarge'>{addTimeLabel}</Typography>
        </Button>
    </Stack>
}

export default GroupWorkingTimesDayTimes