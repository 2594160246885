export const spaceDetailConstants = {
    barOffset: 160,
    fieldCount: 14,
    name: 'name',
    spaceType: 'spaceType',
    description: 'description',
    location: 'location',
    cancellation: 'cancellation',
    rules: 'rules',
    cover: 'cover',
    pictures: 'pictures',
    plan: 'plan',
    video360: 'video360',
    workingDays: 'workingDays',
    workingHours: 'workingHours',
    daysToReserve: 'daysToReserve',
    daysOff: 'daysOff',
    eventType: 'eventType',
    amenities: 'amenities',
    occupancy: 'occupancy',
    price: 'price',
    paidServices: 'paidServices',
    guaranteeDeposit: 'guaranteeDeposit',
    packages: 'packages',
    groups: 'groups',
    documents: 'documents',
    scrollTimeout: 750
}