import { IBooking, IPaidService } from "interfaces";
import { Row, Label, Text, Subtitle } from "./components";
import { Box } from "@pankod/refine-mui";
import { useTranslate } from "@pankod/refine-core";
import { calculatePackagePrice, formatNumberPrice } from "utils";

interface PriceDetailProps {
    reservation: IBooking,
}


const PriceDetail: React.FC<PriceDetailProps> = (props) => {
    const { reservation } = props;
    const t = useTranslate();

    const packageAmount = reservation.package ? calculatePackagePrice(reservation.people, reservation.package.paidServices, reservation.price ? reservation.price : 0, reservation.package.price) : 0;
    const totalContractedServices = reservation.subtotal + reservation.ivaAmount;
    const grandTotal = reservation.guaranteeDeposit + reservation.total;

    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', padding: '8px 0px'}}>
        <Row>
            <Label>{t('reservation.services')}</Label>
            <Label>{t('reservation.unitPrice')}</Label>
            <Label>{t('reservation.total')}</Label>
        </Row>
        {
            packageAmount === 0 && <Row>
                <Label>{t('reservation.priceDetailBase')}</Label>
                <Text>{formatNumberPrice(reservation.price)}</Text>
            </Row>
        }
        {
            reservation.paidServices && reservation.paidServices.length > 0 && reservation.paidServices.map((paidService: IPaidService, index: number) => {
                return <Row key={'paid-service-' + index}>
                    <Label>{paidService.name}</Label>
                    { paidService.isByPerson && <Text>{formatNumberPrice(paidService.price)} {t('reservation.eachOne')}</Text>}
                    <Text>{formatNumberPrice(paidService.total!)}</Text>
                </Row>;
            })
        }
        {
            /* reservation.package && reservation.package.paidServices.length > 0 && reservation.package.paidServices.map((paidService: PaidService, index: number) => {
                return <Row key={'paid-service-' + index}>
                    <Label>{paidService.name}</Label>
                    <Text>{formatNumberPrice(paidService.price)}</Text>
                </Row>;
            }) */
        }
        {
            packageAmount > 0 && <Row>
                <Label>{t('reservation.packagePrice')}</Label>
                <Text>{formatNumberPrice(packageAmount)}</Text>
            </Row>
        }
        <Row>
            <Subtitle>{t('reservation.subtotal')}</Subtitle>
            <Subtitle sx={{color: "primary.main"}}>{formatNumberPrice(reservation.subtotal)}</Subtitle>
        </Row>
        <Row>
            <Label>{t('reservation.iva')} ({reservation.iva * 100}%)</Label>
            <Text>{formatNumberPrice(reservation.ivaAmount)}</Text>
        </Row>
        <Row>
            <Subtitle>{t('reservation.totalContractedServices')}</Subtitle>
            <Subtitle sx={{color: "primary.main"}}>{formatNumberPrice(totalContractedServices)}</Subtitle>
        </Row>
        <Row>
            <Label>{t('reservation.ifiestaCommision')} ({reservation.guestCommission * 100}%)</Label>
            <Text>{formatNumberPrice(reservation.guestCommissionAmount)}</Text>
        </Row>
        <Row>
            <Label>{t('reservation.ivaIfiestaCommision')} ({reservation.iva * 100}%)</Label>
            <Text>{formatNumberPrice(reservation.guestCommissionIvaAmount)}</Text>
        </Row>
        {
            reservation.guaranteeDeposit > 0 && <>
                <Row>
                    <Subtitle>{t('reservation.total')}</Subtitle>
                    <Subtitle sx={{color: "primary.main"}}>{formatNumberPrice(reservation.total)}</Subtitle>
                </Row>
                <Row>
                    <Label>{t('reservation.guaranteeDeposit')}</Label>
                    <Text>{formatNumberPrice(reservation.guaranteeDeposit)}</Text>
                </Row>
            </> 
        }
        <Row>
            <Subtitle>{t('reservation.grandTotal')}</Subtitle>
            <Subtitle sx={{color: "primary.main"}}>{formatNumberPrice(grandTotal)}</Subtitle>
        </Row>
        
    </Box>
}

export default PriceDetail;