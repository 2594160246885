import React, {useCallback, useEffect, useState} from "react"
import Box from "@mui/material/Box"
import {DateCalendar} from '@mui/x-date-pickers/DateCalendar'
import moment from "moment/moment"
import Typography from "@mui/material/Typography"
import { useMediaQuery, useTheme } from "@mui/material"
import { dateToUTCMoment, getSelectedWorkingTime } from "utils"
import { Group, GroupRange, SpaceDetail, WorkingTime } from "interfaces/space"
import { useTranslate } from "@pankod/refine-core"

export interface StringTimeRange {
    time: string,
    label: string
}

const getAvailableTimes = (date: Date, blockedSlotsMap: Map<string, string[]>, workingHourMap: Map<number, string[]>): string[] => {
    if (workingHourMap.size > 0) {
        const dateMoment = dateToUTCMoment(date)
        const stringDate = dateMoment.toISOString()
        const dayNumber = dateMoment.day()
        const selectedWorkingHours = workingHourMap.get(dayNumber)
        if(selectedWorkingHours) {
            const workingHours = [...selectedWorkingHours]
            const blockedTimes = blockedSlotsMap.get(stringDate) !== undefined ? blockedSlotsMap.get(stringDate) : []
            blockedTimes!.forEach((blockedTime) => {
                const index = findBloquedTimeIntoWorkingHours(workingHours, blockedTime)
                if (index > -1) {
                    workingHours.splice(index, 1)
                }
            })
            return workingHours
        }
    }
    return []
}

const findBloquedTimeIntoWorkingHours = (workingHours: string[], bloquedTime: string): number => {
    for(let index in workingHours) {
        const arrWorkingHour = workingHours[index].split('-')
        const arrWorkingHourStartHour = arrWorkingHour[0].split(':')
        const arrWorkingHourEndHour = arrWorkingHour[1].split(':')
        let workingHourStartDate = moment().set('hours', parseInt(arrWorkingHourStartHour[0])).set('minutes', parseInt(arrWorkingHourStartHour[1]))
        let workingHourEndDate = moment().set('hours', parseInt(arrWorkingHourEndHour[0])).set('minutes', parseInt(arrWorkingHourEndHour[1]))
        if(workingHourEndDate.isBefore(workingHourStartDate)) {
            workingHourEndDate.add(1, 'day')
        }

        const arrBloquedTime = bloquedTime.split('-')
        const arrBloquedTimeStartHour = arrBloquedTime[0].split(':')
        const arrBloquedTimeEndHour = arrBloquedTime[1].split(':')
        let bloquedTimeStartDate = moment().set('hours', parseInt(arrBloquedTimeStartHour[0])).set('minutes', parseInt(arrBloquedTimeStartHour[1]))
        let bloquedTimeEndDate = moment().set('hours', parseInt(arrBloquedTimeEndHour[0])).set('minutes', parseInt(arrBloquedTimeEndHour[1]))
        if(bloquedTimeEndDate.isBefore(bloquedTimeStartDate)) {
            bloquedTimeEndDate.add(1, 'day')
        }

        if(bloquedTimeStartDate.isSameOrAfter(workingHourStartDate) && bloquedTimeEndDate.isSameOrBefore(workingHourEndDate)) {
            return parseInt(index)
        }
    }
    return -1
}

interface SpaceWorkingDaysCalendarProps {
    adDetail: SpaceDetail
    selectedDate: Date | null
    setSelectedDate: (date: Date | null) => void
    selectedGroup: Group,
    setAvailableTimes: (availableTimes: string[]) => void
    setSelectedWorkingTime: (workingTime: WorkingTime | null) => void
    setSelectedEventHours: (eventHours: number | null) => void
    setSelectedTime: (time: string | null) => void
    setSelectedRange: (eventHours: GroupRange | null) => void
    setSelectedRangeIndex: (index: number | null) => void
    selectPeople: (people: number) => void
}

const SpaceWorkingDaysCalendar: React.FC<SpaceWorkingDaysCalendarProps> = (props) => {
    const {
        adDetail, 
        selectedDate, 
        setSelectedDate,
        selectedGroup,
        setAvailableTimes,
        setSelectedWorkingTime,
        setSelectedEventHours,
        setSelectedTime,
        setSelectedRange,
        setSelectedRangeIndex,
        selectPeople
    } = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'
    
    const [workingDays, setWorkingDays] = useState<number[]>([])
    const [blockedSlotsMap, setBlockedSlotsMap] = useState<Map<string, string[]>>(new Map())
    const [workingHourMap, setWorkingHourMap] = useState<Map<number, string[]>>(new Map())
    const [daysOffMap, setDaysOffMap] = useState<Map<string, string>>(new Map())

    useEffect(() => {
        const availableTimes = selectedDate !== null ? getAvailableTimes(selectedDate, blockedSlotsMap, workingHourMap) : []
        setAvailableTimes(availableTimes)
    }, [selectedDate, blockedSlotsMap, workingHourMap])

    const t = useTranslate();
    const title = t('previewDetails.adDatePickTitle')
    
    const in7Days = moment().add(7, 'days')
    const inOneYear = moment().add(1, 'year')

    const minToReserveFromSpace = moment().add(adDetail.daysToReserve?.minimum, 'days')
    const maxToReserveFromSpace = moment().add(adDetail.daysToReserve?.maximum, 'days')

    const minDate = moment.max(in7Days, minToReserveFromSpace)
    const maxDate = moment.min(inOneYear, maxToReserveFromSpace)

    const onDateChangedHandler = (newValue: any) => {
        const workingTime = getSelectedWorkingTime(selectedGroup, newValue)
        setSelectedDate(newValue)
        setSelectedWorkingTime(workingTime)
        setSelectedEventHours(null)
        setSelectedTime(null)
        setSelectedRange(null)
        setSelectedRangeIndex(null)
        selectPeople(0)
    }

    const shouldDisableDateHandler = useCallback((day: Date): boolean => {
        const dateMoment = dateToUTCMoment(day)
        const formattedDate = dateMoment.toISOString()
        
        if (daysOffMap.has(formattedDate)) return true
        const dayOfWeek = dateMoment.day()

        if (!workingDays.includes(dayOfWeek)) return true

        const blockedSlotsOnThatDay = blockedSlotsMap.get(formattedDate) !== undefined ? blockedSlotsMap.get(formattedDate) : []
        if (blockedSlotsOnThatDay!.length === 0) return false
        const workingTimes = workingHourMap.get(dayOfWeek) !== undefined ? workingHourMap.get(dayOfWeek) : []
        return blockedSlotsOnThatDay!.length === workingTimes!.length
    }, [workingDays, blockedSlotsMap, daysOffMap, workingHourMap])

    useEffect(() => {
        const workingDays: number[] = []
        setWorkingHourMap(new Map())
        selectedGroup.workingTimes.forEach(workingTime => {
            workingDays.push(workingTime.day)
            setWorkingHourMap((prevState) => {
                return new Map(prevState.set(workingTime.day, workingTime.times))
            })
        })
        setWorkingDays(workingDays)
    }, [selectedGroup])

    useEffect(() => {
        adDetail.blockedSlots.forEach((blockedSlot) => {
            setBlockedSlotsMap((prevState) => {
                return new Map(prevState.set(blockedSlot.date, blockedSlot.times))
            })
        })
    }, [adDetail.blockedSlots])

    useEffect(() => {
        adDetail.daysOff.forEach((dayOff) => {
            setDaysOffMap((prevState) => {
                return new Map(prevState.set(dayOff, ''))
            })
        })
    }, [adDetail.daysOff])

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column'
    }}>
        <Typography variant={titleVariant}>{title}</Typography>
        <Box sx={{
            width: '100%',
            display: 'flex',
            mt: '24px'
        }}>
            <DateCalendar
                shouldDisableDate={shouldDisableDateHandler}
                minDate={minDate.toDate()}
                maxDate={maxDate.toDate()}
                value={selectedDate}
                onChange={onDateChangedHandler}
                sx={{boxShadow: '0px 5.42px 5.42px 0px #00000040', width: '100%', maxWidth: '396px', borderRadius: '24px'}}
            />
        </Box>
    </Box>
}

export default SpaceWorkingDaysCalendar