import React from "react";
import {Result} from "typescript-monads";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { Exception } from "exception/Exception";
import { useTranslate } from "@pankod/refine-core";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import fieldConstants from "constants/fieldConstants";
import DataAsList from "components/DataAsList";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { spaceActions } from "store/space";
import { spaceDetailConstants } from "constants/spaceDetailConstants";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

export interface SpaceDescriptionFormProps {
    spaceDetail: SpaceDetail
}

export const isDescriptionEmpty = (data: SpaceDetail): boolean => data === undefined || data.description === undefined

const isDescriptionValid = (data: FormDescription): boolean => {
    return data !== undefined && data.description !== undefined && data?.description?.length > 10
}

const spaceDetailToDescription = (data: SpaceDetail): FormDescription => {
    return {description: data?.description}
}

export interface FormDescription {
    description?: string
}

const descriptionToList = (data: SpaceDetail): string[] => {
    return data.description ? [data.description] : []
}

const stateHandlers = [StateHandler.Error]

const SpaceDescriptionForm: React.FC<SpaceDescriptionFormProps> = (props) => {

    const {spaceDetail} = props

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    const t = useTranslate()
    const title = t("updateSpace.spaceDescriptionTitle")
    const subtitle = t("updateSpace.spaceDescriptionSubtitle")
    const hint = t("updateSpace.spaceDescriptionHint")

    const getErrorMessage = useSyncExceptionError(t)

    const {updateSpace} = useSpaceUpdate()

    const renderEditableForm = (updateFn: (data: FormDescription) => void, data?: FormDescription) => {
        const onDescriptionChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            const description = event.target.value
            const newData: FormDescription = {description: description}
            updateFn(newData)
        }

        const getLength = () => {
            if (data === undefined || data?.description === undefined) return 0
            return data.description.length
        }

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                mt: 3,
                pr: 8,
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                justifyContent: {xs: 'center', md: 'flex-start'},
                alignItems: {xs: 'flex-start', md: 'center'}
            }}>
                <TextField
                    margin="normal"
                    fullWidth
                    multiline
                    rows={5}
                    placeholder={hint}
                    helperText={`${getLength()}/${fieldConstants.defaultDescriptionLength}`}
                    id="descripcion"
                    name="descripcion"
                    onChange={onDescriptionChangeHandler}
                    value={data?.description}
                    inputProps={{
                        maxLength: fieldConstants.defaultDescriptionLength
                    }}
                />
            </Box>
        </Box>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <DataAsList data={descriptionToList(data)} fieldId={'hostRFC'}/>
        </Box>
    }

    const syncDataHandler = (data: FormDescription) => {
        const body = {description: data.description}
        return updateSpace(spaceDetail.id, body)
    }

    const dispatch = useAppDispatch()
    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    const isFocused = useAppSelector(state => state.space.currentFocusId === spaceDetailConstants.description)

    return <EditableSyncFormV2
        title={title}
        subtitle={subtitle}
        isFocused={isFocused}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        loadData={loadData}
        syncData={syncDataHandler}
        isFormDataValid={isDescriptionValid}
        isServerDataEmpty={isDescriptionEmpty}
        mapServerDataToFormData={spaceDetailToDescription}
        getErrorMessage={getErrorMessage}
        onSync={onSyncHandler}
        stateHandlers={stateHandlers}/>
}

export default SpaceDescriptionForm