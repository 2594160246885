import React, {useState} from "react";
import {getStorage, ref} from "firebase/storage";
import {useTranslation} from "react-i18next";
import {Result} from "typescript-monads";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { useTranslate } from "@pankod/refine-core";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import useStorageRef from "hooks/useStorageRef";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import { Exception } from "exception/Exception";
import ContactModal from "./ContactModal";
import DataAsList from "components/DataAsList";
import DocumentUpload, { getNameFromUrl } from "components/DocumentUpload";
import { useAppDispatch } from "store/hooks";
import { spaceActions } from "store/space";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

export interface FormDocuments {
    documentsUrls?: string[]
}

const spaceDetailToDocuments = (data: SpaceDetail): FormDocuments => {
    return {documentsUrls: data?.documentsUrls}
}

export const isDocumentsEmpty = (data: SpaceDetail): boolean => data === undefined || data?.documentsUrls === undefined || data?.documentsUrls.length === 0

const getArrayLength = (data?: FormDocuments): number => {
    if (data === undefined || data === null) return 0
    if (data.documentsUrls === undefined) return 0
    return data.documentsUrls.length
}

const idStateHandlers = [StateHandler.Error]

const documentUploadSuffix = 'spaceDocument'

export interface SpaceDocumentsFormProps {
    spaceDetail: SpaceDetail
    maxDocuments: number
    minDocuments: number
}

const SpaceDocumentsForm: React.FC<SpaceDocumentsFormProps> = (props) => {
    const {spaceDetail, maxDocuments, minDocuments} = props

    const t = useTranslate()
    const titleText = t('updateSpace.spaceDocumentsTitle')
    const subtitleText = t('updateSpace.spaceDocumentsSubtitle')
    const vca = t('updateSpace.vca')

    const document0 = t('updateSpace.spaceDocuments0')
    const document1 = t('updateSpace.spaceDocuments1')
    const document3 = t('updateSpace.spaceDocuments3')
    const document4 = t('updateSpace.spaceDocuments4')
    const document5 = t('updateSpace.spaceDocuments5')
    const document6 = t('updateSpace.spaceDocuments6')
    const document7 = t('updateSpace.spaceDocuments7')
    const document8 = t('updateSpace.spaceDocuments8')
    const documentList = [document0, document1, document3, document4, document5, document6, document7, document8]

    const hint = t('updateSpace.spaceDocumentsHint')
    const contact = t('updateSpace.homeHelpText')

    const storage = getStorage();
    const getErrorMessage = useSyncExceptionError(t)

    const getRef = useStorageRef()
    const [showEdit, setShowEdit] = useState<boolean>(true)
    const [openModal, setOpenModal] = React.useState(false);

    const {updateSpace} = useSpaceUpdate()

    const isDocumentsValid = (data: FormDocuments): boolean => {
        return data !== undefined && data?.documentsUrls !== undefined && data?.documentsUrls.length >= minDocuments
    }

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    const renderEditableForm = (updateFn: (data: FormDocuments) => void, data?: FormDocuments) => {

        const urlChangedHandler = (index: number, url: string | null) => {
            if (index === -1) {
                if (url !== null) {
                    const newArray = (data?.documentsUrls ? data.documentsUrls : []).concat(url)
                    const newData: FormDocuments = {documentsUrls: newArray}
                    updateFn(newData)
                }
            } else {
                if (url !== null) {
                    const newArray = (data?.documentsUrls ? [...data.documentsUrls] : [])
                    newArray.splice(index, 1, url)
                    const newData: FormDocuments = {documentsUrls: newArray}
                    updateFn(newData)
                } else {
                    const newArray = (data?.documentsUrls ? [...data.documentsUrls] : [])
                    newArray.splice(index, 1)
                    const newData: FormDocuments = {documentsUrls: newArray}
                    updateFn(newData)
                }
            }
        }

        return <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <ContactModal open={openModal} onClose={() => {
                setOpenModal(false)
            }}/>
            <Typography variant='labelLarge' sx={{mt: '8px'}} onClick={() => {
                setOpenModal(true)
            }}>
                <Link>{contact}</Link>
            </Typography>
            <Typography variant='labelLarge' sx={{mt: '8px'}} onClick={() => {
                window.open('https://www.ifiesta.com.mx/documents/quality_policies');
            }}>
                <Link>{vca}</Link>
            </Typography>
            <DataAsList data={documentList} fieldId={'document-list'}/>
            <Box sx={{mt: 3}}>
                {(getArrayLength(data) < maxDocuments) &&
                    <DocumentUpload
                        spaceDetailId={spaceDetail.id}
                        downloadUrl={null}
                        title={hint}
                        onUrlChange={(url) => {
                            urlChangedHandler(-1, url)
                        }}/>}
            </Box>
            <Box sx={{mt: 3}}>
                {data?.documentsUrls && data.documentsUrls.map((documentUrl, index) => {
                    return <DocumentUpload
                        key={`documentUpload-${spaceDetail.id.substring(0, 10)}-${index}`}
                        spaceDetailId={spaceDetail.id}
                        downloadUrl={documentUrl}
                        title={hint}
                        onUrlChange={(url) => {
                            urlChangedHandler(index, url)
                        }}/>

                })}
            </Box>
        </Box>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        return <Box sx={{mt: 2, display: 'flex', flexDirection: 'column'}}>
            {data?.documentsUrls && data?.documentsUrls.map((url, index) => {
                return <Typography key={`docUrl-${index}-${url.substring(0, 10)}`} variant='bodyLarge'>
                    {getNameFromUrl(url)}
                </Typography>
            })}
        </Box>
    }

    const syncDataHandler = (data: FormDocuments) => {
        const body = {documentsUrls: data.documentsUrls}
        return updateSpace(spaceDetail.id, body)
    }

    const dispatch = useAppDispatch()
    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    return <EditableSyncFormV2
        title={titleText}
        subtitle={subtitleText}
        isFocused={false}
        loadData={loadData}
        syncData={syncDataHandler}
        getErrorMessage={getErrorMessage}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        mapServerDataToFormData={spaceDetailToDocuments}
        isServerDataEmpty={isDocumentsEmpty}
        isFormDataValid={isDocumentsValid}
        stateHandlers={idStateHandlers}
        showEdit={showEdit}
        onSync={onSyncHandler}
    />
}

export default SpaceDocumentsForm