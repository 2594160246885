import React, {useCallback, useEffect, useRef, useState} from "react";
import SpaceDetailsMenu, {DetailsMenu} from "./SpaceDetailsMenu";
import SpaceDetailsGeneralInfo from "./generalInfo/SpaceDetailsGeneralInfo";
import SpaceDetailsMultimedia from "./SpaceDetailsMultimedia";
import SpaceDetailsCalendar from "./SpaceDetailsCalendar";
import SpaceDetailsServices from "./SpaceDetailsServices";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import SpaceDetailsAppBar from "./SpaceDetailsAppBar";
import debounce from 'lodash.debounce';
import { spaceDetailConstants } from "constants/spaceDetailConstants";
import { scrollToTop } from "utils/scroll";
import useOnScreen from "hooks/useOnScreen";
import { Focusable } from "components/Focusable";
import SpaceDetailsDocuments from "./SpaceDetailsDocuments";
import { SpaceDetail } from "interfaces/space";

interface SpaceDetailsProps {
    spaceDetail: SpaceDetail
    omittedFields: string[]
    addOmittedField: (field: string) => void
}

const SpaceDetailsForm: React.FC<SpaceDetailsProps> = (props) => {

    const {spaceDetail, omittedFields, addOmittedField} = props

    const generalRef = useRef<HTMLElement>(null)
    const mediaRef = useRef<HTMLElement>(null)
    const calendarRef = useRef<HTMLElement>(null)
    const serviceRef = useRef<HTMLElement>(null)
    const documentsRef = useRef<HTMLElement>(null)
    const barOffset = spaceDetailConstants.barOffset

    const [selectedItem, setSelectedItem] = useState<DetailsMenu>(DetailsMenu.GeneralInfo);
    const [withShortcut, setWithShortcut] = useState(false)

    const onScrollApplied = useCallback((item: DetailsMenu) => {

    }, [])

    const onItemSelected = (item: DetailsMenu) => {
        setWithShortcut(true)
        switch (item) {
            case DetailsMenu.GeneralInfo:
                scrollToTop(generalRef, barOffset)
                break;
            case DetailsMenu.Multimedia:
                scrollToTop(mediaRef, barOffset)
                break;
            case DetailsMenu.Calendar:
                scrollToTop(calendarRef, barOffset)
                break;
            case DetailsMenu.Services:
                scrollToTop(serviceRef, barOffset)
                break;
            case DetailsMenu.Documents:
                scrollToTop(documentsRef, barOffset)
                break;
        }
        setSelectedItem(item)
        setTimeout(() => {
            setWithShortcut(false)
        }, 750)
    }

    const isGeneralInfoVisible = useOnScreen(generalRef)
    const isMultimediaVisible = useOnScreen(mediaRef)
    const isCalendarVisible = useOnScreen(calendarRef)
    const isServicesVisible = useOnScreen(serviceRef)
    const isDocumentsVisible = useOnScreen(documentsRef)

    const handleUpdateMenu = useCallback(() => {
            const debounced = debounce(() => {
                if (!withShortcut) {
                    if (isGeneralInfoVisible) {
                        setSelectedItem(DetailsMenu.GeneralInfo)
                    }
                    if (isMultimediaVisible) {
                        setSelectedItem(DetailsMenu.Multimedia)
                    }
                    if (isCalendarVisible) {
                        setSelectedItem(DetailsMenu.Calendar)
                    }
                    if (isServicesVisible) {
                        setSelectedItem(DetailsMenu.Services)
                    }
                    if (isDocumentsVisible) {
                        setSelectedItem(DetailsMenu.Documents)
                    }
                }
            }, 300)
            debounced()
        }, [isCalendarVisible, isDocumentsVisible, isGeneralInfoVisible, isMultimediaVisible, isServicesVisible, withShortcut]
    )

    useEffect(() => {
        // clean up code
        window.removeEventListener('scroll', handleUpdateMenu);
        window.addEventListener('scroll', handleUpdateMenu, {passive: true});
        return () => window.removeEventListener('scroll', handleUpdateMenu);    
    }, [handleUpdateMenu]);

    return <Box sx={{display: 'flex'}}>
        <SpaceDetailsAppBar spaceDetail={spaceDetail}/>
        <Drawer
            variant="permanent"
            sx={{
                width: {xs: '0px', md: '30%'},
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: {xs: '0px', md: '30%'}, boxSizing: 'border-box', borderWidth: 0},
            }}>
            <Toolbar/>
            <Box sx={{width: '100%', px: 8, pt: 4}}>
                <SpaceDetailsMenu selectedItem={selectedItem} onItemSelected={onItemSelected}/>
            </Box>
        </Drawer>
        <Box component="main" sx={{flexGrow: 1, pl: 8}}>
            <Toolbar/>
            <Toolbar/>
            <Focusable ref={generalRef}>
                <SpaceDetailsGeneralInfo 
                    spaceDetail={spaceDetail} 
                    onScrollApplied={onScrollApplied}
                />
            </Focusable>
            <Box sx={{pt: 8}}>
                <Focusable ref={mediaRef}>
                    <SpaceDetailsMultimedia 
                        spaceDetail={spaceDetail} 
                        onScrollApplied={onScrollApplied} 
                        omittedFields={omittedFields} 
                        addOmittedField={addOmittedField}
                    />
                </Focusable>
            </Box>
            <Box sx={{pt: 8}}>
                <Focusable ref={calendarRef}>
                    <SpaceDetailsCalendar 
                        spaceDetail={spaceDetail} 
                        onScrollApplied={onScrollApplied} 
                        omittedFields={omittedFields} 
                        addOmittedField={addOmittedField}
                    />
                </Focusable>
            </Box>
            <Box sx={{pt: 8}}>
                <Focusable ref={serviceRef}>
                    <SpaceDetailsServices 
                        spaceDetail={spaceDetail} 
                        onScrollApplied={onScrollApplied}
                    />
                </Focusable>
            </Box>
            <Box sx={{pt: 8, pb: 16}}>
                <Focusable ref={documentsRef}>
                    <SpaceDetailsDocuments spaceDetail={spaceDetail} onScrollApplied={onScrollApplied}/>
                </Focusable>
            </Box>
        </Box>
    </Box>
}

export default SpaceDetailsForm