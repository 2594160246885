import { useAppDispatch, useAppSelector } from "store/hooks";
import { spaceActions } from "store/space";
import useViewModel from '../../../viewModels/SpaceDetailsViewModel'
import SpaceDetailsForm from "components/spaces/update/SpaceDetailsForm";
import { useEffect } from "react";

interface UpdateSpaceProps {
    spaceId: string
}

export const UpdateSpace: React.FC<UpdateSpaceProps> = (props) => {
    const {spaceId} = props
    const dispatch = useAppDispatch()
    const refresh = useAppSelector(state => state.space.refreshSpaceDetail)
    const { isLoading, error, spaceDetail, omittedFields, addOmittedField, reloadSpaceDetail }  = useViewModel(spaceId) 

    useEffect(() => {
        if (refresh) {
            dispatch(spaceActions.setRefreshSpaceDetail(false))
            reloadSpaceDetail()
        }
    }, [refresh])

    if(isLoading) {
        return <>Cargando anuncio.</>
    }

    if(error || !spaceDetail) {
        return <>Error al cargar el anuncio.</> 
    }

    return <SpaceDetailsForm spaceDetail={spaceDetail!} omittedFields={omittedFields} addOmittedField={addOmittedField}/>
}