import React from "react";
import {Result} from "typescript-monads";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { Exception } from "exception/Exception";
import { useTranslate } from "@pankod/refine-core";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import { CatalogEntry } from "interfaces/catalogEntry";
import { useAppDispatch, useAppSelector } from "store/hooks";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import { findValueById } from "utils";
import DataAsList from "components/DataAsList";
import { spaceActions } from "store/space";
import { spaceDetailConstants } from "constants/spaceDetailConstants";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

interface SpaceTypeFormProps {
    spaceDetail: SpaceDetail
}

interface FormType {
    id?: string,
    label?: string
}

export const isTypeEmpty = (data: SpaceDetail): boolean => data === undefined || data.spaceType === undefined

const isTypeValid = (data: FormType): boolean => {
    return data !== undefined && data.id !== undefined && data.label !== undefined
}

const stateHandlers = [StateHandler.Error]

const SpaceTypeForm: React.FC<SpaceTypeFormProps> = (props) => {

    const {spaceDetail} = props

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }
    const t = useTranslate()
    const title = t("updateSpace.spaceTypeTitle")
    const subtitle = t("updateSpace.spaceTypeSubtitle")
    const hint = t("updateSpace.spaceTypeHint")
    const options: CatalogEntry[] = useAppSelector(state => state.catalog.spaces)

    const getErrorMessage = useSyncExceptionError(t)

    const {updateSpace} = useSpaceUpdate()
    const dispatch = useAppDispatch()

    const spaceDetailToType = (data: SpaceDetail): FormType => {
        const spaceTypeId = data.spaceType !== undefined ? (data.spaceType as CatalogEntry).id : undefined
        const entry = findValueById(options, spaceTypeId)
        return {id: spaceTypeId, label: entry?.value}
    }

    const renderEditableForm = (updateFn: (data: FormType) => void, data?: FormType) => {

        const onOptionSelected = (event: any, newValue: FormType | null) => {
            if (newValue === null) {
                updateFn({id: undefined, label: undefined})
            } else {
                updateFn(newValue)
            }
        }

        const typeOptions = options.map((entry) => {
            return {label: entry.value, id: entry.id}
        })

        const entry = findValueById(options, data?.id)
        const value = entry ? {label: entry.value, id: entry.id} : null
        return <Box>
            {options.length > 0 && <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                pr: 4,
                pt: 3
            }}>
                <Autocomplete
                    disablePortal
                    id="space-type"
                    value={value}
                    options={typeOptions}
                    onChange={onOptionSelected}
                    renderInput={(params: any) => <TextField {...params} label={hint}/>}
                />
            </Box>
            }
        </Box>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        const spaceTypeId = data.spaceType !== undefined ? (data.spaceType as CatalogEntry).id : undefined
        const entry = findValueById(options, spaceTypeId)
        return <>
            {options.length > 0 && <Box sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <DataAsList data={[entry!.value]} fieldId={'space-type'}/>
            </Box>}
        </>
    }

    const syncDataHandler = (data: FormType) => {
        const body = {spaceType: data.id}
        return updateSpace(spaceDetail.id, body)
    }

    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    const isFocused = useAppSelector(state => state.space.currentFocusId === spaceDetailConstants.spaceType)

    return <EditableSyncFormV2
        title={title}
        subtitle={subtitle}
        isFocused={isFocused}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        loadData={loadData}
        syncData={syncDataHandler}
        isFormDataValid={isTypeValid}
        isServerDataEmpty={isTypeEmpty}
        mapServerDataToFormData={spaceDetailToType}
        getErrorMessage={getErrorMessage}
        onSync={onSyncHandler}
        stateHandlers={stateHandlers}/>
}

export default SpaceTypeForm