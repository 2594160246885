import { TextField, styled } from "@mui/material";

const SearchInputTextField = styled(TextField)(({theme}) => ({
    '.MuiInputBase-root': {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        color: theme.palette.onSurface.light,
        '&:before': {
            borderBottom: 'none !important',
        },
        '&:after': {
            borderBottom: 'none !important',
        }
    },
    '.MuiInputBase-input': {
        padding: '0px !important',
        minHeight: '20px',
        height: '20px',
        '&::placeholder': {
            color: theme.palette.outline.light,
            opacity: '1'
        },
    },
    [theme.breakpoints.up('md')]: {
        '.MuiInputBase-root': {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
        },
        '.MuiInputBase-input': {
            minHeight: '24px',
            height: '24px',
        }
    }
}));

export default SearchInputTextField;