import { InputAdornment } from "@mui/material"
import { TextField, useTheme } from "@pankod/refine-mui"
import { NumberFormatValues, NumericFormat } from "react-number-format"

interface GroupWorkingTimesDayPriceInputProps {
    value: number | string
    onValueChange: (values: NumberFormatValues) => void
    disabled: boolean
}

const GroupWorkingTimesDayPriceInput: React.FC<GroupWorkingTimesDayPriceInputProps> = (props) => {
    const { value, onValueChange, disabled } = props
    const theme = useTheme();

    return <NumericFormat
        sx={{
            maxWidth: '121px', 
            '.MuiOutlinedInput-input': {
                padding: '6px 16px 6px 0px',
                textAlign: 'center',
                fontFamily: 'Montserrat',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '20px'
            },
            '.MuiOutlinedInput-notchedOutline': {
                borderRadius: '8px',
                borderColor: theme.palette.outline.light
            }
        }}
        placeholder={''}
        InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
        }}
        value={value}
        name="costo-del-accesorio"
        customInput={TextField}
        type="text"
        thousandSeparator
        onValueChange={onValueChange}
        disabled={disabled}
    />
}

export default GroupWorkingTimesDayPriceInput


{/* <NumericFormat
        placeholder={''}
        value={value}
        customInput={TextfieldOutlinedPrice}
        type="text"
        thousandSeparator
        onValueChange={onValueChange}
        disabled={disabled}
    /> */}