import { Box } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import { SpaceDetail } from "interfaces/space"
import { useState } from "react"
import AdSpaceTypeTag from "./AdSpaceTypeTag"
import { CatalogEntry } from "interfaces/catalogEntry"
import AdStamps from "./AdStamps"
import AdActionButton from "./AdActionButton"
import AdPicturesModal from "./AdPicturesModal"

interface AdActionsProps {
    spaceDetail: SpaceDetail
    pictureIndex: number
    openModal: boolean
    setOpenModal: (status: boolean) => void
}

const AdActions: React.FC<AdActionsProps> = (props) => {
    const { spaceDetail, pictureIndex, openModal, setOpenModal } = props
    let totalPictures = spaceDetail.coverPictureUrl ? 1 : 0
    totalPictures += spaceDetail.picturesUrls ? spaceDetail.picturesUrls.length : 0
    totalPictures += spaceDetail.floorPlanUrl ? 1 : 0

    const t = useTranslate()
    const showMorePictures = t('previewDetails.showMorePictures')
    const showVideoTour = t('previewDetails.showVideoTour')

    const onShowMorePictures = () => {
        setOpenModal(true)
    }

    const onShowVideoTour = () => {
        window.open(spaceDetail.video360Url)
    }

    return <>
        <Box sx={{
            display: 'flex',
            flexDirection: {xs: 'column-reverse', md: 'row'},
            justifyContent: {xs: 'flex-start', md: 'space-between'},
            gap: {xs: '16px', md: 0}
        }}>
            <Box sx={{display: 'flex', gap: '16px', alignItems: 'center'}}>
                <AdSpaceTypeTag spaceType={spaceDetail.spaceType as CatalogEntry} onFilterSpaceType={() => {}}/>
                <AdStamps 
                    verificationStamp={spaceDetail.verificationStamp} 
                    guaranteeStamp={spaceDetail.guaranteeStamp}
                ></AdStamps>
            </Box>
            <Box sx={{display: 'flex', justifyContent: {xs: 'space-between', md: 'flex-start'}, gap: '24px'}}>
                { totalPictures > 5 && <AdActionButton label={showMorePictures} onClick={onShowMorePictures}/>}
                { spaceDetail.video360Url && <AdActionButton label={showVideoTour} onClick={onShowVideoTour}/>}
            </Box>
        </Box>
        <AdPicturesModal
            spaceDetail={spaceDetail}
            openModal={openModal}
            setOpenModal={setOpenModal}
            pictureIndex={pictureIndex}
        />
    </> 
}

export default AdActions