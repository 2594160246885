import { Box } from "@pankod/refine-mui";
import { IPayment } from "interfaces";
import { Subtitle } from "./components";
import { useTranslate } from "@pankod/refine-core";
import Payment from "./payment";

interface PaymentsProps {
    payments: IPayment[]
}

const Payments: React.FC<PaymentsProps> = (props) => {
    const { payments } = props;
    const t = useTranslate();

    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px 0px'}}>
        <Subtitle>{t('reservation.paymentsAmounts')}</Subtitle>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            {
                payments.map((payment, index) => {
                    return <Payment
                        key={'payment-' + index}
                        payment={payment}
                        index={index}
                        totalPayments={payments.length}
                    ></Payment>
                })
            }
        </Box>
    </Box>
}

export default Payments;