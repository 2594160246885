import React, { createContext, useEffect, useState } from "react";
import { ThemeProvider } from "@pankod/refine-mui";
import { CustomLightTheme } from "theme/customLightTheme";
import { CustomDarkTheme } from "theme/customDarkTheme";

type ColorModeContextType = {
  mode: string;
  setMode: () => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
  {} as ColorModeContextType
);

export const ColorModeContextProvider: React.FC = ({ children }) => {
  const colorModeFromLocalStorage = localStorage.getItem("colorMode");
  const isSystemPreferenceDark = window?.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;

  const systemPreference = isSystemPreferenceDark ? "dark" : "light";
  const [mode, setMode] = useState(
    colorModeFromLocalStorage || systemPreference
  );

  useEffect(() => {
    window.localStorage.setItem("colorMode", mode);
  });

  const setColorMode = () => {
    const modeSelected = mode === 'light' ? 'dark' : 'light';
    setMode(modeSelected);
    window.localStorage.setItem("colorMode", modeSelected);
  };

  return (
    <ColorModeContext.Provider
      value={{
        setMode: setColorMode,
        mode,
      }}
    >
      <ThemeProvider theme={mode === "light" ? CustomLightTheme : CustomDarkTheme}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
