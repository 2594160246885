import React, {MouseEventHandler} from "react";
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import { useTranslate } from "@pankod/refine-core";

export interface SpaceDetailsMenuProps {
    selectedItem: DetailsMenu
    onItemSelected: (selection: DetailsMenu) => void
}

export enum DetailsMenu {
    GeneralInfo = "menuGeneralInfo",
    Multimedia = "menuMultimedia",
    Calendar = "menuCalendar",
    Services = "menuServices",
    Documents = "menuDocuments"
}

const SpaceDetailsMenu: React.FC<SpaceDetailsMenuProps> = (props) => {
    const theme = useTheme()
    const t = useTranslate();
    const {selectedItem, onItemSelected} = props

    const handleMenuItemClick = (
        event: React.MouseEvent,
        item: DetailsMenu,
    ) => {
        onItemSelected(item)
    };

    const getColorVariant = (current: DetailsMenu) => {
        if (current === selectedItem) {
            return theme.palette.primary30.main
        } else {
            return theme.palette.onSurfaceVariant.main
        }
    }

    return <MenuList>
        <SpaceMenuItem
            selected={DetailsMenu.GeneralInfo === selectedItem}
            onClick={(event) => handleMenuItemClick(event, DetailsMenu.GeneralInfo)}
            color={getColorVariant(DetailsMenu.GeneralInfo)}
            text={t(`updateSpace.${DetailsMenu.GeneralInfo.valueOf()}`)}/>
        <SpaceMenuItem
            selected={DetailsMenu.Multimedia === selectedItem}
            onClick={(event) => handleMenuItemClick(event, DetailsMenu.Multimedia)}
            color={getColorVariant(DetailsMenu.Multimedia)}
            text={t(`updateSpace.${DetailsMenu.Multimedia.valueOf()}`)}/>
        <SpaceMenuItem
            selected={DetailsMenu.Calendar === selectedItem}
            onClick={(event) => handleMenuItemClick(event, DetailsMenu.Calendar)}
            color={getColorVariant(DetailsMenu.Calendar)}
            text={t(`updateSpace.${DetailsMenu.Calendar.valueOf()}`)}/>
        <SpaceMenuItem
            selected={DetailsMenu.Services === selectedItem}
            onClick={(event) => handleMenuItemClick(event, DetailsMenu.Services)}
            color={getColorVariant(DetailsMenu.Services)}
            text={t(`updateSpace.${DetailsMenu.Services.valueOf()}`)}/>
        <SpaceMenuItem
            selected={DetailsMenu.Documents === selectedItem}
            onClick={(event) => handleMenuItemClick(event, DetailsMenu.Documents)}
            color={getColorVariant(DetailsMenu.Documents)}
            text={t(`updateSpace.${DetailsMenu.Documents.valueOf()}`)}/>
    </MenuList>
}

export interface SpaceMenuItemProps {
    selected: boolean
    onClick: MouseEventHandler
    color: string
    text: string
}

const SpaceMenuItem: React.FC<SpaceMenuItemProps> = (props) => {
    const {selected, onClick, color, text} = props
    return <MenuItem
        sx={{height: "56px", borderRadius: '100px'}}
        selected={selected}
        onClick={onClick}>
        <Typography variant="menu" color={color}>{text}</Typography>
    </MenuItem>
}
export default SpaceDetailsMenu