import { Stack, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import { GroupRange, WorkingTime } from "interfaces/space"
import { formatNumberPrice } from "utils"

interface GroupDetailWorkingTimeProps {
    workingTime: WorkingTime
}

const GroupDetailWorkingTime: React.FC<GroupDetailWorkingTimeProps> = (props) => {
    const { workingTime } = props

    const t = useTranslate()
    const priceByEventLabel = t("updateSpace.spaceGroupsPriceTypeByEvent")
    const priceByPersonLabel = t("updateSpace.spaceGroupsPriceTypeByPerson")
    const eventDurationTitle = t("updateSpace.eventDuration")
    const hoursLabel = t("updateSpace.hours")
    const workingTimesTitle = t("updateSpace.workingTimes")
    const occupancyAndPricesTitle = t("updateSpace.spaceOccupancyAndPrices")
    const minPeopleAllowedLabel = t("updateSpace.groupRangeMinPeopleAllowed")
    const maxPeopleAllowedLabel = t("updateSpace.groupRangeMaxPeopleAllowed")
    const priceByHourText = t("updateSpace.priceByHour")
    
    const monday = t("updateSpace.monday")
    const tuesday = t("updateSpace.tuesday")
    const wednesday = t("updateSpace.wednesday")
    const thursday = t("updateSpace.thursday")
    const friday = t("updateSpace.friday")
    const saturday = t("updateSpace.saturday")
    const sunday = t("updateSpace.sunday")

    const getDayById = (id: string): string => {
        switch (id) {
            case  "0":
                return sunday
            case  "1":
                return monday
            case  "2":
                return tuesday
            case  "3":
                return wednesday
            case  "4":
                return thursday
            case  "5":
                return friday
            case  "6":
                return saturday
            default :
                return sunday
        }
    }
    
    const dayName = getDayById(workingTime.day.toString())
    
    return <Stack spacing={0.5}>
        <Typography variant="titleLarge">{dayName}</Typography>
        <Typography variant="titleMedium">{eventDurationTitle}</Typography>
        <Typography variant="bodyLarge">{workingTime.maxHours} {hoursLabel}</Typography>
        <Typography variant="titleMedium">{workingTimesTitle}</Typography>
        <Stack>
            {
                workingTime.times.map((time: string, index: number) => <Typography key={'group-detail-working-time-time-' + index} variant="bodyLarge">{time}</Typography>)
            }
        </Stack>
        <Typography variant="titleMedium">{occupancyAndPricesTitle}</Typography>
        <Stack spacing={1}>
            {
                workingTime.ranges.map((range: GroupRange, index: number) => {
                    return <Stack key={'group-detail-working-time-range-' + index}>
                        <Typography variant="bodyLarge">{minPeopleAllowedLabel}: {range.occupancy.minimum}</Typography>
                        <Typography variant="bodyLarge">{maxPeopleAllowedLabel}: {range.occupancy.maximum}</Typography>
                        <Typography variant="bodyLarge">{priceByEventLabel}: {formatNumberPrice(range.prices.byEvent)}</Typography>
                        <Typography variant="bodyLarge">{priceByPersonLabel}: {formatNumberPrice(range.prices.byPerson)}</Typography>
                        <Typography variant="bodyLarge">{priceByHourText}: {formatNumberPrice(range.prices.byHour)}</Typography>
                    </Stack>
                })
            }
        </Stack>
    </Stack>
}

export default GroupDetailWorkingTime