import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import SpaceAmenitiesCollapsable from "./SpaceAmenitiesCollapsable";
import { CatalogEntry } from "interfaces/catalogEntry";
import { GroupPackage } from "interfaces/space";
import { useTranslate } from "@pankod/refine-core";
import Divider from "components/Divider";

interface SpaceAmenitiesTextProps {
    spaceAmenities: CatalogEntry[] | string[],
    selectedPackage?: GroupPackage,
}

const SpaceAmenitiesText: React.FC<SpaceAmenitiesTextProps> = (props) => {
    const { spaceAmenities, selectedPackage } = props;
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'
    const textVariant = isSM ? 'bodyMedium' : 'bodyLarge'
    
    const t = useTranslate();
    const amenitiesIncluded = t('previewDetails.amenitiesIncluded');
    
    if(spaceAmenities.length === 0 || (selectedPackage && selectedPackage.amenities.length === 0)) {
        return <></>
    }

    return <Stack spacing={4}>
        <Divider/>
        <Stack spacing={2}>
            <Typography variant={titleVariant}>{amenitiesIncluded}</Typography>
            <SpaceAmenitiesCollapsable
                    spaceAmenities={spaceAmenities}
                    selectedPackage={selectedPackage}
                    textVariant={textVariant}
            ></SpaceAmenitiesCollapsable>
        </Stack>
    </Stack> 
}

export default SpaceAmenitiesText;
