import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core";
import { PriceTypes } from "interfaces/space";

interface GroupPriceTypeFormProps {
    priceType?: string;
    onPriceTypeChangedHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const GroupPriceTypeForm: React.FC<GroupPriceTypeFormProps> = (props) => {
    const { priceType, onPriceTypeChangedHandler } = props

    const t = useTranslate()
    const title = t("updateSpace.spaceGroupsPriceTypeTitle")
    const subtitle = t("updateSpace.spaceGroupsPriceTypeSubtitle")
    const optionByEvent = t("updateSpace.spaceGroupsPriceTypeByEvent")
    const optionByPerson = t("updateSpace.spaceGroupsPriceTypeByPerson")
    const optionByHour = t("updateSpace.spaceGroupsPriceTypeByHour")
    
    return <Stack>
        <Typography variant="titleMedium">{title}</Typography>
        <Typography variant="labelLarge" sx={{mt: 1, mb: 2, color: 'neutral50.light'}}>{subtitle}</Typography>
        <FormControl>
            <RadioGroup
                value={priceType}
                onChange={onPriceTypeChangedHandler}

            >
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '6px'}}>
                    <FormControlLabel 
                        value={PriceTypes.ByEvent} 
                        control={<Radio />} 
                        label={<Typography variant="bodyLarge">{optionByEvent}</Typography>} 
                    />
                    <FormControlLabel 
                        value={PriceTypes.ByPerson}
                        control={<Radio />} 
                        label={<Typography variant="bodyLarge">{optionByPerson}</Typography>} 
                    />
                    <FormControlLabel 
                        value={PriceTypes.ByHour} 
                        control={<Radio />} 
                        label={<Typography variant="bodyLarge">{optionByHour}</Typography>} 
                    />
                </Box>
            </RadioGroup>
        </FormControl>
    </Stack>
}

export default GroupPriceTypeForm