import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-mui";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import { useTranslation } from "react-i18next";
import { ColorModeContextProvider } from "contexts";
import { SignIn } from "pages/login";
import { UsersList, UsersEdit } from "pages/users";
import { CatalogCU, CatalogList } from "pages/catalogs";
import { Title, Sider, Layout, Header } from "components/layout";
import app from "./providers/firebase";
import FirebaseAuth from "./providers/firebaseAuth";
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import StyleIcon from '@mui/icons-material/Style';
import CelebrationIcon from '@mui/icons-material/Celebration';
import StadiumIcon from '@mui/icons-material/Stadium';
import FestivalIcon from '@mui/icons-material/Festival';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from "axios";
import { AdvertisementEdit, AdvertisementList, AdvertisementDetails, AdvertisementAvailability } from "pages/advertisements";
import { BookingShow, BookingsList } from "pages/bookings";
import { FinancesList } from "pages/finances";
import { SettingsEdit, SettingsList } from "pages/settings";
import { AdvertisementUpdate } from "pages/advertisements/update";
import paths from "constants/paths";

const axiosInstance = axios.create()
const firebaseAuth = new FirebaseAuth(app, axiosInstance);

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
    default: 'es'
  };

  return (
    <ColorModeContextProvider>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <Refine
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          routerProvider={{
            ...routerProvider,
            routes: [
                 {
                    element: <AdvertisementDetails />,
                    path: paths.adDetails + "/:id",
                    layout: false
                },
                 {
                    element: <AdvertisementAvailability />,
                    path: paths.adAvailability + "/:id",
                    layout: false
                },
                 {
                    element: <AdvertisementUpdate />,
                    path: "/advertisements/update/:id",
                    layout: false
                },
            ],
        }}
          dataProvider={dataProvider(process.env.REACT_APP_SERVICES_HOST as string, axiosInstance)}
          authProvider={firebaseAuth.getAuthProvider()}
          LoginPage={SignIn}
          resources={[
            {
              name: "users",
              list: UsersList,
              edit: UsersEdit,
              icon: <PlagiarismIcon color="primary"/>,
              options: { label: t("users.title") }
            }, {
              name: "advertisements",
              list: AdvertisementList,
              edit: AdvertisementEdit,
              icon: <AnnouncementIcon color="primary"/>,
              options: { label: t("advertisements.title") }
            }, {
              name: "bookings",
              list: BookingsList,
              show: BookingShow,
              icon: <BookmarksIcon color="primary"/>,
              options: { label: t("bookings.title") }
            }, {
              name: "finances",
              list: FinancesList,
              icon: <MonetizationOnIcon color="primary"/>,
              options: { label: t("finances.title") }
            }, {
              name: "catalogs/spaces",
              list: CatalogList,
              create: CatalogCU,
              //edit: CatalogCU,
              icon: <FestivalIcon color="primary"/>,
              options: { label: t("catalogs.spaces") }
            }, {
              name: "catalogs/events",
              list: CatalogList,
              create: CatalogCU,
              //edit: CatalogCU,
              icon: <CelebrationIcon color="primary"/>,
              options: { label: t("catalogs.events") }
            }, {
              name: "catalogs/amenities",
              list: CatalogList,
              create: CatalogCU,
              //edit: CatalogCU,
              icon: <StadiumIcon color="primary"/>,
              options: { label: t("catalogs.amenities") }
            }, {
              name: "catalogs/tags",
              list: CatalogList,
              create: CatalogCU,
              //edit: CatalogCU,
              icon: <StyleIcon color="primary"/>,
              options: { label: t("catalogs.tags") }
            }
            , {
              name: "settings",
              list: SettingsList,
              edit: SettingsEdit,
              icon: <SettingsIcon color="primary"/>,
              options: { label: t("settings.title") }
            }
          ]}
          Title={Title}
          Sider={Sider}
          Layout={Layout}
          Header={Header}
          i18nProvider={i18nProvider}
        />
      </RefineSnackbarProvider>
    </ColorModeContextProvider>
  );
}

export default App;
