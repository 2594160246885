import { Box, Chip, Typography } from "@mui/material"
import Icon from "components/Icon"

interface AdEventTypeTagProps {
    icon: string
    label: string
}

const AdEventTypeTag: React.FC<AdEventTypeTagProps> = (props) => {
    const { icon, label } = props

    return <Box sx={{
        display: 'flex', 
        gap: '8px', 
        padding: '6px 12px 6px 8px', 
        border: '1px solid', 
        borderColor: 'outline.light',
        borderRadius: '8px'
    }}>
        <Icon
            containerWidth={18}
            containerHeight={18}
            iconWidth={18}
            iconHeight={18}
            iconSrc={icon}
        />
        <Chip sx={{
            backgroundColor: 'transparent',
            height: '20px',
            '.MuiChip-label': {
                paddingLeft: '0px'
            }
        }}
            label={<Typography variant="labelLarge">{label}</Typography>}/>
    </Box>
}

export default AdEventTypeTag