import React, {useState} from "react";
import {Result} from "typescript-monads";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {StaticDatePicker} from "@mui/x-date-pickers";
import MuiModal from "@mui/material/Modal";
import TodayIcon from '@mui/icons-material/Today';
import moment from "moment/moment";
import {Divider} from "@mui/material";
import {es} from 'date-fns/locale'
// @ts-ignore
import * as locales from 'react-date-range/dist/locale';
import { SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { useTranslate } from "@pankod/refine-core";
import { Exception } from "exception/Exception";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import RoundedButton from "components/RoundedButton";
import DataAsList from "components/DataAsList";
import { useAppDispatch } from "store/hooks";
import { spaceActions } from "store/space";
import EditableSyncFormV2 from "components/EditableSyncFormV2";
import {DateRange, Range} from 'react-date-range';
import DayChipGroup from "./DayChipGroup";
import { sortDateArray } from "utils";

export interface SpaceDaysOffFormProps {
    spaceDetail: SpaceDetail,
    onSkip: () => void,
    isOmitted?: boolean
}

export const isDaysOffEmpty = (data: SpaceDetail, isOmitted?: boolean): boolean => !isOmitted && (data === undefined || data.daysOff === undefined || data.daysOff.length === 0) 

const isDaysOffValid = (data: FormDaysOff): boolean => {
    return data !== undefined
}

const spaceDetailToDaysOff = (data: SpaceDetail): FormDaysOff => {
    const set = new Set<string>()
    data.daysOff.forEach((date) => {
        set.add(date)
    })
    return {daysOff: set}
}

export interface FormDaysOff {
    daysOff: Set<string>
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24
};

const stateHandlers = [StateHandler.Error]

const SpaceDaysOffForm: React.FC<SpaceDaysOffFormProps> = (props) => {

    const {spaceDetail, onSkip, isOmitted} = props

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    const t = useTranslate()
    const title = t("updateSpace.spaceDaysOffTitle")
    const subtitle = t("updateSpace.spaceDaysOffSubtitle")
    const singleButtonText = t("updateSpace.spaceDaysOffSingle")
    const rangeButtonText = t("updateSpace.spaceDaysOffRange")
    const okText = t("updateSpace.confirm")
    const cancelText = t("updateSpace.cancel")
    const startDateText = t("updateSpace.startDate")
    const endDateText = t("updateSpace.endDate")

    const getErrorMessage = useSyncExceptionError(t)

    const [openSingleModal, setOpenSingleModal] = useState(false)
    const [openRangeModal, setOpenRangeModal] = useState(false)

    const initialRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }

    const [dateRangeState, setDateRangeState] = useState<Range[]>([initialRange]);

    const daysToReserveToList = (data: SpaceDetail | null): string[] => {
        if(data === null) {
            return []
        }
        return data.daysOff.map((date) => {
            return moment(date, "YYYY-MM-DDTHH:mm:ss.SSSZ").add(1, 'days').format('D MMM')
        })
    }

    const {updateSpace} = useSpaceUpdate()

    const renderEditableForm = (updateFn: (data: FormDaysOff) => void, data?: FormDaysOff) => {

        const dateButtonHandler = () => {
            setOpenSingleModal(true)
        }

        const dateCloseHandler = () => {
            setOpenSingleModal(false)
        }

        const rangeButtonHandler = () => {
            setOpenRangeModal(true)
        }

        const rangeCloseHandler = () => {
            setOpenRangeModal(false)
        }

        const onDateChangeHandler = (date: Date | null) => {
            if (date !== null) {
                const newMoment = moment(date)
                // @ts-ignore
                const deepClonedSet = data?.daysOff ? new Set<string>(JSON.parse(JSON.stringify([...data!.daysOff]))) : new Set<string>()
                const newDate = newMoment.format('YYYY/MM/DD')
                deepClonedSet.add(newDate)
                updateFn({daysOff: deepClonedSet})
            }
        }

        const onRangeChangeHandler = (range: Range[]) => {
            if (range[0] !== undefined) {
                // @ts-ignore
                const deepClonedSet = data?.daysOff ? new Set<string>(JSON.parse(JSON.stringify([...data!.daysOff]))) : new Set<string>()
                if (range[0].startDate !== undefined) {
                    if (range[0].endDate === undefined) {
                        const startDate = moment(range[0].startDate)
                        const newDate = startDate.format('YYYY/MM/DD')
                        deepClonedSet.add(newDate)
                        setOpenRangeModal(false)
                        updateFn({daysOff: deepClonedSet})
                    } else {
                        const startDate = moment(range[0].startDate)
                        const endDate = moment(range[0].endDate)
                        while (startDate.isBefore(endDate)) {
                            const newDate = startDate.format('YYYY/MM/DD')
                            deepClonedSet.add(newDate)
                            startDate.add(1, 'days');
                        }
                        const newDate = startDate.format('YYYY/MM/DD')
                        deepClonedSet.add(newDate)
                        setOpenRangeModal(false)
                        updateFn({daysOff: deepClonedSet})
                    }
                    setDateRangeState([initialRange])
                }
            }
        }

        const onDayRemovedHandler = (date: string) => {
            // @ts-ignore
            const deepClonedSet = data?.daysOff ? new Set<string>(JSON.parse(JSON.stringify([...data!.daysOff]))) : new Set<string>()
            deepClonedSet.delete(date)
            updateFn({daysOff: deepClonedSet})
        }

        const dayArray: string[] = []
        data?.daysOff.forEach((date) => {
            dayArray.push(date)
        })

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <MuiModal
                open={openSingleModal}
                onClose={dateCloseHandler}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{...style}}>
                    <Box sx={{width: '400px', height: '300px', overflowY: 'auto'}}>
                        <DayChipGroup days={dayArray} onDayRemoved={onDayRemovedHandler}/>
                    </Box>
                    <Divider sx={{mt: 2}}/>
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        value={null}
                        onChange={onDateChangeHandler}
                    />
                    <Box sx={{width: '400px', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                        <RoundedButton
                            id="submit_button"
                            fullWidth
                            variant="contained"
                            sx={{mb: 2, mr: 2, width: '120px'}}
                            size='large'
                            onClick={() => {
                                setOpenSingleModal(false)
                            }}>
                            <Typography variant='labelLarge'>{okText}</Typography>
                        </RoundedButton>
                    </Box>
                </Box>
            </MuiModal>

            <MuiModal
                open={openRangeModal}
                onClose={rangeCloseHandler}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{...style}}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                            <DateRange
                                editableDateInputs={false}
                                onChange={item => setDateRangeState([item.selection])}
                                moveRangeOnFirstSelection={false}
                                ranges={dateRangeState}
                                locale={locales['es']}
                                startDatePlaceholder={startDateText}
                                endDatePlaceholder={endDateText}
                            />
                        </LocalizationProvider>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                px: '24px',
                                py: '8px'
                            }}>
                            <RoundedButton
                                variant="text"
                                onClick={() => {
                                    setOpenRangeModal(false)
                                }}>
                                <Typography variant='labelLarge'>{cancelText}</Typography>
                            </RoundedButton>
                            <RoundedButton
                                variant="contained"
                                onClick={() => {
                                    onRangeChangeHandler(dateRangeState)
                                    setOpenRangeModal(false)
                                }}>
                                <Typography variant='labelLarge'>{okText}</Typography>
                            </RoundedButton>
                        </Box>
                    </Box>
                </Box>
            </MuiModal>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 3
            }}>
                <DayChipGroup days={dayArray} onDayRemoved={onDayRemovedHandler}/>
                <Button
                    startIcon={<TodayIcon/>}
                    variant='outlined'
                    id="date_button"
                    sx={{borderRadius: '8px', mt: 3, width: {xs: '200px', md: '300px'}}}
                    onClick={dateButtonHandler}>
                    <Typography variant='labelLarge'>{singleButtonText}</Typography>
                </Button>
                <Button
                    startIcon={<TodayIcon/>}
                    variant='outlined'
                    id="date_button"
                    sx={{borderRadius: '8px', mt: 2, width: {xs: '200px', md: '380px'}}}
                    onClick={rangeButtonHandler}>
                    <Typography variant='labelLarge'>{rangeButtonText}</Typography>
                </Button>
            </Box>
        </Box>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <DataAsList data={daysToReserveToList(data)} fieldId={'name'}/>
        </Box>
    }

    const syncDataHandler = (data: FormDaysOff) => {
        const array: string[] = []
        data.daysOff.forEach((date) => {
            array.push(date)
        })
        sortDateArray(array)
        const body = {daysOff: array}
        return updateSpace(spaceDetail.id, body)
    }

    const dispatch = useAppDispatch()
    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    return <EditableSyncFormV2
        title={title}
        subtitle={subtitle}
        isFocused={false}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        loadData={loadData}
        syncData={syncDataHandler}
        isFormDataValid={isDaysOffValid}
        isServerDataEmpty={isDaysOffEmpty}
        mapServerDataToFormData={spaceDetailToDaysOff}
        getErrorMessage={getErrorMessage}
        stateHandlers={stateHandlers}
        onSync={onSyncHandler}
        optional={true}
        onSkip={onSkip}
        isOmitted={isOmitted}
    />
}

export default SpaceDaysOffForm