import { useCreate, useDelete, useUpdate } from "@pankod/refine-core";
import { Exception } from "exception/Exception";
import { NetworkException } from "exception/NetworkException";
import { Group, SpaceDetail } from "interfaces/space";
import { useAppDispatch } from "store/hooks";
import { spaceActions } from "store/space";
import {Result} from "typescript-monads";

const useGroups = () => {

    const dispatch = useAppDispatch()
    const { mutate: mutateCreate } = useCreate();
    const { mutate: mutateUpdate } = useUpdate();
    const { mutate: mutateDelete } = useDelete();

    const reloadSpace = async (spaceId: string): Promise<Result<SpaceDetail, Exception>> => {
        const response = await fetch(`${process.env.REACT_APP_SERVICES_HOST as string}/advertisements/${spaceId}`)
        const data = await response.json();
        return new Promise((resolve, reject) => {
            if(data) {
                resolve(Result.ok(data as any))
            } else {
                reject(Result.fail(new NetworkException(500, 'Network Error')))
            }
        })
    }

    const createGroup = async (spaceId: string, update: Group): Promise<Result<Group, Exception>> => {
        return new Promise((resolve, reject) => {
            mutateCreate({
                resource: 'advertisements/' + spaceId + "/groups",
                values: update,
            },
            {
                onError: (error) => {
                    reject(Result.fail(new NetworkException(error.statusCode, error.message)))
                },
                onSuccess: (data) => {
                    dispatch(spaceActions.setRefreshSpaceDetail(true))
                    resolve(Result.ok(data.data as any))
                }
            });
        })
    }

    const updateGroup = async (spaceId: string, groupId: string, update: Group): Promise<Result<Group, Exception>> => {
        return new Promise((resolve, reject) => {
            mutateUpdate(
            {
                resource: 'advertisements/' + spaceId + "/groups",
                values: update,
                id: groupId
            },
            {
                onError: (error) => {
                    reject(Result.fail(new NetworkException(error.statusCode, error.message)))
                },
                onSuccess: (data) => {
                    dispatch(spaceActions.setRefreshSpaceDetail(true))
                    resolve(Result.ok(data.data as any))
                }
            });
        });
    }

    const deleteGroup = async (spaceId: string, groupId: string): Promise<Result<void, Exception>> => {
        return new Promise((resolve, reject) => {
            mutateDelete(
            {
                resource: 'advertisements/' + spaceId + "/groups",
                id: groupId
            },
            {
                onError: (error) => {
                    reject(Result.fail(new NetworkException(error.statusCode, error.message)))
                },
                onSuccess: (data) => {
                    dispatch(spaceActions.setRefreshSpaceDetail(true))
                    resolve(Result.ok(data.data as any))
                }
            });
        });
    }

    return {reloadSpace, createGroup, updateGroup, deleteGroup}
}

export default useGroups