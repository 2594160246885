import { Box, Stack, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import { Occupancy, PriceTypes, WorkingTime } from "interfaces/space"
import GroupWorkingTimesDayMaxHours from "./GroupWorkingTimesDayMaxHours"
import GroupWorkingTimesDayTimes from "./GroupWorkingTimesDayTimes"
import Divider from "components/Divider"
import GroupWorkingTimesDayRanges from "./GroupWorkingTimesDayRanges"

interface GroupWorkingTimesDayFormProps {
    workingTime: WorkingTime
    index: number
    updateWorkingTime: (index: number, workingTime: WorkingTime) => void
    priceType: PriceTypes
    spaceOccupancy: Occupancy
    totalWorkingTimes: number
    onCopyWorkingTimesHandler: () => void
}

const GroupWorkingTimesDayForm: React.FC<GroupWorkingTimesDayFormProps> = (props) => {
    const { 
        workingTime, 
        index, 
        updateWorkingTime, 
        priceType, 
        spaceOccupancy, 
        totalWorkingTimes, 
        onCopyWorkingTimesHandler
    } = props

    const t = useTranslate()
    const description = t("updateSpace.eventMaxHours")
    const monday = t("previewDetails.monday")
    const tuesday = t("previewDetails.tuesday")
    const wednesday = t("previewDetails.wednesday")
    const thursday = t("previewDetails.thursday")
    const friday = t("previewDetails.friday")
    const saturday = t("previewDetails.saturday")
    const sunday = t("previewDetails.sunday")

    const getDayById = (id: string): string => {
        switch (id) {
            case  "0":
                return sunday
            case  "1":
                return monday
            case  "2":
                return tuesday
            case  "3":
                return wednesday
            case  "4":
                return thursday
            case  "5":
                return friday
            case  "6":
                return saturday
            default :
                return sunday
        }
    }

    return <Stack>
        <Typography variant="titleLarge">{getDayById(workingTime.day.toString())}</Typography>
        <Typography variant="titleMedium" sx={{mt: 1, mb: 1}}>{description}</Typography>
        <GroupWorkingTimesDayMaxHours 
            index={index}    
            workingTime={workingTime}    
            updateWorkingTime={updateWorkingTime}  
            spaceOccupancy={spaceOccupancy}  
        />
        <GroupWorkingTimesDayTimes 
            index={index}    
            workingTime={workingTime}    
            updateWorkingTime={updateWorkingTime}  
            priceType={priceType}
        />
        <GroupWorkingTimesDayRanges
            index={index}    
            workingTime={workingTime}    
            updateWorkingTime={updateWorkingTime} 
            priceType={priceType}
            spaceOccupancy={spaceOccupancy}
            totalWorkingTimes={totalWorkingTimes}
            onCopyWorkingTimesHandler={onCopyWorkingTimesHandler}
        />
        <Box sx={{mt: 3}}>
            <Divider/>
        </Box>
    </Stack>
}

export default GroupWorkingTimesDayForm