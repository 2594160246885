import React, {useCallback, useState} from "react";
import MuiModal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Checkbox} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {NumericFormat} from "react-number-format";
import {NumberFormatValues, SourceInfo} from "react-number-format/types/types";
import { PaidService } from "interfaces/space";
import { useTranslate } from "@pankod/refine-core";
import SelectableChip from "components/SelectableChip";
import RoundedButton from "components/RoundedButton";
import { parseCommaPrice } from "utils";

interface PaidServicesCreateFormModalProps {
    open: boolean,
    onClose: () => void
    onSavePaidService: (paidService: PaidService) => void
    isPackage: boolean
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '28px'
};

const personId = 'person'
const eventId = 'event'

const PaidServicesCreateFormModal: React.FC<PaidServicesCreateFormModalProps> = (props) => {

    const {open, onClose, onSavePaidService, isPackage} = props

    const t = useTranslate()
    const addGeneralServiceTitle = t("updateSpace.spacePaidServicesDialogTitle")
    const addPackageServiceTitle = t("updateSpace.spacePackagesPaidServicesDialogTitle")

    const addServiceNameHint = t("updateSpace.spacePaidServicesDialogName")
    const addServicePriceHint = t("updateSpace.spacePaidServicesDialogPrice")
    const addServicePersonHint = t("updateSpace.spacePaidServicesDialogPersonOption")
    const addServiceEventHint = t("updateSpace.spacePaidServicesDialogEventOption")
    const addServiceMandatoryHint = t("updateSpace.spacePaidServicesDialogMandatory")
    const mandatoryTextGeneral = t("updateSpace.spacePaidServicesDialogMandatoryGeneral")
    const mandatoryTextPackage = t("updateSpace.spacePaidServicesDialogMandatoryPackage")

    const addServiceTitle = isPackage ? addPackageServiceTitle : addGeneralServiceTitle
    const mandatoryText = isPackage ? mandatoryTextPackage : mandatoryTextGeneral

    const cancelText = t("buttons.cancel")
    const saveText = t("buttons.save")

    const [newServiceName, setNewServiceName] = useState('')
    const [newServicePrice, setNewServicePrice] = useState('')
    const [newServiceByPerson, setNewServiceByPerson] = useState(false)
    const [newServiceByEvent, setNewServiceByEvent] = useState(false)
    const [newServiceMandatory, setNewServiceMandatory] = useState(true)

    const clearDialog = () => {
        setNewServiceName('')
        setNewServicePrice('0.00')
        setNewServiceByPerson(false)
        setNewServiceByEvent(false)
        onClose()
    }

    const newServiceDialogCloseHandler = () => {
        clearDialog()
    }

    const onNameChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewServiceName(event.target.value)
    }

    const onPriceChangeHandler = useCallback((values: NumberFormatValues, sourceInfo: SourceInfo) => {
        const value = values.formattedValue
        setNewServicePrice(value)
    }, [])

    const onSaveNewServiceHandler = () => {
        onSavePaidService({
            name: newServiceName,
            price: parseCommaPrice(newServicePrice),
            isByPerson: newServiceByPerson,
            mandatory: newServiceMandatory
        })
        clearDialog()
    }

    const onChipToggleHandler = (id: string) => {
        if (id === personId) {
            setNewServiceByPerson(true)
            setNewServiceByEvent(false)
        }
        if (id === eventId) {
            setNewServiceByPerson(false)
            setNewServiceByEvent(true)
        }
    }

    return <MuiModal
        open={open}
        onClose={newServiceDialogCloseHandler}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box sx={{...style}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: '560px',
                height: '480px'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 2,
                    ml: 4,
                    mr: 2
                }}>
                    <Typography sx={{flexGrow: 1}} variant='titleSmall'
                                color='neutral0'>{addServiceTitle}</Typography>
                    <CloseIcon onClick={newServiceDialogCloseHandler}/>
                </Box>
                <Divider sx={{mt: 2}}/>
                <TextField
                    margin="normal"
                    placeholder={addServiceNameHint}
                    id="nombre-del-servicio"
                    name="nombre-del-servicio"
                    onChange={onNameChangedHandler}
                    value={newServiceName}
                    sx={{mt: 4, ml: 4, mr: 6}}
                />
                <NumericFormat
                    sx={{width: '250px', mt: 2, ml: 4}}
                    placeholder={addServicePriceHint}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    value={newServicePrice}
                    name="costo-del-accesorio"
                    customInput={TextField}
                    type="text"
                    thousandSeparator
                    onValueChange={onPriceChangeHandler}
                />
                <Typography variant="labelMedium" sx={{ml: 4, mt: 2, mr: 6}}>
                    {mandatoryText}
                </Typography>
                <FormControlLabel
                    sx={{
                        ml: 3
                    }}
                    control={
                        <Checkbox
                            checked={newServiceMandatory}
                            onChange={(event, checked) => {
                                setNewServiceMandatory(checked)
                            }}/>
                    }
                    label={addServiceMandatoryHint}/>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 2,
                    ml: 4
                }}>
                    <SelectableChip
                        item={{id: personId, value: addServicePersonHint}}
                        selected={newServiceByPerson}
                        onChipToggle={() => {
                            onChipToggleHandler(personId)
                        }}/>
                    <SelectableChip
                        item={{id: eventId, value: addServiceEventHint}}
                        selected={newServiceByEvent}
                        onChipToggle={() => {
                            onChipToggleHandler(eventId)
                        }}/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mt: 6,
                    mr: 4,
                    mb: 2
                }}>
                    <Button sx={{mr: 3}} color="primary" variant='text' onClick={newServiceDialogCloseHandler}>
                        <Typography variant="labelLarge">
                            {cancelText}
                        </Typography>
                    </Button>
                    <RoundedButton
                        loading={false}
                        variant="contained"
                        disabled={newServiceName.length === 0 || newServicePrice.length === 0 || parseCommaPrice(newServicePrice) <= 0 || (!newServiceByPerson && !newServiceByEvent)}
                        onClick={onSaveNewServiceHandler}>
                        <Typography variant='labelLarge'>
                            {saveText}
                        </Typography>
                    </RoundedButton>
                </Box>
            </Box>
        </Box>
    </MuiModal>
}

export default PaidServicesCreateFormModal