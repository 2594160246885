import React, {useCallback, useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {DetailsMenu} from "./SpaceDetailsMenu";
import { SpaceDetail } from "interfaces/space";
import { useTranslate } from "@pankod/refine-core";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { spaceDetailConstants } from "constants/spaceDetailConstants";
import { scrollToTop } from "utils/scroll";
import { spaceActions } from "store/space";
import { Focusable } from "components/Focusable";
import SpaceCoverForm from "./multimedia/SpaceCoverForm";
import SpacePicturesForm from "./multimedia/SpacePicturesForm";
import SpaceFloorPlanForm from "./multimedia/SpaceFloorPlanForm";
import SpaceVideo360Form from "./multimedia/SpaceVideo360Form";

interface SpaceDetailsMultimediaProps {
    spaceDetail: SpaceDetail
    onScrollApplied: (item: DetailsMenu) => void
    omittedFields: string[]
    addOmittedField: (field: string) => void
}

const SpaceDetailsMultimedia: React.FC<SpaceDetailsMultimediaProps> = (props) => {

    const {spaceDetail, onScrollApplied, omittedFields, addOmittedField} = props
    const t = useTranslate();
    const header = t('updateSpace.menuMultimedia')

    const coverRef = useRef<HTMLElement>(null)
    const picturesRef = useRef<HTMLElement>(null)
    const planRef = useRef<HTMLElement>(null)
    const video360Ref = useRef<HTMLElement>(null)

    const dispatch = useAppDispatch()
    const currentFocusField = useAppSelector(state => state.space.currentFocusId)
    const shouldFocus = useAppSelector(state => state.space.shouldFocus)

    const [isPlanOmitted, setIsPlanOmitted] = useState<boolean>(false);
    const [isVideo360Omitted, setIsVideo360Omitted] = useState<boolean>(false);

    const focusCurrentField = useCallback(() => {
        if (shouldFocus) {
            switch (currentFocusField) {
                case spaceDetailConstants.cover:
                    scrollToTop(coverRef, spaceDetailConstants.barOffset)
                    dispatch(spaceActions.setShouldFocus(false))
                    onScrollApplied(DetailsMenu.Multimedia)
                    break;
                case spaceDetailConstants.pictures:
                    scrollToTop(picturesRef, spaceDetailConstants.barOffset)
                    dispatch(spaceActions.setShouldFocus(false))
                    onScrollApplied(DetailsMenu.Multimedia)
                    break;
                case spaceDetailConstants.plan:
                    scrollToTop(planRef, spaceDetailConstants.barOffset)
                    dispatch(spaceActions.setShouldFocus(false))
                    onScrollApplied(DetailsMenu.Multimedia)
                    break;
                case spaceDetailConstants.video360:
                    scrollToTop(video360Ref, spaceDetailConstants.barOffset)
                    dispatch(spaceActions.setShouldFocus(false))
                    onScrollApplied(DetailsMenu.Multimedia)
                    break;
            }
        }
    }, [currentFocusField, dispatch, onScrollApplied, shouldFocus])

    //Requires empty dep array to only run once
    useEffect(() => {
        setTimeout(focusCurrentField, spaceDetailConstants.scrollTimeout)
    }, [])

    focusCurrentField()
    
    const onSkipPlan = useCallback(() => {
        setIsPlanOmitted(true);
        addOmittedField(spaceDetailConstants.plan)
    }, []);

    const onSkipVideo360 = useCallback(() => {
        setIsVideo360Omitted(true);
        addOmittedField(spaceDetailConstants.video360)
    }, []);

    useEffect(() => {
        if(omittedFields.length > 0) {
            if(omittedFields.includes(spaceDetailConstants.plan)) {
                setIsPlanOmitted(true);
            } 
            if(omittedFields.includes(spaceDetailConstants.video360)) {
                setIsVideo360Omitted(true);
            }
        }
    }, [omittedFields]);

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
    }}>
        <Typography variant="headlineLarge">{header}</Typography>
        <Box sx={{pt: 6, pr: 4}}>
            <Focusable ref={coverRef}>
                <SpaceCoverForm spaceDetail={spaceDetail}/>
            </Focusable>
        </Box>
        <Box sx={{pt: 4, pr: 4}}>
            <Focusable ref={picturesRef}>
                <SpacePicturesForm spaceDetail={spaceDetail} maxPictures={30} minPictures={3}/>
            </Focusable>
        </Box>
        <Box sx={{pt: 4, pr: 4}}>
            <Focusable ref={planRef}>
                <SpaceFloorPlanForm spaceDetail={spaceDetail} onSkip={onSkipPlan} isOmitted={isPlanOmitted}/>
            </Focusable>
        </Box>
        <Box sx={{pt: 4, pr: 4}}>
            <Focusable ref={video360Ref}>
                <SpaceVideo360Form spaceDetail={spaceDetail} onSkip={onSkipVideo360} isOmitted={isVideo360Omitted}/>
            </Focusable>
        </Box>
    </Box>
}

export default SpaceDetailsMultimedia