import React from "react";
import {Result} from "typescript-monads";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { Exception } from "exception/Exception";
import { useTranslate } from "@pankod/refine-core";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import fieldConstants from "constants/fieldConstants";
import DataAsList from "components/DataAsList";
import { useAppDispatch } from "store/hooks";
import { spaceActions } from "store/space";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

export interface SpaceOccupancyFormProps {
    spaceDetail: SpaceDetail
}

export const isOccupancyEmpty = (data: SpaceDetail): boolean => data === undefined || data.occupancy === undefined

const isMinLowerThanMax = (data?: FormOccupancy): boolean => {
    if (data === undefined) return true
    if (data?.minimum === undefined) return true
    return data?.maximum !== undefined && data.minimum < data.maximum
}

const isOccupancyValid = (data: FormOccupancy): boolean => {
    return data !== undefined && data?.maximum !== undefined && isMinLowerThanMax(data)
}

const spaceDetailToOccupancy = (data: SpaceDetail): FormOccupancy => {
    return {minimum: data?.occupancy?.minimum, maximum: data?.occupancy?.maximum}
}

export interface FormOccupancy {
    minimum?: number
    maximum?: number
}

const stateHandlers = [StateHandler.Error]

export const occupancyToList = (data: SpaceDetail, minTitle: string, maxTitle: string): string[] => {
    return data?.occupancy ? (
        data?.occupancy?.minimum ? [
            `${minTitle} - ${data.occupancy?.minimum}`,
            `${maxTitle} - ${data.occupancy?.maximum}`
        ] : [`${maxTitle} - ${data.occupancy?.maximum}`]
    ) : []
}

const SpaceOccupancyForm: React.FC<SpaceOccupancyFormProps> = (props) => {

    const {spaceDetail} = props

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    const t = useTranslate()
    const title = t("updateSpace.spaceOccupancyTitle")
    const subtitle = t("updateSpace.spaceOccupancySubtitle")
    const min_title = t("updateSpace.spaceOccupancyMin")
    const min_hint = t("updateSpace.spaceOccupancyMinHint")
    const max_title = t("updateSpace.spaceOccupancyMax")
    const max_hint = t("updateSpace.spaceOccupancyMaxHint")
    const max_min_error = t("updateSpace.maxMinError")

    const getErrorMessage = useSyncExceptionError(t)

    const {updateSpace} = useSpaceUpdate()

    const renderEditableForm = (updateFn: (data: FormOccupancy) => void, data?: FormOccupancy) => {
        const onMaxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            const max = event.target.value
            if (max.length === 0) {
                updateFn({minimum: data?.minimum})
            } else {
                const newData = Object.assign({}, data)
                newData.maximum = parseInt(max)
                updateFn(newData)
            }
        }

        const onMinChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            const min = event.target.value
            if (min.length === 0) {
                updateFn({maximum: data?.maximum})
            } else {
                const newData: FormOccupancy = Object.assign({}, data)
                newData.minimum = parseInt(min)
                updateFn(newData)
            }
        }

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                mt: 3,
                pr: 8,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start'
            }}>
                <Typography sx={{pt: 2}} variant="titleMedium">{min_title}</Typography>
                <TextField
                    margin="normal"
                    fullWidth
                    placeholder={min_hint}
                    id="minimo-de-personas"
                    name="minimo-de-personas"
                    onChange={onMinChangeHandler}
                    value={data?.minimum ? data.minimum : ''}
                    sx={{width: '250px'}}
                    inputProps={{
                        min: 0,
                        max: data?.maximum ? data.maximum : fieldConstants.defaultMaxOccupancy,
                        type: 'number'
                    }}
                />
                <Typography sx={{pt: 2}} variant="titleMedium">{max_title}</Typography>
                <TextField
                    error={!isMinLowerThanMax(data)}
                    helperText={!isMinLowerThanMax(data) ? max_min_error : ''}
                    margin="normal"
                    fullWidth
                    placeholder={max_hint}
                    id="maximo-de-personas"
                    name="maximo-de-personas"
                    onChange={onMaxChangeHandler}
                    value={data?.maximum ? data.maximum : ''}
                    sx={{width: '250px'}}
                    inputProps={{
                        min: data?.minimum ? data.minimum : fieldConstants.defaultMaxOccupancy,
                        max: fieldConstants.defaultMaxOccupancy,
                        type: 'number'
                    }}
                />
            </Box>
        </Box>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <DataAsList data={occupancyToList(data, min_title, max_title)} fieldId={'name'}/>
        </Box>
    }

    const syncDataHandler = (data: FormOccupancy) => {
        const body = {occupancy: {minimum: data.minimum, maximum: data.maximum}}
        return updateSpace(spaceDetail.id, body)
    }

    const dispatch = useAppDispatch()
    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    return <EditableSyncFormV2
        title={title}
        subtitle={subtitle}
        isFocused={false}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        loadData={loadData}
        syncData={syncDataHandler}
        isFormDataValid={isOccupancyValid}
        isServerDataEmpty={isOccupancyEmpty}
        mapServerDataToFormData={spaceDetailToOccupancy}
        getErrorMessage={getErrorMessage}
        stateHandlers={stateHandlers}
        onSync={onSyncHandler}/>
}

export default SpaceOccupancyForm