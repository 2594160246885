import {createSlice} from "@reduxjs/toolkit";
import { CatalogEntry } from "interfaces/catalogEntry";
import { CatalogType } from "interfaces/catalogType";

export interface CatalogState {
    spaces: CatalogEntry[],
    events: CatalogEntry[],
    amenities: CatalogEntry[],
    tags: CatalogEntry[]
}

const initialState: CatalogState = {
    spaces: [],
    events: [],
    amenities: [],
    tags: []
}

export interface SetCatalogPayload {
    type: CatalogType
    entries: CatalogEntry[]
}

const catalogSlice = createSlice({
    name: 'catalog',
    initialState: initialState,
    reducers: {
        setCatalogs(state: CatalogState, action: { payload: SetCatalogPayload }) {
            switch (action.payload.type) {
                case CatalogType.Spaces:
                    state.spaces = action.payload.entries
                    break;
                case CatalogType.Events:
                    state.events = action.payload.entries
                    break;
                case CatalogType.Amenities:
                    state.amenities = action.payload.entries
                    break;
                case CatalogType.Tags:
                    state.tags = action.payload.entries
                    break;
            }
        }
    }

})

export const catalogActions = catalogSlice.actions
export default catalogSlice