import { Box } from "@mui/material"
import AdPictures from "./AdPictures"
import AdActions from "./AdActions"
import { SpaceDetail } from "interfaces/space"
import { useState } from "react"

interface AdMultimediaProps {
    spaceDetail: SpaceDetail
}

const AdMultimedia: React.FC<AdMultimediaProps> = (props) => {
    const { spaceDetail } = props

    const [openModal, setOpenModal] = useState(false)
    const [pictureIndex, setPictureIndex] = useState<number>(0)

    const showPicture = (pictureIndex: number) => {
        setPictureIndex(pictureIndex)
        setOpenModal(true)
    }

    return <>
        <AdPictures spaceDetail={spaceDetail} showPicture={showPicture}/>
        <Box sx={{mt: {xs: '16px', md: '20px'}}}>
            <AdActions 
                spaceDetail={spaceDetail} 
                pictureIndex={pictureIndex} 
                openModal={openModal} 
                setOpenModal={setOpenModal} 
            />
        </Box>
    </>
}

export default AdMultimedia