import LoadingButton from '@mui/lab/LoadingButton';
import React from "react";

const RoundedButton = (props: any) => {
    let finalProps;
    if (!props) {
        finalProps = {sx: {borderRadius: '100px', minHeight: '40px', textTransform: 'none'}}
    } else {
        if (!props.sx) {
            finalProps = {...props, sx: {borderRadius: '100px', minHeight: '40px', textTransform: 'none'}}
        } else {
            finalProps = {...props, sx: {...props.sx, borderRadius: '100px', minHeight: '40px', textTransform: 'none'}}
        }
    }
    return <LoadingButton  {...finalProps} >{props.children}</LoadingButton>
}

export default RoundedButton
