
import { Typography, useMediaQuery, useTheme } from "@mui/material"
import RoundedButton from "components/RoundedButton"

interface AdActionButtonProps {
    label: string
    onClick: () => void
}

const AdActionButton: React.FC<AdActionButtonProps> = (props) => {
    const {label, onClick} = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md')) 
    const labelVariant = isSM ? 'labelMedium' : 'titleMedium'
    
    return <RoundedButton
        variant="outlined"
        sx={{padding: { xs: '8px 20px', md: '10px 40px'}, height: {xs: '32px', md: '44px'}}}
        onClick={onClick}
    >
        <Typography variant={labelVariant} sx={{letterSpacing: 0}}>{label}</Typography>
    </RoundedButton>
}

export default AdActionButton