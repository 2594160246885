import { Exception } from "exception/Exception";
import { SpaceDetail } from "interfaces/space";
import {Result} from "typescript-monads";
import { UpdateSpaceBody } from '../interfaces/updateSpaceBody'
import { useUpdate } from "@pankod/refine-core";
import { NetworkException } from "exception/NetworkException";

const useSpaceUpdate = () => {
    const { mutate } = useUpdate();

    const updateSpace = (spaceId: string, update: UpdateSpaceBody): Promise<Result<SpaceDetail, Exception>> => {
        return new Promise((resolve, reject) => {
            mutate(
            {
                resource: "advertisements/update-by-admin",
                values: update,
                id: spaceId,
            },
            {
                onError: (error, variables, context) => {
                    reject(Result.fail(new NetworkException(error.statusCode, error.message)))
                },
                onSuccess: (data, variables, context) => {
                    resolve(Result.ok(data.data as any))
                }
            });
        });
    }

    return {updateSpace}
}

export default useSpaceUpdate