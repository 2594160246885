import React, {useRef, useState} from "react";
import {Result} from "typescript-monads";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {GoogleMap, useLoadScript, Marker} from "@react-google-maps/api";
import Typography from "@mui/material/Typography";
import { Coordinates, GAddress, PlaceType, SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { Exception } from "exception/Exception";
import { useTranslate } from "@pankod/refine-core";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import fieldConstants, { libraries } from "constants/fieldConstants";
import {getGeocode, getLatLng} from "use-places-autocomplete";
import { getAddressComponents, getValueOrEmptyString } from "utils";
import PlacesAutocomplete from "components/PlacesAutocomplete";
import DataAsList from "components/DataAsList";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { spaceActions } from "store/space";
import { spaceDetailConstants } from "constants/spaceDetailConstants";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

interface SpaceLocationFormProps {
    spaceDetail: SpaceDetail
}

interface FormAddress {
    location?: Coordinates,
    fullAddress?: string,
    interiorNo?: string,
    city?: string,
    state?: string,
    postalCode?: string,
    references?: string,
    gAddress?: GAddress
}

export const hasAddress = (data: SpaceDetail): boolean => data === undefined || data.address === undefined

const isAddressValid = (data: FormAddress): boolean => {
    return data !== undefined && data.fullAddress !== undefined && data.location !== undefined
}

const containerStyle = {
    width: '100%',
    height: '400px'
};

const stateHandlers = [StateHandler.Error]

const SpaceLocationForm: React.FC<SpaceLocationFormProps> = (props) => {
    
    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY!;
    const {spaceDetail} = props
    const initialCenter = spaceDetail?.address?.location?.coordinates !== undefined ?
        {lat: spaceDetail.address.location.coordinates[1], lng: spaceDetail.address.location.coordinates[0]} :
        {lat: 19.419, lng: -99.162}
    const [center, setCenter] = useState(initialCenter)
    const initialMarker = spaceDetail?.address?.location?.coordinates !== undefined ?
        {lat: spaceDetail.address.location.coordinates[1], lng: spaceDetail.address.location.coordinates[0]} : null
    const [selected, setSelected] = useState<{ lat: number, lng: number } | null>(initialMarker)

    const mapRef = useRef<google.maps.Map>()

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    const t = useTranslate()
    const title = t("updateSpace.spaceLocationTitle")
    const subtitle = t("updateSpace.spaceLocationSubtitle")
    const number_hint = t("updateSpace.spaceLocationInner")
    const refs_hint = t("updateSpace.spaceLocationRefs")
    const city_hint = t("updateSpace.spaceLocationCity")
    const state_hint = t("updateSpace.spaceLocationState")
    const post_hint = t("updateSpace.spaceLocationPost")
    const map_hint = t("updateSpace.spaceLocationMapHint")
    const addressHint = t('updateSpace.spaceLocationAddress')

    const getErrorMessage = useSyncExceptionError(t)

    const {updateSpace} = useSpaceUpdate()

    const spaceDetailToAddress = (data: SpaceDetail): FormAddress => {
        return {
            location: data.address?.location,
            fullAddress: data.address?.fullAddress,
            interiorNo: data.address?.interiorNo,
            city: data.address?.city,
            state: data.address?.state,
            postalCode: data.address?.postalCode,
            references: data.address?.references,
            gAddress: data.address?.gAddress
        }
    }

    const {isLoaded} = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: ['places']
    })

    const renderEditableForm = (updateFn: (data: FormAddress) => void, data?: FormAddress) => {

        const onAddressSelectHandler = async (address: PlaceType | null) => {
            if (address != null) {
                const results = await getGeocode({address: address.description})
                if (results.length > 0) {
                    const firstResult = results[0]
                    const {lat, lng} = await getLatLng(firstResult)
                    const currentNo = data?.interiorNo
                    const currentRefs = data?.references
                    const {city, state, postal} = getAddressComponents(firstResult)
                    const newData = {
                        location: {coordinates: [lng, lat]},
                        fullAddress: firstResult.formatted_address,
                        interiorNo: currentNo,
                        city: city,
                        state: state,
                        postalCode: postal,
                        references: currentRefs,
                        gAddress: {place: address, geocode: firstResult}
                    }
                    setCenter({
                        lat: lat,
                        lng: lng
                    })
                    setSelected({
                        lat: lat,
                        lng: lng
                    })
                    updateFn(newData)
                }
            } else {
                updateFn({})
            }
        }

        const onInteriorChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
            const interiorNo = event.target.value
            const newData: FormAddress = Object.assign({}, data)
            newData.interiorNo = interiorNo
            updateFn(newData)
        }

        const onReferencesChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
            const references = event.target.value
            const newData: FormAddress = Object.assign({}, data)
            newData.references = references
            updateFn(newData)
        }

        return <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                pr: 4,
                pt: 3
            }}>
                {isLoaded && <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <PlacesAutocomplete
                        formValue={data?.gAddress?.place ? data.gAddress.place : null}
                        addressHint={addressHint}
                        addressSelectedHandler={onAddressSelectHandler}
                    />
                    <TextField
                        label={number_hint}
                        margin="normal"
                        fullWidth
                        id="numero-interior"
                        name="numero-interior"
                        value={getValueOrEmptyString(data?.interiorNo)}
                        onChange={onInteriorChanged}
                        inputProps={{
                            maxLength: fieldConstants.defaultMaxLengthShort
                        }}
                    />
                    <TextField
                        label={refs_hint}
                        margin="normal"
                        fullWidth
                        id="referencias"
                        name="referencias"
                        value={getValueOrEmptyString(data?.references)}
                        onChange={onReferencesChanged}
                        inputProps={{
                            maxLength: fieldConstants.defaultMaxLength
                        }}
                    />
                    <TextField
                        label={city_hint}
                        margin="normal"
                        fullWidth
                        id="ciudad"
                        name="ciudad"
                        value={getValueOrEmptyString(data?.city)}
                        disabled
                    />
                    <TextField
                        label={state_hint}
                        margin="normal"
                        fullWidth
                        id="estado"
                        name="estado"
                        value={getValueOrEmptyString(data?.state)}
                        disabled
                    />
                    <TextField
                        label={post_hint}
                        margin="normal"
                        fullWidth
                        id="codigo-postal"
                        name="codigo-postal"
                        value={getValueOrEmptyString(data?.postalCode)}
                        disabled
                    />
                    <Typography sx={{pt: 4}} variant="labelLarge">{map_hint}</Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        pt: 2
                    }}>
                        <GoogleMap
                            onLoad={(map) => {
                                mapRef.current = map
                                map.addListener("click", (mapsMouseEvent: any) => {
                                    const position: google.maps.LatLng = mapsMouseEvent.latLng
                                    if (position !== undefined && position != null) {
                                        setCenter({lat: position.lat(), lng: position.lng()})
                                        setSelected({lat: position.lat(), lng: position.lng()})
                                        const newData = Object.assign({}, data)
                                        newData.location = {coordinates: [position.lng(), position.lat()]}
                                    }
                                });
                            }}
                            onUnmount={() => {
                                mapRef.current = undefined
                            }}
                            zoom={15}
                            center={center}
                            mapContainerStyle={containerStyle}>
                            {selected && <Marker position={selected}/>}
                        </GoogleMap>
                    </Box>
                </Box>}
            </Box>
        </>
    }

    const renderDisplayForm = (data: SpaceDetail) => {

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <DataAsList data={[data.address!.fullAddress!]} fieldId={'space-type'}/>
        </Box>
    }

    const syncDataHandler = (data: FormAddress) => {
        const body = {
            address: {
                location: data.location!,
                fullAddress: data.fullAddress!,
                interiorNo: data.interiorNo,
                city: data.city!,
                state: data.state!,
                postalCode: data.postalCode!,
                references: data.references,
                gAddress: data.gAddress!
            }
        }
        return updateSpace(spaceDetail.id, body)
    }

    const dispatch = useAppDispatch()
    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    const isFocused = useAppSelector(state => state.space.currentFocusId === spaceDetailConstants.location)

    return <EditableSyncFormV2
        title={title}
        subtitle={subtitle}
        isFocused={isFocused}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        loadData={loadData}
        syncData={syncDataHandler}
        isFormDataValid={isAddressValid}
        isServerDataEmpty={hasAddress}
        mapServerDataToFormData={spaceDetailToAddress}
        getErrorMessage={getErrorMessage}
        onSync={onSyncHandler}
        stateHandlers={stateHandlers}/>
}

export default SpaceLocationForm