import React from "react";
import {Result} from "typescript-monads";
import Box from "@mui/material/Box";
import { SpaceDetail } from "interfaces/space";
import { CatalogEntry } from "interfaces/catalogEntry";
import { getSelectionsById } from "utils";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { Exception } from "exception/Exception";
import { useTranslate } from "@pankod/refine-core";
import { useAppDispatch, useAppSelector } from "store/hooks";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import MultiSelectChipGroup from "components/MultiSelectChipGroup";
import DataAsList from "components/DataAsList";
import { spaceActions } from "store/space";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

interface SpaceEventTypeFormProps {
    spaceDetail: SpaceDetail
}

interface FormEventType {
    eventTypes: CatalogEntry[]
}

export const isEventTypeEmpty = (data: SpaceDetail): boolean => data === undefined || data?.eventTypes.length === 0

const isEventTypeValid = (data: FormEventType): boolean => {
    return data.eventTypes.length > 0
}

const getSelectionsFromSpaceDetail = (entries: CatalogEntry[] | string[], catalog: CatalogEntry[]): CatalogEntry[] => {
    if (entries.length > 0) {
        const first = entries[0]
        if (typeof first === 'string') {
            return getSelectionsById(catalog, (entries as string[]))
        } else {
            return entries as CatalogEntry[]
        }
    }
    return []
}

const stateHandlers = [StateHandler.Error]

const SpaceEventTypeForm: React.FC<SpaceEventTypeFormProps> = (props) => {

    const {spaceDetail} = props

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    const t = useTranslate()
    const title = t("updateSpace.spaceEventTypeTitle")
    const subtitle = t("updateSpace.spaceEventTypeSubtitle")
    const options: CatalogEntry[] = useAppSelector(state => state.catalog.events)

    const getErrorMessage = useSyncExceptionError(t)

    const {updateSpace} = useSpaceUpdate()

    const spaceDetailToEventType = (data: SpaceDetail): FormEventType => {
        const selections = getSelectionsFromSpaceDetail(data.eventTypes, options)
        return {eventTypes: selections}
    }

    const renderEditableForm = (updateFn: (data: FormEventType) => void, data?: FormEventType) => {
        const onSelectionsUpdateHandler = (items: CatalogEntry[]) => {
            updateFn({eventTypes: items})
        }

        return <MultiSelectChipGroup
            items={options}
            selections={data?.eventTypes ? data?.eventTypes : []}
            onSelectionsUpdated={onSelectionsUpdateHandler}/>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        const selections = getSelectionsFromSpaceDetail(data.eventTypes, options)
        return <>
            {options.length > 0 && <Box sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <DataAsList data={selections.map((entry: CatalogEntry) => entry.value)} fieldId={'space-type'}/>
            </Box>}
        </>
    }

    const syncDataHandler = (data: FormEventType) => {
        const body = {eventTypes: data.eventTypes?.map((entry: CatalogEntry) => entry.id)}
        return updateSpace(spaceDetail.id, body)
    }

    const dispatch = useAppDispatch()
    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    return <EditableSyncFormV2
        title={title}
        subtitle={subtitle}
        isFocused={false}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        loadData={loadData}
        syncData={syncDataHandler}
        isFormDataValid={isEventTypeValid}
        isServerDataEmpty={isEventTypeEmpty}
        mapServerDataToFormData={spaceDetailToEventType}
        getErrorMessage={getErrorMessage}
        stateHandlers={stateHandlers}
        onSync={onSyncHandler}/>
}

export default SpaceEventTypeForm