import { useTranslate, useUpdate } from "@pankod/refine-core";
import { SpaceDetail } from "interfaces/space";
import {useCallback, useEffect, useState} from "react";

const SpaceDetailsViewModel = (spaceId: string) => {
    const t = useTranslate()
    const errorText = t("updateSpace.loadSpaceDetailError")
    const { mutate, isLoading: isUpdating } = useUpdate();

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState('')
    const [spaceDetail, setSpaceDetail] = useState<SpaceDetail | null>(null)
    const [omittedFields, setOmittedFields] = useState<string[]>([])

    const fetchSpaceDetail = useCallback(async () => {
        setIsLoading(true)
        setError('')
        const response = await fetch(`${process.env.REACT_APP_SERVICES_HOST as string}/advertisements/${spaceId}`)
        const data = await response.json();
        if (data) {
            setSpaceDetail(data);
            if(data.omittedFields) {
                const omittedFields = data.omittedFields.split(',');
                setOmittedFields(omittedFields);
            }
        } else {
            setError(errorText)
        }
        setIsLoading(false)
    }, [errorText, spaceId])

    const addOmittedField = useCallback(async (field: string) => {
        omittedFields.push(field)
        const requestBody = {
            omittedFields: omittedFields.join(',')
        }
        await mutate({
            resource: "advertisements/omitted-fields",
            id: spaceId,
            values: requestBody
        },
        {
            onError: () => {
                setError(errorText)
            },
            onSuccess: (data) => {
                const omittedFieldsArray = data.data.omittedFields.split(',')
                setOmittedFields(omittedFieldsArray);
            }
        });
    }, [errorText, spaceId, omittedFields, setOmittedFields])

    const reloadSpaceDetail = useCallback(async () => {
        const response = await fetch(`${process.env.REACT_APP_SERVICES_HOST as string}/advertisements/${spaceId}`)
        const data = await response.json();
        if (data) {
            setSpaceDetail(data)
        } else {
            await fetchSpaceDetail()
        }
    }, [fetchSpaceDetail, spaceId])

    useEffect(() => {
        fetchSpaceDetail()
    }, [fetchSpaceDetail])

    return {isLoading, error, spaceDetail, fetchSpaceDetail, omittedFields, addOmittedField, reloadSpaceDetail}
}

export default SpaceDetailsViewModel