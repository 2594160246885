import React from "react";
import Grid from "@mui/material/Grid";
import { CatalogEntry } from "interfaces/catalogEntry";
import SelectableChip from "./SelectableChip";

interface SelectableChipGroupProps {
    items: CatalogEntry[]
    selections: CatalogEntry[]
    itemsDisabled?: CatalogEntry[]
    onSelectionsUpdated: (selections: CatalogEntry[]) => void,
    sx?: any
}

const MultiSelectChipGroup: React.FC<SelectableChipGroupProps> = (props) => {
    const {items, selections, itemsDisabled, onSelectionsUpdated, sx} = props
    
    const onChipToggleHandler = (item: CatalogEntry) => {
        const indexOfItem = selections.findIndex((entry) => entry.id === item.id)
        if (indexOfItem > -1) {
            const newArray = [...selections]
            newArray.splice(indexOfItem, 1)
            onSelectionsUpdated(newArray)

        } else {
            const newArray = [...selections, item]
            onSelectionsUpdated(newArray)
            return newArray
        }
    }

    return (
        <Grid sx={{...sx}}>
            {items.map((item) => {
                let disabled = false
                if(itemsDisabled) {
                    disabled = itemsDisabled.find(itemDisabled => itemDisabled.id === item.id) ? true : false
                }
                return <SelectableChip
                    key={item.id}
                    item={item}
                    selected={selections.find((entry) => entry.id === item.id) !== undefined}
                    onChipToggle={onChipToggleHandler}
                    disabled={disabled}
                />
            })}
        </Grid>
    )
}

export default MultiSelectChipGroup