import { Box } from "@mui/material";

interface IconProps {
    containerWidth?: number
    containerHeight?: number
    iconWidth: number
    iconHeight: number
    iconSrc: string
    className?: string
    title?: string
}

const Icon: React.FC<IconProps> = (props) => {
    const {
        containerWidth,
        containerHeight,
        iconWidth,
        iconHeight,
        iconSrc,
        className,
        title
    } = props;

    return <Box className={className} sx={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        width: containerWidth ? containerWidth : iconWidth, 
        height: containerHeight ? containerHeight : iconHeight,
        minWidth: containerWidth,
        minHeight: containerHeight
    }}>
        <img src={iconSrc} width={iconWidth} height={iconHeight} alt="icon" title={title}/>
    </Box>
}

export default Icon;