import { Box } from "@mui/material";
import AlertIcon from '../icons/alert.svg';

interface NoticeProps {
    children: React.ReactElement
}

const Notice: React.FC<NoticeProps> = (props) => {
    return <Box sx={{display: 'flex', gap: '8px', padding: '8px 16px', borderRadius: '8px', backgroundColor: 'surface.light'}}>
        <img src={AlertIcon} alt="Notice" width={24} height={24}/>
        {props.children}
    </Box>
}

export default Notice;