import React from "react";
import { useRouterContext, useTranslate } from "@pankod/refine-core";
import { BooleanField, Box, DataGrid, DateField, EditButton, ShowButton, GridColumns, GridToolbar, List, useDataGrid, Button } from "@pankod/refine-mui";
import { IAdvertisement } from "interfaces";
import { Check, Close, VisibilityOutlined } from "@mui/icons-material";
import paths from "constants/paths";

export const AdvertisementList: React.FC = () => {
  const t = useTranslate();
  const { Link } = useRouterContext();

  const { dataGridProps } = useDataGrid<IAdvertisement>();

  const columns = React.useMemo<GridColumns<IAdvertisement>>(
    () => [
      {
        field: "name",
        headerName: t("advertisements.fields.name"),
        renderCell: function render({ row }) {
          return <p>{row.name}</p>;
        },
        minWidth: 100,
        flex: 0.2,
      },
      {
        field: "host",
        headerName: t("advertisements.fields.host"),
        maxWidth: 300,
        flex: 0.2,
      },
      {
        field: "fullAddress",
        headerName: t("advertisements.fields.address"),
        renderCell: function render({ row }) {
          return <p>{row.fullAddress}</p>;
        },
        minWidth: 100,
        flex: 0.2,
      },
      {
        field: "status",
        headerName: t("advertisements.fields.status"),
        maxWidth: 100,
        flex: 0.2,
      },
      {
        field: "verificationStamp",
        headerName: t("advertisements.fields.verificationStamp"),
        renderCell: function render({ row }) {
            return <BooleanField
              value={row.verificationStamp}
              trueIcon={<Check />}
              falseIcon={<Close />}
            />
        },
        maxWidth: 200,
        flex: 0.2,
      },
      {
        field: "guaranteeStamp",
        headerName: t("advertisements.fields.guaranteeStamp"),
        renderCell: function render({ row }) {
            return <BooleanField
              value={row.guaranteeStamp}
              trueIcon={<Check />}
              falseIcon={<Close />}
            />
        },
        maxWidth: 200,
        flex: 0.2,
      },
      {
        field: "published",
        headerName: t("advertisements.fields.published"),
        renderCell: function render({ row }) {
            return <BooleanField
              value={row.published}
              trueIcon={<Check />}
              falseIcon={<Close />}
            />
          return '';
        },
        maxWidth: 200,
        flex: 0.2,
      },
      {
        field: "updatedAt",
        headerName: t("advertisements.fields.updatedAt"),
        renderCell: function render({ row }) {
          return <DateField value={row.updatedAt} format="DD/MM/YYYY H:mm" />;
        },
        maxWidth: 170,
        flex: 0.2,
      },
      {
        field: "actions",
        type: "actions",
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "row" }}
              autoComplete="off"
            >
              <Link
                to={ paths.adDetails + '/' + row.id}
              >
                <Button
                  title={t("advertisements.fields.preview")}
                  sx={{ minWidth: 0 }}
                >
                  <VisibilityOutlined fontSize="small"/>
                </Button>
              </Link>
              {<EditButton hideText recordItemId={row.id} />}
            </Box>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [t]
  );

  return (
    <List>
      <DataGrid 
      {...dataGridProps} 
      columns={columns} 
      components={{
        Toolbar: GridToolbar,
      }}
      autoHeight
      />
    </List>
  );
};