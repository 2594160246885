import {AppDispatch} from "./index";
import {catalogActions} from "./catalog";
import { CatalogType } from "interfaces/catalogType";
import { CatalogEntry } from "interfaces/catalogEntry";
import { HttpError, useList } from "@pankod/refine-core";

export const fetchCatalog = (type: CatalogType) => {

    return async (dispatch: AppDispatch) => {
        const { data } = useList<CatalogEntry, HttpError>({
            resource: 'catalogs/' + type,
            config: {
                hasPagination: false
            }
        });
        if(data?.data) {
            dispatch(catalogActions.setCatalogs({type: type, entries: data?.data}))
        }
    }
}