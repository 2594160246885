import { useTranslate } from "@pankod/refine-core";
import { Box } from "@pankod/refine-mui";
import { Subtitle, Text, Label, Row } from "./components";
import { IBooking } from "interfaces";
import { formatFullDateString, formatNumberPrice, formatTimeRangeString } from "utils";
import ReservationStatus from "./reservationStatus";
import { CatalogEntry } from "interfaces/catalogEntry";

interface SummaryProps {
    reservation: IBooking
}

const Summary:React.FC<SummaryProps> = (props) => {
    const { reservation } = props;
    const t = useTranslate();
    
    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px 0px', borderBottom: '1px solid', borderBottomColor: 'primary.main'}}>
        <Subtitle>{t('reservation.reservationSummary')}</Subtitle>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px'}}>
            <Row>
                <Label>{t('reservation.host')}</Label>
                <Text sx={{textAlign: 'right'}}>{reservation.host.name + ' ' + reservation.host.lastName}</Text>
            </Row>
            <Row>
                <Label sx={{width: '50%'}}>{t('reservation.hostPhone')}</Label>
                <Text>{reservation.host.phone}</Text>
            </Row>
            <Row>
                <Label sx={{width: '50%'}}>{t('reservation.hostEmail')}</Label>
                <Text sx={{textAlign: 'right'}}>{reservation.host.email}</Text>
            </Row>
            {
                reservation.eventType && <Row>
                    <Label>{t('reservation.eventType')}</Label>
                    <Text>{(reservation.eventType as CatalogEntry).value}</Text>
                </Row>
            }
            <Row>
                <Label>{t('reservation.eventDate')}</Label>
                <Text>{formatFullDateString(reservation.date)}</Text>
            </Row>
            <Row>
                <Label>{t('reservation.eventTime')}</Label>
                <Text>{formatTimeRangeString(reservation.time)}</Text>
            </Row>
            <Row>
                <Label>{t('reservation.totalPeople')}</Label>
                <Text>{reservation.people}</Text>
            </Row>
            <Row>
                <Label>{t('reservation.status')}</Label>
                <ReservationStatus
                    reservationStatus={reservation.status}
                ></ReservationStatus>
            </Row>
        </Box>
    </Box>
}

export default Summary;