import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useState } from "react";
import { useTheme } from "@mui/material";
import Icon from "components/Icon";

interface AdStampProps {
    iconSrc: string
    title: string
}

const AdStamp: React.FC<AdStampProps> = (props) => {
    const { iconSrc, title } = props
    const [open, setOpen] = useState(false);
    const theme = useTheme()

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip 
            arrow
            title={title} 
            PopperProps={{
                disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            slotProps={{
                popper: {
                sx: {
                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                    {
                        marginTop: '4px',
                    },
                    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                    {
                        marginBottom: '4px',
                    },
                    [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                    {
                        marginLeft: '4px',
                    },
                    [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                    {
                        marginRight: '4px',
                    },
                    [`& .${tooltipClasses.arrow}`]: {
                        color: theme.palette.primary.light,
                    },
                    [`& .${tooltipClasses.tooltip}`]: {
                        color: theme.palette.onPrimary.light,
                        fontFamily: 'Montserrat',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        backgroundColor: theme.palette.primary.light,
                    }
                },
                },
            }}
        >
            <IconButton sx={{
                    width: '50px',
                    height: '50px',
                    padding: '0px'
                }}
                onClick={handleTooltipOpen}
            >
                <Icon 
                    containerWidth={50}
                    containerHeight={50}
                    iconWidth={50}
                    iconHeight={50}
                    iconSrc={iconSrc}
                />
            </IconButton>
        </Tooltip>
    </ClickAwayListener>
}

export default AdStamp