import { Box, Stack, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import CustomModal from "components/CustomModal"
import RoundedButton from "components/RoundedButton"

interface GroupConfirmDeleteModalProps {
    canOpen: boolean
    onClose: () => void
    onConfirm: () => void
}

const GroupConfirmDeleteModal: React.FC<GroupConfirmDeleteModalProps> = (props) => {
    const { canOpen, onClose, onConfirm } = props
    
    const t = useTranslate()
    const title = t("updateSpace.confirmDeleteGroupTitle")
    const description = t("updateSpace.confirmDeleteGroupDescription")
    const cancelLabel = t("buttons.cancel")
    const deleteLabel = t("buttons.remove")

    return <CustomModal 
        sxContainer={{maxWidth: '528px'}}
        sxCloseButton={{top: '16px', right: '8px'}}
        canOpen={canOpen}
        onCloseModal={onClose}
    >
        <Stack>
            <Typography variant="titleLarge">{title}</Typography>
            <Typography sx={{mt: 3, letterSpacing: '0.5px'}} variant="bodyLarge">{description}</Typography>
            <Stack sx={{mt: 3}} spacing={3} direction={'row'} justifyContent={'flex-end'}>
                <RoundedButton
                    variant="outlined"
                    sx={{width: 'max-content'}}
                    onClick={onClose}
                >
                    <Typography variant="labelLarge">{cancelLabel}</Typography>
                </RoundedButton>
                <RoundedButton
                    variant="contained"
                    sx={{width: 'max-content'}}
                    onClick={onConfirm}
                >
                    <Typography variant="labelLarge">{deleteLabel}</Typography>
                </RoundedButton>
            </Stack>
        </Stack>
    </CustomModal>
}

export default GroupConfirmDeleteModal