import { Box, Drawer, Tab, Tabs, Typography, styled } from "@pankod/refine-mui";

export const CustomDrawer = styled(Drawer)(() => ({
    '&.MuiDrawer-root .MuiBackdrop-root': {
        backgroundColor: 'transparent'
    },
    '&.MuiDrawer-root .MuiPaper-root': {
        width: '320px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '6px',
        padding: '16px'
    }
}))

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {
                value === index && children
            }
        </div>
    );
}

export const Title = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
}))

export const Subtitle = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
}))

export const Label = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '14.63px',
}))

export const Text = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.63px',
}))

export const Row = styled(Box)(() => ({
    display: 'flex', 
    justifyContent: 'space-between',
    width: '100%'
}))

export const Circle = styled(Typography)(() => ({
    width: '8px',
    height: '8px',
    borderRadius: '100%'
}));

export const CircleContainer = styled(Box)(() => ({
    display: "flex", 
    alignItems: 'center', 
    gap: '4px',
    justifyContent: 'flex-end'
}));

export const CustomTabs = styled(Tabs)(() => ({
    minHeight: '28px',
    '&.MuiTabs-root .MuiTabs-indicator': {
        display: 'none',
    },
    '&.MuiTabs-root .MuiTabs-flexContainer': {
        gap: '8px'
    }
}))

export const CustomTab = styled(Tab)(() => ({
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#49454F',
    padding: '4px 8px',
    height: '28px',
    minHeight: '28px',
    backgroundColor: '#67BE2326',
    borderRadius: '100px',
    letterSpacing: '0.10px',
    textTransform: 'none',
    '&.Mui-selected': {
        backgroundColor: '#67BE23',
        color: '#FFFFFF',
        fontWeight: 700
    }
}));