import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { Exception } from "exception/Exception";
import { Result } from "typescript-monads";
import { useAppDispatch } from "store/hooks";
import { useTranslate } from "@pankod/refine-core";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import { SpaceDetail } from "interfaces/space";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import fieldConstants from "constants/fieldConstants";
import DataAsList from "components/DataAsList";
import { spaceActions } from "store/space";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

export interface SpaceNameFormProps {
    spaceDetail: SpaceDetail
}

export const isNameEmpty = (data: SpaceDetail): boolean => false

const isNameValid = (data: FormName): boolean => {
    return data !== undefined && data.name !== undefined && data?.name?.length > 3
}

const spaceDetailToDescription = (data: SpaceDetail): FormName => {
    return {name: data?.name}
}

export interface FormName {
    name?: string
}

const nameToList = (data: SpaceDetail): string[] => {
    return data?.name ? [data.name] : []
}

const nameStateHandlers = [StateHandler.Error]

const SpaceNameForm: React.FC<SpaceNameFormProps> = (props) => {

    const {spaceDetail} = props
    const t = useTranslate()

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    const dispatch = useAppDispatch()
    const title = t("updateSpace.spaceNameTitle")
    const subtitle = t("updateSpace.spaceNameSubtitle")
    const hint = t("updateSpace.spaceNameHint")

    const getErrorMessage = useSyncExceptionError(t)

    const {updateSpace} = useSpaceUpdate()

    const renderEditableForm = (updateFn: (data: FormName) => void, data?: FormName) => {
        const onNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            const name = event.target.value
            const newData: FormName = {name: name}
            updateFn(newData)
        }

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                mt: 3,
                pr: 8,
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                justifyContent: {xs: 'center', md: 'flex-start'},
                alignItems: {xs: 'flex-start', md: 'center'}
            }}>
                <TextField
                    margin="normal"
                    fullWidth
                    placeholder={hint}
                    id="nombre"
                    name="nombre"
                    onChange={onNameChangeHandler}
                    value={data?.name}
                    inputProps={{
                        maxLength: fieldConstants.defaultMaxLengthShort
                    }}
                />
            </Box>
        </Box>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <DataAsList data={nameToList(data)} fieldId={'name'}/>
        </Box>
    }

    const syncDataHandler = (data: FormName) => {
        const body = {name: data.name}
        return updateSpace(spaceDetail.id, body)
    }

    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    return <EditableSyncFormV2
        title={title}
        subtitle={subtitle}
        isFocused={false}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        loadData={loadData}
        syncData={syncDataHandler}
        isFormDataValid={isNameValid}
        isServerDataEmpty={isNameEmpty}
        mapServerDataToFormData={spaceDetailToDescription}
        getErrorMessage={getErrorMessage}
        stateHandlers={nameStateHandlers}  // This prop is required for onSyncHandler to be called
        onSync={onSyncHandler}/>
}

export default SpaceNameForm