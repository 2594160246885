import React, {useEffect, useState} from "react";
import {Result} from "typescript-monads";
import {getStorage, ref, StorageReference} from "firebase/storage";
import Grid from "@mui/material/Grid";
import { SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { useTranslate } from "@pankod/refine-core";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import useStorageRef from "hooks/useStorageRef";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import { Exception } from "exception/Exception";
import { storagePaths } from "constants/storagePaths";
import PictureUpload from "components/PictureUpload";
import { useAppDispatch } from "store/hooks";
import { spaceActions } from "store/space";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

export interface FormPictures {
    picturesUrls?: string[]
}

const spaceDetailToCover = (data: SpaceDetail): FormPictures => {
    return {picturesUrls: data?.picturesUrls}
}

export const isPicturesEmpty = (data: SpaceDetail): boolean => data === undefined || data?.picturesUrls === undefined || data?.picturesUrls.length === 0

const getArrayLength = (data?: FormPictures): number => {
    if (data === undefined || data === null) return 0
    if (data.picturesUrls === undefined) return 0
    return data.picturesUrls.length
}

const idStateHandlers = [StateHandler.Error]

const pictureUploadSuffix = 'spacePicture'

export interface SpacePicturesFormProps {
    spaceDetail: SpaceDetail
    maxPictures: number
    minPictures: number
}

const SpacePicturesForm: React.FC<SpacePicturesFormProps> = (props) => {

    const {spaceDetail, maxPictures, minPictures} = props
    const t = useTranslate();
    const titleText = t('updateSpace.spacePicturesTitle')
    const subtitleText = t('updateSpace.spacePicturesSubtitle')
    const hint = t('updateSpace.spacePicturesHint')

    const storage = getStorage();
    const getErrorMessage = useSyncExceptionError(t)

    const getRef = useStorageRef()
    const [showEdit, setShowEdit] = useState<boolean>(true)

    const {updateSpace} = useSpaceUpdate()

    const isPicturesValid = (data: FormPictures): boolean => {
        return data !== undefined && data?.picturesUrls !== undefined && data?.picturesUrls.length >= minPictures
    }

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    const renderEditableForm = (updateFn: (data: FormPictures) => void, data?: FormPictures) => {

        const urlChangedHandler = (index: number, url: string | null) => {
            if (index === -1) {
                if (url !== null) {
                    const newArray = (data?.picturesUrls ? data.picturesUrls : []).concat(url)
                    const newData: FormPictures = {picturesUrls: newArray}
                    updateFn(newData)
                }
            } else {
                if (url !== null) {
                    const newArray = (data?.picturesUrls ? [...data.picturesUrls] : [])
                    newArray.splice(index, 1, url)
                    const newData: FormPictures = {picturesUrls: newArray}
                    updateFn(newData)
                } else {
                    const newArray = (data?.picturesUrls ? [...data.picturesUrls] : [])
                    newArray.splice(index, 1)
                    const newData: FormPictures = {picturesUrls: newArray}
                    updateFn(newData)
                }
            }
        }

        const timestamp = Date.now()
        const newPictureRef = ref(storage, `${storagePaths.hostDocs}${spaceDetail.id}_${pictureUploadSuffix}_${timestamp}`);

        return <Grid container spacing={2} sx={{mt: 2}}>
            {data?.picturesUrls && data.picturesUrls.map((pictureUrl, index) => {
                const editPictureRef = ref(storage, `${storagePaths.hostDocs}${spaceDetail.id}_${pictureUploadSuffix}_${index}_${timestamp}`);
                return <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
                             key={`picture-${pictureUrl.substring(pictureUrl.length - 5, pictureUrl.length)}`}>
                    <PictureUpload
                        downloadUrl={pictureUrl}
                        uploadedRef={editPictureRef}
                        title={hint}
                        onUrlChange={(url) => {
                            urlChangedHandler(index, url)
                        }}/>
                </Grid>
            })}
            {(getArrayLength(data) < maxPictures) &&
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <PictureUpload
                        downloadUrl={null}
                        uploadedRef={newPictureRef}
                        title={hint}
                        onUrlChange={(url) => {
                            urlChangedHandler(-1, url)
                        }}/>
                </Grid>}
        </Grid>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        return <Grid container sx={{mt: 2}}>
            {data?.picturesUrls && data?.picturesUrls.map((url, index) => {
                return <Grid xs={12} sm={12} md={12} lg={6} xl={4} key={`picture-display-${index}`}>
                    <img alt="complex" src={url} width={240} height={240}
                           style={{borderRadius: '8px'}}/>
                </Grid>
            })}
        </Grid>
    }

    const syncDataHandler = (data: FormPictures) => {
        const body = {picturesUrls: data.picturesUrls}
        return updateSpace(spaceDetail.id, body)
    }

    const dispatch = useAppDispatch()
    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    return <EditableSyncFormV2
        title={titleText}
        subtitle={subtitleText}
        isFocused={false}
        loadData={loadData}
        syncData={syncDataHandler}
        getErrorMessage={getErrorMessage}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        mapServerDataToFormData={spaceDetailToCover}
        isServerDataEmpty={isPicturesEmpty}
        isFormDataValid={isPicturesValid}
        stateHandlers={idStateHandlers}
        showEdit={showEdit}
        onSync={onSyncHandler}
    />
}

export default SpacePicturesForm