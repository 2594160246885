import { Exception } from "exception/Exception";
import React, { useEffect } from "react";
import {Result} from "typescript-monads";
import useEditableSyncViewModel, { RetryType, StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { SyncExceptionAttribute } from "./spaces/update/generalInfo/SyncException";
import EditableFormCard from "./EditableFormCard";
import DisplayFormCard from "./DisplayFormCard";

interface EditableSyncPropsV2<T, F> {
    fieldId?: string,
    title: string,
    subtitle: string,
    isFocused: boolean
    editableForm: (updateFormData: (data: any) => void, data?: F) => React.ReactNode
    displayForm: (data: T) => React.ReactNode
    loadData: () => Promise<Result<T, Exception>>,
    syncData: (formData: F) => Promise<Result<T, Exception>>,
    isFormDataValid: (formData: F) => boolean,
    isServerDataEmpty: (serverData: T) => boolean,
    mapServerDataToFormData: (serverData: T) => F,
    getErrorMessage: (exception: Exception, attribute: SyncExceptionAttribute) => string,
    stateHandlers?: StateHandler[],
    onLoad?: (result: Result<T, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => void,
    onSync?: (result: Result<T, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => void,
    showEdit?: boolean
    hideSaveButton?: boolean
    forceSync?: (formData: F) => Promise<Result<T, Exception>>
    optional?: boolean,
    onSkip?: () => void,
    isOmitted?: boolean,
    isFormEditing?: boolean
}

const EditableSyncFormV2 = <T extends object, F extends object>(props: EditableSyncPropsV2<T, F>) => {

    const {
        title,
        subtitle,
        isFocused,
        editableForm,
        displayForm,
        loadData,
        syncData,
        getErrorMessage,
        isServerDataEmpty,
        mapServerDataToFormData,
        isFormDataValid,
        stateHandlers,
        onLoad,
        onSync,
        showEdit,
        hideSaveButton,
        optional,
        onSkip,
        isOmitted,
        isFormEditing
    } = props

    const {
        isInit,
        isLoading,
        isEditing, setEditing,
        isFormTouched, setFormTouched,
        isFormValid, setFormValid,
        error, setError,
        sync,
        setFormData,
        formData,
        backingData,
        loadInitialData,
        resetChanges
    } = useEditableSyncViewModel(
        loadData,
        syncData,
        isServerDataEmpty,
        mapServerDataToFormData,
        getErrorMessage,
        optional !== undefined ? optional : false,
        stateHandlers,
        onLoad,
        onSync,
        isOmitted
    )
    
    const onEditClickHandler = () => {
        setEditing(true)
    }

    const onEditCancelHandler = () => {
        resetChanges()
        setEditing(false)
    }

    const onSaveClickHandler = () => {
        sync()
    }

    const onRetryHandler = () => {
        if (error !== null) {
            if (error.retryType === RetryType.Load) {
                loadInitialData()
            }
            if (error.retryType === RetryType.Sync) {
                sync()
            }
        }
    }

    const validateAndSetFormData = (formData: any, forceSync: boolean = false) => {
        setFormValid(isFormDataValid(formData))
        setFormData(formData)
        if (forceSync) {
            sync()
        }
    }

    useEffect(() => {
        if(isFormEditing) {
            onEditClickHandler()
        }
    }, [isFormEditing])

    return isInit ? (isEditing ?
        <EditableFormCard
            title={title}
            subtitle={subtitle}
            showCancel={backingData !== null}
            error={error}
            isFormValid={isFormValid}
            isFocused={isFocused}
            onCancel={onEditCancelHandler}
            onSave={onSaveClickHandler}
            onRetry={onRetryHandler}
            isLoading={isLoading}
            hideSaveButton={hideSaveButton}
            optional={optional}
            onSkip={onSkip}
        >
            {editableForm(validateAndSetFormData, formData)}
        </EditableFormCard> :
        <DisplayFormCard
            title={title}
            isFocused={isFocused}
            onEditClick={onEditClickHandler}
            error={error}
            onRetry={onRetryHandler}
            showEdit={showEdit !== undefined ? showEdit : true}
        >
            {displayForm(backingData)}

        </DisplayFormCard>) : <></>
}

export default EditableSyncFormV2