import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import MultiSelectChipGroup from "components/MultiSelectChipGroup"
import { CatalogEntry } from "interfaces/catalogEntry"
import { Group, GroupRange, WorkingTime } from "interfaces/space"

interface SpaceEventTypesProps {
    groups: Group[]
    selectedEventType: CatalogEntry | null
    setSelectedEventType: (value: CatalogEntry) => void
    setSelectedGroup: (value: Group) => void
    setSelectedDate: (date: Date | null) => void
    setSelectedEventHours: (eventHours: number | null) => void
    setSelectedTime: (time: string | null) => void
    setSelectedRange: (eventHours: GroupRange | null) => void
    setSelectedRangeIndex: (index: number | null) => void
    setAvailableTimes: (availableTimes: string[]) => void
    setSelectedWorkingTime: (workingTime: WorkingTime | null) => void
    selectPeople: (people: number) => void
}

const SpaceEventTypes: React.FC<SpaceEventTypesProps> = (props) => {
    const { 
        groups, 
        selectedEventType, 
        setSelectedEventType, 
        setSelectedGroup,
        setSelectedDate,
        setSelectedEventHours,
        setSelectedTime,
        setSelectedRange,
        setSelectedRangeIndex,
        setAvailableTimes,
        setSelectedWorkingTime,
        selectPeople
    } = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'
    const t = useTranslate();
    const title = t('previewDetails.selectEventType')

    const generateEventTypes = (): CatalogEntry[] => {
        let eventTypes: CatalogEntry[] = []
        groups.forEach(group => eventTypes = eventTypes.concat(group.eventTypes as CatalogEntry[]))
        return eventTypes
    }
    const eventTypes = generateEventTypes()
    
    const onEventTypesSelectedHandler = (values: CatalogEntry[]) => {
        setSelectedEventType(values[1])
        let selectedGroup = null
        for(let group of groups) {
            const index = group.eventTypes.findIndex(eventType => eventType === values[1]) 
            if(index > -1) {
                selectedGroup = group
                break
            }
        }
        if(selectedGroup) {
            setSelectedGroup(selectedGroup)
            setSelectedDate(null)
            setSelectedEventHours(null)
            setSelectedTime(null)
            setSelectedRange(null)
            setSelectedRangeIndex(null)
            setAvailableTimes([])
            setSelectedWorkingTime(null)
            selectPeople(0)
        }
    }
    
    return <Stack>
        <Typography variant={titleVariant} sx={{mb: 1}}>{title}</Typography>
        <MultiSelectChipGroup
            items={eventTypes}
            selections={selectedEventType ? [selectedEventType] : []}
            onSelectionsUpdated={onEventTypesSelectedHandler}
        />
    </Stack>
}

export default SpaceEventTypes