export enum EReservationStatus {
    DRAFT = "Draft",
    REQUEST = "Request",
    PENDING = "Pending",
    CONFIRMED = "Confirmed",
    DEPOSIT_PAID = "Deposit Paid",
    PAID = "Paid",
    CANCELLED = "Cancelled",
    EXPIRED = "Expired",
    WAITING = "Waiting",
    PAYMENTS_IN_PROCESS = "Payments In Process"
  }

  export enum EPaymentStatus {
    PAID = 'Paid',
    TO_PAY = 'To Pay',
    PENDING = 'Pending',
    EXPIRED = 'Expired',
}