import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import InfoIcon from '../../icons/info.svg'
import Icon from "components/Icon"
import { externalLinks } from "constants/externalLinks"
import { useTranslate } from "@pankod/refine-core"
import LinkTo from "components/LinkTo"

const linkStylesSM = { 
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    textDecoration: 'underline'
}

const linkStylesMD = { 
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '28px',
    textDecoration: 'underline'
}

const AdLegales: React.FC = () => {
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const textVariant = isSM ? 'titleSmall' : 'titleLarge'
    const linkStyles = isSM ? linkStylesSM : linkStylesMD
    const cancellationPoliciesUrl = externalLinks.cancellationPolicies
    const paymentPoliciesUrl = externalLinks.paymentPolicies
    const t = useTranslate()
    const adLegalesText = t('previewDetails.adLegalesText')
    const cancellationPoliciesText = t('previewDetails.cancellationPolicies')
    const andText = t('previewDetails.andText')
    const paymentPoliciesText = t('previewDetails.paymentPoliciesText')

    return <Box sx={{
        display: 'flex',
        gap: '12px',
        padding: {xs: '16px', md: '16px 24px'},
        borderRadius: {xs: '8px', md: '100px'},
        backgroundColor: 'secondaryContainer.light'
    }}>
        <Icon 
            iconWidth={32}
            iconHeight={32}
            iconSrc={InfoIcon}
        />
        <Box>
            <Typography variant={textVariant} sx={{color: 'primary.light'}}>
                {adLegalesText} <LinkTo href={cancellationPoliciesUrl} sx={linkStyles}>{cancellationPoliciesText}</LinkTo> {andText} <LinkTo href={paymentPoliciesUrl} sx={linkStyles}>{paymentPoliciesText}.</LinkTo>
            </Typography>
        </Box>
    </Box>
}

export default AdLegales