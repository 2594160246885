import { Button, Stack, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import { GroupRange, Occupancy, PriceTypes, WorkingTime } from "interfaces/space"
import { useTranslate } from "@pankod/refine-core"
import GroupWorkingTimesDayRange from "./GroupWorkingTimesDayRange"

interface GroupWorkingTimesDayRangesProps {
    workingTime: WorkingTime
    index: number
    updateWorkingTime: (index: number, workingTime: WorkingTime) => void
    priceType: PriceTypes
    spaceOccupancy: Occupancy
    totalWorkingTimes: number
    onCopyWorkingTimesHandler: () => void
}

const GroupWorkingTimesDayRanges: React.FC<GroupWorkingTimesDayRangesProps> = (props) => {
    const { workingTime, index, updateWorkingTime, priceType, spaceOccupancy, totalWorkingTimes, onCopyWorkingTimesHandler } = props
    
    const t = useTranslate()
    const addRangeLabel = t("updateSpace.addRange")
    const copyWorkingTimesLabel = t("updateSpace.copyWorkingTimes")

    const canAddRange: boolean = !!workingTime.maxHours && !!spaceOccupancy.minimum && !!spaceOccupancy.maximum && !!priceType

    const onAddRangeClickHandler = () => {
        const newWorkingTime = {...workingTime}
        const isFirstRange = newWorkingTime.ranges.length === 0
        const lastOccupancyMin = isFirstRange ? 0 : newWorkingTime.ranges[newWorkingTime.ranges.length - 1].occupancy.maximum
        newWorkingTime.ranges.push({
            occupancy: {
                minimum: isFirstRange ? spaceOccupancy.minimum : lastOccupancyMin! + 1,
                maximum: isFirstRange ? spaceOccupancy.maximum : 0
            },
            prices: {
                byEvent: 0,
                byPerson: 0,
                byHour: 0
            }
        })
        updateWorkingTime(index, newWorkingTime)
    }

    const deleteRange = (rangeIndex: number) => {
        const newWorkingTime = {...workingTime}
        newWorkingTime.ranges.splice(rangeIndex, 1) 
        updateWorkingTime(index, newWorkingTime)
    }

    const updateRange = (rangeIndex: number, range: GroupRange) => {
        const newWorkingTime = {...workingTime}
        newWorkingTime.ranges[rangeIndex] = range
        
        if(range.occupancy.maximum && rangeIndex + 1 < newWorkingTime.ranges.length) {
            newWorkingTime.ranges[rangeIndex +1].occupancy.minimum = range.occupancy.maximum + 1
        }
        updateWorkingTime(index, newWorkingTime)
    }

    return <Stack spacing={2} sx={{mt: 2}}>
        {
            workingTime.ranges.map((range, index) => <GroupWorkingTimesDayRange 
                key={'working-times-day-range-' + index} 
                index={index}
                range={range}
                updateRange={updateRange}
                deleteRange={deleteRange}
                priceType={priceType}
                maxHours={workingTime.maxHours}
                isFirstRange={index === 0}
                isLastRange={index === (workingTime.ranges.length - 1)}
                spaceOccupancy={spaceOccupancy}
            />)
        }
        <Button
            startIcon={<AddIcon/>}
            variant='text'
            sx={{width: 'max-content'}}
            onClick={onAddRangeClickHandler}
            disabled={!canAddRange}
        >
            <Typography variant='labelLarge'>{addRangeLabel}</Typography>
        </Button>
        {
            index === 0 && totalWorkingTimes > 1 && <Button 
                sx={{width: 'max-content'}} 
                variant='text' 
                onClick={onCopyWorkingTimesHandler}>
                <Typography variant='labelLarge'>{copyWorkingTimesLabel}</Typography>
            </Button>
        }
    </Stack>
}

export default GroupWorkingTimesDayRanges