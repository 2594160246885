import { Box, IconButton, Stack, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'
import { Group } from "interfaces/space"
import { useTranslate } from "@pankod/refine-core"
import GroupListItem from "./GroupListItem"
import RoundedButton from "components/RoundedButton"

interface GroupDetailPreviewCardProps {
    group: Group
    canCreateGroup: boolean
    onShowMoreClickHandler: (group: Group) => void
    onEditGroupClickHandler: (group: Group) => void
    onDeleteGroupClickHandler: (group: Group) => void
    onDuplicateGroupClickHandler: (group: Group) => void
}

const GroupDetailPreviewCard: React.FC<GroupDetailPreviewCardProps> = (props) => {
    const { 
        group, 
        canCreateGroup,
        onShowMoreClickHandler, 
        onEditGroupClickHandler, 
        onDeleteGroupClickHandler, 
        onDuplicateGroupClickHandler 
    } = props
    
    const t = useTranslate()
    const editLabel = t("buttons.edit")
    const duplicateLabel = t("updateSpace.duplicate")

    const onEditClickHandler = () => {
        onEditGroupClickHandler(group)
    }

    const onDuplicateClickHandler = () => {
        onDuplicateGroupClickHandler(group)
    }

    const onDeleteClickHandler = () => {
        onDeleteGroupClickHandler(group)
    }

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'onPrimary.main',
        border: '1px solid',
        borderColor: 'neutral80.light',
        borderRadius: '32px',
        padding: '16px 24px',
        width: '100%',
        maxWidth: '615px',
        position: 'relative'
    }}>
        <GroupListItem 
            group={group}
            onShowMoreClickHandler={onShowMoreClickHandler}
        />
        <Stack sx={{mt: 2}} spacing={2} direction={'row'} justifyContent={'flex-end'}>
            <RoundedButton
                variant="outlined"
                sx={{width: 'max-content'}}
                onClick={onEditClickHandler}
            >
                <Typography variant="labelLarge">{editLabel}</Typography>
            </RoundedButton>
            <RoundedButton
                variant="contained"
                sx={{width: 'max-content'}}
                onClick={onDuplicateClickHandler}
                disabled={!canCreateGroup}
            >
                <Typography variant="labelLarge">{duplicateLabel}</Typography>
            </RoundedButton>
        </Stack>
        <IconButton sx={{
            position: 'absolute',
            top: '8px',
            right: '16px'
        }} onClick={() => { onDeleteClickHandler() }}>
            <DeleteIcon/>
        </IconButton>
    </Box>
}

export default GroupDetailPreviewCard