import { Box, Grid, IconButton, Typography } from "@mui/material";
import MuiModal from "@mui/material/Modal";
import CheckIcon from '../../../src/icons/check.svg'
import CloseIcon from "@mui/icons-material/Close"
import { PaidService } from "interfaces/space";
import { useTranslate } from "@pankod/refine-core";
import Icon from "components/Icon";

interface SpacePackagePaidServicesModalProps {
    openModal: boolean
    setOpenModal: (status: boolean) => void
    paidServices: PaidService[]
    isSM: boolean
}

const SpacePackagePaidServicesModal: React.FC<SpacePackagePaidServicesModalProps> = (props) => {
    const { openModal, setOpenModal, paidServices, isSM } = props;
    const paidServiceIconSize = isSM ? 25 : 30
    const paidServiceVariant = isSM ? 'bodyMedium' : 'bodyLarge'
    
    const t = useTranslate();
	const title = t('previewDetails.includedServicesTitle');
    
    return <MuiModal
        open={openModal}
    >
        <Box sx={{
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            width: '100%', 
            height: '100%'
        }}>
            <Box sx={{
                width: '100%',
                maxWidth: '569px', 
                borderRadius: '8px', 
                backgroundColor: 'onPrimary.light', 
                padding: '32px 24px', 
                display: 'flex', 
                flexDirection: 'column', 
                gap: '24px',
                position: 'relative',
                height: '100%',
                maxHeight: '480px'
            }}>
                <Typography variant="titleLarge" sx={{ml: '40px'}}>{title}</Typography>
                <Grid container sx={{
                    height: '100%',
                    overflow: 'auto'
                }}>
                    {paidServices.map((item, index) => {
                        return <Grid item xs={6} key={`all-paid-service-${index}`}>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '8px', marginBottom: '8px'}}>
                            <Icon
                                iconWidth={paidServiceIconSize}
                                iconHeight={paidServiceIconSize}
                                iconSrc={CheckIcon}
                            />
                            <Typography variant={paidServiceVariant} sx={{letterSpacing: '0.25px'}}>{item.name}</Typography>
                        </Box>
                    </Grid>
                    })}
                </Grid>
                <IconButton sx={{
                    color: 'neutral0.light',
                    position: 'absolute',
                    top: '26px',
                    left: '16px'
                }} onClick={() => { setOpenModal(false) }}>
                    <CloseIcon/>
                </IconButton>
            </Box>
        </Box>
    </MuiModal>
}

export default SpacePackagePaidServicesModal;