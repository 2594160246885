import { InputAdornment, Stack, TextField, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import NumberFormatCustom from "components/NumberFormatCustom"
import fieldConstants from "constants/fieldConstants"
import { parseCommaPrice } from "utils"

interface GroupGuaranteeDepositFormProps {
    guaranteeDeposit?: number
    setGuaranteeDeposit: (guaranteeDeposit?: number) => void
}

const GroupGuaranteeDepositForm: React.FC<GroupGuaranteeDepositFormProps> = (props) => {
    const { guaranteeDeposit, setGuaranteeDeposit } = props

    const t = useTranslate()
    const title = t("updateSpace.spaceGuaranteeDepositTitle")
    const subtitle = t("updateSpace.spaceGuaranteeDepositSubtitle")
    const amountLabel = t("updateSpace.spaceGuaranteeDepositLabel")
    const hintLabel = t("updateSpace.spaceGuaranteeDepositHint")

    const onGuaranteeDepositChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const guaranteeDeposit = value ? parseCommaPrice(value) : undefined
        setGuaranteeDeposit(guaranteeDeposit)
    }
    
    return <Stack sx={{mt: 3}}>
        <Typography variant="titleMedium">{title}</Typography>
        <Typography variant="labelLarge" sx={{mt: 1, color: 'neutral50.light'}}>{subtitle}</Typography>
        <Typography sx={{mt: 2}} variant="labelLarge">{amountLabel}</Typography>
        <TextField
            margin="normal"
            fullWidth
            placeholder={hintLabel}
            id="guarantee-deposit"
            name="guarantee-deposit"
            onChange={onGuaranteeDepositChangedHandler}
            value={guaranteeDeposit || guaranteeDeposit === 0  ? guaranteeDeposit : ''}
            sx={{width: '328px', mt: 1, '.MuiOutlinedInput-notchedOutline': { borderColor: 'outline.light' }}}
            InputProps={{
                startAdornment: <InputAdornment position="start" sx={{'.MuiTypography-root': { color: 'onSurfaceVariant.light'}}}>$</InputAdornment>,
                inputComponent: NumberFormatCustom
            }}
            inputProps={{
                maxLength: fieldConstants.defaultGuaranteeDepositMaxLength
            }}
        />
    </Stack>
}

export default GroupGuaranteeDepositForm