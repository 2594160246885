import { FormControl, MenuItem, Select } from "@mui/material"
import moment from "moment"

interface GroupWorkingTimesDayTimeSelectProps {
    hour: string
    updateHour: (hour: string) => void
}

const GroupWorkingTimesDayTimeSelect: React.FC<GroupWorkingTimesDayTimeSelectProps> = (props) => {
    const { hour, updateHour } = props
    const startHour = moment().set('hours', 0).set('minutes', 0).set('seconds', 0)
    
    const times = []
    for(let i = 0; i<24; i++) {
        times[i] = startHour.clone().add(i, 'hours').format('hh:mm a')
    }

    const onHourChangedHandler = (event: any) => {
        let hour = event.target.value
        updateHour(hour)
    }

    return  <FormControl>
        <Select
            sx={{
                borderRadius: '8px',
                height:'32px',
                width: '132px',
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'outline.light',

                }
            }}
            id="working-times-day-time-select"
            value={hour}
            onChange={onHourChangedHandler}
        >
            {times.map((time, index) =>
                <MenuItem
                    key={`working-times-day-time-select-item-${index}`}
                    value={moment(time, 'hh:mm a').format('HH:mm')}>
                    {time}
                </MenuItem>)}
        </Select>
    </FormControl>
}

export default GroupWorkingTimesDayTimeSelect