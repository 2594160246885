import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import UploadIcon from '@mui/icons-material/Upload';
import Button from "@mui/material/Button";
import useFileUpload from "../hooks/useFileUpload";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from '@mui/icons-material/Cancel';
import {ref, getStorage} from "firebase/storage";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import IconButton from "@mui/material/IconButton";
import { storagePaths } from "constants/storagePaths";

export interface DocumentUploadProps {
    children?: React.ReactNode
    title: string
    downloadUrl: string | null
    onUrlChange: (url: string | null) => void
    spaceDetailId: string
}

const documentUploadSuffix = 'spaceDocument'

export const getNameFromUrl = (downloadUrl: string): string => {
    return decodeURI(downloadUrl.split('___')[1])
}

const allowedFormats = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/webp',
    'application/pdf'
]

const DocumentUpload: React.FC<DocumentUploadProps> = (props) => {

    const {downloadUrl, title, spaceDetailId} = props
    const [file, setFile] = useState<File | null>(null)

    const storage = getStorage();
    const theme = useTheme();

    const description = file ? file.name : title

    const {
        uploadFile,
        reset,
        isUploading,
        isComplete,
        progress,
        error
    } = useFileUpload()

    const fileSelectionHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        if(fileList != null) {
            const currentFile = fileList[0];
            if (currentFile != null) {
                const fileType = currentFile.type;
                if(allowedFormats.includes(fileType)) {
                    setFile(currentFile)
                    const timestamp = Date.now()
                    const newDocumentRef = await ref(storage, `${storagePaths.hostDocs}${spaceDetailId}_${documentUploadSuffix}_${timestamp}___${currentFile.name}___`);
                    await uploadFile(currentFile, newDocumentRef, (url: string) => {
                        props.onUrlChange(url)
                    }, {
                        name: currentFile.name
                    })
                }
            }
        }
    }

    const clearClickHandler = async (event: React.MouseEvent<any>) => {
        event.preventDefault()
        reset()
        setFile(null)
        props.onUrlChange(null)
    }

    return <Box
        sx={{width: '400px', display: 'flex', flexDirection: 'row', justifyContent: 'flexStart', alignItems: 'center'}}>
        {downloadUrl !== null && <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
            <IconButton><SaveAltIcon/></IconButton>
        </a>
        }
        <Button
            sx={{
                display: 'flex',
                flexDirection: 'row'
            }}
            variant={downloadUrl !== null ? 'text' : 'outlined'}
            fullWidth
            component='label'
            disabled={isUploading}
            startIcon={downloadUrl !== null ? null : <UploadIcon color="primary"/>}
        >
            <Typography sx={{ml: 1}} variant='labelLarge' color="primary">
                {downloadUrl !== null ? getNameFromUrl(downloadUrl) : title}
            </Typography>
            <input type="file" accept={allowedFormats.join(', ')} onChange={fileSelectionHandler} hidden/>
            {isUploading &&
                <CircularProgress sx={{ml: 2, width: '20px', height: '20px'}} variant="determinate"
                                  value={progress}/>}
        </Button>
        {downloadUrl !== null &&
            <IconButton onClick={clearClickHandler}>
                <CancelIcon color='primary'/>
            </IconButton>}
    </Box>
}

export default DocumentUpload