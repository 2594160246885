import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm, ValidateResult } from 'react-hook-form';
import { ILoginArgs } from 'interfaces';
import { useLogin, useTranslate } from '@pankod/refine-core';

const theme = createTheme();

export function SignIn() {
  const t = useTranslate();
  const { mutate: login, isLoading } = useLogin<ILoginArgs>();
  const { register, handleSubmit, formState: { errors } } = useForm<ILoginArgs>();
  const regExpEmail = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
  
  const generateErrorMessage = React.useCallback((fieldName: string) => {
    let errorMessage;
    const errorsAux: any = {...errors};
    if(errorsAux[fieldName]) {
      switch(errorsAux[fieldName].type) {
        case 'required': errorMessage = t("errors.required.field"); break;
        case 'pattern': errorMessage = t("errors.invalidEmail.field"); break;
        default: errorMessage = '';
      }
    }
    return errorMessage;
  }, [errors])
  
  const onLogin = (args: ILoginArgs) => {
    login(args)
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
          { t("pages.login.title") }
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onLogin)} noValidate sx={{ mt: 1 }}>
            <TextField {...register('username', { required: true, pattern: regExpEmail })}
              margin="normal"
              fullWidth
              label={ t("pages.login.username") }
              autoComplete="email"
              autoFocus
              error={!!errors.username}
              helperText={generateErrorMessage('username')}
            />
            <TextField {...register('password', { required: true })}
              margin="normal"
              fullWidth
              label={ t("pages.login.password") }
              type="password"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={generateErrorMessage('password')}
            />
            <FormControlLabel
              control={<Checkbox {...register('remember')} color="primary" />}
              label={ t("pages.login.remember") }
            />
            <Button
              disabled={isLoading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              { t("pages.login.signin") }
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}