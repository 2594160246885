import { Box, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core";
import { formatNumberPrice } from "utils";

interface PriceByPersonProps {
    subtotal: number;
    people: number;
}

const PriceByPerson: React.FC<PriceByPersonProps> = (props) => {
    const { subtotal, people } = props;
    const priceByPerson = subtotal / people;
    const t = useTranslate();
	const priceByPersonText = t('previewDetails.priceByPersonText');
    
    return <Box sx={{
        borderRadius: '4px',
        padding: '4px',
        display: 'flex',
        gap: '4px',
        border: '1.5px solid',
        borderColor: 'primary.light'
    }}>
        <Typography variant="titleSmall" sx={{color: 'primary.light'}}>
            {priceByPersonText}: {formatNumberPrice(priceByPerson)}
        </Typography>
    </Box>
}

export default PriceByPerson;