import { Box, Divider, IconButton, Stack, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { Group } from "interfaces/space"
import { useTranslate } from "@pankod/refine-core"

interface GroupFormHeaderProps {
    onCloseHandler: () => void
    currentGroup: Group | null
}

const GroupFormHeader: React.FC<GroupFormHeaderProps> = (props) => {
    const { onCloseHandler, currentGroup } = props
    
    const t = useTranslate()
    const addGroup = t("updateSpace.spaceGroupsAdd")
    const editGroup = t("updateSpace.spaceGroupsEdit")

    return <Stack sx={{
        position: 'absolute', 
        top: 0, 
        left: 0, 
        zIndex: 1,
        width: '100%', 
        backgroundColor: 'onPrimary.light'
    }}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '10px 8px 10px 24px'
        }}>
            <IconButton onClick={onCloseHandler}>
                <CloseIcon sx={{color: 'onSurface.light'}}/>
            </IconButton>
            <Typography 
                sx={{ml: 2}} 
                variant='titleLarge'
                color='neutral0'>{currentGroup === null ? addGroup : editGroup}</Typography>

        </Box>
        <Divider/>
    </Stack>
}

export default GroupFormHeader