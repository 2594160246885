import { Box } from "@mui/material"
import { Package, SpaceDetail } from "interfaces/space"
import AdName from "./AdName"
import AdInfo from "./AdInfo"
import Divider from "components/Divider"
import AdDescription from "./AdDescription"
import AdEventTypes from "./AdEventTypes"
import AdRules from "./AdRules"
import AdLocation from "./AdLocation"
import AdReviews from "./AdReviews"
import AdLegales from "./AdLegales"
import AdMultimedia from "./AdMultimedia"

interface AdContainerProps {
    spaceDetail: SpaceDetail
    onReservePackage: (selectedPackage: Package) => void
}

const AdContainer: React.FC<AdContainerProps> = (props) => {
    const { spaceDetail, onReservePackage } = props

    return <Box sx={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
        <AdMultimedia spaceDetail={spaceDetail}/>
        <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: '40px'}}>
            <Box>
                <Box sx={{mt: {xs: '8px', md: '16px'}}}>
                    <AdName spaceDetail={spaceDetail}/>
                </Box>
                <Box sx={{mt: {xs: '24px', md: '16px'}}}>
                    <AdInfo spaceDetail={spaceDetail}/>
                </Box>
            </Box>
            <Divider/>
            <AdDescription spaceDetail={spaceDetail}/>
            <Divider/>
            <AdEventTypes spaceDetail={spaceDetail}/>
            <Divider/>
            <AdRules spaceDetail={spaceDetail}/>
            <Divider/>
            <AdLocation spaceDetail={spaceDetail}/>
            <Divider/>
            <AdReviews spaceDetail={spaceDetail}/>
            <Divider/>
        </Box>
        <Box sx={{pt: '40px', pb: '72px'}}>
            <AdLegales/>
        </Box> 
    </Box>
}

export default AdContainer