import React, {useCallback} from "react";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { useTranslate } from "@pankod/refine-core";

interface SpacePeopleSliderProps {
    min: number
    max: number
    selectedPeople: number
    selectPeople: (people: number) => void
}

const SpacePeopleSlider: React.FC<SpacePeopleSliderProps> = (props) => {
    const {selectedPeople, selectPeople, min, max} = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'
    
    const t = useTranslate()
    const title = t('previewDetails.enterGuestsNumber');

    const onChangePeople = useCallback((event: Event, newValue: number | number[]) => {
        selectPeople(newValue as number)
    }, [selectPeople])

    const marks = [
        {value: min, label: min.toString()},
        {value: max, label: max.toString()}
    ]

    if(min === 0 || max === 0) {
        return <></>
    }

    return <Stack spacing={2}>
        <Typography variant={titleVariant}>{title} {selectedPeople}</Typography>
        <Slider
            marks={marks}
            min={min}
            max={max}
            value={selectedPeople}
            onChange={onChangePeople}
            valueLabelDisplay="auto"
        />
    </Stack>
}

export default SpacePeopleSlider