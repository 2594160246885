import { Box, FormControlLabel, Radio, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import { GroupRange } from "interfaces/space"
import { formatNumberPrice } from "utils"

interface SpacePeopleRangeProps {
    range: GroupRange
    rangeIndex: number
}

const SpacePeopleRange: React.FC<SpacePeopleRangeProps> = (props) => {
    const { range, rangeIndex } = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const textVariant = isSM ? 'labelLarge' : 'titleMedium'
    const priceVariant = isSM ? 'bodyMedium' : 'bodyLarge'
    
    const t = useTranslate()
    const ofLabel = t('previewDetails.ofLabel')
    const toLabel = t('previewDetails.toLabel')
    const priceByEventLabel = t('updateSpace.spaceGroupsPriceTypeByEvent')
    const priceByPersonLabel = t('updateSpace.spaceGroupsPriceTypeByPerson')
    const priceByHourLabel = t('updateSpace.priceByHour')
    
    return <Box sx={{display: 'flex', width: '100%'}}>
        <FormControlLabel 
            value={rangeIndex} 
            control={<Radio/>} 
            sx={{
                width: '100%', 
                '.MuiFormControlLabel-label': {width: '100%'},
                alignItems: 'flex-start'
            }}
            label={
                <Stack>
                    <Typography variant={priceVariant}>{ofLabel} <Typography variant={textVariant}>{range.occupancy.minimum}</Typography> {toLabel} <Typography variant={textVariant}>{range.occupancy.maximum}</Typography></Typography>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography variant={priceVariant}>{priceByEventLabel}</Typography>
                        <Typography variant={textVariant} color={'outline.light'}>{formatNumberPrice(range.prices.byEvent)}</Typography>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography variant={priceVariant}>{priceByPersonLabel}</Typography>
                        <Typography variant={textVariant} color={'outline.light'}>{formatNumberPrice(range.prices.byPerson)}</Typography>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography variant={priceVariant}>{priceByHourLabel}</Typography>
                        <Typography variant={textVariant} color={'outline.light'}>{formatNumberPrice(range.prices.byHour)}</Typography>
                    </Box>
                </Stack>
            } 
        />
    </Box>
}

export default SpacePeopleRange