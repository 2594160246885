import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./i18n";
import { Provider } from "react-redux";
import store from "store";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {es} from "date-fns/locale";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Provider store={store}>
          <App />
        </Provider>
      </LocalizationProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
