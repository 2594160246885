import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api"
import { SpaceDetail } from "interfaces/space"
import { useRef, useState } from "react"

interface AdLocationProps {
    spaceDetail: SpaceDetail
}

const AdLocation: React.FC<AdLocationProps> = (props) => {
    const {spaceDetail} = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'
    const textVariant = isSM ? 'titleSmall' : 'bodyLarge'
    const t = useTranslate()
    const title = t('previewDetails.locationTitle')

    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY!;
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: ["places"]
    })
    const mapRef = useRef<google.maps.Map>()
    const initialCenter = spaceDetail?.address?.location?.coordinates !== undefined ?
        {lat: spaceDetail.address.location.coordinates[1], lng: spaceDetail.address.location.coordinates[0]} :
        {lat: 19.419, lng: -99.162}
    const initialMarker = spaceDetail?.address?.location?.coordinates !== undefined ?
    {lat: spaceDetail.address.location.coordinates[1], lng: spaceDetail.address.location.coordinates[0]} : null
    const [center, setCenter] = useState(initialCenter)
    const containerStyle = {
        width: '100%',
        height: isSM ? '111px' : '250px'
    };
    const [selected, setSelected] = useState<{ lat: number, lng: number } | null>(initialMarker)

    if(!spaceDetail.address) {
        return <></>
    }
    
    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
        <Typography variant={titleVariant}>{title}</Typography>
        <Typography variant={textVariant}>{spaceDetail.address.fullAddress}</Typography>
        {
            isLoaded && <GoogleMap
                onLoad={(map) => {
                    mapRef.current = map
                }}
                onUnmount={() => {
                    mapRef.current = undefined
                }}
                zoom={15}
                center={center}
                mapContainerStyle={containerStyle}>
                {selected && <Marker position={selected}/>}
            </GoogleMap>
        }
    </Box>
}

export default AdLocation