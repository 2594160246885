import { useCallback } from "react";
import { useNavigation, useOne, useRouterContext, useTranslate } from "@pankod/refine-core";
import {
  Box, Typography,
} from "@pankod/refine-mui";
import SpacePreviewEditButton from "components/details/SpacePreviewEditButton";
import paths from "constants/paths";
import AdContainer from "components/ads/AdContainer";
import PageTitle from "components/PageTitle";
import Notice from "components/Notice";
import { Package, SpaceDetail } from "interfaces/space";
import { getReservationUrl } from "utils";
import AdFixedButton from "components/ads/AdFixedButton";

export const AdvertisementDetails: React.FC = () => {
	const t = useTranslate();
	const { useParams } = useRouterContext()
	const params: any = useParams()
	const { data, isError, isLoading } = useOne<SpaceDetail>({
		resource: "advertisements",
		id: params.id,
	});
	const spaceDetail: SpaceDetail | undefined = data?.data

	const { push } = useNavigation();
	const title = t('previewDetails.title');
    const notice = t('previewDetails.notice');

	const onGoBack = useCallback(() => {
		push(paths.adList);
	}, []);

	const onGoToAdAvailability = useCallback(() => {
		push(paths.adAvailability + "/" + data?.data.id);
	}, [data]);

	const onReservePackage = useCallback((selectedPackage: Package) => {
        const url = getReservationUrl(spaceDetail!.id, false, undefined, undefined, selectedPackage._id)
        push(url)
    }, [spaceDetail])

	if(isLoading) {
		return <></>
	}
	if(isError || !spaceDetail) {
		return <>Error al cargar el anuncio.</> 
	}

  	return <Box sx={{width: '100%', backgroundColor: 'white', pt: '32px'}}>
		<Box sx={{
			width: '100%',
			maxWidth: '1094px',
			display: 'flex',
			flexDirection: 'column',
			margin: '0 auto',
			padding: '16px'
		}}>
			<PageTitle title={title} onBack={onGoBack}></PageTitle>
			<Box sx={{marginTop: '28px', marginBottom: '40px'}}>
				<Notice>
					<Box sx={{display: 'flex', alignItems: 'center'}}>
						<Typography variant="labelLarge">{notice}</Typography>
					</Box>
				</Notice>
			</Box>
			<Box sx={{display: 'flex', justifyContent: 'flex-end', marginBottom: '18px'}}>
				<SpacePreviewEditButton spaceId={spaceDetail.id}></SpacePreviewEditButton>
			</Box>
			<AdContainer 
				spaceDetail={spaceDetail} 
				onReservePackage={onReservePackage}
			/>
		</Box>
		<AdFixedButton onGoToCheckAvailability={onGoToAdAvailability}/>
	</Box> 
}

