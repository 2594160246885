import { Box, Typography, styled, useMediaQuery, useTheme } from "@mui/material"
import {OverridableStringUnion} from "@mui/types"
import { Variant } from "@mui/material/styles/createTypography"
import { TypographyPropsVariantOverrides } from "@mui/material/Typography/Typography"
import { PaidService } from "interfaces/space"
import { useTranslate } from "@pankod/refine-core"
import PriceByPerson from "./PriceByPerson"
import { formatNumberPrice } from "utils"

const RawContainer = styled(Box)(() => ({
    display: 'flex'
}))

const ServiceLabel = styled(Typography)(() => ({
    width: '100%',
}))

const PriceLabel = styled(Typography)(() => ({
    width: '100%',
    textAlign: 'right'
}))

export type PriceDetailSizes = 'SM' | 'MD' | 'LG'

interface PriceDetailProps {
    canHideTitle?: boolean
    canShowPriceByPerson?: boolean
    subtotal: number
    people: number
    packageAmount: number
    spaceBasePrice?: number
    paidServices: PaidService[]
    iva: number
    ivaAmount: number
    commission: number
    commissionAmount: number
    commissionIvaAmount: number
    guaranteeDeposit: number
    total: number
    grandTotal: number
    size: PriceDetailSizes
}

interface TextVariant {
    title: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
    header: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
    content: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>
}

const PriceDetail: React.FC<PriceDetailProps> = (props) => {
    const { 
        canHideTitle,
        subtotal, 
        people, 
        packageAmount, 
        spaceBasePrice, 
        paidServices, 
        iva,
        ivaAmount,
        commission,
        commissionAmount,
        commissionIvaAmount,
        guaranteeDeposit,
        total,
        grandTotal,
        size
    } = props
    
    const canShowPriceByPerson = props.canShowPriceByPerson === false ? false : true
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))

    const t = useTranslate();
    const title = t('previewDetails.priceDetail')
    const servicesLabel = t('previewDetails.menuServices')
    const unitPriceLabel = t('previewDetails.unitPrice')
    const priceBaseLabel = t('previewDetails.priceDetailBase')
    const packagePriceLabel = t('previewDetails.reservationPackagePrice')
    const eachOneLabel = t('previewDetails.eachOne')
    const subtotalLabel = t('previewDetails.priceDetailSubtotal')
    const ivaLabel = t('previewDetails.ivaLabel')
    const ifiestaCommisionLabel = t('previewDetails.ifiestaCommisionLabel')
    const ivaIfiestaCommisionLabel = t('previewDetails.ivaIfiestaCommisionLabel')
    const guaranteeDepositLabel = t('previewDetails.guaranteeDepositLabel')
    const totalLabel = t('previewDetails.priceDetailTotal')
    const grandTotalLabel = t('previewDetails.grandTotalText')

    const generateTextVariants = (size: PriceDetailSizes, isSM: boolean, canHideTitle?: boolean): TextVariant  => {
        switch (size) {
            case 'SM':
                return {
                    title: isSM ? 'labelMedium' : 'titleMedium',
                    header: isSM ? 'labelMedium' : 'titleMedium', 
                    content: isSM ? 'labelMedium' : 'labelMedium'
                }

            case 'MD':
                return {
                    title: isSM ? 'labelLarge' : 'titleMedium', 
                    header: isSM ? 'labelLarge' : 'labelLarge', 
                    content: isSM ? 'bodyMedium' : 'bodyMedium'
                }
        
            default: // LG
                return {
                    title: isSM ? 'titleMedium' : 'titleLarge', 
                    header: isSM ? 'labelLarge' : 'titleMedium', 
                    content: isSM ? 'labelMedium' : 'bodyLarge'
                }
        }
    }
    
    const textVariants = generateTextVariants(size, isSM, canHideTitle)
    const titleVariant = textVariants.title
    const headerLabelVariant = textVariants.header
    const contentLabelVariant = textVariants.content

    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', padding: '8px'}}>
        {
            !canHideTitle && <Box sx={{
                display: 'flex',
                flexDirection: {xs: 'column-reverse', md: 'row'},
                alignItems: {xs: 'flex-start', md: 'center'},
                justifyContent: 'space-between',
                gap: {xs: '16px', md: '0px'}
            }}>
                <Typography variant={titleVariant}>{title}</Typography>
                {
                    canShowPriceByPerson && <PriceByPerson
                        subtotal={subtotal}
                        people={people}
                    />
                }
            </Box>
        }
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
            <RawContainer>
                <ServiceLabel variant={headerLabelVariant}>{servicesLabel}</ServiceLabel>
                <PriceLabel variant={headerLabelVariant}>{unitPriceLabel}</PriceLabel>
                <PriceLabel variant={headerLabelVariant}>{totalLabel}</PriceLabel>
            </RawContainer>
            {
                packageAmount === 0 && <RawContainer>
                    <ServiceLabel variant={contentLabelVariant}>{priceBaseLabel}</ServiceLabel>
                    <PriceLabel variant={contentLabelVariant}>{formatNumberPrice(spaceBasePrice!)}</PriceLabel>
                </RawContainer>
            }
            {
                paidServices && paidServices.length > 0 && paidServices.map((paidService: PaidService, index: number) => {
                    return <RawContainer key={'paid-service-' + index}>
                        <ServiceLabel variant={contentLabelVariant}>{paidService.name}</ServiceLabel>
                        { paidService.isByPerson && <PriceLabel variant={contentLabelVariant}>{formatNumberPrice(paidService.price)} {eachOneLabel}</PriceLabel>}
                        <PriceLabel variant={contentLabelVariant}>{formatNumberPrice(paidService.isByPerson ? paidService.price * people : paidService.price)}</PriceLabel>
                    </RawContainer>;
                })
            }
            {
                packageAmount > 0 && <RawContainer>
                    <ServiceLabel variant={contentLabelVariant}>{packagePriceLabel}</ServiceLabel>
                    <PriceLabel variant={contentLabelVariant}>{formatNumberPrice(packageAmount)}</PriceLabel>
                </RawContainer>
            }
            <RawContainer>
                <ServiceLabel variant={headerLabelVariant}>{subtotalLabel}</ServiceLabel>
                <PriceLabel variant={headerLabelVariant}>{formatNumberPrice(subtotal)}</PriceLabel>
            </RawContainer>
            <RawContainer>
                <ServiceLabel variant={contentLabelVariant}>{ivaLabel} ({iva * 100}%)</ServiceLabel>
                <PriceLabel variant={contentLabelVariant}>{formatNumberPrice(ivaAmount)}</PriceLabel>
            </RawContainer>
            <RawContainer>
                <ServiceLabel variant={contentLabelVariant}>{ifiestaCommisionLabel} ({commission * 100}%)</ServiceLabel>
                <PriceLabel variant={contentLabelVariant}>{formatNumberPrice(commissionAmount)}</PriceLabel>
            </RawContainer>
            <RawContainer>
                <ServiceLabel variant={contentLabelVariant}>{ivaIfiestaCommisionLabel} ({iva * 100}%)</ServiceLabel>
                <PriceLabel variant={contentLabelVariant}>{formatNumberPrice(commissionIvaAmount)}</PriceLabel>
            </RawContainer>
            {
                guaranteeDeposit > 0 && <>
                    <RawContainer>
                        <ServiceLabel variant={headerLabelVariant}>{totalLabel}</ServiceLabel>
                        <PriceLabel variant={headerLabelVariant}>{formatNumberPrice(total)}</PriceLabel>
                    </RawContainer>
                    <RawContainer>
                        <ServiceLabel variant={contentLabelVariant}>{guaranteeDepositLabel}</ServiceLabel>
                        <PriceLabel variant={contentLabelVariant}>{formatNumberPrice(guaranteeDeposit)}</PriceLabel>
                    </RawContainer>
                </>
            }
            <RawContainer>
                <ServiceLabel variant={headerLabelVariant}>{grandTotalLabel}</ServiceLabel>
                <PriceLabel variant={headerLabelVariant}>{formatNumberPrice(grandTotal)}</PriceLabel>
            </RawContainer>
        </Box>
    </Box>
}

export default PriceDetail