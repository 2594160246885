import { Group, GroupPackage, GroupRange, PaidService } from "interfaces/space"
import PriceDetail, { PriceDetailSizes } from "./PriceDetail"
import { calculateGroupBasePrice, calculatePackagePrice, sumServices } from "utils"
import { useAppSelector } from "store/hooks"


interface SpacePriceDetailProps {
    people: number
    paidServices: PaidService[]
    selectedPackage?: GroupPackage
    size: PriceDetailSizes
    canHideTitle?: boolean
    isHost?: boolean
    group: Group
    range: GroupRange | null
    eventHours: number | null
}

const SpacePriceDetail: React.FC<SpacePriceDetailProps> = (props) => {
    const { 
        people, 
        paidServices, 
        selectedPackage, 
        size, 
        isHost, 
        group,
        range,
        eventHours
    } = props
    const guaranteeDeposit = group && group.guaranteeDeposit ? group.guaranteeDeposit : 0
    const canHideTitle = props.canHideTitle === true ? true : false
    const groupBasePrice = calculateGroupBasePrice(group, range, people, eventHours)
        
    const iva = 0.16
    const guestCommission = 0.09
    const hostCommission = 0.05
    const commission = isHost ? hostCommission : guestCommission
    
    const paidServicesTotal = sumServices(paidServices, people)
    const packageAmount = selectedPackage ? calculatePackagePrice(people, selectedPackage.paidServices, groupBasePrice, selectedPackage.price) : 0
    const subtotal = selectedPackage ? paidServicesTotal + packageAmount : paidServicesTotal + groupBasePrice
    const ivaAmount = subtotal * iva
    let commissionAmount = subtotal * commission
    commissionAmount = isHost ? commissionAmount * -1 : commissionAmount
    const commissionIvaAmount = commissionAmount * iva
    const total = subtotal + ivaAmount + commissionAmount + commissionIvaAmount;
    const grandTotal = total + guaranteeDeposit
    
    return <PriceDetail
        people={people}
        subtotal={subtotal}
        spaceBasePrice={groupBasePrice}
        paidServices={paidServices}
        packageAmount={packageAmount}
        iva={iva}
        ivaAmount={ivaAmount}
        commission={commission}
        commissionAmount={commissionAmount}
        commissionIvaAmount={commissionIvaAmount}
        guaranteeDeposit={guaranteeDeposit}
        total={total}
        grandTotal={grandTotal}
        size={size}
        canHideTitle={canHideTitle}
    />
}

export default SpacePriceDetail