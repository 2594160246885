import React, {useCallback} from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { SyncError } from "viewModels/EditableSyncViewModel";
import { useTranslate } from "@pankod/refine-core";
import RoundedButton from "./RoundedButton";

export interface EditableFormCardProps {
    children?: React.ReactNode
    title: string
    subtitle: string
    cancelText?: string
    showCancel: boolean
    isLoading: boolean
    isFormValid: boolean
    isFocused: boolean
    error: SyncError | null
    onCancel: () => void
    onSkip?: () => void
    onSave: () => void
    onRetry: () => void
    optional?: boolean
    hideSaveButton?: boolean
}

const EditableFormCard: React.FC<EditableFormCardProps> = (props) => {
    const theme = useTheme();
    const t = useTranslate()
    const saveText = t('updateSpace.save')
    const skipText = t('updateSpace.skip')
    const retryText = t('updateSpace.retry')
    let cancelText = t('updateSpace.cancel')
    cancelText = props.cancelText ? props.cancelText : cancelText

    // const getDisabled = useCallback(() => {
    //     if (props.optional === true) {
    //         return false
    //     } else {
    //         !props.isFormValid
    //     }
    // }, [props.isFormValid, props.optional])

    const isDisabled = !props.isFormValid//getDisabled()

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        border: 1,
        borderRadius: "28px",
        borderColor: props.isFocused ? theme.palette.primary50.main : theme.palette.neutral80.main,
        backgroundColor: 'onPrimary.main'
    }}>
        <Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                py: '40px',
                paddingLeft: '38px',
                paddingRight: '16px'
            }}>
                <Typography
                    variant='titleLarge'>{props.title}</Typography>
                <Typography sx={{mt: 1, mr: 3}}
                            variant='labelSmall'>{props.subtitle}</Typography>
                {props.children}
            </Box>
            {props.error !== null && <Alert
                sx={{width: '100%'}}
                severity="error"
                action={props.error.showRetry ?
                    <Button color="inherit" size="small" onClick={props.onRetry}>
                        <Typography variant="labelLarge">
                            {retryText}
                        </Typography>
                    </Button> : null
                }
            >
                {props.error.errorTitle && <AlertTitle>{props.error.errorTitle}</AlertTitle>}
                <Typography variant="bodyMedium">{props.error.errorMessage}</Typography>
            </Alert>}
        </Box>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: props.showCancel || props.optional ? 'space-between' : 'flex-end',
            borderTop: 1,
            height: '72px',
            px: '40px',
            borderColor: props.isFocused ? theme.palette.primary50.main : theme.palette.neutral80.main
        }}>
            {props.showCancel ?
                <Button variant='text' onClick={props.onCancel}>
                    <Typography variant='labelLarge'>{cancelText}</Typography>
                </Button> : ''
            }
            {props.optional && !props.showCancel ?
                <Button variant='text' onClick={props.onSkip}>
                    <Typography variant='labelLarge'>{skipText}</Typography>
                </Button> : ''
            }
            {!props.hideSaveButton && <RoundedButton
                loading={props.isLoading}
                variant="contained"
                disabled={isDisabled}
                onClick={props.onSave}>
                <Typography variant='labelLarge'>
                    {saveText}
                </Typography>
            </RoundedButton>}
        </Box>
    </Box>
}

export default EditableFormCard