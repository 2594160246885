import React, {useEffect, useState} from "react";
import MuiModal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {Divider} from "@mui/material";
import Button from "@mui/material/Button";
import { CatalogEntry } from "interfaces/catalogEntry";
import { useTranslate } from "@pankod/refine-core";
import MultiSelectChipGroup from "components/MultiSelectChipGroup";
import RoundedButton from "components/RoundedButton";

interface GroupPackageAmenitiesModalProps {
    options: CatalogEntry[]
    selectedAmenitiesNotInSpace: CatalogEntry[]
    open: boolean
    onClose: () => void
    onSave: (entries: CatalogEntry[]) => void
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '28px'
};

const GroupPackageAmenitiesModal: React.FC<GroupPackageAmenitiesModalProps> = (props) => {

    const {options, selectedAmenitiesNotInSpace, open, onClose, onSave} = props
    const [amenities, setAmenities] = useState<CatalogEntry[]>([])
    const t = useTranslate()
    const addAmenities = t("updateSpace.spacePackagesAmenitiesAdd")
    const cancelText = t("buttons.cancel")
    const saveText = t("buttons.save")

    // Requires empty deps array to only run once
    useEffect(() => {
        setAmenities([...selectedAmenitiesNotInSpace])
    }, [selectedAmenitiesNotInSpace])

    const onSelectionsUpdateHandler = (items: CatalogEntry[]) => {
        setAmenities(items)
    }

    const onSaveHandler = () => {
        onSave(amenities)
        setAmenities([])
    }

    const onCloseHandler = () => {
        onClose()
    }

    return <MuiModal
        open={open}
        onClose={onCloseHandler}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box sx={{...style}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: '560px',
                height: '410px'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 2,
                    ml: 4,
                    mr: 2
                }}>
                    <Typography sx={{flexGrow: 1}} variant='titleSmall'
                                color='neutral0'>{addAmenities}</Typography>
                    <CloseIcon onClick={onCloseHandler}/>
                </Box>
                <Divider sx={{mt: 2}}/>
                <Box sx={{flexGrow: 1, mx: 2, mt: 2, overflowY: 'auto'}}>
                    <MultiSelectChipGroup
                        items={options}
                        selections={amenities}
                        onSelectionsUpdated={onSelectionsUpdateHandler}/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mt: 2,
                    mb: 2,
                    mr: 4
                }}>
                    <Button sx={{mr: 3}} color="primary" variant='text' onClick={onCloseHandler}>
                        <Typography variant="labelLarge">
                            {cancelText}
                        </Typography>
                    </Button>
                    <RoundedButton
                        loading={false}
                        variant="contained"
                        onClick={onSaveHandler}>
                        <Typography variant='labelLarge'>
                            {saveText}
                        </Typography>
                    </RoundedButton>
                </Box>
            </Box>
        </Box>
    </MuiModal>
}

export default GroupPackageAmenitiesModal