import { Box, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import visa from "../../images/visa.svg";
import mastercard from "../../images/mastercard.svg";
import { Label } from "./components";

export enum PaymentCardSize {
    SMALL = 'SMALL',
    BIG = 'BIG',
}

interface PaymentCardInfoProps {
    cardbrand: string,
    cardLast4: string,
}

const PaymentCardInfo: React.FC<PaymentCardInfoProps> = (props) => {
    const {cardbrand, cardLast4} = props;
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('md'));

    return <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
        <img src={cardbrand === 'visa' ? visa : cardbrand === 'mastercard' ? mastercard : ''} alt={cardbrand} width={22} height={14}/>
        <Label> **** {cardLast4}</Label>
    </Box>
}

export default PaymentCardInfo;