import { SyncExceptionAttribute } from "components/spaces/update/generalInfo/SyncException";
import { Exception } from "exception/Exception";
import {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {Result} from "typescript-monads";

export enum RetryType {
    Load,
    Sync,
    None
}

export enum StateHandler {
    IsEditing,
    IsFormTouched,
    IsFormValid,
    Error,
    IsLoading,
    IsInit,
    BackingData,
    FormData
}

export interface StatePair<T> {
    value: T,
    setValue: Dispatch<SetStateAction<T>>
}

export interface SyncError {
    errorTitle?: string
    errorMessage: string
    retryType: RetryType
    showRetry: boolean
}

const hasStateHandlers = (stateHandlers?: StateHandler[], fn?: (data: any, stateHandlers: Map<StateHandler, StatePair<any>>) => void): boolean => {
    return stateHandlers !== undefined && fn !== undefined
}

const useEditableSyncViewModel = <T, F>(
    loadData: () => Promise<Result<T, Exception>>,
    syncData: (formData: F) => Promise<Result<T, Exception>>,
    isServerDataEmpty: (serverData: T, isOmitted?: boolean) => boolean,
    mapServerDataToFormData: (serverData: T) => F,
    getErrorMessage: (exception: Exception, attribute: SyncExceptionAttribute) => string,
    optional: boolean,
    stateHandlers ?: StateHandler[],
    onLoad ?: (result: Result<T, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => void,
    onSync ?: (result: Result<T, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => void,
    isOmitted?: boolean
) => {

    // Retain state to know if a form is in editing mode. Should be true when there is no backing data in the server
    // and is the first time edition or when there is backing data and edit has been clicked
    const [isEditing, setEditing] = useState<boolean>(false)

    // Retain state to know if a form has been touched. true when any field value within the form has been changed
    const [isFormTouched, setFormTouched] = useState<boolean>(false)

    // Retain state to know if a form has valid values and is ready to sync with server
    const [isFormValid, setFormValid] = useState<boolean>(optional)

    // Retain state of an error occurring in the form. If the error is null then no error is displayed
    const [error, setError] = useState<SyncError | null>(null)

    // Retain state to know if an async task is running
    const [isLoading, setLoading] = useState<boolean>(true)

    // Retain state to know if the initial load has completed
    const [isInit, setInit] = useState<boolean>(false)

    // Retain state to have the server data
    const [backingData, setBackingData] = useState<any | null>(null)

    // Retain state to have the data as it is set in the form
    const [formData, setFormData] = useState<any | null>(null)

    const mapHandler = useCallback((handler: StateHandler): StatePair<any> => {
        switch (handler) {
            case StateHandler.IsEditing:
                return {value: isEditing, setValue: setFormTouched}
            case StateHandler.IsFormTouched:
                return {value: isFormTouched, setValue: setEditing}
            case StateHandler.IsFormValid:
                return {value: isFormValid, setValue: setFormValid}
            case StateHandler.Error:
                return {value: error, setValue: setError}
            case StateHandler.IsLoading:
                return {value: isLoading, setValue: setLoading}
            case StateHandler.IsInit:
                return {value: isInit, setValue: setInit}
            case StateHandler.BackingData:
                return {value: backingData, setValue: setBackingData}
            case StateHandler.FormData:
                return {value: formData, setValue: setFormData}
        }
    }, [backingData, error, formData, isEditing, isFormTouched, isFormValid, isInit, isLoading])

    const getStateHandlers = useCallback((handlers: StateHandler[]): Map<StateHandler, StatePair<any>> => {
        const result = new Map<StateHandler, StatePair<any>>();
        handlers.forEach((handler) => {
            result.set(handler, mapHandler(handler))
        })
        return result
    }, [mapHandler])

    const resetChanges = useCallback(() => {
        setFormData(mapServerDataToFormData(backingData))
    }, [backingData, mapServerDataToFormData])

    const loadInitialData = useCallback(async () => {
        setLoading(true)
        const result = await loadData()
        if (result.isOk()) {
            const data: T = result.unwrap()
            if (data !== null) {
                if (isServerDataEmpty(data, isOmitted)) {
                    // Server has no data so set in editing mode
                    setEditing(true)
                } else {
                    // Server has data so update backing data and form data
                    setEditing(false)
                    const serverData = result.unwrap()
                    setBackingData(serverData)
                    setFormData(mapServerDataToFormData(serverData))
                }
                setInit(true)
            }
        } else {
            setError({
                errorTitle: getErrorMessage(result.unwrapFail(), SyncExceptionAttribute.Title),
                errorMessage: getErrorMessage(result.unwrapFail(), SyncExceptionAttribute.Content),
                retryType: RetryType.Load,
                showRetry: true
            })
        }
        setLoading(false)
        if (hasStateHandlers(stateHandlers, onLoad)) onLoad!(result, getStateHandlers(stateHandlers!))
    }, [getErrorMessage, getStateHandlers, isServerDataEmpty, loadData, mapServerDataToFormData, onLoad, stateHandlers])

    const sync = useCallback(async () => {
        setLoading(true)
        let result;
        try { result = await syncData(formData) } 
        catch (error) { setLoading(false) }
        if(!result) return;

        if (result.isOk()) {
            const serverData = result.unwrap()
            setBackingData(serverData)
            setFormData(mapServerDataToFormData(serverData))
            setEditing(false)
            setError(null)
        } else {
            setError({
                errorTitle: getErrorMessage(result.unwrapFail(), SyncExceptionAttribute.Title),
                errorMessage: getErrorMessage(result.unwrapFail(), SyncExceptionAttribute.Content),
                retryType: RetryType.Sync,
                showRetry: true
            })
        }
        setLoading(false)
        if (hasStateHandlers(stateHandlers, onSync)) onSync!(result, getStateHandlers(stateHandlers!))
    }, [formData, getErrorMessage, getStateHandlers, mapServerDataToFormData, onSync, stateHandlers, syncData])

    useEffect(() => {
        loadInitialData()
    }, [isOmitted])

    return {
        isInit,
        isLoading,
        isEditing, setEditing,
        isFormTouched, setFormTouched,
        isFormValid, setFormValid,
        error, setError,
        sync,
        setFormData,
        backingData,
        formData,
        loadInitialData,
        resetChanges
    }
}

export default useEditableSyncViewModel