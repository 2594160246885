import { Box, ShowButton, styled } from "@pankod/refine-mui";
import { IBooking } from "interfaces";

interface ActionsProps {
    reservations: readonly IBooking[],
    setOpenSidebar: (status: boolean) => void,
    setSelectedReservation: (reservation: IBooking) => void,
}

const ActionsContainer = styled(Box)(() => ({
    position: "absolute",
    top: '36px',
    right: '0px',
}))

const ActionsHeader = styled(Box)(() => ({
    width: '100%',
    height: '56px',
    backgroundColor: '#F0F0F0',
    '&.theme-dark': {
        backgroundColor: 'rgb(34, 34, 34)',
        borderBottom: '1px solid rgb(32, 32, 32)'
    }
}))

const ActionsCell = styled(Box)(() => ({
    display: 'flex', alignItems: 'center', gap: '8px',
    padding: '0px 16px',
    height: '52px',
    border: '1px solid #F2F2F2',
    borderTop: 'none',
    backgroundColor: '#FFFFFF',
    '&.theme-dark': {
        backgroundColor: '#242424',
        border: '1px solid rgb(34, 34, 34)'
    }
}))

const Actions: React.FC<ActionsProps> = (props) => {
    const { reservations, setOpenSidebar, setSelectedReservation } = props;
    const colorMode = localStorage.getItem("colorMode");
    
    return <ActionsContainer>
        <ActionsHeader className={colorMode === 'dark' ? 'theme-dark' : ''}></ActionsHeader>
        {
            reservations.length > 0 && reservations.map((reservation, index) => {
                return <ActionsCell className={colorMode === 'dark' ? 'theme-dark' : ''} key={'row-' + index}>
                    <ShowButton hideText onClick={() => { 
                            setOpenSidebar(true);
                            setSelectedReservation(reservation)
                    }} />
                </ActionsCell>
            })
        }
    </ActionsContainer>
}

export default Actions;