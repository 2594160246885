import { Box, Typography } from "@mui/material"
import Icon from "components/Icon"

interface AdInfoTagProps {
    icon: string
    text: string
}

const AdInfoTag: React.FC<AdInfoTagProps> = (props) => {
    const { icon, text } = props

    return <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        padding: '8px 16px',
        width: {xs: '100%', md: 'max-content'},
        backgroundColor: 'inverseOnSurface.light',
        borderRadius: '8px'
    }}>
        <Icon
            containerWidth={24}
            containerHeight={24}
            iconWidth={24}
            iconHeight={24}
            iconSrc={icon}
        />
        <Typography variant="labelLarge">{text}</Typography>
    </Box>
}

export default AdInfoTag