import { Box } from "@pankod/refine-mui";
import { IBooking } from "interfaces";
import { Label, Row, Subtitle, Text } from "./components";
import { useTranslate } from "@pankod/refine-core";
import { EPaymentStatus } from "enums";
import { formatNumberPrice } from "utils";

interface PricesPaymentsProps {
    reservation: IBooking
}

const PricesPayments: React.FC<PricesPaymentsProps> = (props) => {
    const { reservation } = props;
    const t = useTranslate();

    let totalPaymentsPaid = 0;
    reservation.payments.forEach((payment) => {
        if(payment.pay_status === EPaymentStatus.PAID) {
            totalPaymentsPaid++;
        }
    })

    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px 0px', borderBottom: '1px solid', borderBottomColor: 'primary.main'}}>
        <Subtitle>{t('reservation.paymentDetails')}</Subtitle>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
            <Row>
                <Label>{t('reservation.totalPayments')}</Label>
                <Text>{reservation.payments.length} {t('reservation.payments')}</Text>
            </Row>
            <Row>
                <Label>{t('reservation.totalPaymentsPaid')}</Label>
                <Text>{totalPaymentsPaid} {t('reservation.of')} {reservation.payments.length} {t('reservation.payments')}</Text>
            </Row>
            <Row>
                <Label>{t('reservation.investmentAmount')}</Label>
                <Text sx={{color: "primary.main", fontWeight: 600}}>{formatNumberPrice(reservation.total, true)}</Text>
            </Row>
            {
                reservation.guaranteeDeposit > 0 && <>
                    <Row>
                        <Label>{t('reservation.guaranteeDeposit')}</Label>
                        <Text>{formatNumberPrice(reservation.guaranteeDeposit, true)}</Text>
                    </Row>
                    <Row>
                        <Label>{t('reservation.grandTotal')}</Label>
                        <Text sx={{color: "primary.main", fontWeight: 600}}>{formatNumberPrice(reservation.grandTotal, true)}</Text>
                    </Row>
                </>
            }
            <Row>
                <Label>{t('reservation.amountPaid')}</Label>
                <Text>{formatNumberPrice(reservation.paidAmount, true)}</Text>
            </Row>
            <Row>
                <Label>{t('reservation.pendingAmount')}</Label>
                <Text>{formatNumberPrice(reservation.pendingAmount, true)}</Text>
            </Row>
        </Box>
    </Box>
}

export default PricesPayments;