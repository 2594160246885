import { Button, Stack, Typography } from "@mui/material"
import GroupDetailPreview from "./GroupDetailPreview"
import { Group } from "interfaces/space"
import { useTranslate } from "@pankod/refine-core"

interface GroupListItemProps {
    group: Group
    onShowMoreClickHandler: (group: Group) =>  void
}

const GroupListItem: React.FC<GroupListItemProps> = (props) => {
    const { group, onShowMoreClickHandler } = props
    const t = useTranslate()
    const showMoreLabel = t("previewDetails.detailShowMore")
    
    return <Stack>
        <GroupDetailPreview group={group}/>
        <Button
            variant="text"
            sx={{width: 'max-content', mt: 2}}
            onClick={() => onShowMoreClickHandler(group)}
        >
            <Typography variant="labelLarge">{showMoreLabel}</Typography>
        </Button>
    </Stack>
}

export default GroupListItem