import { Box, Button, Stack, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import { useState } from "react"
import { PaidService } from "interfaces/space"
import { useTranslate } from "@pankod/refine-core"
import PaidServicesChipGroup from "./PaidServicesChipGroup"
import PaidServicesCreateFormModal from "./PaidServicesCreateFormModal"
import PaidServicesEditFormModal from "./PaidServicesEditFormModal"

interface GroupPaidServicesFormProps {
    paidServices: PaidService[]
    setPaidServices: (paidServices: PaidService[]) => void
}

const GroupPaidServicesForm: React.FC<GroupPaidServicesFormProps> = (props) => {
    const { paidServices, setPaidServices } = props
    const [openCreateServiceModal, setOpenCreateServiceModal] = useState(false)
    const [openEditServiceModal, setOpenEditServiceModal] = useState(false)
    const [editIndex, setEditIndex] = useState(-1)
    const [editService, setEditService] = useState<PaidService | null>(null)

    const t = useTranslate()
    const title = t("updateSpace.spacePaidServicesTitle")
    const subtitle = t("updateSpace.spacePaidServicesSubtitle")
    const addPaidServiceLabel = t("updateSpace.spacePaidServicesAdd")

    const onOpenCreatePaidServiceModal = () => {
        setOpenCreateServiceModal(true)
    }

    const onCloseCreatePaidServiceModal = () => {
        setOpenCreateServiceModal(false)
    }

    const onCloseEditPaidServiceModal = () => {
        setOpenEditServiceModal(false)
    }

    const onPaidServiceClickedHandler = (index: number, paidService: PaidService) => {
        setOpenEditServiceModal(true)
        setEditIndex(index)
        setEditService(paidService)
    }

    const onPaidServiceRemovedHandler = (paidService: PaidService) => {
        const paidServicesCopy = [...paidServices]
        const index = paidServicesCopy.findIndex((service) => {
            return service.name === paidService.name &&
                service.price === paidService.price &&
                service.isByPerson === paidService.isByPerson
        })
        paidServicesCopy.splice(index, 1)
        setPaidServices(paidServicesCopy)
    }

    const onSavePaidServiceHandler = (paidService: PaidService) => {
        const paidServicesCopy = [...paidServices]
        paidServicesCopy.push(paidService)
        setPaidServices(paidServicesCopy)
    }

    const onSavePaidServiceEditedHandler = (index: number, paidService: PaidService) => {
        const paidServicesCopy = [...paidServices]
        paidServicesCopy[index] = paidService
        setPaidServices(paidServicesCopy)
    }
    
    return <Stack sx={{mt: 3}}>
        <Typography variant="titleMedium">{title}</Typography>
        <Typography variant="labelLarge" sx={{mt: 1, color: 'neutral50.light'}}>{subtitle}</Typography>
        {
            paidServices.length > 0 && <Box sx={{mt: 1}}>
                <PaidServicesChipGroup
                    paidServices={paidServices}
                    onServiceClicked={onPaidServiceClickedHandler}
                    onServiceRemoved={onPaidServiceRemovedHandler}
                />
            </Box>
        }
        <Button
            startIcon={<AddIcon/>}
            variant='text'
            sx={{width: 'max-content', mt: 2}}
            onClick={onOpenCreatePaidServiceModal}
        >
            <Typography variant='labelLarge'>{addPaidServiceLabel}</Typography>
        </Button>
        <PaidServicesCreateFormModal
            open={openCreateServiceModal}
            onSavePaidService={onSavePaidServiceHandler}
            onClose={onCloseCreatePaidServiceModal}
            isPackage={false}
        />
        {editIndex !== -1 && editService !== null &&
            <PaidServicesEditFormModal
                open={openEditServiceModal}
                index={editIndex}
                paidService={editService}
                onSavePaidService={onSavePaidServiceEditedHandler}
                onClose={onCloseEditPaidServiceModal}
                isPackage={false}
            />
        }
    </Stack>
}

export default GroupPaidServicesForm