import React, {useCallback} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SpacePaidServiceCheckbox from "./SpacePaidServiceCheckbox";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { GroupPackage, PaidService } from "interfaces/space";
import { useTranslate } from "@pankod/refine-core";
import { containsPaidService, isPaidServiceTheSame, sortPaidServices, uniqueArray } from "utils";
import Divider from "components/Divider";
import ShowMoreButton from "components/ShowMoreButton";

interface SpaceServicesProps {
    groupBasePrice: number,
    groupPaidServices: PaidService[],
    selectedServices: PaidService[],
    selectedPackage?: GroupPackage,
    selectPaidService: (paidService: PaidService, checked: boolean) => void,
    canShowAllServices: boolean,
    setCanShowAllServices: (state: boolean) => void
}

const SpaceServices: React.FC<SpaceServicesProps> = (props) => {
    const {
        groupBasePrice,
        groupPaidServices, 
        selectedServices, 
        selectedPackage, 
        selectPaidService, 
        canShowAllServices, 
        setCanShowAllServices
    } = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'

    const t = useTranslate();
    const reservationServicesTitle = t('previewDetails.reservationServicesTitle');
    const priceDetailBase = t('previewDetails.priceDetailBase');

    let newArray: PaidService[] = [];
    if(selectedPackage) {
        newArray = uniqueArray([...groupPaidServices, ...selectedPackage.paidServices]);
        newArray = newArray.map(paidService => { 
            const newPaidService = {...paidService};
            if(containsPaidService(newPaidService, selectedPackage.paidServices)){
                newPaidService.isIncludedInPackage = true;
            }
            return newPaidService;
        })
        
        newArray.unshift({
            name: priceDetailBase,
            price: groupBasePrice,
            isByPerson: false,
            mandatory: false,
            isIncludedInPackage: true
        })
        
    } else {
        newArray = [...groupPaidServices];
    }
    const sortedServices = sortPaidServices(newArray)
    
    const paidServicesLimit = 4;
    const paidServices = [...sortedServices];
    const selectedPaidServices = paidServices.length > paidServicesLimit 
        ?   
            canShowAllServices ? [...paidServices] : paidServices.slice(0, paidServicesLimit) 
        :   [...paidServices];

    const isSelected = useCallback((paidService: PaidService) => {
        return selectedServices.findIndex(currentPaidService => isPaidServiceTheSame(paidService, currentPaidService)) !== -1
    }, [selectedServices]);

    if(selectedPaidServices.length === 0) {
        return <></>
    }

    return <Stack spacing={3}>
        <Divider/>
        <Typography variant={titleVariant}>{reservationServicesTitle}</Typography>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            {
                selectedPaidServices.map((item, index) => {
                    return <SpacePaidServiceCheckbox
                        key={'paid-service-' + index}
                        paidService={item}
                        isSelected={isSelected}
                        selectPaidService={selectPaidService}
                        isIncludedInPackage={item.isIncludedInPackage}
                    ></SpacePaidServiceCheckbox>
                })
            }
            {
                paidServices.length > paidServicesLimit && 
                !canShowAllServices && 
                <ShowMoreButton 
                    setShowAll={setCanShowAllServices} 
                    sx={{margin: '0 auto', mt: '12px'}}
                /> 
            }
        </Box>
    </Stack>
}

export default SpaceServices;