import { Box, Divider, FormControl, MenuItem, Select, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import { MIN_EVENT_HOURS } from "interfaces/space"

interface SpaceEventHoursProps {
    eventMaxHours: number
    selectedEventHours: number | null
    setSelectedEventHours: (eventHours: number | null) => void
}

const SpaceEventHours: React.FC<SpaceEventHoursProps> = (props) => {
    const { eventMaxHours, selectedEventHours, setSelectedEventHours } = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'
    
    const t = useTranslate();
    const title = t('previewDetails.spaceContractedHoursPerEvent')
    const hourLabel = t('previewDetails.hourLabel')
    const hoursLabel = t('previewDetails.hoursLabel')

    const generateArrHours = () => {
        const arrHours: number[] = []
        for(let i=eventMaxHours; i>=MIN_EVENT_HOURS; i--) {
            arrHours.push(i)
        }
        return arrHours.reverse()
    }
    
    const arrHours = generateArrHours()
    
    const onEventHoursChangedHandler = (event: any) => {
        let eventHours = parseInt(event.target.value)
        setSelectedEventHours(eventHours)
    }

    return <Stack spacing={2}>
        <Typography variant={titleVariant}>{title}</Typography>
        <FormControl>
            <Select
                sx={{borderRadius: '8px'}}
                id="space-event-hours"
                value={selectedEventHours ? selectedEventHours : ''}
                onChange={onEventHoursChangedHandler}
            >
                {
                    arrHours.map((hour, index) =>
                        <MenuItem value={hour} sx={{
                            padding: '8px 16px',
                            borderBottom: index < (arrHours.length - 1) ? '1px solid' : 'none',
                            borderBottomColor: index < (arrHours.length - 1) ? 'neutral70.light' : 'none',
                        }} key={`space-event-hours-${index}`}>
                            <Typography variant="titleMedium">{hour} { hour == 1 ? hourLabel : hoursLabel}</Typography>
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    </Stack>
}

export default SpaceEventHours