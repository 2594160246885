import { Box, Stack, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import { CatalogEntry } from "interfaces/catalogEntry"
import { Group, PriceTypes, WorkingTime } from "interfaces/space"
import { generateCatalogValues } from "utils"
import GroupDetailWorkingTime from "./GroupDetailWorkingTime"

interface GroupDetailPreviewProps {
    group: Group
}

const GroupDetailPreview: React.FC<GroupDetailPreviewProps> = (props) => {
    const { group } = props
    
    const t = useTranslate()
    const priceTypeTitle = t("updateSpace.spaceGroupsPriceTypeTitle")
    const eveentTypesTitle = t("updateSpace.spaceEventTypeTitle")
    const priceByEventLabel = t("updateSpace.spaceGroupsPriceTypeByEvent")
    const priceByPersonLabel = t("updateSpace.spaceGroupsPriceTypeByPerson")
    const priceByHourLabel = t("updateSpace.spaceGroupsPriceTypeByHour")
    const timesTitle = t("updateSpace.spaceGroupsWorkingTimesTitle")
    
    const generateGroupPriceTypeName = (priceType: PriceTypes): string => {
        let name
        switch (priceType) {
            case PriceTypes.ByEvent: name = priceByEventLabel; break;
            case PriceTypes.ByPerson: name = priceByPersonLabel; break;
            case PriceTypes.ByHour: name = priceByHourLabel; break;
            default: name = ''; break;
        }
        return name
    }
    
    const priceTypeName = generateGroupPriceTypeName(group.priceType)
    const eventTypes = generateCatalogValues(group.eventTypes as CatalogEntry[])
    
    return <Stack spacing={3}>
        <Stack spacing={1}>
            <Typography variant="titleLarge">{eveentTypesTitle}</Typography>
            <Typography variant="bodyLarge">{eventTypes}</Typography>
        </Stack>
        <Stack spacing={1}>
            <Typography variant="titleLarge">{priceTypeTitle}</Typography>
            <Typography variant="bodyLarge">{priceTypeName}</Typography>
        </Stack>
        {
            group.workingTimes.length > 0 && <Stack spacing={1}>
                <Typography variant="titleLarge">{timesTitle}</Typography>
                {
                    group.workingTimes.map((workingTime: WorkingTime, index: number) => {
                        return <GroupDetailWorkingTime 
                            key={'group-detail-working-time-' + index} 
                            workingTime={workingTime}
                        />
                    })
                }
            </Stack>
        }
    </Stack>
}

export default GroupDetailPreview