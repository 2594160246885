import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import { PaidService } from "interfaces/space";
import { useTranslate } from "@pankod/refine-core";
import { formatNumberPrice } from "utils";

interface PaidServicesLabelProps {
    paidService: PaidService
}

const PaidServicesLabel: React.FC<PaidServicesLabelProps> = (props) => {

    const {paidService} = props
    const theme = useTheme()
    const t = useTranslate()
    const byPerson = t("updateSpace.spacePaidServicesByPerson")
    const byEvent = t("updateSpace.spacePaidServicesByEvent")
    const mandatory = t("updateSpace.spacePaidServicesDialogMandatory")

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column'
    }}>
        <Typography
            variant="labelLarge"
            color={theme.palette.onSecondaryContainer.main}>
            {`${paidService.name} ${paidService.mandatory ? `(${mandatory})` : ''}`}
        </Typography>
        <Typography
            variant="labelSmall"
            color={theme.palette.onSecondaryContainer.main}>
            {`${formatNumberPrice(paidService.price)} ${paidService.isByPerson ? byPerson : byEvent}`}
        </Typography>
    </Box>
}

export default PaidServicesLabel