import React from "react";
import MuiModal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTranslate } from "@pankod/refine-core";
import { emails, phoneNumbers } from "constants/contactData";
import { IconButton } from "@pankod/refine-mui";
import CloseIcon from "@mui/icons-material/Close";

interface ContactModalProps {
    open: boolean
    onClose: () => void
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xl: "30%", lg: "40%", md: "50%", sm: "70%", xs: "90%"},
    height: {xl: "35%", lg: "40%", md: "50%", sm: "70%", xs: "60%"},
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '28px'
};

const ContactModal: React.FC<ContactModalProps> = (props) => {

    const {open, onClose} = props

    const t = useTranslate()
    const title = t("updateSpace.contactTitle")
    const subtitle = t("updateSpace.contactSubtitle")
    const emailPh = t("updateSpace.contactEmailPlaceholder")
    const phonePh = t("updateSpace.contactPhonePlaceholder")
    const email = emails.contact
    const phone = phoneNumbers.contactFormatted

    return <MuiModal
        open={open}
        onClose={onClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box sx={{...style}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                position: 'relative'
            }}>
                <Typography variant='titleLarge'>{title}</Typography>
                <Typography sx={{mt: 2, textAlign: 'center'}} variant='titleMedium'>{subtitle}</Typography>
                <Typography sx={{mt: 2}} variant='titleMedium'>{emailPh}</Typography>
                <Link href={`mailto:${email}`}>
                    <Typography variant='titleLarge' color='#39197B'>
                        {email}
                    </Typography>
                </Link>
                <Typography sx={{mt: 2}} variant='titleMedium'>{phonePh}</Typography>
                <Link href={`https://wa.me/${phone.replaceAll(" ", "")}`}>
                    <Typography variant='titleLarge' color='#39197B'>
                        {phone}
                    </Typography>
                </Link>
                <IconButton sx={{
                    color: 'neutral0.light',
                    position: 'absolute',
                    top: '16px',
                    right: '16px'
                }} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </Box>
        </Box>
    </MuiModal>
}

export default ContactModal