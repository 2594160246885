import { useGetIdentity } from "@pankod/refine-core";
import { storagePaths } from "constants/storagePaths";
import {getStorage, ref as storageRef} from "firebase/storage";
import {useCallback} from "react";

const useStorageRef = () => {
    const { data: user } = useGetIdentity();
    
    return useCallback(async (suffix: string) => {
        const storage = await getStorage();
        const timestamp = Date.now()
        try {
            return storageRef(storage, `${storagePaths.hostDocs}${user.uid}_${suffix}_${timestamp}`);
        } catch (e) {
        }
    }, [])
}

export default useStorageRef