import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import CollapsableText from "components/preview/CollapsableText"
import { SpaceDetail } from "interfaces/space"

interface AdRulesProps {
    spaceDetail: SpaceDetail
}

const AdRules: React.FC<AdRulesProps> = (props) => {
    const {spaceDetail} = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'
    const textVariant = isSM ? 'bodyMedium' : 'bodyLarge'
    const t = useTranslate()
    const title = t('previewDetails.restrictionsTitle')

    if(!spaceDetail.spaceRules) {
        return <></>
    }

    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
        <Typography variant={titleVariant}>{title}</Typography>
        <CollapsableText text={spaceDetail.spaceRules} textVariant={textVariant} threshold={250}/>
    </Box>
}

export default AdRules