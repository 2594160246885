import { Box, IconButton } from "@mui/material"
import MuiModal from "@mui/material/Modal"
import CloseIcon from "@mui/icons-material/Close"

interface CustomModalProps {
    sxContainer?: any
    sxCloseButton?: any
    canOpen: boolean
    children: React.ReactElement
    onCloseModal: () => void
}

const CustomModal: React.FC<CustomModalProps> = (props) => {
    const { sxContainer, sxCloseButton, canOpen, children, onCloseModal } = props

    return <MuiModal
        open={canOpen}
    >
        <Box sx={{
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            width: '100%', 
            height: '100%'
        }}>
            <Box sx={{
                width: '100%',
                maxWidth: '360px', 
                borderRadius: '8px', 
                backgroundColor: 'onPrimary.light', 
                padding: '24px 16px', 
                position: 'relative',
                ...sxContainer
            }}>
                { children }
                <IconButton sx={{
                    color: 'neutral0.light',
                    position: 'absolute',
                    top: '12px',
                    right: '12px',
                    ...sxCloseButton
                }} onClick={onCloseModal}>
                    <CloseIcon/>
                </IconButton>
            </Box>
        </Box>
    </MuiModal>
}

export default CustomModal

