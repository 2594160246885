import { CatalogEntry } from "./catalogEntry";

export enum SpaceStatus {
    StandBy = "Stand By",
    InReview = "On Review",
    Approved = "Approved",
    Rejected = "Rejected",
    Published = "Published"
}

export enum PriceTypes {
    ByEvent = "By Event",
    ByPerson = "By Person",
    ByHour = "By Hour"
}

export const MIN_EVENT_HOURS = 1

export interface Space {
    id: string
    name: string
    state: string
    user: {}
    spaceType: {}
    description: string
}

export interface Coordinates {
    coordinates: number[]
}

export interface PlaceType {
    description: string;
    place_id: string;
    structured_formatting?: StructuredFormatting;
}

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}

interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

export interface GAddress {
    place: PlaceType
    geocode: any
}

export interface Address {
    location: Coordinates
    fullAddress: string
    references?: string
    interiorNo?: string
    city: string
    state: string
    postalCode?: string
    gAddress: GAddress
}

export interface Occupancy {
    minimum?: number
    maximum?: number
}

export interface DaysToReserve {
    minimum?: number
    maximum?: number
}

export interface WorkingHour {
    day: number,
    times: string[]
}

export interface BlockedSlot {
    date: string,
    times: string[]
}

export interface PaidService {
    name: string
    price: number
    isByPerson: boolean
    mandatory: boolean
    total?: number
    isIncludedInPackage?: boolean
}

export interface PackageOccupancy {
    minimum?: number
    maximum: number
}

export interface Package {
    _id?: string
    name: string
    description: string
    price: number
    isByPerson: boolean
    occupancy: PackageOccupancy
    amenities: CatalogEntry[] | string[]
    paidServices: PaidService[]
}

export enum CancelationPolicy {
    Flexible = ("Flexible"),
    Moderate = ("Moderate"),
    Strict = ("Strict")
}

export interface SpaceDetail {
    id: string
    name: string
    status: SpaceStatus
    user?: string
    updatedAt: string
    spaceType?: CatalogEntry | string
    description?: string
    documentsUrls?: string[]
    fullAddress?: string
    address?: Address
    cancelationPolicy?: CancelationPolicy
    spaceRules?: string
    coverPictureUrl?: string
    picturesUrls?: string[]
    floorPlanUrl?: string
    video360Url?: string
    eventTypes: CatalogEntry[] | string[]
    amenities: CatalogEntry[] | string[]
    occupancy?: Occupancy
    price?: number
    workingDays: number[]
    workingHours: WorkingHour[]
    blockedSlots: BlockedSlot[]
    daysToReserve?: DaysToReserve
    daysOff: string[]
    paidServices: PaidService[]
    packages: Package[]
    upcomingEvents?: number
    rating?: number
    ratings?: number
    published: boolean
    rejectedMessage?: string
    guaranteeDeposit?: number
    verificationStamp?: boolean
    guaranteeStamp?: boolean,
    omittedFields?: string,
    groups: Group[]
}

export interface Group {
    _id?: string
    eventTypes: CatalogEntry[] | string[]
    priceType: PriceTypes
    workingTimes: WorkingTime[]
    amenities: CatalogEntry[] | string[]
    paidServices: PaidService[]
    guaranteeDeposit?: number
    packages: GroupPackage[]
}

export interface WorkingTime {
    day: number
    maxHours: number
    times: string[]
    ranges: GroupRange[]
}

export interface GroupRange {
    occupancy: Occupancy
    prices: GroupPrice
}

export interface GroupPrice {
    byEvent: number
    byPerson: number
    byHour: number
}

export interface GroupPackage {
    _id?: string
    name: string
    description: string
    price: number
    amenities: CatalogEntry[] | string[]
    paidServices: PaidService[]
}
