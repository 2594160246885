import { Button, Stack, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import MultiSelectChipGroup from "components/MultiSelectChipGroup"
import { CatalogEntry } from "interfaces/catalogEntry"

interface GroupEventTypesFormProps {
    spaceEventTypes: CatalogEntry[]
    spaceEventTypesDisabled: CatalogEntry[]
    groupEventTypes: CatalogEntry[]
    onEventTypesChangedHandler: (values: CatalogEntry[]) => void
}

const GroupEventTypesForm: React.FC<GroupEventTypesFormProps> = (props) => {
    const { spaceEventTypes, groupEventTypes, spaceEventTypesDisabled, onEventTypesChangedHandler } = props
    
    const t = useTranslate()
    const title = t("updateSpace.spaceEventTypeTitle")
    const subtitle = t("updateSpace.spaceGroupsEventTypeSubtitle")
    const selectAllLabel = t("updateSpace.selectAll")

    const generateSpaceEventTypesEnabled = () => {
        const eventTypesEnabled: CatalogEntry[] = []
        spaceEventTypes.forEach((eventType) => {
            const inSpace = spaceEventTypesDisabled.find(value => value.id === eventType.id) ? true : false
            const inGroup = groupEventTypes.find(value => value.id === eventType.id) ? true : false
            if(!inSpace && !inGroup) {
                eventTypesEnabled.push(eventType)
            }
        })
        return eventTypesEnabled
    }

    const spaceEventTypesEnabled = generateSpaceEventTypesEnabled()
    
    const onSelectAllClickHandler = () => {
        const selectedEventTypes: CatalogEntry[] = []
        spaceEventTypes.forEach((eventType) => {
            const inSpace = spaceEventTypesDisabled.find(value => value.id === eventType.id)
            if(!inSpace) {
                selectedEventTypes.push(eventType)
            }
        })
        onEventTypesChangedHandler(selectedEventTypes)
    }
    
    return <Stack sx={{mt: 3}}>
        <Typography variant="titleMedium">{title}</Typography>
        <Typography variant="labelLarge" sx={{mt: 1, mb: 2, color: 'neutral50.light'}}>{subtitle}</Typography>
        <MultiSelectChipGroup
            items={spaceEventTypes}
            itemsDisabled={spaceEventTypesDisabled}
            selections={groupEventTypes}
            onSelectionsUpdated={onEventTypesChangedHandler}
        />
        <Button 
            variant="text" 
            onClick={onSelectAllClickHandler} 
            sx={{mt: 2, width: 'max-content'}}
            disabled={spaceEventTypesEnabled.length === 0}
        >
            <Typography variant='labelLarge'>{selectAllLabel}</Typography>
        </Button>
    </Stack>
}

export default GroupEventTypesForm