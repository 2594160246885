import React from "react";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import CheckIcon from '@mui/icons-material/Check';
import {Chip} from "@mui/material";
import { CatalogEntry } from "interfaces/catalogEntry";

interface SelectableChipProps {
    item: CatalogEntry
    selected: boolean
    disabled?: boolean
    onChipToggle: (item: CatalogEntry) => void
}

const SelectableChip: React.FC<SelectableChipProps> = (props) => {

    const theme = useTheme()
    const {item, selected, disabled, onChipToggle} = props
    const textColor = selected ? theme.palette.onSecondaryContainer.main : theme.palette.onSurfaceVariant.main

    const onChipToggleHandler = () => {
        onChipToggle(item)
    }

    return <Chip
        sx={{
            borderRadius: '8px', 
            mx: 1, mt: 1, 
            maxWidth: '500px',
            color: 'secondaryContainer.light'
        }}
        icon={selected ?
            <CheckIcon
                sx={{
                    width: '18px',
                    height: '18px',
                    "&&": {color: theme.palette.onSecondaryContainer.main}
                }}/> : undefined}
        label={
            <Typography
                variant="labelLarge"
                color={textColor}>
                {item.value}
            </Typography>}
        variant={selected ? "filled" : "outlined"}
        onClick={onChipToggleHandler}
        disabled={disabled}
    />
}

export default SelectableChip