import {useCallback, useState} from "react";
import {
    getDownloadURL,
    StorageError, StorageReference,
    uploadBytesResumable,
    UploadTask
} from "firebase/storage";

const getErrorMessage: (error: StorageError) => string = (error: StorageError) => {
    let errorMessage: string = 'upload_error_message'
    switch (error.code) {
        case 'storage/unauthorized':
            // User doesn't have permission to access the object
            errorMessage = 'upload_error_message'
            break;
        case 'storage/canceled':
            // User canceled the upload
            errorMessage = 'upload_error_message'
            break;
        case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            errorMessage = 'upload_error_message'
            break;
    }
    return errorMessage
}

const useFileUpload = () => {

    const [uploadTaskState, setUploadTask] = useState<UploadTask | null>(null)
    const [isUploading, setIsUploading] = useState(false)
    const [isComplete, setIsComplete] = useState(false)
    const [progress, setProgress] = useState(0)
    const [error, setError] = useState("")

    const reset = useCallback(() => {
        setUploadTask(null)
        setIsUploading(false)
        setIsComplete(false)
        setProgress(0)
        setError("")
    }, [])

    const cancelUpload = useCallback(() => {
        if (uploadTaskState !== null) {
            uploadTaskState.cancel()
            setProgress(0)
            setUploadTask(null)
            setIsUploading(false)
            setError("")
        }
    }, [uploadTaskState])

    const uploadFile = useCallback(async (
        file: File,
        storageRef: StorageReference,
        onFileUploaded: (url: string) => void,
        metadata?: any
    ) => {

        setError("")
        setIsComplete(false)
        setIsUploading(true)
        const uploadTask = metadata === undefined ?
            uploadBytesResumable(storageRef, file) :
            uploadBytesResumable(storageRef, file, metadata)
        setUploadTask(uploadTask)

        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress)
                switch (snapshot.state) {
                    case 'paused':
                        setIsUploading(false)
                        break;
                    case 'running':
                        setIsUploading(true)
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                setError(getErrorMessage(error))
                setProgress(0)
                setUploadTask(null)
                setIsUploading(false)
                setIsComplete(false)
            },
            async () => {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref)
                onFileUploaded(downloadUrl)
                setIsUploading(false)
                setIsComplete(true)
            }
        );


    }, [])

    return {
        uploadFile,
        cancelUpload,
        reset,
        isUploading,
        isComplete,
        progress,
        error
    }
}

export default useFileUpload