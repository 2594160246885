import React, {ChangeEvent, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import {CancelationPolicy} from "./SpaceCancelationPolicyForm";
import { useTranslate } from "@pankod/refine-core";
import CustomSwitch from "components/CustomSwitch";

interface CancelationPolicyProps {
    title: string
    description: string
    showDivider: boolean
    checked: boolean
    id: CancelationPolicy
    onCheckChanged: (id: CancelationPolicy, checked: boolean) => void
    extraDescription: string
}

const CancelationPolicySwitch: React.FC<CancelationPolicyProps> = (props) => {
    const t = useTranslate()
    const more = t("updateSpace.showMore")
    const less = t("updateSpace.showLess")

    const {title, description, showDivider, checked, id, onCheckChanged, extraDescription} = props
    const [collapsed, setCollapsed] = useState(true)

    const onCheckChangedHandler = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onCheckChanged(id, checked)
    }

    const onExpandClickHandler = () => {
        setCollapsed((isCollapsed) => {
            return !isCollapsed
        })
    }

    return <>
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            pt: 3
        }}>
            <CustomSwitch checked={checked} onChange={onCheckChangedHandler}/>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                px: 2
            }}>
                <Typography variant="titleMedium">{title}</Typography>
                <Typography sx={{pt: 1}} variant="bodyLarge">{description}</Typography>
                {!collapsed && <Typography sx={{pt: 1}} variant="bodyLarge">{extraDescription}</Typography>}
                <Typography
                    variant='bodyLarge'
                    color="secondary"
                    onClick={onExpandClickHandler}>{`   ${collapsed ? more : less}`}
                </Typography>
            </Box>
        </Box>
        {showDivider && <Divider sx={{pt: 2}}/>}
    </>
}

export default CancelationPolicySwitch