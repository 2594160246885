import React from "react";
import {Result} from "typescript-monads";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { Exception } from "exception/Exception";
import { useTranslate } from "@pankod/refine-core";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import fieldConstants from "constants/fieldConstants";
import CollapsableText from "components/preview/CollapsableText";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { spaceActions } from "store/space";
import { spaceDetailConstants } from "constants/spaceDetailConstants";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

export interface SpaceRulesFormProps {
    spaceDetail: SpaceDetail
}

export const isRulesEmpty = (data: SpaceDetail): boolean => data === undefined || data.spaceRules === undefined

const isRulesValid = (data: FormRules): boolean => {
    return data !== undefined && data.spaceRules !== undefined && data?.spaceRules?.length > 10
}

const spaceDetailToRules = (data: SpaceDetail): FormRules => {
    return {spaceRules: data?.spaceRules}
}

export interface FormRules {
    spaceRules?: string
}

const stateHandlers = [StateHandler.Error]

const SpaceRulesForm: React.FC<SpaceRulesFormProps> = (props) => {

    const {spaceDetail} = props

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }
    
    const t = useTranslate()
    const title = t("updateSpace.spaceRulesTitle")
    const subtitle = t("updateSpace.spaceRulesSubtitle")
    const hint = t("updateSpace.spaceRulesHint")

    const getErrorMessage = useSyncExceptionError(t)

    const {updateSpace} = useSpaceUpdate()

    const renderEditableForm = (updateFn: (data: FormRules) => void, data?: FormRules) => {
        const onRulesChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            const spaceRules = event.target.value
            const newData: FormRules = {spaceRules: spaceRules}
            updateFn(newData)
        }

        const getLength = () => {
            if (data === undefined || data?.spaceRules === undefined) return 0
            return data.spaceRules.length
        }

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                mt: 3,
                pr: 8,
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                justifyContent: {xs: 'center', md: 'flex-start'},
                alignItems: {xs: 'flex-start', md: 'center'}
            }}>
                <TextField
                    margin="normal"
                    fullWidth
                    multiline
                    rows={10}
                    placeholder={hint}
                    helperText={`${getLength()}/${fieldConstants.defaultRulesLength}`}
                    id="reglas"
                    name="reglas"
                    onChange={onRulesChangeHandler}
                    value={data?.spaceRules}
                    inputProps={{
                        maxLength: fieldConstants.defaultRulesLength
                    }}
                />
            </Box>
        </Box>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        return <CollapsableText text={data?.spaceRules ? data.spaceRules : ''}/>
    }

    const syncDataHandler = (data: FormRules) => {
        const body = {spaceRules: data.spaceRules}
        return updateSpace(spaceDetail.id, body)
    }

    const dispatch = useAppDispatch()
    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    const isFocused = useAppSelector(state => state.space.currentFocusId === spaceDetailConstants.rules)

    return <EditableSyncFormV2
        title={title}
        subtitle={subtitle}
        isFocused={isFocused}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        loadData={loadData}
        syncData={syncDataHandler}
        isFormDataValid={isRulesValid}
        isServerDataEmpty={isRulesEmpty}
        mapServerDataToFormData={spaceDetailToRules}
        getErrorMessage={getErrorMessage}
        onSync={onSyncHandler}
        stateHandlers={stateHandlers}/>
}

export default SpaceRulesForm