import { styled } from "@mui/material"

interface LinkToProps {
    href: string,
    target?: string,
    children: React.ReactNode,
    sx?: any,
    onClick?: () => void
}

const CustomLink = styled('a')(({theme}) => ({
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    color: theme.palette.primary.light,
    '&:hover': {
        textDecoration: 'underline'
    }
}));

const LinkTo: React.FC<LinkToProps> = (props) => {
    const { href, target, children, sx, onClick } = props;

    return <CustomLink href={href} sx={{...sx}} onClick={onClick} target={target ? target : '_self'}>{children}</CustomLink>
}

export default LinkTo;