import { Box, useMediaQuery, useTheme } from "@mui/material"
import { SpaceDetail } from "interfaces/space"
import { LazyLoadImage } from "react-lazy-load-image-component"
import AdPicture from "./AdPicture"

interface AdPicturesProps {
    spaceDetail: SpaceDetail
    showPicture: (index: number) => void
}

interface Picture {
    index: number
    pictureUrl: string
}

const AdPictures: React.FC<AdPicturesProps> = (props) => {
    const { spaceDetail, showPicture } = props
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('sm'));
    const picturesUrls = spaceDetail.picturesUrls ? [...spaceDetail.picturesUrls] : []
    if(spaceDetail.floorPlanUrl) {
        picturesUrls.push(spaceDetail.floorPlanUrl)
    }
    let index = spaceDetail.coverPictureUrl ? 1 : 0
    const pictures: Picture[] = picturesUrls.map((pictureUrl: string) => {
        const picture = {
            index: index,
            pictureUrl: pictureUrl,
        }
        index++
        return picture
    })
    
    const coverMaxWidth = pictures.length > 0 ? '519px' : '100%'
    const coverHeigth = isXS ? '150px' : '487px'
    const pictureGap = isXS ? '8px' : '24px'
    const picturesSection1 = pictures.length > 0 ? pictures.slice(0, 2) : []
    const picturesSection2 = pictures.length > 0 && pictures.length > 2 ? pictures.slice(2, 4) : []
    
    return <Box sx={{
        display: 'flex',
        gap: {xs: '8px', sm: '24px'},
        height: {xs: '150px', sm: '487px'}
    }}>
        <Box sx={{width: '50%'}}>
            {
                spaceDetail.coverPictureUrl && <LazyLoadImage
                    src={spaceDetail.coverPictureUrl}
                    alt={"Ad cover picture"}
                    style={{
                        width: '100%', 
                        maxWidth: coverMaxWidth, 
                        height: coverHeigth, 
                        borderRadius: '16px',
                        cursor: 'pointer'
                    }}
                    onClick={() => { showPicture(0)}}
                />
            }
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: {xs: '14px', sm: '42px'}, width: '50%'}}>
            {
                picturesSection1.length > 0 && <Box sx={{display: 'flex', gap: pictureGap}}>
                    {
                        picturesSection1.map((picture: Picture, index: number) => {
                            return <AdPicture 
                                key={`ad-picture-${index}`} 
                                index={picture.index} 
                                pictureUrl={picture.pictureUrl}
                                showPicture={showPicture}
                            />
                        })
                    }
                </Box>
            }
            {
                picturesSection2.length > 0 && <Box sx={{display: 'flex', gap: pictureGap}}>
                    {
                        picturesSection2.map((picture: Picture, index: number) => {
                            return <AdPicture 
                                key={`ad-picture-${index}`} 
                                index={picture.index} 
                                pictureUrl={picture.pictureUrl}
                                showPicture={showPicture}
                            />
                        })
                    }
                </Box>
            }
        </Box>
    </Box>
}

export default AdPictures