import { Stack, TextField, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import fieldConstants from "constants/fieldConstants"

interface GroupPackagesDescriptionFormProps {
    description: string
    onDescriptionChangedHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const GroupPackagesDescriptionForm: React.FC<GroupPackagesDescriptionFormProps> = (props) => {
    const { description, onDescriptionChangedHandler } = props

    const t = useTranslate()
    const descriptionSubtitle = t("updateSpace.spacePackagesDescriptionSubtitle")
    const descriptionHint = t("updateSpace.spacePackagesDescriptionHint")
    
    return <Stack>
        <Typography sx={{mt: 3}} variant='titleMedium'>{descriptionSubtitle}</Typography>
        <TextField
            sx={{mr: 4}}
            margin="normal"
            multiline
            rows={5}
            placeholder={descriptionHint}
            helperText={`${description.length}/${fieldConstants.defaultDescriptionLength}`}
            onChange={onDescriptionChangedHandler}
            value={description}
            inputProps={{
                maxLength: fieldConstants.defaultDescriptionLength
            }}
        />
    </Stack>
}

export default GroupPackagesDescriptionForm