import { useTheme } from "@mui/material";
import Line, { LineOrientation } from "./Line";

const Divider: React.FC = () => {
    const theme = useTheme();

    return <Line
        orientation={LineOrientation.Horizontal} 
        size={'100%'}
        color={theme.palette.neutral70.main}
        borderWidth={0.5}
    ></Line>
}

export default Divider;