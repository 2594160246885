import {configureStore} from "@reduxjs/toolkit";
import spaceSlice, { SpaceState } from "./space";
import catalogSlice, { CatalogState } from "./catalog";

/* const persistAuthPersistConfig = {
    key: 'persistAuth',
    storage,
} */

/* const persistedReducer = persistReducer(persistAuthPersistConfig, persistAuthSlice.reducer) */

const store = configureStore({
    reducer: {
        catalog: catalogSlice.reducer,
        space: spaceSlice.reducer
    },
    devTools: process.env.NODE_ENV !== 'production'
})

export default store

//export const persistor = persistStore(store)

export type RootState = {
    catalog: CatalogState,
    space: SpaceState
}
export type AppDispatch = typeof store.dispatch