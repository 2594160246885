import { TextField, styled } from "@mui/material";

export const TextfieldOutlined = styled(TextField)(({theme}) => ({
    '.MuiOutlinedInput-input': {
        padding: '6px 16px',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px'
    },
    '.MuiOutlinedInput-notchedOutline': {
        borderRadius: '8px',
        borderColor: theme.palette.outline.light
    }
}))

export const TextfieldOutlinedPrice = styled(TextfieldOutlined)(({theme}) => ({
    '.MuiOutlinedInput-input': {
        padding: '6px 16px 6px 0px'
    }
}))