import { Stack, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import MultiSelectChipGroup from "components/MultiSelectChipGroup"
import { CatalogEntry } from "interfaces/catalogEntry"
import { GroupRange, WorkingTime } from "interfaces/space"

export interface FormWorkingTime {
    day: number
    maxHours: number
    times: string[]
    ranges: GroupRange[]
    enabled: boolean
}

interface GroupWorkingTimesFormProps {
    workingTimes: WorkingTime[]
    setWorkingTimes: (data: WorkingTime[]) => void
}

const GroupWorkingTimesForm: React.FC<GroupWorkingTimesFormProps> = (props) => {
    const { workingTimes, setWorkingTimes } = props

    const t = useTranslate()
    const title = t("updateSpace.spaceGroupsWorkingTimesTitle")
    const subtitle = t("updateSpace.spaceGroupsWorkingTimesSubtitle")
    const monday = t("previewDetails.monday")
    const tuesday = t("previewDetails.tuesday")
    const wednesday = t("previewDetails.wednesday")
    const thursday = t("previewDetails.thursday")
    const friday = t("previewDetails.friday")
    const saturday = t("previewDetails.saturday")
    const sunday = t("previewDetails.sunday")

    const days: CatalogEntry[] = [
        {id: "1", value: monday},
        {id: "2", value: tuesday},
        {id: "3", value: wednesday},
        {id: "4", value: thursday},
        {id: "5", value: friday},
        {id: "6", value: saturday},
        {id: "0", value: sunday}
    ]

    const getDayById = (id: string): string => {
        switch (id) {
            case  "0":
                return sunday
            case  "1":
                return monday
            case  "2":
                return tuesday
            case  "3":
                return wednesday
            case  "4":
                return thursday
            case  "5":
                return friday
            case  "6":
                return saturday
            default :
                return sunday
        }
    }

    const workingTimesToCatalogEntries = (data: FormWorkingTime[]): CatalogEntry[] => {
        return data.filter((formWorkingTime: FormWorkingTime) => formWorkingTime.enabled)
            .map((formWorkingTime) => {
                const id = formWorkingTime.day.toString()
                return {id: id, value: getDayById(id)}
            })
    }

    const workingTimesToFormWorkingTimes = (workingTimes?: WorkingTime[]): FormWorkingTime[] => {
        const formWorkingTimes: FormWorkingTime[] = []
        days.forEach((day) => {
            const current = workingTimes?.find((entry: WorkingTime) => {
                return entry.day === parseInt(day.id)
            })
            formWorkingTimes.push({
                day: parseInt(day.id),
                enabled: current !== undefined,
                maxHours: current ? current.maxHours : 0,
                times: current ? current.times : [],
                ranges: current ? current.ranges : [],
            })
        })
        return formWorkingTimes
    }

    const catalogEntriesToWorkingTimes = (entries: CatalogEntry[], formWorkingTimes?: FormWorkingTime[]): FormWorkingTime[] => {
        if (formWorkingTimes !== undefined && formWorkingTimes !== null && formWorkingTimes.length > 0) {
            return formWorkingTimes ? formWorkingTimes.map((formWorkingTime) => {
                const isEnabled: boolean = entries.find((entry) => formWorkingTime.day.toString() === entry.id) !== undefined
                return {
                    day: formWorkingTime.day, 
                    maxHours: formWorkingTime.maxHours, 
                    times: formWorkingTime.times, 
                    ranges: formWorkingTime.ranges, 
                    enabled: isEnabled
                }
            }) : []
        } else {
            const defaultWorkingTimes = workingTimesToFormWorkingTimes(workingTimes)
            entries.forEach((entry) => {
                const workingTime = defaultWorkingTimes.find((formWorkingDay) => {
                    return formWorkingDay.day.toString() === entry.id
                })
                if (workingTime !== undefined) workingTime.enabled = true
            })
            return defaultWorkingTimes
        }
    }

    const formWorkingTimes = workingTimes.length > 0 ? workingTimesToFormWorkingTimes(workingTimes) : []
    
    const onDaySelectedHandler = (items: CatalogEntry[]) => {
        const selectedFormWorkingTimes = catalogEntriesToWorkingTimes(items, formWorkingTimes)
        const selectedWorkingTimes = selectedFormWorkingTimes.filter((selectedFormWorkingTime: FormWorkingTime) => selectedFormWorkingTime.enabled).map((formWorkingTime: FormWorkingTime) => { 
            return {
                day: formWorkingTime.day, 
                maxHours: formWorkingTime.maxHours, 
                times: formWorkingTime.times, 
                ranges: formWorkingTime.ranges
            }
        })
        setWorkingTimes(selectedWorkingTimes)
    }
    
    return <Stack>
        <Typography variant="titleMedium">{title}</Typography>
        <Typography variant="labelLarge" sx={{mt: 1, mb: 2, color: 'neutral50.light'}}>{subtitle}</Typography>
        <MultiSelectChipGroup
            items={days}
            selections={formWorkingTimes ? workingTimesToCatalogEntries(formWorkingTimes) : []}
            onSelectionsUpdated={onDaySelectedHandler}
        />
    </Stack>
}

export default GroupWorkingTimesForm