import React from "react";
import {Result} from "typescript-monads";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { Exception } from "exception/Exception";
import { useAppDispatch } from "store/hooks";
import { useTranslate } from "@pankod/refine-core";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import fieldConstants from "constants/fieldConstants";
import DataAsList from "components/DataAsList";
import { spaceActions } from "store/space";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

export interface SpaceVideo360FormProps {
    spaceDetail: SpaceDetail
    onSkip: () => void
    isOmitted?: boolean
}

export const isVideo360Empty = (data: SpaceDetail, isOmitted?: boolean): boolean => !isOmitted && (data === undefined || data.video360Url === undefined)


const isVideo360Valid = (data: FormVideo360): boolean => {
    return data !== undefined && data.video360Url !== undefined && data?.video360Url?.length > 3
}

const spaceDetailToVideo360 = (data: SpaceDetail): FormVideo360 => {
    return {video360Url: data?.video360Url}
}

export interface FormVideo360 {
    video360Url?: string
}

const video360ToList = (data: SpaceDetail): string[] => {
    return data?.video360Url ? [data.video360Url] : []
}

const video360StateHandlers = [StateHandler.Error]

const SpaceVideo360Form: React.FC<SpaceVideo360FormProps> = (props) => {

    const {spaceDetail, onSkip, isOmitted} = props

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    const dispatch = useAppDispatch()
    const t = useTranslate()
    const title = t("updateSpace.spaceVideo360Title")
    const subtitle = t("updateSpace.spaceVideo360Subtitle")
    const hint = t("updateSpace.spaceVideo360Hint")

    const getErrorMessage = useSyncExceptionError(t)

    const {updateSpace} = useSpaceUpdate()

    const renderEditableForm = (updateFn: (data: FormVideo360) => void, data?: FormVideo360) => {
        const onVideo360ChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            const video360Url = event.target.value
            const newData: FormVideo360 = {video360Url: video360Url}
            updateFn(newData)
        }

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                mt: 3,
                pr: 8,
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                justifyContent: {xs: 'center', md: 'flex-start'},
                alignItems: {xs: 'flex-start', md: 'center'}
            }}>
                <TextField
                    margin="normal"
                    fullWidth
                    placeholder={hint}
                    id="video360"
                    name="video360"
                    onChange={onVideo360ChangeHandler}
                    value={data?.video360Url}
                    inputProps={{
                        maxLength: fieldConstants.defaultDescriptionLength
                    }}
                />
            </Box>
        </Box>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <DataAsList data={video360ToList(data)} fieldId={'video360'}/>
        </Box>
    }

    const syncDataHandler = (data: FormVideo360) => {
        const body = {video360Url: data.video360Url}
        return updateSpace(spaceDetail.id, body)
    }

    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    return <EditableSyncFormV2
        title={title}
        subtitle={subtitle}
        isFocused={false}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        loadData={loadData}
        syncData={syncDataHandler}
        isFormDataValid={isVideo360Valid}
        isServerDataEmpty={isVideo360Empty}
        mapServerDataToFormData={spaceDetailToVideo360}
        getErrorMessage={getErrorMessage}
        stateHandlers={video360StateHandlers}  // This prop is required for onSyncHandler to be called
        onSync={onSyncHandler}
        optional={true}
        onSkip={onSkip}
        isOmitted={isOmitted}
    />
}

export default SpaceVideo360Form