import { useTranslate } from "@pankod/refine-core";
import usePagination from "hooks/usePagination";
import { IReview } from "interfaces";
import { SpaceDetail } from "interfaces/space";
import {useCallback, useEffect, useState} from "react";

const ReviewViewModel = (adDetails: SpaceDetail, isPreview: boolean) => {
    const t = useTranslate()
    const errorText = t("previewDetails.loadRatingsError")

    const [isInit, setIsInit] = useState<boolean>(false)
    const [error, setError] = useState('')
    const [reviews, setReviews] = useState<IReview[]>([])

    return {
        isInit,
        error,
        reviews,
    }
}

export default ReviewViewModel