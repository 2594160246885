import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Result} from "typescript-monads";
import {StorageReference} from "firebase/storage";
import { SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { useTranslate } from "@pankod/refine-core";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import useStorageRef from "hooks/useStorageRef";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import { Exception } from "exception/Exception";
import CoverImageUpload from "components/CoverImageUpload";
import { useAppDispatch } from "store/hooks";
import { spaceActions } from "store/space";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

export interface FormCover {
    coverPictureUrl?: string
}

const spaceDetailToCover = (data: SpaceDetail): FormCover => {
    return {coverPictureUrl: data?.coverPictureUrl}
}

export const isCoverEmpty = (data: SpaceDetail): boolean => data === undefined || data.coverPictureUrl === undefined

const isCoverValid = (data: FormCover): boolean => {
    return data !== undefined && data.coverPictureUrl !== undefined
}

const idStateHandlers = [StateHandler.Error]

const coverUploadSuffix = 'spaceCover'

export interface SpaceCoverFormProps {
    spaceDetail: SpaceDetail
}

const SpaceCoverForm: React.FC<SpaceCoverFormProps> = (props) => {

    const {spaceDetail} = props

    const t = useTranslate()
    const titleText = t('updateSpace.spaceCoverTitle')
    const subtitleText = t('updateSpace.spaceCoverSubtitle')
    const hint = t('updateSpace.spaceCoverHint')

    const getErrorMessage = useSyncExceptionError(t)

    const getRef = useStorageRef()
    const [showEdit, setShowEdit] = useState<boolean>(true)
    const [coverRef, setCoverRef] = useState<StorageReference | null>(null)

    const {updateSpace} = useSpaceUpdate()

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    useEffect(() => {
        const getRefs = async () => {
            const newCoverRef = await getRef(coverUploadSuffix)
            setCoverRef(newCoverRef === undefined ? null : newCoverRef)
        }

        getRefs()
    }, [getRef])

    const renderEditableForm = (updateFn: (data: FormCover) => void, data?: FormCover) => {

        const coverChangedHandler = (url: string | null) => {
            if (url === null) {
                updateFn({})
            } else {
                const newData: FormCover = {
                    coverPictureUrl: url,
                }
                updateFn(newData)
            }
        }

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                mt: 3,
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                justifyContent: {xs: 'center', md: 'flex-start'},
                alignItems: {xs: 'flex-start', md: 'center'}
            }}>
                <CoverImageUpload
                    downloadUrl={data?.coverPictureUrl ? data.coverPictureUrl : null}
                    uploadedRef={coverRef}
                    title={hint}
                    onUrlChange={coverChangedHandler}/>
            </Box>
        </Box>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Box sx={{
                    position: 'relative',
                }} component='div'>
                    <img alt="complex" src={data.coverPictureUrl!} width={300} height={300}
                           style={{borderRadius: '8px'}}/>
                </Box>
            </Box>
        </Box>
    }

    const syncDataHandler = (data: FormCover) => {
        const body = {coverPictureUrl: data.coverPictureUrl}
        return updateSpace(spaceDetail.id, body)
    }

    const dispatch = useAppDispatch()
    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    return <EditableSyncFormV2
        title={titleText}
        subtitle={subtitleText}
        isFocused={false}
        loadData={loadData}
        syncData={syncDataHandler}
        getErrorMessage={getErrorMessage}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        mapServerDataToFormData={spaceDetailToCover}
        isServerDataEmpty={isCoverEmpty}
        isFormDataValid={isCoverValid}
        stateHandlers={idStateHandlers}
        showEdit={showEdit}
        onSync={onSyncHandler}
    />
}

export default SpaceCoverForm