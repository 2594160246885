import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import usePlacesAutocomplete from "use-places-autocomplete";
import locationSrc from '../icons/location.svg'
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslate } from '@pankod/refine-core';
import Icon from './Icon';
import SearchInputTextField from './SearchInputTextField';
import parse from 'autosuggest-highlight/parse';

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}

interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

export interface PlaceType {
    description: string;
    place_id: string;
    structured_formatting?: StructuredFormatting;
}

export interface PlacesAutocompleteProps {
    formValue: PlaceType | null
    addressHint: string
    addressSelectedHandler: (address: PlaceType | null) => void
    sx?: any
    isSearching?: boolean
}

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = (props) => {
    const {
        ready,
        setValue,
        suggestions: {status, data},
        clearSuggestions
    } = usePlacesAutocomplete({requestOptions: {componentRestrictions: {country: "mx"}}})

    const {
        formValue,
        sx,
        addressHint,
        addressSelectedHandler,
        isSearching
    } = props
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('md'));
    const iconContainerWidth = isSM ? 16 : 24;
    const iconContainerHeight = isSM ? 16 : 24;
    const iconWidth = isSM ? 9.33 : 14;
    const iconHeight = isSM ? 13.33 : 20;

    const t = useTranslate()
    const searchInputWhereText = t('updateSpace.searchInputWhereText')

    const clearInput = () => {
        setValue('')
        addressSelectedHandler({
            description: '',
            place_id: '',
            structured_formatting: undefined
        })
    }

    const selectOption = (address: PlaceType | null) => {
        setValue(address !== null ? address.description : '', false)
        addressSelectedHandler(address)
        clearSuggestions()
    }
    
    return (
        <Autocomplete
            disabled={!ready}
            id="ubicacion"
            selectOnFocus={false}
            getOptionLabel={(option) => typeof option === 'string' ? option : option.description}
            filterOptions={(x) => x}
            options={data}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={formValue}
            onChange={(event: any, address: PlaceType | null, reason) => {
                if(reason && reason === 'clear') {
                    clearInput()
                } else {
                    selectOption(address)
                }
            }}
            onInputChange={(event, newInputValue) => {
                setValue(newInputValue)
            }}
            renderInput={(params) => (
                isSearching 
                ? 
                    <Box sx={{display: 'flex', gap: '8px', padding: {xs: '7.5px 0px', md: '0px'}, borderBottom: {xs: '1px solid' + theme.palette.outline.light, md: 'none'}}}>
                        <Icon
                            containerWidth={iconContainerWidth}
                            containerHeight={iconContainerHeight}
                            iconWidth={iconWidth}
                            iconHeight={iconHeight}
                            iconSrc={locationSrc}
                        />
                        <SearchInputTextField
                            {...params}
                            variant='standard'
                            placeholder={searchInputWhereText}
                            sx={{width: {xs: '100%', md: '190px', lg: '293px'}}}
                        />
                    </Box>
                : 
                    <TextField {...params} placeholder={addressHint} fullWidth sx={{...sx}}/>
            )}
            renderOption={(props, option) => {
                if (option.structured_formatting !== undefined) {
                    const matches = option.structured_formatting.main_text_matched_substrings;
                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match: any) => [match.offset, match.offset + match.length]),
                    );

                    return (<li {...props}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Box
                                        component={LocationOnIcon}
                                        sx={{color: 'text.secondary', mr: 2}}
                                    />
                                </Grid>
                                <Grid item xs>
                                    {parts.map((part: any, index: number) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                    <Typography variant="body2" color="text.secondary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }
                return <></>
            }}
        />
    );
}

export default PlacesAutocomplete