import { Stack } from "@mui/material"
import { Occupancy, PriceTypes, WorkingTime } from "interfaces/space"
import GroupWorkingTimesDayForm from "./GroupWorkingTimesDayForm"

interface GroupWorkingTimesDaysProps {
    workingTimes: WorkingTime[]
    setWorkingTimes: (data: WorkingTime[]) => void
    priceType: PriceTypes
    spaceOccupancy: Occupancy
    onCopyWorkingTimesHandler: () => void
}

const GroupWorkingTimesDays: React.FC<GroupWorkingTimesDaysProps> = (props) => {
    const { 
        workingTimes, 
        setWorkingTimes, 
        priceType, 
        spaceOccupancy, 
        onCopyWorkingTimesHandler
    } = props
    const totalWorkingTimes = workingTimes.length

    if(workingTimes.length === 0) {
        return <></>
    }

    const updateWorkingTime = (index: number, workingTime: WorkingTime) => {
        const workingTimesAux = [...workingTimes]
        workingTimesAux[index] = workingTime
        setWorkingTimes(workingTimesAux)
    }
    
    return <Stack spacing={2}>
        {
            workingTimes.map((workingTime, index) => <GroupWorkingTimesDayForm
                    key={'working-time-day-form-' + index}
                    index={index}
                    workingTime={workingTime}
                    updateWorkingTime={updateWorkingTime}
                    priceType={priceType}
                    spaceOccupancy={spaceOccupancy}
                    totalWorkingTimes={totalWorkingTimes}
                    onCopyWorkingTimesHandler={onCopyWorkingTimesHandler}
                />
            )
        }
    </Stack>
}

export default GroupWorkingTimesDays