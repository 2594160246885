import { Box } from "@mui/material"
import LocationFilledIcon from '../../icons/location-filled.svg'
import GroupIcon from '../../icons/group.svg'
import SaleTagIcon from '../../icons/sale-tag.svg'
import { SpaceDetail } from "interfaces/space"
import { useTranslate } from "@pankod/refine-core"
import { calculateSearchResultMaxPrice, formatNumberPrice, getOccupancyString, getSpaceMinPriceByPerson, roundAmount, roundPricePerPerson } from "utils"
import AdInfoTag from "./AdInfoTag"

interface AdInfoProps {
    spaceDetail: SpaceDetail
}

const AdInfo: React.FC<AdInfoProps> = (props) => {
    const { spaceDetail } = props

    const t = useTranslate()
    const occupancyText = t('previewDetails.occupancyText')
    const occupancyHalfPlaceholder = t('previewDetails.occupancyHalfPlaceholder')
    const occupancyFullText = getOccupancyString(occupancyText, occupancyHalfPlaceholder, spaceDetail.occupancy)
    const priceByPersonPlaceholder = t('previewDetails.priceByPersonPlaceholder')
    const spaceMinPriceByPerson = getSpaceMinPriceByPerson(spaceDetail);
    const pricePerPerson = roundAmount(spaceMinPriceByPerson)
    const priceByPersonText = pricePerPerson ? priceByPersonPlaceholder.replace('${price}', formatNumberPrice(pricePerPerson, false, 'MXN', false)) : '';

    return <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row', gap: '16px'}, flexWrap: 'wrap'}}>
        { spaceDetail.address && spaceDetail.address.city && <AdInfoTag icon={LocationFilledIcon} text={spaceDetail.address.city}/> }
        { occupancyFullText && <AdInfoTag icon={GroupIcon} text={occupancyFullText}/> }
        { priceByPersonText && <AdInfoTag icon={SaleTagIcon} text={priceByPersonText}/> }
    </Box>
}

export default AdInfo