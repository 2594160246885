import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import { CatalogEntry } from "interfaces/catalogEntry"

interface AdSpaceTypeTagProps {
    spaceType?: CatalogEntry
    onFilterSpaceType: (spaceType: string) => void
}

const AdSpaceTypeTag: React.FC<AdSpaceTypeTagProps> = (props) => {
    const {spaceType, onFilterSpaceType } = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const textVariant = isSM ? 'labelLarge' : 'titleMedium'

    if(!spaceType) {
        return <></>
    }

    return <Box sx={{
        display: 'flex',
        gap: '8px',
        padding: '8px',
        borderRadius: '4px',
        cursor: 'pointer',
        backgroundColor: 'primaryContainer.main',
        width: 'max-content'
    }}
    onClick={() => {onFilterSpaceType(spaceType.id)}}>
        <Typography variant={textVariant} sx={{color: 'onPrimaryContainer.light'}}>{spaceType.value}</Typography>
    </Box>
}

export default AdSpaceTypeTag