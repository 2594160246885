import { Typography } from "@mui/material";
import { useCallback } from "react";
import editIconSrc from '../../icons/edit.svg';
import { useNavigation, useTranslate } from "@pankod/refine-core";
import RoundedButton from "components/RoundedButton";

interface SpacePreviewEditButtonProps {
    spaceId: string
}

const SpacePreviewEditButton: React.FC<SpacePreviewEditButtonProps> = (props) => {
    const { push } = useNavigation()
    const t = useTranslate()
    const edit_space_label = t('reservation.editSpaceLabel');

    const goToEditSpace = useCallback(() => {
        push("/advertisements/update/" + props.spaceId);
    }, []);

    return <RoundedButton
        variant='outlined'
        sx={{borderColor: 'outline.light', textTransform: 'none', color: '#684DAC', '&:hover': { borderColor: 'outline.light'}}}
        onClick={goToEditSpace}>
            <Typography variant='labelLarge' sx={{marginRight: '8px', letterSpacing: '0.1px'}}>{edit_space_label}</Typography>
            <img src={editIconSrc} alt={'edit'} width={20} height={20}/>
    </RoundedButton>
}

export default SpacePreviewEditButton;