import { Box, IconButton, Stack, Typography } from "@mui/material"
import { NumberFormatValues } from "react-number-format/types/types"
import CloseIcon from '@mui/icons-material/Close'
import { useEffect } from "react"
import { GroupPrice, GroupRange, Occupancy, PriceTypes } from "interfaces/space"
import { useTranslate } from "@pankod/refine-core"
import { checkRangeHasMissingData, checkRangeHasOccupancyExceeded, checkRangeHasWrong, parseCommaPrice, roundAmount } from "utils"
import { TextfieldOutlined } from "components/TextfieldOutlined"
import GroupWorkingTimesDayPriceInput from "./GroupWorkingTimesDayPriceInput"

interface GroupWorkingTimesDayRangeProps {
    index: number
    range: GroupRange
    updateRange: (index: number, range: GroupRange) => void
    deleteRange: (index: number) => void
    priceType: PriceTypes
    maxHours: number
    isFirstRange: boolean
    isLastRange: boolean
    spaceOccupancy: Occupancy
}

const GroupWorkingTimesDayRange: React.FC<GroupWorkingTimesDayRangeProps> = (props) => {
    const { range, index, updateRange, priceType, maxHours, deleteRange, isFirstRange, isLastRange, spaceOccupancy } = props
    const hasRangeOccupancy: boolean = !!range.occupancy.minimum && !!range.occupancy.maximum
    
    const t = useTranslate()
    const peopleMinAllowedTitle = t("updateSpace.peopleMinAllowed")
    const peopleMaxAllowedTitle = t("updateSpace.peopleMaxAllowed")
    const missingError = t("updateSpace.spaceTimeRangeMissingError")
    const wrongRangeError = t("updateSpace.spaceTimeRangeWrongRangeError")
    const occupancyExceededError = t("updateSpace.spaceTimeRangeOccupancyExceededError")
    const priceByTitle = t("updateSpace.priceBy")
    const eventTitle = t("updateSpace.event")
    const personTitle = t("updateSpace.person")
    const hourTitle = t("updateSpace.hour")

    const onOccupancyMinimumChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let minimum = parseInt(event.target.value)
        updateRangeOccupancy('minimum', minimum)
    }

    const onOccupancyMaximumChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let maximum = parseInt(event.target.value)
        updateRangeOccupancy('maximum', maximum)
    }

    const onPriceByEventChangedHandler = (values: NumberFormatValues) => {
        let priceByEvent = parseCommaPrice(values.formattedValue)
        generatePrices(priceByEvent)
    }

    const onPriceByPersonChangedHandler = (values: NumberFormatValues) => {
        let priceByPerson = parseCommaPrice(values.formattedValue)
        generatePrices(priceByPerson)
    }

    const onPriceByHourChangedHandler = (values: NumberFormatValues) => {
        let priceByHour = parseCommaPrice(values.formattedValue)
        generatePrices(priceByHour)
    }

    const updateRangeOccupancy = (field: string, value: number) => {
        const updatedRange: any = {...range}
        updatedRange.occupancy[field] = value
        updateRange(index, updatedRange)
    }

    const generatePrices = (currentPrice: number) => {
        let newPrices: GroupPrice
        switch (priceType as PriceTypes) {
            case PriceTypes.ByEvent:
                newPrices = generatePricesByEvent(currentPrice)
                break;

            case PriceTypes.ByPerson:
                newPrices = generatePricesByPerson(currentPrice)
                break;

            case PriceTypes.ByHour:
                newPrices = generatePricesByHour(currentPrice)
                break;
        }

        const updatedRange: GroupRange = {...range}
        updatedRange.prices = newPrices
        updateRange(index, updatedRange)
    }

    const generatePricesByEvent = (currentPrice: number): GroupPrice => {
        const byPerson = roundAmount(currentPrice / range.occupancy.maximum!)
        const byHour = roundAmount(currentPrice / maxHours)
        return {
            byEvent: currentPrice,
            byPerson,
            byHour
        }
    }

    const generatePricesByPerson = (currentPrice: number): GroupPrice => {
        const byEvent = roundAmount(currentPrice * range.occupancy.maximum!)
        const byHour = roundAmount(byEvent / maxHours)
        return {
            byEvent: byEvent,
            byPerson: currentPrice,
            byHour
        }
    }

    const generatePricesByHour = (currentPrice: number): GroupPrice => {
        const byEvent = roundAmount(currentPrice * maxHours)
        const byPerson = roundAmount(byEvent / range.occupancy.maximum!)
        return {
            byEvent: byEvent,
            byPerson: byPerson,
            byHour: currentPrice
        }
    }

    const onDeleteRangeHandler = () => {
        deleteRange(index)
    }

    const getPriceByPriceType = (priceType: PriceTypes, groupPrice: GroupPrice) => {
        switch(priceType) {
            case PriceTypes.ByEvent: return groupPrice.byEvent
            case PriceTypes.ByPerson: return groupPrice.byPerson
            case PriceTypes.ByHour: return groupPrice.byHour
        }
    }

    const hasMissingData = checkRangeHasMissingData(range)
    const hasWrongRange = checkRangeHasWrong(range)
    const hasOccupancyExceeded = checkRangeHasOccupancyExceeded(range, spaceOccupancy)

    let errorText = '' 
    if(hasMissingData) {
        errorText = missingError
    } else if(hasWrongRange) {
        errorText = wrongRangeError
    } else if(hasOccupancyExceeded) {
        errorText = occupancyExceededError
    }

    useEffect(() => {
        if(maxHours && range.occupancy.maximum) {
            const currentPrice = getPriceByPriceType(priceType, range.prices)
            if(currentPrice) {
                generatePrices(currentPrice)
            }
        }
    }, [maxHours, range.occupancy.maximum, priceType])

    return <Stack spacing={1}>
        <Box sx={{
            borderLeft: '1px solid',
            borderLeftColor: 'neutral60.main',
            pl: 1,
            display: 'flex',
            alignItems: 'flex-end',
            gap: 2.9,
            flexWrap: 'wrap'
        }}>
            <Stack sx={{width: '132px'}} spacing={1}>
                <Typography variant="labelLarge">{peopleMinAllowedTitle}</Typography>
                <TextfieldOutlined
                    id='working-time-price-occupancy-minimun'
                    onChange={onOccupancyMinimumChangedHandler}
                    value={range.occupancy.minimum ? range.occupancy.minimum : ''}
                    inputProps={{
                        type: 'number'
                    }}
                    sx={{maxWidth: '121px'}}
                    disabled={!isFirstRange}
                />
            </Stack>
            <Stack sx={{width: '132px'}} spacing={1}>
                <Typography variant="labelLarge">{peopleMaxAllowedTitle}</Typography>
                <TextfieldOutlined
                    id='working-time-price-occupancy-maximum'
                    onChange={onOccupancyMaximumChangedHandler}
                    value={range.occupancy.maximum ? range.occupancy.maximum : ''}
                    inputProps={{
                        type: 'number'
                    }}
                    sx={{maxWidth: '121px'}}
                />
            </Stack>
            <Stack sx={{width: '132px'}} spacing={1}>
                <Stack>
                    <Typography variant="labelLarge">{priceByTitle}</Typography>
                    <Typography variant="labelLarge">{eventTitle}</Typography>
                </Stack>
                <GroupWorkingTimesDayPriceInput 
                    value={range.prices.byEvent ? range.prices.byEvent : ''}
                    onValueChange={onPriceByEventChangedHandler}
                    disabled={!(priceType === PriceTypes.ByEvent) || !hasRangeOccupancy}
                />
            </Stack>
            <Stack sx={{width: '132px'}} spacing={1}>
                <Stack>
                    <Typography variant="labelLarge">{priceByTitle}</Typography>
                    <Typography variant="labelLarge">{personTitle}</Typography>
                </Stack>
                <GroupWorkingTimesDayPriceInput 
                    value={range.prices.byPerson ? range.prices.byPerson : ''}
                    onValueChange={onPriceByPersonChangedHandler}
                    disabled={!(priceType === PriceTypes.ByPerson) || !hasRangeOccupancy}
                />
            </Stack>
            <Stack sx={{width: '132px'}} spacing={1}>
                <Stack>
                    <Typography variant="labelLarge">{priceByTitle}</Typography>
                    <Typography variant="labelLarge">{hourTitle}</Typography>
                </Stack>
                <GroupWorkingTimesDayPriceInput 
                    value={range.prices.byHour ? range.prices.byHour : ''}
                    onValueChange={onPriceByHourChangedHandler}
                    disabled={!(priceType === PriceTypes.ByHour) || !hasRangeOccupancy}
                />
            </Stack>
            { 
            isLastRange &&  <IconButton
                onClick={onDeleteRangeHandler}    
            >
                <CloseIcon sx={{color: 'onSurface.light'}}/>
            </IconButton>
        }
        </Box>
        { errorText && <Typography color='error' variant='labelLarge'>{errorText}</Typography> }
    </Stack> 
}

export default GroupWorkingTimesDayRange