import React, {useCallback} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import { useMediaQuery, useTheme } from "@mui/material";
import { SpaceDetail } from "interfaces/space";
import { useTranslate } from "@pankod/refine-core";
import useViewModel from '../../viewModels/ReviewViewModel'

interface AdReviewsProps {
    spaceDetail: SpaceDetail
}

const AdReviews: React.FC<AdReviewsProps> = (props) => {
    const {spaceDetail} = props
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'
    const messageVariant = isSM ? 'titleSmall' : 'titleLarge'

    const t = useTranslate();
    const title = t('previewDetails.reviewsTitle')
    const ratings_title_placeholder = t('previewDetails.ratingsTitlePlaceholder')
    const ratings_showAll = t('previewDetails.ratingsSeeAll')
    const noRatingsYet = t('previewDetails.noRatingsYet')
    const ratings_title = ratings_title_placeholder.replace("${ratings}", (spaceDetail.ratings ? spaceDetail.ratings : 0).toString())

    const {reviews} = useViewModel(spaceDetail, true)

    const onShowAllClickHandler = useCallback(() => {

    }, [])

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    }}>
         <Typography variant={titleVariant}>{title}</Typography>
        
        {reviews.length === 0 ?
            <Typography color="primary" variant={messageVariant}>{noRatingsYet}</Typography> :
            <>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: '32px'
                }}>

                    <Typography variant='titleMedium' sx={{}}>{ratings_title}</Typography>
                    <Typography variant='titleMedium' sx={{}}>
                        <>
                            <StarIcon sx={{height: '10px', width: '10px ', mr: '2px'}}/>
                            {`(${spaceDetail.rating ? spaceDetail.rating : 0})`}
                        </>
                    </Typography>

                </Box>
                {spaceDetail.ratings !== undefined && spaceDetail.ratings > 2 &&
                    <Button variant='text' color='primary' sx={{mt: '24px'}} onClick={onShowAllClickHandler}>
                        <Typography variant='labelLarge'>{ratings_showAll}</Typography>
                    </Button>
                }
            </>
        }
    </Box>
}

export default AdReviews