import { FormControl, MenuItem, Select, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import moment from "moment"
import { useEffect, useState } from "react"
import { addHoursToTime, formatTime, removeHoursToTime } from "utils"

export const generateCustomWorkingTimes = (times: string[], rangeHours: number): string[] => {
    const workingTimes: string[] = []
    times.forEach(time => {
        const arrHour = time.split('-')
        let startHour = arrHour[0]
        const endHour = arrHour[1]
        const arrStartHour = startHour.split(':')
        let startDate = moment().set('hours', parseInt(arrStartHour[0])).set('minutes', parseInt(arrStartHour[1]))
        const limitHour = removeHoursToTime(endHour, rangeHours)
        const arrLimitHour = limitHour.split(':')
        const limitDate = moment().set('hours', parseInt(arrLimitHour[0])).set('minutes', parseInt(arrLimitHour[1]))
        if(limitDate.isBefore(startDate)) {
            limitDate.add(1, 'day')
        }
        while(startDate.isSameOrBefore(limitDate)) {
            const newEndHour = addHoursToTime(startHour, rangeHours)
            workingTimes.push(startHour + '-' + newEndHour)
            startHour = addHoursToTime(startHour, 1)
            startDate.add(1, 'hours')
        }
    })
    return workingTimes
}

interface SpaceTimesProps {
    times: string[]
    selectedTime: string | null
    setSelectedTime: (time: string | null) => void
    eventMaxHours: number
    selectedEventHours: number | null
    isPriceByHour: boolean
}

const SpaceTimes: React.FC<SpaceTimesProps> = (props) => {
    const { times, selectedTime, setSelectedTime, eventMaxHours, selectedEventHours, isPriceByHour } = props
    const [customTimes, setCustomTimes] = useState<string[]>([])
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'
    
    const t = useTranslate()
    const title = t('previewDetails.spaceChooseTime')
    const ofLabel = t('previewDetails.ofLabel')
    const toLabel = t('previewDetails.toLabel')
    
    const onTimeChangedHandler = (event: any) => {
        let time = event.target.value
        setSelectedTime(time)
    }

    const generateNewTimes = (times: string[], rangeHours: number) => {
        if(times.length > 0 && rangeHours > 0) {
            const newTimes = generateCustomWorkingTimes(times, rangeHours)
            setCustomTimes(newTimes)
        } else {
            setCustomTimes([])
        }
    }

    const generateNewTimesByEventMaxHours = () => {
        generateNewTimes(times, eventMaxHours)
        
    }

    const generateNewTimesBySelectedEventHours = () => {
        const rangeHours = selectedEventHours ? selectedEventHours : 0
        generateNewTimes(times, rangeHours)
    }

    useEffect(() => {
        if(isPriceByHour) {
            generateNewTimesBySelectedEventHours()
        } else {
            generateNewTimesByEventMaxHours()
        }
    }, [isPriceByHour, times, selectedEventHours])

    return <Stack spacing={2}>
        <Typography variant={titleVariant}>{title}</Typography>
        <Stack spacing={2} direction={'row'} alignItems={'center'}>
            <FormControl sx={{width: '100%'}}>
                <Select
                    sx={{borderRadius: '8px'}}
                    id="space-times"
                    value={selectedTime ? selectedTime : ''}
                    onChange={onTimeChangedHandler}
                >
                    {
                        customTimes.map((time, index) => {
                            const arrTime = time.split('-')
                            return <MenuItem 
                                value={time} 
                                sx={{
                                padding: '8px 16px',
                                borderBottom: index < (customTimes.length - 1) ? '1px solid' : 'none',
                                borderBottomColor: index < (customTimes.length - 1) ? 'neutral70.light' : 'none',
                            }} key={`space-times-${index}`}>
                                <Typography variant="bodyLarge">{ofLabel} <Typography variant="titleMedium">{formatTime(arrTime[0])}</Typography> {toLabel} <Typography variant="titleMedium"> {formatTime(arrTime[1])}</Typography></Typography>
                            </MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </Stack>
    </Stack>
}

export default SpaceTimes