import React, { useState } from "react";
import { useTranslate } from "@pankod/refine-core";
import { Box, DataGrid, DateField, GridColumns, GridToolbar, List, Typography, styled, useDataGrid } from "@pankod/refine-mui";
import { IFinance, ICatalog, IBooking } from "interfaces";
import Details from "../bookings/details";
import ReservationStatus from "components/details/reservationStatus";
import { formatNumberPrice } from "utils";
import Actions from "../../components/list/actions";
import PaymentStatus from "components/details/paymentStatus";

const CellLabel = styled(Typography)(() => ({
  fontSize: '14.16px',
  lineHeight: '56.64px'
}));

export const FinancesList: React.FC = (props: any) => {
  const t = useTranslate();
  const { dataGridProps } = useDataGrid<ICatalog>();

  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [selectedReservation, setSelectedReservation] = useState<IFinance | undefined>(undefined);

  const columns = React.useMemo<GridColumns<IFinance>>(
    () => [
      {
        field: "id",
        headerName: t("finances.fields.paymentId"),
        minWidth: 0,
        flex: 0.1,
      },
      {
        field: "incrementId",
        headerName: t("finances.fields.bookingId"),
        minWidth: 150,
        flex: 0.2,
      },
      {
        field: "bookingId",
        headerName: t("finances.fields.confirmationNumber"),
        minWidth: 230,
        flex: 0.2,
      },
      {
        field: "formattedStatus",
        headerName: t("finances.fields.eventStatus"),
        renderCell: function render({ row }) {
          return <ReservationStatus
              reservationStatus={row.status}
              sx={{
                fontSize: '14.16px'
              }}
          ></ReservationStatus>;
        },
        minWidth: 140,
        flex: 0.2,
      },
      {
        field: "hostName",
        headerName: t("finances.fields.host"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.hostName}</CellLabel>;
        },
        minWidth: 150,
        flex: 0.2,
      },
      {
        field: "guestName",
        headerName: t("finances.fields.guest"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.guestName}</CellLabel>;
        },
        minWidth: 150,
        flex: 0.2,
      },
      {
        field: "date",
        headerName: t("finances.fields.reservationDate"),
        renderCell: function render({ row }) {
          return <DateField value={row.date.substring(0,10)} format="DD/MM/YYYY"  />;
        },
        minWidth: 145,
        flex: 0.2,
      },
      {
        field: "time",
        headerName: t("finances.fields.reservationTime"),
        minWidth: 145,
        flex: 0.2,
      },
      {
        field: "advertisementName",
        headerName: t("finances.fields.spaceName"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.advertisementName}</CellLabel>;
        },
        minWidth: 200,
        flex: 0.2,
      },
      {
        field: "subtotal",
        headerName: t("finances.fields.subtotal"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.subtotal, true)}</CellLabel>;
        },
        minWidth: 160,
        flex: 0.2,
      },
      {
        field: "ivaAmount",
        headerName: t("finances.fields.ivaAmount"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.ivaAmount, true)}</CellLabel>;
        },
        minWidth: 160,
        flex: 0.2,
      },
      {
        field: "guestCommissionAmount",
        headerName: t("finances.fields.guestComission"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.guestCommissionAmount, true)}</CellLabel>;
        },
        minWidth: 150,
        flex: 0.2,
      },
      {
        field: "guestCommissionIvaAmount",
        headerName: t("finances.fields.guestCommissionIva"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.guestCommissionIvaAmount, true)}</CellLabel>;
        },
        minWidth: 150,
        flex: 0.2,
      },
      {
        field: "guaranteeDeposit",
        headerName: t("finances.fields.guaranteeDeposit"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.guaranteeDeposit, true)}</CellLabel>;
        },
        minWidth: 155,
        flex: 0.2,
      },
      {
        field: "grandTotal",
        headerName: t("finances.fields.totalAmountToPaid"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.grandTotal, true)}</CellLabel>;
        },
        minWidth: 175,
        flex: 0.2,
      },
      {
        field: "paymentNumber",
        headerName: t("finances.fields.paymentNumber"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.paymentNumber} {t("finances.of")} {row.totalPayments}</CellLabel>;
        },
        minWidth: 70,
        flex: 0.2,
      },
      {
        field: "paymentStatus",
        headerName: t("finances.fields.paymentStatus"),
        renderCell: function render({ row }) {
          return <PaymentStatus
              paymentStatus={row.paymentStatus}
              sx={{
                fontSize: '14.16px'
              }}
          ></PaymentStatus>;
        },
        minWidth: 175,
        flex: 0.2,
      },
      {
        field: "paymentAmount",
        headerName: t("finances.fields.paymentAmount"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.paymentAmount, true)}</CellLabel>;
        },
        minWidth: 155,
        flex: 0.2,
      },
      {
        field: "paidAmount",
        headerName: t("finances.fields.paidAmount"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.paidAmount, true)}</CellLabel>;
        },
        minWidth: 155,
        flex: 0.2,
      },
      {
        field: "hostCommissionAmount",
        headerName: t("finances.fields.hostCommission"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.hostCommissionAmount, true)}</CellLabel>;
        },
        minWidth: 160,
        flex: 0.2,
      },
      {
        field: "hostCommissionIvaAmount",
        headerName: t("finances.fields.hostCommissionIva"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.hostCommissionIvaAmount, true)}</CellLabel>;
        },
        minWidth: 160,
        flex: 0.2,
      },
      {
        field: "hostAmount",
        headerName: t("finances.fields.hostAmount"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.hostAmount, true)}</CellLabel>;
        },
        minWidth: 160,
        flex: 0.2,
      },
      {
        field: "stripeComissionAmount",
        headerName: t("finances.fields.stripeCommission"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.stripeComissionAmount, true)}</CellLabel>;
        },
        minWidth: 160,
        flex: 0.2,
      },
      {
        field: "ifiestaComission",
        headerName: t("finances.fields.ifiestaComission"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.ifiestaComission, true)}</CellLabel>;
        },
        minWidth: 160,
        flex: 0.2,
      },
      {
        field: "actions",
        headerName: "",
        minWidth: 70,
        flex: 0.2,
      }
    ],
    [t]
  );

  return (
    <List>
      <Box sx={{position: 'relative'}}>
        <DataGrid 
          {...dataGridProps} 
          columns={columns} 
          components={{
            Toolbar: GridToolbar,
          }}
          autoHeight
        />
        <Actions
          reservations={dataGridProps.rows}
          setOpenSidebar={setOpenSidebar}
          setSelectedReservation={(reservation: IBooking) => {
            const selectedReservation = {...reservation as IFinance};
            selectedReservation.id = selectedReservation.bookingId;
            setSelectedReservation(selectedReservation);
          }}
        ></Actions>
      </Box>
      <Details
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        reservation={selectedReservation}
      ></Details>
    </List>
  );
};