import {Exception} from "./Exception";

export class NetworkException extends Exception {
    messageKey: string
    status: number
    message: any

    constructor(status: number, message: string, messageKey: string = 'default_network_error') {
        super(messageKey, message);
        this.status = status
        this.message = message
        this.messageKey = messageKey
    }
}

export const ERROR_CODE_DEFAULT = 0