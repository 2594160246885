import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Result} from "typescript-monads";
import {StorageReference} from "firebase/storage";
import { SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { useTranslate } from "@pankod/refine-core";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import useStorageRef from "hooks/useStorageRef";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import { Exception } from "exception/Exception";
import CoverImageUpload from "components/CoverImageUpload";
import { useAppDispatch } from "store/hooks";
import { spaceActions } from "store/space";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

export interface FormFloorPlan {
    floorPlanUrl?: string | null
}

const spaceDetailToFloorPlan = (data: SpaceDetail): FormFloorPlan => {
    return {floorPlanUrl: data?.floorPlanUrl}
}

export const isFloorPlanEmpty = (data: SpaceDetail, isOmitted?: boolean): boolean => !isOmitted && (data === undefined || data.floorPlanUrl === undefined)

const isFloorPlanValid = (data: FormFloorPlan): boolean => {
    return data !== undefined && data.floorPlanUrl !== undefined
}

const stateHandlers = [StateHandler.Error]

const floorPlanUploadSuffix = 'spaceFloorPlan'

export interface SpaceFloorPlanFormProps {
    spaceDetail: SpaceDetail
    onSkip: () => void
    isOmitted?: boolean
}

const SpaceFloorPlanForm: React.FC<SpaceFloorPlanFormProps> = (props) => {

    const {spaceDetail, onSkip, isOmitted} = props

    const t = useTranslate();
    const titleText = t('updateSpace.spaceFloorPlanTitle')
    const subtitleText = t('updateSpace.spaceFloorPlanSubtitle')
    const hint = t('updateSpace.spaceFloorPlanHint')

    const getErrorMessage = useSyncExceptionError(t)

    const getRef = useStorageRef()
    const [showEdit, setShowEdit] = useState<boolean>(true)
    const [floorPlanRef, setFloorPlanRef] = useState<StorageReference | null>(null)

    const {updateSpace} = useSpaceUpdate()

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    useEffect(() => {
        const getRefs = async () => {
            const newFloorPlanRef = await getRef(floorPlanUploadSuffix)
            setFloorPlanRef(newFloorPlanRef === undefined ? null : newFloorPlanRef)
        }

        getRefs()
    }, [getRef])

    const renderEditableForm = (updateFn: (data: FormFloorPlan) => void, data?: FormFloorPlan) => {

        const floorPlanChangedHandler = (url: string | null) => {
            if (url === null) {
                updateFn({})
            } else {
                const newData: FormFloorPlan = {
                    floorPlanUrl: url,
                }
                updateFn(newData)
            }
        }

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                mt: 3,
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                justifyContent: {xs: 'center', md: 'flex-start'},
                alignItems: {xs: 'flex-start', md: 'center'}
            }}>
                <CoverImageUpload
                    downloadUrl={data?.floorPlanUrl ? data.floorPlanUrl : null}
                    uploadedRef={floorPlanRef}
                    title={hint}
                    onUrlChange={floorPlanChangedHandler}/>
            </Box>
        </Box>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                mt: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>{data !== undefined && data !== null && data.floorPlanUrl !== null && data.floorPlanUrl !== undefined &&
                < Box sx={{
                position: 'relative',
            }} component='div'>
                <img alt="complex" src={data.floorPlanUrl!} width={300} height={300}
                style={{borderRadius: '8px'}}/>
                </Box>
            }
            </Box>
        </Box>
    }

    const syncDataHandler = (data: FormFloorPlan) => {
        if (data !== undefined && data !== null && data.floorPlanUrl !== undefined) {
            const body = {floorPlanUrl: data.floorPlanUrl}
            return updateSpace(spaceDetail.id, body)
        } else {
            const body = {floorPlanUrl: null}
            return updateSpace(spaceDetail.id, body)
        }
    }

    const dispatch = useAppDispatch()
    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    return <EditableSyncFormV2
        title={titleText}
        subtitle={subtitleText}
        isFocused={false}
        loadData={loadData}
        syncData={syncDataHandler}
        getErrorMessage={getErrorMessage}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        mapServerDataToFormData={spaceDetailToFloorPlan}
        isServerDataEmpty={isFloorPlanEmpty}
        isFormDataValid={isFloorPlanValid}
        stateHandlers={stateHandlers}
        showEdit={showEdit}
        onSync={onSyncHandler}
        optional={true}
        onSkip={onSkip}
        isOmitted={isOmitted}
    />
}

export default SpaceFloorPlanForm