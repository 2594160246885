import React, {useState} from "react";
import {Result} from "typescript-monads";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { SpaceDetail } from "interfaces/space";
import { StateHandler, StatePair } from "viewModels/EditableSyncViewModel";
import { useTranslate } from "@pankod/refine-core";
import { Exception } from "exception/Exception";
import useSyncExceptionError from "hooks/useSyncExceptionError";
import useSpaceUpdate from "hooks/useSpaceUpdate";
import fieldConstants from "constants/fieldConstants";
import DataAsList from "components/DataAsList";
import { useAppDispatch } from "store/hooks";
import { spaceActions } from "store/space";
import EditableSyncFormV2 from "components/EditableSyncFormV2";

export interface SpaceDaysToReserveFormProps {
    spaceDetail: SpaceDetail
}

const isMinLowerThanMax = (data?: FormDaysToReserve): boolean => {
    if (data === undefined) return true
    if (data?.minimum === undefined) return true
    return data?.maximum !== undefined && data.minimum < data.maximum
}

export const isDaysToReserveEmpty = (data: SpaceDetail): boolean => data === undefined || data.daysToReserve === undefined
    || data.daysToReserve.minimum === 0

const isDaysToReserveValid = (data: FormDaysToReserve): boolean => {
    return data !== undefined && data?.minimum !== undefined && isMinGreaterThan7(data) && isMinLowerThanMax(data)
}

const isMinGreaterThan7 = (data: FormDaysToReserve): boolean => {
    if (data === undefined || data.minimum === undefined) return false
    return data.minimum >= 7;
}

const spaceDetailToDaysToReserve = (data: SpaceDetail): FormDaysToReserve => {
    return {minimum: data?.daysToReserve?.minimum, maximum: data?.daysToReserve?.maximum}
}

export interface FormDaysToReserve {
    minimum?: number
    maximum?: number
}

const stateHandlers = [StateHandler.Error]

const SpaceDaysToReserveForm: React.FC<SpaceDaysToReserveFormProps> = (props) => {

    const {spaceDetail} = props

    const loadData = async () => {
        return Result.ok<SpaceDetail, Exception>(spaceDetail)
    }

    const t = useTranslate()
    const title = t("updateSpace.spaceDaysToReserveTitle")
    const subtitle = t("updateSpace.spaceDaysToReserveSubtitle")
    const min_title = t("updateSpace.spaceDaysToReserveMin")
    const min_hint = t("updateSpace.spaceDaysToReserveMinHint")
    const max_title = t("updateSpace.spaceDaysToReserveMax")
    const max_hint = t("updateSpace.spaceDaysToReserveMaxHint")
    const max_min_error = t("updateSpace.maxMinError")

    const getErrorMessage = useSyncExceptionError(t)

    const [minError, setMinError] = useState(false)

    const daysToReserveToList = (data: SpaceDetail): string[] => {
        return data?.daysToReserve ? (
            data?.daysToReserve?.maximum ? [
                `${min_title} - ${data.daysToReserve?.minimum}`,
                `${max_title} - ${data.daysToReserve?.maximum}`
            ] : [`${min_title} - ${data.daysToReserve?.minimum}`]
        ) : []
    }

    const {updateSpace} = useSpaceUpdate()

    const renderEditableForm = (updateFn: (data: FormDaysToReserve) => void, data?: FormDaysToReserve) => {
        const onMaxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            const max = event.target.value
            if (max.length === 0) {
                updateFn({minimum: data?.minimum})
            } else {
                let maxInt = parseInt(max)
                if (maxInt > 10000) maxInt = 10000
                const newData: FormDaysToReserve = Object.assign({}, data)
                newData.maximum = maxInt
                updateFn(newData)
            }
        }

        const onMinChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            let min = event.target.value
            const newData: FormDaysToReserve = Object.assign({}, data)
            const newVal = parseInt(min)
            setMinError(newVal < 7)
            newData.minimum = newVal
            updateFn(newData)
        }

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Box sx={{
                mt: 3,
                pr: 8,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start'
            }}>
                <Typography sx={{pt: 2}} variant="titleMedium">{min_title}</Typography>
                <TextField
                    error={minError}
                    margin="normal"
                    fullWidth
                    placeholder={min_hint}
                    id="minimo-de-personas"
                    name="minimo-de-personas"
                    onChange={onMinChangeHandler}
                    value={data?.minimum ? data.minimum : ''}
                    sx={{width: '250px'}}
                    inputProps={{
                        min: 0,
                        max: data?.maximum ? data.maximum : fieldConstants.defaultMaxOccupancy,
                        type: 'number'
                    }}
                />
                <Typography sx={{pt: 2}} variant="titleMedium">{max_title}</Typography>
                <TextField
                    error={!isMinLowerThanMax(data)}
                    helperText={!isMinLowerThanMax(data) ? max_min_error : ''}
                    margin="normal"
                    fullWidth
                    placeholder={max_hint}
                    id="maximo-de-personas"
                    name="maximo-de-personas"
                    onChange={onMaxChangeHandler}
                    value={data?.maximum ? data.maximum : ''}
                    sx={{width: '250px'}}
                    inputProps={{
                        min: data?.minimum ? data.minimum : fieldConstants.defaultMaxOccupancy,
                        max: fieldConstants.defaultMaxOccupancy,
                        type: 'number'
                    }}
                />
            </Box>
        </Box>
    }

    const renderDisplayForm = (data: SpaceDetail) => {
        return <Box sx={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <DataAsList data={daysToReserveToList(data)} fieldId={'name'}/>
        </Box>
    }

    const syncDataHandler = (data: FormDaysToReserve) => {
        const body = {daysToReserve: {minimum: data.minimum, maximum: data.maximum}}
        return updateSpace(spaceDetail.id, body)
    }

    const dispatch = useAppDispatch()
    const onSyncHandler = (result: Result<SpaceDetail, Exception>, stateHandlers: Map<StateHandler, StatePair<any>>) => {
        if (result.isOk()) dispatch(spaceActions.setRefreshSpaceDetail(true))
    }

    return <EditableSyncFormV2
        title={title}
        subtitle={subtitle}
        isFocused={false}
        editableForm={renderEditableForm}
        displayForm={renderDisplayForm}
        loadData={loadData}
        syncData={syncDataHandler}
        isFormDataValid={isDaysToReserveValid}
        isServerDataEmpty={isDaysToReserveEmpty}
        mapServerDataToFormData={spaceDetailToDaysToReserve}
        getErrorMessage={getErrorMessage}
        stateHandlers={stateHandlers}
        onSync={onSyncHandler}/>
}

export default SpaceDaysToReserveForm