import { EPaymentStatus } from "enums";
import { Circle, CircleContainer, Text } from "./components";

interface PaymentStatusProps {
    paymentStatus: EPaymentStatus;
    sx?: any
}

const PaymentStatus: React.FC<PaymentStatusProps> = (props) => {
    const { paymentStatus, sx } = props;

    let paymentStatusName = '';
    let paymentStatusColor = '';
    
    switch(paymentStatus) {
        case EPaymentStatus.PAID:
            paymentStatusName = 'Pagado';
            paymentStatusColor = '#3F992F';
            break;

        case EPaymentStatus.TO_PAY:
            paymentStatusName = 'Por pagar';
            paymentStatusColor = '#684DAC';
            break;

        case EPaymentStatus.PENDING:
            paymentStatusName = 'Pago pendiente';
            paymentStatusColor = '#ECC147';
            break;

        case EPaymentStatus.EXPIRED:
            paymentStatusName = 'Vencido';
            paymentStatusColor = '#BA1B1B';
            break;
    }

    return <CircleContainer>
        <Circle sx={{backgroundColor: paymentStatusColor}}></Circle>
        <Text sx={{...sx}}>{paymentStatusName}</Text>
    </CircleContainer>
}

export default PaymentStatus;