const fieldConstants = {
    rfcLength: 13,
    defaultMaxLengthShort: 100,
    defaultMaxLength: 100,
    defaultGuaranteeDepositMaxLength: 10,
    defaultDescriptionLength: 1000,
    defaultRulesLength: 5000,
    defaultErrorTimeoutMillis: 5000,
    longErrorTimeoutMillis: 30000,
    defaultSuccessAlertTimeoutMillis: 30000,
    reviewSuccessAlertTimeoutMillis: 10000,
    indefiniteAlert: -1,
    defaultMinOccupancy: 1,
    defaultMaxOccupancy: 10000,
    defaultMaxPrice: 1000000,
    defaultTextThreshold: 1000,
    shortThreshold: 120,
    defaultServicesThreshold: 3,
    defaultMaxPeople: 500,
    defaultMaxHours: 24,
}

export const libraries: ("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = ["places"]
export default fieldConstants