import React from "react";
import Box from "@mui/material/Box";

export interface LineProps {
    children?: React.ReactNode,
    orientation: LineOrientation,
    size: string,
    color: string,
    borderWidth?: number,
}

export enum LineOrientation {
    Vertical,
    Horizontal
}

const Line: React.FC<LineProps> = (props) => {
    const {size, color, orientation, borderWidth} = props;

    return <Box
        sx={{
            height: orientation === LineOrientation.Vertical ? size : "0px",
            width: orientation === LineOrientation.Horizontal ? size : "0px",
            border: `${borderWidth ? borderWidth : 1}px solid ${color}`,
        }}/>
}

export default Line