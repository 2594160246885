import { IBooking } from "interfaces";
import PricesPayments from "./pricesPayments";
import Payments from "./payments";

interface PaymentDetailProps {
    reservation: IBooking,
}

const PaymentDetail: React.FC<PaymentDetailProps> = (props) => {
    const { reservation } = props;
        
    return <>
        <PricesPayments
            reservation={reservation}
        ></PricesPayments>
        <Payments
            payments={reservation.payments}
        ></Payments>
    </>
}

export default PaymentDetail;