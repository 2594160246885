import {createSlice} from "@reduxjs/toolkit";

export interface SpaceState {
    currentFocusId: string
    shouldFocus: boolean
    refreshSpaceDetail: boolean
}

const initialState: SpaceState = {
    currentFocusId: 'fieldId',
    shouldFocus: true,
    refreshSpaceDetail: false
}

const spaceSlice = createSlice({
    name: 'space',
    initialState: initialState,
    reducers: {
        setCurrentFocusId(state: SpaceState, action: { payload: string }) {
            state.currentFocusId = action.payload
        },
        setShouldFocus(state: SpaceState, action: { payload: boolean }) {
            state.shouldFocus = action.payload
        },
        setRefreshSpaceDetail(state: SpaceState, action: { payload: boolean }) {
            state.refreshSpaceDetail = action.payload
        },
    }
})

export const spaceActions = spaceSlice.actions
export default spaceSlice