import { Box, Stack } from "@mui/material"
import SpaceSettings from "./SpaceSettings";
import SpacePriceDetail from "./SpacePriceDetail";
import SpaceReserveButton from "./SpaceReserveButton";
import { Group, GroupPackage, GroupRange, PaidService, SpaceDetail } from "interfaces/space";
import AdName from "components/ads/AdName";
import AdInfo from "components/ads/AdInfo";
import Divider from "components/Divider";
import AdMultimedia from "components/ads/AdMultimedia";
import { useEffect, useState } from "react";
import { calculateGroupBasePrice, getSelectedGroupRange } from "utils";
import { CatalogEntry } from "interfaces/catalogEntry";
import SpacePackages from "./SpacePackages";
import SpaceReservationType from "./SpaceReservationType";
import SpaceAmenitiesText from "./SpaceAmenitiesText";
import SpaceServices from "./SpaceServices";

const initRange = (group: Group, date: Date | null, people: number): GroupRange | null => {
    return getSelectedGroupRange(group.workingTimes, date, people)
}

interface SpaceAvailabilityContentProps {
    isPreview: boolean,
    setIsLoading?: (status: boolean) => void
    space: SpaceDetail,
    selectedPackage?: GroupPackage,
    selectedPeople: number,
    selectPeople: (people: number) => void,
    selectedPaidServices: PaidService[],
    selectPaidService: (paidService: PaidService, checked: boolean) => void,
    selectedDate: Date | null
    setSelectedDate: (date: Date | null) => void
    selectedTime: string | null
    setSelectedTime: (time: string | null) => void
    onCheckout: () => void,
    canShowAllServices: boolean,
    setCanShowAllServices: (state: boolean) => void,
    reservePackage: (selectedPackage: GroupPackage) => void,
    requestOpenChat?: boolean
    selectedEventType: CatalogEntry | null
    selectedGroup: Group
    setSelectedEventType: (value: CatalogEntry) => void
    setSelectedGroup: (value: Group) => void
    selectedEventHours: number | null
    setSelectedEventHours: (eventHours: number | null) => void
    setSelectedPaidServices: (paidServices: PaidService[]) => void
}

const SpaceAvailabilityContent: React.FC<SpaceAvailabilityContentProps> = (props) => {
    const { 
        isPreview, 
        space, 
        selectedPackage,
        selectedPeople, 
        selectPeople, 
        selectedPaidServices, 
        selectPaidService, 
        selectedDate, 
        setSelectedDate, 
        selectedTime, 
        setSelectedTime, 
        onCheckout,
        canShowAllServices,
        setCanShowAllServices,
        reservePackage,
        setIsLoading,
        requestOpenChat,
        selectedGroup,
        selectedEventType,
        setSelectedEventType,
        setSelectedGroup,
        selectedEventHours,
        setSelectedEventHours,
        setSelectedPaidServices
    } = props;

    const isDisabled = isPreview ? true : selectedDate === null || selectedTime === null;
    const [selectedRange, setSelectedRange] = useState<GroupRange | null>(() => initRange(selectedGroup, selectedDate, selectedPeople))
    const [groupBasePrice, setGroupBasePrice] = useState<number>()
    const isReserveButtonDisabled = isPreview ? true : selectedEventType === null || selectedDate === null || selectedTime === null || selectedPeople === 0

    useEffect(() => {
        if(selectedGroup) {
            const mandatoryServices = selectedGroup.paidServices.filter(paidService => paidService.mandatory)
            setSelectedPaidServices(mandatoryServices)
        }    
    }, [selectedGroup])

    useEffect(() => {
        const groupBasePrice = calculateGroupBasePrice(selectedGroup, selectedRange, selectedPeople, selectedEventHours)
        setGroupBasePrice(groupBasePrice)
    }, [selectedGroup, selectedRange, selectedPeople, selectedEventHours])
    
    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px'}}>
        <Stack>
            <AdMultimedia spaceDetail={space}/>
            <Box sx={{mt: {xs: '8px', md: '16px'}}}>
                <AdName spaceDetail={space}/>
            </Box>
            <Box sx={{mt: {xs: '24px', md: '16px'}}}>
                <AdInfo spaceDetail={space}/>
            </Box>
        </Stack>
        <Stack sx={{
            display: 'flex', 
            flexDirection: 'column', 
            gap: '32px',
            width: '100%', 
            margin: '0 auto'
        }}>
            <SpaceSettings 
                space={space}
                selectedPeople={selectedPeople}
                selectPeople={selectPeople}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
                selectedGroup={selectedGroup}
                selectedEventType={selectedEventType}
                setSelectedEventType={setSelectedEventType}
                setSelectedGroup={setSelectedGroup}
                selectedEventHours={selectedEventHours}
                setSelectedEventHours={setSelectedEventHours}
                selectedRange={selectedRange}
                setSelectedRange={setSelectedRange}
            ></SpaceSettings>
            <SpacePackages
                selectedGroup={selectedGroup}
                selectedPackage={selectedPackage}
                onReservePackage={reservePackage}
            />
            <Stack spacing={4} sx={{
                width: '100%', 
                maxWidth: '656px',
                margin: '0 auto'
            }}>
                <SpaceReservationType 
                    selectedPackage={selectedPackage}
                />
                <SpaceAmenitiesText 
                    spaceAmenities={selectedGroup.amenities} 
                    selectedPackage={selectedPackage}
                />
                <SpaceServices
                    groupBasePrice={groupBasePrice ? groupBasePrice : 0}
                    groupPaidServices={selectedGroup.paidServices}
                    selectedServices={selectedPaidServices}
                    selectedPackage={selectedPackage}
                    selectPaidService={selectPaidService}
                    canShowAllServices={canShowAllServices}
                    setCanShowAllServices={setCanShowAllServices}
                />
                {
                    selectedPeople > 0 && <>
                        <Divider/>
                        <SpacePriceDetail
                            people={selectedPeople}
                            selectedPackage={selectedPackage}
                            paidServices={selectedPaidServices}
                            size="LG"
                            group={selectedGroup}
                            range={selectedRange}
                            eventHours={selectedEventHours}
                        />
                    </> 
                }
                <Box sx={{pb: 10}}>
                    <SpaceReserveButton 
                        isDisabled={isReserveButtonDisabled} 
                        onReserveSpace={onCheckout}
                    />
                </Box>
            </Stack>
        </Stack>
    </Box>
}

export default SpaceAvailabilityContent;
