import { RadioGroup, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import { GroupRange } from "interfaces/space"
import SpacePeopleRange from "./SpacePeopleRange"

interface SpacePeopleRangesProps {
    ranges: GroupRange[]
    selectedRangeIndex: number | null
    setSelectedRangeIndex: (index: number | null) => void
    setSelectedRange: (range: GroupRange | null) => void
    selectPeople: (people: number) => void
}

const SpacePeopleRanges: React.FC<SpacePeopleRangesProps> = (props) => {
    const { ranges, selectedRangeIndex, setSelectedRangeIndex, setSelectedRange, selectPeople } = props    
    const theme = useTheme()
    const isSM = useMediaQuery(theme.breakpoints.down('md'))
    const titleVariant = isSM ? 'titleMedium' : 'titleLarge'
    const t = useTranslate()
    const title = t('previewDetails.spaceSelectPeopleRange')
    
    const onPeopleRangeChanedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value)
        const range = ranges[value]
        setSelectedRange(range)
        selectPeople(range.occupancy.maximum!)
        setSelectedRangeIndex(value)
    }

    if(ranges.length === 0) {
        return <></>
    }
    
    return <Stack spacing={2}>
        <Typography variant={titleVariant}>{title}</Typography>
        <RadioGroup
            value={selectedRangeIndex}
            onChange={onPeopleRangeChanedHandler}
            sx={{gap: '16px'}}
        >
            {
                ranges.map((range, index) => {
                    return <SpacePeopleRange
                        key={'people-range-'+index}
                        range={range}
                        rangeIndex={index}
                    />
                })
            }
        </RadioGroup>
    </Stack>
}

export default SpacePeopleRanges