import { Stack, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import CustomModal from "components/CustomModal"
import { CatalogEntry } from "interfaces/catalogEntry"
import { Group, GroupPackage, PaidService } from "interfaces/space"
import { formatNumberPrice, generateCatalogValues } from "utils"
import GroupDetailPreview from "./GroupDetailPreview"
import GroupDetailPackage from "./GroupDetailPackage"
import RoundedButton from "components/RoundedButton"

interface GroupDetailModalProps {
    group: Group | null
    canOpen: boolean
    closeModal: () => void
    onEditGroupClickHandler: (group: Group) => void
    onDuplicateGroupClickHandler: (group: Group) => void
    canCreateGroup: boolean
}

const GroupDetailModal: React.FC<GroupDetailModalProps> = (props) => {
    const { group, canOpen, closeModal, onEditGroupClickHandler, onDuplicateGroupClickHandler, canCreateGroup } = props

    const t = useTranslate()
    const amenitiesTitle = t("updateSpace.spacePackagesAmenitiesTitle")
    const paidServicesTitle = t("updateSpace.spacePaidServicesTitle")
    const mandatoryLabel = t("updateSpace.spacePaidServicesDialogMandatory")
    const byPersonLabel = t("updateSpace.spacePaidServicesByPerson")
    const byEventLabel = t("updateSpace.spacePaidServicesByEvent")
    const guaranteeDepositTitle = t("updateSpace.spaceGuaranteeDepositTitle")
    const packagesTitle = t("updateSpace.menuPackages")
    const editLabel = t("buttons.edit")
    const duplicateLabel = t("updateSpace.duplicate")

    if(group === null) {
        return <></>
    }

    const onEditClickHandler = () => {
        closeModal()
        onEditGroupClickHandler(group)
    }

    const onDuplicateClickHandler = () => {
        onDuplicateGroupClickHandler(group)
        closeModal()
    }

    const generatePaidServiceDescription = (paidService: PaidService): string => {
        let description = paidService.name
        description += paidService.mandatory ? ' (' + mandatoryLabel + ')' : ''
        description += ': '
        description += formatNumberPrice(paidService.price) + ' '
        description += paidService.isByPerson ? byPersonLabel : byEventLabel
        return description
    }

    const amenities = generateCatalogValues(group.amenities as CatalogEntry[])

    return <CustomModal 
        sxContainer={{maxWidth: '876px'}}
        sxCloseButton={{top: '16px', right: '16px'}}
        canOpen={canOpen}
        onCloseModal={closeModal}
    >
        <Stack>
            <Stack 
                spacing={3}
                sx={{
                    height: '100%', 
                    maxHeight: {xs: 'calc(100vh - 120px)', sm: '560px'}, 
                    overflow: 'auto',
                    mr: -2
                }}
            >
                <GroupDetailPreview group={group}/>
                {
                    amenities && <Stack spacing={1}>
                        <Typography variant="titleLarge">{amenitiesTitle}</Typography>
                        <Typography variant="bodyLarge">{amenities}</Typography>
                    </Stack>
                }
                {
                    group.paidServices.length > 0 && <Stack spacing={1}>
                        <Typography variant="titleLarge">{paidServicesTitle}</Typography>
                        <Stack>
                            {
                                group.paidServices.map((paidService: PaidService, index: number) => {
                                    const description = generatePaidServiceDescription(paidService)
                                    return <Typography key={'group-detail-paid-service-' + index} variant="bodyLarge">{description}</Typography>
                                })
                            }
                        </Stack>
                    </Stack>
                }
                {
                    (group.guaranteeDeposit || group.guaranteeDeposit === 0) && <Stack spacing={1}>
                        <Typography variant="titleLarge">{guaranteeDepositTitle}</Typography>
                        <Typography variant="bodyLarge">{formatNumberPrice(group.guaranteeDeposit)}</Typography>
                    </Stack>
                }
                {
                    group.packages.length > 0 && <Stack spacing={1}>
                        <Typography variant="titleLarge">{packagesTitle}</Typography>
                        <Stack spacing={2}>
                        {
                            group.packages.map((packageData: GroupPackage, index: number) => {
                                return <GroupDetailPackage 
                                        key={'group-detail-package-' + index}
                                        packageData={packageData}
                                        workingTimes={group.workingTimes}
                                        priceType={group.priceType}
                                    />
                            })
                        }
                        </Stack>
                    </Stack>
                }
            </Stack>
            <Stack sx={{mt: 4}} direction={'row'} spacing={4} justifyContent={'flex-end'}>
                <RoundedButton
                    variant="outlined"
                    sx={{width: 'max-content'}}
                    onClick={onEditClickHandler}
                >
                    <Typography variant="labelLarge">{editLabel}</Typography>
                </RoundedButton>
                <RoundedButton
                    disabled={!canCreateGroup}
                    variant="contained"
                    sx={{width: 'max-content'}}
                    onClick={onDuplicateClickHandler}
                >
                    <Typography variant="labelLarge">{duplicateLabel}</Typography>
                </RoundedButton>
            </Stack>
        </Stack>
    </CustomModal>
}

export default GroupDetailModal