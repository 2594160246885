import { Box, IconButton, Stack, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import { useTranslate } from "@pankod/refine-core"
import { checkTimeHasMissingHours } from "utils"
import GroupWorkingTimesDayTimeSelect from "./GroupWorkingTimesDayTimeSelect"

interface GroupWorkingTimesDayTimeProps {
    index: number
    time: string
    maxHours: number
    updateTime: (index: number, time: string) => void
    deleteTime: (index: number) => void
}

const GroupWorkingTimesDayTime: React.FC<GroupWorkingTimesDayTimeProps> = (props) => {
    const { index, time, maxHours, updateTime, deleteTime } = props
    const hours = time.split('-')

    const t = useTranslate()
    const rangeBetweenLabel = t("updateSpace.spaceWorkingDaysRangeBetween")
    const hoursMissingError = t("updateSpace.spaceTimeHoursMissingError")
    const andLabel = t("updateSpace.andText")
    const betweenLabel = rangeBetweenLabel.replace('${eventNumber}', (index + 1).toString())

    const onDeleteTimeHandler = () => {
        deleteTime(index)
    }

    const hasMissingHours = checkTimeHasMissingHours(time, maxHours)

    let errorText = '' 
    if(hasMissingHours) {
        errorText = hoursMissingError
    }

    return <Stack>
        <Box sx={{
            mt: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            flexWrap: 'wrap'
        }}>
            <Typography variant="bodyLarge">{betweenLabel}</Typography>
            <GroupWorkingTimesDayTimeSelect 
                hour={hours[0]}
                updateHour={(newHour: string) => {
                    const arrTime = time.split('-')
                    const newTime = newHour + '-' + arrTime[1]
                    updateTime(index, newTime)
                }}
            />
            <Typography variant="bodyLarge">{andLabel}</Typography>
            <GroupWorkingTimesDayTimeSelect 
                hour={hours[1]}
                updateHour={(newHour: string) => {
                    const arrTime = time.split('-')
                    const newTime = arrTime[0] + '-' + newHour
                    updateTime(index, newTime)
                }}
            />
            <IconButton
                onClick={onDeleteTimeHandler}    
            >
                <CloseIcon sx={{
                    color: "onSurface.light"
                }}/>
            </IconButton>
        </Box>
        { errorText && <Typography color='error' variant='labelLarge'>{errorText}</Typography> }
    </Stack>
}

export default GroupWorkingTimesDayTime