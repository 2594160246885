import React, {MutableRefObject} from "react";
import { NumericFormat }  from "react-number-format";

interface NumberFormatCustomProps {
    name: string
    inputRef: MutableRefObject<any>
    onChange: (target: {}) => void
}

const NumberFormatCustom: React.FC<any> = (props) => {

    const {inputRef, onChange, ...other} = props;

    return <NumericFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value
                }
            });
        }}
        thousandSeparator
    />
}

export default NumberFormatCustom