export const TypographyList = {
    displayLarge: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '57px',
        lineHeight: '64px'
    },
    displayMedium: {
        fontFamily: 'Montserrat',
        fontWeight: 400,
        fontSize: '45px',
        lineHeight: '52px'
    },
    displaySmall: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '36px',
        lineHeight: '44px'
    },
    headlineLarge: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '32px',
        lineHeight: '40px'
    },
    headlineMedium: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '36px'
    },
    headlineSmall: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '32px'
    },
    titleLarge: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '22px',
        lineHeight: '28px'
    },
    titleMedium: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px'
    },
    titleSmall: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px'
    },
    labelLarge: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px'
    },
    labelMedium: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px'
    },
    labelSmall: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: '11px',
        lineHeight: '16px'
    },
    bodyLarge: {
        fontFamily: 'Montserrat',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px'
    },
    bodyMedium: {
        fontFamily: 'Montserrat',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px'
    },
    bodySmall: {
        fontFamily: 'Montserrat',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px'
    },
    menu: {
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '20px'
    }
}