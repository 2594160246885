
import { Box, Button, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import RoundedButton from "components/RoundedButton"

interface GroupFormActionsProps {
    onCloseHandler: () => void
    onSaveHandler: () => void
    isValid: () => boolean
    isLoading: boolean
}

const GroupFormActions: React.FC<GroupFormActionsProps> = (props) => {
    const { onCloseHandler, onSaveHandler, isValid, isLoading } = props
    
    const t = useTranslate()
    const cancelText = t("buttons.cancel")
    const saveText = t("buttons.save")

    return <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 5,
        mb: 5
    }}>
        <Button 
            sx={{mr: 3}} 
            color="primary" 
            variant='text'
                onClick={onCloseHandler}>
            <Typography variant="labelLarge">
                {cancelText}
            </Typography>
        </Button>
        <RoundedButton
            variant="contained"
            disabled={!isValid() || isLoading}
            onClick={onSaveHandler}>
            <Typography variant='labelLarge'>
                {saveText}
            </Typography>
        </RoundedButton>
    </Box>
}

export default GroupFormActions
