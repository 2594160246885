import { Box } from "@pankod/refine-mui";
import { EPaymentStatus } from "enums";
import { IPayment } from "interfaces";
import { Label, Row, Text } from "./components";
import { useTranslate } from "@pankod/refine-core";
import PaymentCardInfo from "./paymentCardInfo";
import PaymentStatus from "./paymentStatus";
import { formatDateString, formatNumberPrice, formatTimeString } from "utils";

interface PaymentProps {
    payment: IPayment;
    index: number;
    totalPayments: number;
}

const Payment: React.FC<PaymentProps> = (props) => {
    const { payment, index, totalPayments } = props;
    const t = useTranslate();
    const paymentLabel = index === 0 ? t('reservation.initialPayment') : t('reservation.payment') + ' ' + (index + 1);
    const paymentNumberLabel = '(' + (index + 1) + ' ' + t('reservation.of') + ' ' + totalPayments + ')';
    let paymentStatusName = '';
    let paymentStatusColor = '';
    const paymentsAmount = payment.pay_status === EPaymentStatus.PAID && payment.lateFee ? payment.amount + payment.lateFee : payment.amount;

    switch(payment.pay_status) {
        case EPaymentStatus.PAID:
            paymentStatusName = 'Pagado';
            paymentStatusColor = 'green.main';
            break;

        case EPaymentStatus.TO_PAY:
            paymentStatusName = 'Por pagar';
            paymentStatusColor = 'neutral10.main';
            break;

        case EPaymentStatus.PENDING:
            paymentStatusName = 'Pago pendiente';
            paymentStatusColor = 'errorRef.main';
            break;

        case EPaymentStatus.EXPIRED:
            paymentStatusName = 'Vencido';
            paymentStatusColor = 'errorRef.main';
            break;
    }

    return <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px', padding: '4px 0px 16px 0px', borderBottom: '1px dashed', borderBottomColor: 'primary.main'}}>
        <Row>
            <Label>{paymentLabel} {paymentNumberLabel}</Label>
        </Row>
        {
            payment.pay_status === EPaymentStatus.PAID && <PaymentCardInfo
                cardbrand={payment.cardbrand!}
                cardLast4={payment.cardLast4!}
            ></PaymentCardInfo>
        }
        <Row>
            <Label>{t('reservation.status')}</Label>
            <PaymentStatus
                paymentStatus={payment.pay_status}
            ></PaymentStatus>
        </Row>
        <Row>
            <Label>{t('reservation.amount')}</Label>
            <Text>{formatNumberPrice( paymentsAmount / 100, true)}</Text>
        </Row>
        {
            payment.pay_status === EPaymentStatus.PAID && <>
                <Row>
                    <Label>{t('reservation.paymentDate')}</Label>
                    <Text>{formatDateString(payment.paymentDate!)}</Text>
                </Row>
                <Row>
                    <Label>{t('reservation.paymentTime')}</Label>
                    <Text>{formatTimeString(payment.paymentDate!)}</Text>
                </Row>
            </>
        }
        {
            payment.pay_status !== EPaymentStatus.PAID && <Row>
                <Label>{t('reservation.paymentDueDate')}</Label>
                <Text>{formatDateString(payment.paymentDueDate!)}</Text>
            </Row>
        }
        {
            payment.pay_status === EPaymentStatus.EXPIRED && <Row>
                <Label>{t('reservation.lateFeeAmount')}</Label>
                <Text>{formatNumberPrice(payment.lateFee! / 100, true)}</Text>
            </Row>
        }
    </Box>
}

export default Payment;