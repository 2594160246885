import React, {ChangeEvent, useCallback, useEffect, useState} from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import FormControlLabel from "@mui/material/FormControlLabel"
import {Checkbox} from "@mui/material"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import { CatalogEntry } from "interfaces/catalogEntry"
import { useTranslate } from "@pankod/refine-core"
import { useAppSelector } from "store/hooks"
import { intersection, removeAllFrom } from "utils"
import GroupPackageAmenitiesModal from "./GroupPackageAmenitiesModal"
import MultiSelectChipGroup from "components/MultiSelectChipGroup"

interface GroupPackagesAmenitiesFormProps {
    groupAmenities?: CatalogEntry[]
    previous: CatalogEntry[] | null
    onChange: (amenities: CatalogEntry[]) => void
}

const GroupPackagesAmenitiesForm: React.FC<GroupPackagesAmenitiesFormProps> = (props) => {
    const {groupAmenities, previous, onChange} = props

    const t = useTranslate()
    const amenitiesTitle = t("updateSpace.spacePackagesAmenitiesTitle")
    const amenitiesSubtitle = t("updateSpace.spacePackagesAmenitiesSubtitle")
    const addAmenities = t("updateSpace.spaceAddAmenities")

    const allAmenityOptions: CatalogEntry[] = useAppSelector(state => state.catalog.amenities)
    const [openAmenities, setOpenAmenities] = useState(false)

    const [amenitiesSelectedInSpace, setAmenitiesSelectedInSpace] = useState<CatalogEntry[]>([])
    const [checkedAmenitiesSelectedInSpace, setCheckedAmenitiesSelectedInSpace] = useState<CatalogEntry[]>([])

    const [amenitiesNotSelectedInSpace, setAmenitiesNotSelectedInSpace] = useState<CatalogEntry[]>([])
    const [checkedAmenitiesNotSelectedInSpace, setCheckedAmenitiesNotSelectedInSpace] = useState<CatalogEntry[]>([])

    useEffect(() => {
        if (groupAmenities !== undefined && groupAmenities.length > 0) {
            const amenitiesInSpace = groupAmenities
            if (previous !== null) { // space has amenitites and is editing package
                setAmenitiesSelectedInSpace([...amenitiesInSpace])
                setCheckedAmenitiesSelectedInSpace(intersection(amenitiesInSpace, previous))
                const withoutAmenitiesSelectedInSpace = removeAllFrom(allAmenityOptions, amenitiesInSpace)
                setAmenitiesNotSelectedInSpace(withoutAmenitiesSelectedInSpace)
                setCheckedAmenitiesNotSelectedInSpace(intersection(withoutAmenitiesSelectedInSpace, previous))
            } else { // space has amenitites and is new package
                setAmenitiesSelectedInSpace([...amenitiesInSpace])
                setCheckedAmenitiesSelectedInSpace([...amenitiesInSpace])
                const withoutAmenitiesSelectedInSpace = removeAllFrom(allAmenityOptions, amenitiesInSpace)
                setAmenitiesNotSelectedInSpace(withoutAmenitiesSelectedInSpace)
            }
        } else {
            if (previous !== null) { // space amenitites is empty and is editing package
                setAmenitiesNotSelectedInSpace([...allAmenityOptions])
                setCheckedAmenitiesNotSelectedInSpace([...previous])
            } else { // space amenitites is empty and is new package
                setAmenitiesNotSelectedInSpace([...allAmenityOptions])
            }
        }
    }, [])

    useEffect(() => {
        onChange([...checkedAmenitiesSelectedInSpace, ...checkedAmenitiesNotSelectedInSpace])
    }, [onChange, checkedAmenitiesSelectedInSpace, checkedAmenitiesNotSelectedInSpace])

    const onAmenityCheckedChanged = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean, entry: CatalogEntry) => {
        const newArray = [...checkedAmenitiesSelectedInSpace]
        if (checked) {
            newArray.push(entry)
        } else {
            newArray.splice(checkedAmenitiesSelectedInSpace.findIndex(catalogEntry => catalogEntry.id === entry.id), 1)
        }
        setCheckedAmenitiesSelectedInSpace(newArray)
    }, [checkedAmenitiesSelectedInSpace])

    const onCloseAmenitiesHandler = useCallback(() => {
        setOpenAmenities(false)
    }, [])

    const onSaveAmenities = useCallback((selected: CatalogEntry[]) => {
        setOpenAmenities(false)
        const newArray = [...selected]
        setCheckedAmenitiesNotSelectedInSpace(newArray)
    }, [])

    const onAdditionalAmenitiesChangeHandler = useCallback((selected: CatalogEntry[]) => {
        const newArray = [...selected]
        setCheckedAmenitiesNotSelectedInSpace(newArray)
    }, [])

    const addAmenityButtonHandler = useCallback(() => {
        setOpenAmenities(true)
    }, [])

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column'
    }}>
        <Typography sx={{mt: 3}} variant='titleMedium'>{amenitiesTitle}</Typography>
        <Typography sx={{mt: 1}} variant='bodyMedium'>{amenitiesSubtitle}</Typography>
        {amenitiesSelectedInSpace.length > 0 &&
            <Grid container spacing={3} sx={{mt: 1}}>
                {amenitiesSelectedInSpace.map((amenity) => {
                    const catalogEntry = (amenity as CatalogEntry)
                    return <Grid item md={4} key={catalogEntry.id} sx={{pt: '8px !important'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkedAmenitiesSelectedInSpace.includes(catalogEntry)}
                                    onChange={(event, checked) => {
                                        onAmenityCheckedChanged(event, checked, catalogEntry)
                                    }}/>
                            }
                            label={catalogEntry.value}/>
                    </Grid>
                })}
            </Grid>
        }
        {amenitiesNotSelectedInSpace.length > 0 &&
            <>
                <GroupPackageAmenitiesModal
                    options={amenitiesNotSelectedInSpace}
                    selectedAmenitiesNotInSpace={checkedAmenitiesNotSelectedInSpace}
                    open={openAmenities}
                    onClose={onCloseAmenitiesHandler}
                    onSave={onSaveAmenities}
                />
                <Button
                    startIcon={<AddIcon/>}
                    variant='text'
                    id="add_amenity_button"
                    sx={{mt: 2, width: 'max-content'}}
                    onClick={addAmenityButtonHandler}>
                    <Typography variant='labelLarge'>{addAmenities}</Typography>
                </Button>
            </>
        }
        {
            checkedAmenitiesNotSelectedInSpace.length > 0 && <MultiSelectChipGroup
                sx={{mt: 1}}
                items={checkedAmenitiesNotSelectedInSpace}
                selections={checkedAmenitiesNotSelectedInSpace}
                onSelectionsUpdated={onAdditionalAmenitiesChangeHandler}
            />
        }
    </Box>
}

export default GroupPackagesAmenitiesForm