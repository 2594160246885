import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Chip} from "@mui/material";
import moment from "moment/moment";
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from "@mui/material/styles";

interface DayChipGroupProps {
    days: string[]
    onDayRemoved: (date: string) => void
}

const DayChipGroup: React.FC<DayChipGroupProps> = (props) => {
    const {days, onDayRemoved} = props
    const theme = useTheme()

    return <Grid>
        {days.map((item) => {
            const formattedDate = moment(item, "YYYY/MM/DD").format('D MMM');
            return <Chip
                key={`date-${item}`}
                sx={{borderRadius: '8px', mx: 1, mt: 1, color: 'secondaryContainer.main'}}
                onDelete={() => {
                    onDayRemoved(item)
                }}
                deleteIcon={<CloseIcon sx={{"&&": {color: theme.palette.onSecondaryContainer.main}}}/>}
                label={
                    <Typography
                        variant="labelLarge"
                        color={theme.palette.onSecondaryContainer.main}>
                        {formattedDate}
                    </Typography>}
                variant="filled"
            />
        })}
    </Grid>
}

export default DayChipGroup